import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { withStyles } from '@material-ui/core/styles';
import { withStylesPropTypes } from '../../helper/misc';

const useStyles = (() => ({
  root: {
    fontWeight: 'bold',
    padding: 0,
    '&:hover $icon': {
      display: 'inherit',
    },
  },
  icon: {
    display: 'none',
  },
  label: {
    padding: 0,
    justifyContent: 'left',
  },
}));

function SortableTableHeader({
  classes,
  label,
  dataKey,
  sort,
  onSort,
}) {
  const handleClick = () => {
    let direction = 1;
    if (sort.key === dataKey) {
      if (sort.direction === 1) direction = -1;
    }
    onSort({ key: dataKey, direction });
  };
  return (
    <Button
      className={classes.root}
      classes={{
        label: classes.label,
        endIcon: clsx((sort.key !== dataKey || sort.direction === 0) && classes.icon),
      }}
      variant="text"
      size="small"
      fullWidth
      endIcon={sort.key === dataKey && sort.direction === -1 ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
      onClick={handleClick}
    >
      {label}
    </Button>
  );
}

SortableTableHeader.defaultProps = {
  label: '',
  sort: { direction: 0 },
  dataKey: '',
  onSort: () => {},
};

SortableTableHeader.propTypes = {
  ...withStylesPropTypes,
  label: PropTypes.string,
  dataKey: PropTypes.string,
  sort: PropTypes.shape({
    key: PropTypes.string,
    direction: PropTypes.number,
  }),
  onSort: PropTypes.func,
};
export default withStyles(useStyles)(SortableTableHeader);
