import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import { withStylesPropTypes, formatDate } from '../../helper/misc';
import { selectSelectedPayloadsWithHistoryAndSelectedStation, selectPayloadSaving, selectPayloadError } from '../../app/payloadSelectors';
import { selectListById } from '../../app/listSelectors';
import { selectCurrentLocationAndField } from '../../app/locationsSelectors';
import { selectGeofencesProperty } from '../../app/geofencesSelectors';
import { rejectLocationChanges } from '../../app/payloadSlice';
import { getLocationFieldStationData, isFieldValueApproved, isCurrentSubstation } from '../../helper/payloadmisc';
import Loading from '../common/loading';
import Error from '../common/error';
import AutoFields from './autofields';
import Flags from './flags';
import StationErrorList from './stationerrorlist';
import Geofences from './geofences';

const useStyles = ((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  summaryPanel: {
    margin: '0 !important',
  },
  summaryTitleRoot: {
    backgroundColor: theme.palette.background.header,
    lineHeight: `${theme.spacing(4)}px`,
    height: theme.spacing(4),
    minHeight: `${theme.spacing(4)}px !important`,
    flexFlow: 'row-reverse',
    padding: `0 ${theme.spacing(3)}px 0 0`,
  },
  summaryTitleContent: {
    margin: '0 !important',
  },
  summaryTitleIcon: {
    margin: 0,
    padding: theme.spacing(0.5),
    width: theme.spacing(2),
  },
  summaryDetailsRoot: {
    flexDirection: 'column',
    padding: `${theme.spacing(1)}px 0px`,
  },
  value: {
    color: theme.palette.primary.main,
  },
  sectionTitle: {
    fontSize: '1em',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    lineHeight: `${theme.spacing(2)}px`,
    position: 'relative',
    '&:before': {
      content: '\'\'',
      position: 'absolute',
      borderBottom: `solid 1px ${theme.palette.border.primary}`,
      width: '100%',
      top: theme.spacing(1),
      zIndex: 1,
    },
    '&>span': {
      zIndex: 2,
      backgroundColor: theme.palette.background.paper,
      position: 'absolute',
      top: 0,
      marginLeft: theme.spacing(2),
      padding: `0px ${theme.spacing(1)}px`,
      textTransform: 'uppercase',
    },
  },
  section: {
    padding: `${theme.spacing(3)}px`,
  },
  headersRows: {
    fontWeight: 'bold',
  },
  cellValues: {
    marginTop: theme.spacing(2),
  },
  cellErrors: {
    marginTop: theme.spacing(2),
    color: theme.palette.error.main,
  },
}));

const GEOFENCES_SECTION_NAME = 'geofences';

function SummaryWidgetQA({
  classes,
  payloads,
  listsById,
  isSaving,
  error,
  selectedCell,
  property,
  fnRejectLocationChanges,
}) {
  const [selectedSection, setSelectedSection] = useState(null);

  useEffect(() => {
    if (payloads && payloads.length) {
      setSelectedSection(payloads[0]._id);
    }
  }, [payloads]);

  useEffect(() => {
    if (property) setSelectedSection(GEOFENCES_SECTION_NAME);
  }, [property]);

  const handleReject = (change) => {
    fnRejectLocationChanges([{
      _id: selectedCell.location._id,
      [change.field]: change.original,
    }],
    change.station,
    change.substation);
  };

  return (
    <>
      { isSaving && (<Loading text="Saving..." />) }
      { error && (<Error message={error.message} />) }
      <div className={classes.root}>
        { property && selectedCell && selectedCell.location && property._id === selectedCell.location.propertyId && (
          <ExpansionPanel classes={{ root: classes.summaryPanel }} expanded={GEOFENCES_SECTION_NAME === selectedSection} onChange={(e, expanded) => setSelectedSection(expanded ? GEOFENCES_SECTION_NAME : undefined)}>
            <ExpansionPanelSummary
              classes={{ root: classes.summaryTitleRoot, content: classes.summaryTitleContent, expandIcon: classes.summaryTitleIcon }}
              expandIcon={<ExpandMoreIcon />}
            >
              Geofences
            </ExpansionPanelSummary>
            <ExpansionPanelDetails classes={{ root: classes.summaryDetailsRoot }}>
              <Geofences />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )}
        {
          payloads.map((payload) => {
            const list = listsById[payload.listId];
            const cellStationData = (selectedCell && payload.selectedStation) && getLocationFieldStationData(selectedCell.location, selectedCell.field.data, payload.selectedStation);
            const cellStationErrors = cellStationData && cellStationData.filter((d) => !!d.error && !isFieldValueApproved(d)).reduce((errors, data) => (typeof data.error === 'string' ? [...errors, data.error] : [...errors, ...data.error]), []);
            const cellStationChange = cellStationData && cellStationData.find((d) => !!(d.current || d.original));
            const canReject = cellStationChange && isCurrentSubstation(payload, cellStationChange.station, cellStationChange.substation) && !isFieldValueApproved(cellStationChange);
            return (
              <ExpansionPanel key={payload._id} classes={{ root: classes.summaryPanel }} expanded={payload._id === selectedSection} onChange={(e, expanded) => setSelectedSection(expanded ? payload._id : undefined)}>
                <ExpansionPanelSummary
                  classes={{ root: classes.summaryTitleRoot, content: classes.summaryTitleContent, expandIcon: classes.summaryTitleIcon }}
                  expandIcon={<ExpandMoreIcon />}
                >
                  { `${(list ? list.shortName : '')} Summary (${formatDate(payload.scrapedAt)})` }
                </ExpansionPanelSummary>
                <ExpansionPanelDetails classes={{ root: classes.summaryDetailsRoot }}>
                  {
                  (selectedCell && selectedCell.location.payloadId === payload._id) && (
                    <>
                      <h3 className={classes.sectionTitle}><span>Select Cell</span></h3>
                      <div className={classes.section}>
                        <Grid container spacing={1} className={classes.headersRows}>
                          <Grid item xs={2}>ALI</Grid>
                          <Grid item xs={2}>Field</Grid>
                          <Grid item xs={4}>Dev Error</Grid>
                          <Grid item xs={4}>Scrape Error</Grid>
                        </Grid>
                        <Grid container spacing={1} alignItems="center">
                          <Grid item xs={2}>{selectedCell.location.ali}</Grid>
                          <Grid item xs={2}>{selectedCell.field.match}</Grid>
                          <Grid item xs={4}>
                            <Input
                              fullWidth
                              classes={{ input: classes.value }}
                              placeholder="Enter text"
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <Input
                              fullWidth
                              classes={{ input: classes.value }}
                              placeholder="Enter text"
                            />
                          </Grid>
                        </Grid>
                        { cellStationErrors && (
                          <div className={classes.cellErrors}>
                            <StationErrorList issueList={cellStationErrors} />
                          </div>
                        )}
                        {
                          cellStationChange && (
                            <div className={classes.cellValues}>
                              <Grid container spacing={1} className={classes.headersRows}>
                                <Grid item xs={4}>Before</Grid>
                                <Grid item xs={4}>After</Grid>
                              </Grid>
                              <Grid container spacing={1} alignItems="center">
                                <Grid item xs={4}>{cellStationChange.original}</Grid>
                                <Grid item xs={4}>{cellStationChange.current}</Grid>
                                <Grid item xs={2}>
                                  { canReject && <Button type="button" variant="outlined" color="secondary" size="small" onClick={() => handleReject(cellStationChange)}>Reject</Button> }
                                </Grid>
                              </Grid>
                            </div>
                          )
                        }
                      </div>
                    </>
                  )
                }
                  <h3 className={classes.sectionTitle}><span>Summary</span></h3>
                  <div className={classes.section}>
                    <Grid container spacing={1}>
                      <Grid item xs={2}>Payload</Grid>
                      <Grid item xs={10}><span className={classes.value}>{formatDate(payload.scrapedAt)}</span></Grid>
                      <Grid item xs={2}>Source URL</Grid>
                      <Grid item xs={10}><a target="_blank" rel="noopener noreferrer" className={classes.value} href={list ? `http://${list.sourceUrl}` : ''}>{list ? list.sourceUrl : ''}</a></Grid>
                    </Grid>
                  </div>
                  <h3 className={classes.sectionTitle}><span>Auto Fields</span></h3>
                  <div className={classes.section}>
                    <AutoFields payload={payload} />
                  </div>
                  <h3 className={classes.sectionTitle}><span>Flags</span></h3>
                  <div className={classes.section}>
                    <Flags payload={payload} />
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            );
          })
        }
      </div>
    </>
  );
}


SummaryWidgetQA.propTypes = {
  ...withStylesPropTypes,
};

const mapStateToProps = (state, props) => ({
  payloads: selectSelectedPayloadsWithHistoryAndSelectedStation(state, props),
  listsById: selectListById(state, props),
  isSaving: selectPayloadSaving(state, props),
  error: selectPayloadError(state, props),
  selectedCell: selectCurrentLocationAndField(state, props),
  property: selectGeofencesProperty(state, props),
});

export default connect(mapStateToProps, {
  fnRejectLocationChanges: rejectLocationChanges,
})(withStyles(useStyles)(SummaryWidgetQA));
