import KeyCode from 'key-code';

export default (e, callbacks) => {
  const handle = (cb) => {
    let callback = null;
    let stopPropagation = true;
    let preventDefault = true;
    if (Array.isArray(cb)) {
      [callback, stopPropagation, preventDefault] = cb;
    } else {
      callback = cb;
    }
    if (preventDefault) e.preventDefault();
    if (stopPropagation) e.stopPropagation();
    callback(e);
  };
  if (e.ctrlKey && e.keyCode === KeyCode.F && callbacks.onSearch) handle(callbacks.onSearch);
  else if (e.ctrlKey && e.keyCode === KeyCode.H && callbacks.onFindReplace) handle(callbacks.onFindReplace);
  else if (e.shiftKey && e.keyCode === KeyCode.ENTER && callbacks.onShiftEnter) handle(callbacks.onShiftEnter);
  else if (e.ctrlKey && e.keyCode === KeyCode.C && callbacks.onCopy) handle(callbacks.onCopy);
  else if (e.ctrlKey && e.keyCode === KeyCode.V && callbacks.onPaste) handle(callbacks.onPaste);
  else if (e.ctrlKey && e.keyCode === KeyCode.X && callbacks.onCut) handle(callbacks.onCut);
  else if (e.ctrlKey && e.keyCode === KeyCode.A && callbacks.onSelectAll) handle(callbacks.onSelectAll);
  else if (e.ctrlKey && e.keyCode === KeyCode.Z && callbacks.onUndo) handle(callbacks.onUndo);
  else if (e.ctrlKey && e.keyCode === KeyCode.M && callbacks.onCtrlM) handle(callbacks.onCtrlM);
  else if (e.keyCode === KeyCode.TAB && callbacks.onTab) handle(callbacks.onTab);
  else if (e.keyCode === KeyCode.RIGHT && callbacks.onRight) handle(callbacks.onRight);
  else if (e.keyCode === KeyCode.LEFT && callbacks.onLeft) handle(callbacks.onLeft);
  else if (e.keyCode === KeyCode.UP && callbacks.onUp) handle(callbacks.onUp);
  else if (e.keyCode === KeyCode.DOWN && callbacks.onDown) handle(callbacks.onDown);
  else if (e.keyCode === KeyCode.ENTER && callbacks.onEnter) handle(callbacks.onEnter);
  else if (e.keyCode === KeyCode.SPACEBAR && callbacks.onSpaceBar) handle(callbacks.onSpaceBar);
  else if (e.keyCode === KeyCode.ESC && callbacks.onEsc) handle(callbacks.onEsc);
  else if (e.keyCode === KeyCode.F1 && callbacks.onF1) handle(callbacks.onF1);
  else if (e.keyCode === KeyCode.F2 && callbacks.onF2) handle(callbacks.onF2);
  else if (e.keyCode === KeyCode.F3 && callbacks.onF3) handle(callbacks.onF3);
  else if (e.keyCode === KeyCode.F4 && callbacks.onF4) handle(callbacks.onF4);
  else if (e.keyCode === KeyCode.F5 && callbacks.onF5) handle(callbacks.onF5);
  else if (e.keyCode === KeyCode.F6 && callbacks.onF6) handle(callbacks.onF6);
  else if (e.keyCode === KeyCode.F7 && callbacks.onF7) handle(callbacks.onF7);
  else if (e.keyCode === KeyCode.F8 && callbacks.onF8) handle(callbacks.onF8);
  else if (e.keyCode === KeyCode.F9 && callbacks.onF9) handle(callbacks.onF9);
  else if (e.keyCode === KeyCode.F10 && callbacks.onF10) handle(callbacks.onF10);
  else if (e.keyCode === KeyCode.F11 && callbacks.onF11) handle(callbacks.onF11);
  else if (e.keyCode === KeyCode.F12 && callbacks.onF12) handle(callbacks.onF12);
  else if (e.keyCode === KeyCode.DELETE && callbacks.onDelete) handle(callbacks.onDelete);
  else if (callbacks.default) handle(callbacks.default);
};
