import React, { useState } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { Tabs, Tab } from '@material-ui/core';
import { withStylesPropTypes } from '../../helper/misc';
import NavigationALIMatching from './navigationalimatching';
import History from './history';
import ALIMatchingFilter from './alimatchingfilter';

const useStyles = ((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
    height: '100%',
  },
  tabBar: {
    flexGrow: '0',
    fontSize: '1.1rem',
    backgroundColor: theme.palette.background.toolbar,
  },
  tab: {
    display: 'block',
    flexGrow: '1',
    overflow: 'hidden',
    '& > div': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
  },
  hidden: {
    display: 'none !important',
  },
}));

const TABS = {
  NAVIGATION: 0,
  FILTERS: 1,
  HISTORY: 2,
};


function StationsBoardWidgetALIMatching({ classes }) {
  const [currentTab, setCurrentTab] = useState(TABS.NAVIGATION);


  return (
    <div className={classes.root}>
      <Tabs
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
        value={currentTab}
        onChange={(event, value) => setCurrentTab(value)}
        className={classes.tabBar}
      >
        <Tab label="Navigation" value={TABS.NAVIGATION} />
        <Tab label="Filters" value={TABS.FILTERS} />
        <Tab label="History" value={TABS.HISTORY} />
      </Tabs>

      <div className={classes.tab}>
        <div className={clsx(currentTab !== TABS.NAVIGATION && classes.hidden)}><NavigationALIMatching /></div>
        { currentTab === TABS.FILTERS && <ALIMatchingFilter /> }
        { currentTab === TABS.HISTORY && <History /> }
      </div>
    </div>
  );
}

StationsBoardWidgetALIMatching.propTypes = {
  ...withStylesPropTypes,
};

export default withStyles(useStyles)(StationsBoardWidgetALIMatching);
