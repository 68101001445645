import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { setSelectedPayloadHistory } from '../../app/payloadSlice';
import { withStylesPropTypes, useMountEffect } from '../../helper/misc';
import LOCK_RECORD_TYPE from '../../const/lockrecordtype';
import NavigationByPayloadAliMatching from './navigationbypayloadalimatching';

const useStyles = ((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    ...theme.mixins.header,
    fontSize: '1.1rem',
    '&>*': {
      flexGrow: 1,
      flexBasis: 0,
    },
    '&>button': {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      backgroundColor: 'transparent',
      border: 'none',
      textAlign: 'left',
      minHeight: theme.spacing(4),
      fontSize: 'inherit',
    },
  },
  activeButton: {
    color: theme.palette.primary.main,
    border: `solid 1px ${theme.palette.primary.main} !important`,
  },
  pane: {
    flexGrow: '1',
    minHeight: '0',
  },
}));

const NAVIGATION_BY = {
  FULL_HISTORY: 0,
  MOST_RECENT: 1,
  CUSTOM_DATE: 2,
};

function NavigationALIMatching({ classes, fnSetSelectedPayloadHistory }) {
  useMountEffect(() => {
    fnSetSelectedPayloadHistory();
  });

  const [navigationBy, setNavigationBy] = useState(NAVIGATION_BY.FULL_HISTORY);
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <button className={clsx(navigationBy === NAVIGATION_BY.FULL_HISTORY && classes.activeButton)} type="button" onClick={() => setNavigationBy(NAVIGATION_BY.FULL_HISTORY)}>
          Full History
        </button>
        <button className={clsx(navigationBy === NAVIGATION_BY.MOST_RECENT && classes.activeButton)} type="button" onClick={() => setNavigationBy(NAVIGATION_BY.MOST_RECENT)}>
          Most Recent
        </button>
        <button className={clsx(navigationBy === NAVIGATION_BY.CUSTOM_DATE && classes.activeButton)} type="button" onClick={() => setNavigationBy(NAVIGATION_BY.CUSTOM_DATE)}>
          Custom Date
        </button>
      </div>
      <div className={classes.pane}>
        { navigationBy === NAVIGATION_BY.FULL_HISTORY && <NavigationByPayloadAliMatching listsSelectable payloadsSelectable={false} lockRecordType={LOCK_RECORD_TYPE.BACKFILL_LIST} /> }
        { navigationBy === NAVIGATION_BY.MOST_RECENT && <NavigationByPayloadAliMatching listsSelectable payloadsSelectable={false} lockRecordType={LOCK_RECORD_TYPE.BACKFILL_LIST} /> }
        { navigationBy === NAVIGATION_BY.CUSTOM_DATE && <NavigationByPayloadAliMatching listsSelectable payloadsSelectable={false} lockRecordType={LOCK_RECORD_TYPE.BACKFILL_LIST} /> }
      </div>
    </div>
  );
}


NavigationALIMatching.propTypes = {
  ...withStylesPropTypes,
};

export default connect(null, {
  fnSetSelectedPayloadHistory: setSelectedPayloadHistory,
})(withStyles(useStyles)(NavigationALIMatching));
