export const COLORS = ['#ff0000', '#ff8800', '#008000', '#000080', '#800080'];

export const getColor = (index, opacity = 1) => {
  let color = COLORS[index % COLORS.length];
  if (opacity < 1) {
    const r = parseInt(color.substr(1, 2), 16);
    const g = parseInt(color.substr(3, 2), 16);
    const b = parseInt(color.substr(5, 2), 16);
    color = `rgba(${r},${g},${b},${opacity})`;
  }
  return color;
};
