import io from 'socket.io-client';
import config from '../config';
import LogAPI from './eventslog';

const socket = io(config.socketUrl);

const prevOnEvent = socket.onevent;
socket.onevent = (packet) => {
  const { data } = packet;
  if (data && data.length === 2) {
    const e = {
      event: data[0],
      data: data[1],
    };
    LogAPI.addLog('info', JSON.stringify(e));
  }
  prevOnEvent.apply(socket, [packet]);
};

export const JOB_STATUS = {
  DONE: 'done',
  FAILED: 'failed',
};

export const batchStatusListener = (event, batchId, onDone, onFailed) => {
  LogAPI.addLog('info', JSON.stringify({
    watingFor: batchId,
  }));

  const listener = (data) => {
    if (!Object.keys(batchId).some((field) => batchId[field] !== data[field])) {
      socket.off(event, listener);
      if (!data.status || data.status === JOB_STATUS.DONE) {
        if (onDone) onDone();
      } else if (data.status === JOB_STATUS.FAILED) {
        if (onFailed) onFailed({ message: data.statusMessage });
      }
    }
  };
  socket.on(event, listener);
};

export default socket;
