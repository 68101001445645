import { createSelector } from '@reduxjs/toolkit';
import { MATCH_TYPE_DEFAULT, GROUP_BY_DEFAULT, DISTANCE } from '../const/alimatch';

export const selectListsLoading = (state) => state.list.isLoading && { progress: state.list.loadingProgress };

export const selectListsError = (state) => state.list.error;

export const selectListById = (state) => state.list.byId;

export const selectListIds = (state) => state.list.allIds;

export const selectExpandedListIds = (state) => state.list.expandedListIds;

export const selectSelectedListId = (state) => state.list.selectedListId;

export const selectSelectedList = createSelector(
  [selectSelectedListId, selectListById],
  (listId, listById) => listById[listId],
);


export const selectLists = createSelector(
  [selectListIds, selectListById],
  (listIds, listById) => listIds.map((id) => listById[id]),
);


export const selectSelectedListMatchFilter = createSelector(
  [selectSelectedListId, selectListById],
  (listId, listById) => {
    let ret;
    if (listId) {
      const list = listById[listId];
      ret = (list || {}).matchFilter || GROUP_BY_DEFAULT;
    }
    return ret;
  },
);


export const selectSelectedListCortexMatchType = createSelector(
  [selectSelectedListId, selectListById],
  (listId, listById) => {
    let ret;
    if (listId) {
      const list = listById[listId];
      ret = (list || {}).cortexMatchType || MATCH_TYPE_DEFAULT;
    }
    return ret;
  },
);

export const selectSelectedListMatchDistance = createSelector(
  [selectSelectedListId, selectListById],
  (listId, listById) => {
    let ret;
    if (listId) {
      const list = listById[listId];
      ret = (list || {}).matchDistance || DISTANCE.MIN;
    }
    return ret;
  },
);
