import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { withStyles } from '@material-ui/core/styles';
import ListNameAutocomplete from './listnameautocomplete';
import { withStylesPropTypes } from '../../helper/misc';
import { STATION_STATUS } from '../../const/stations';
import { selectListsLoading, selectListsError, selectListIds } from '../../app/listSelectors';
import ListPayloadTable from './listpayloadtable';
import Loading from '../common/loading';
import Error from '../common/error';

const useStyles = ((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },

  searchBar: {
    flexGrow: 0,
    minHeight: theme.spacing(4),
    flexDirection: 'row',
    display: 'flex',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    alignItems: 'center',
    '&>div': {
      flexGrow: 1,
      flexBasis: 0,
      overflow: 'hidden',
    },
    '&>div:nth-child(2)': {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    '&>div:nth-child(3)': {
      flexBasis: theme.spacing(2),
    },
  },
  table: {
    flexGrow: 1,
  },
  sortOption: {
    color: theme.palette.primary.main,
    '&>.MuiButtonBase-root': {
      padding: 0,
    },
  },
}));

const FIELDS = {
  PAYLOADS: 'pendingPayloads',
  RECORDS: 'pendingRecords',
  TOTAL_RECORDS: 'records',
  LIST_ID: 'listId'
};


function NavigationByPayload({
  classes,
  listsLoading,
  listsError,
  anyListsLoaded,
  payloadsSelectable,
  listsSelectable,
  multiListSelect,
  extraHeaderComponent,
  extraPayloadComponentRenderer,
  lockRecordType,
}) {
  const [searchByName, setSearchByName] = useState('');

  return (
    <div className={classes.root}>
      {
        listsError && (<div className={classes.searchBar}><Error message={listsError.message} /></div>)
      }
      {
        listsLoading && (<Loading progress={listsLoading.progress} />)
      }
      {
        anyListsLoaded && !listsError && (
        <>
          <div className={classes.searchBar}>
            <div>LIST SELECTION</div>
            <div>
              <ListNameAutocomplete
                value={searchByName}
                onChange={(value) => setSearchByName(value ? value.shortName : null)}
              />
            </div>
            <div>{extraHeaderComponent}</div>
          </div>
          <div className={classes.table}>
            <ListPayloadTable
              nameFilter={searchByName}
              payloadsSelectable={payloadsSelectable}
              listsSelectable={listsSelectable}
              multiListSelect={multiListSelect}
              lockRecordType={lockRecordType}
              payloadsFilter={(p) => !!p.count}
              extraPayloadComponentRenderer={extraPayloadComponentRenderer}
              columns={[
                {
                  title: 'List IDs',
                  dataKey: FIELDS.LIST_ID,
                  type: 'number',
                  payloadCellRenderer: (p) => (<>{[STATION_STATUS.FAILED, STATION_STATUS.MANUAL].includes(p.status) ? 'Y' : 'N'}</>),
                  payloadFailed: (p) => p.status === STATION_STATUS.FAILED,
                },
                {
                  title: 'Pending Payloads',
                  dataKey: FIELDS.PAYLOADS,
                  type: 'number',
                  payloadCellRenderer: (p) => (<>{[STATION_STATUS.FAILED, STATION_STATUS.MANUAL].includes(p.status) ? 'Y' : 'N'}</>),
                  payloadFailed: (p) => p.status === STATION_STATUS.FAILED,
                }, {
                  title: 'Pending Records',
                  dataKey: FIELDS.RECORDS,
                  type: 'number',
                  payloadCellRenderer: (p) => {
                    let station = p.stations && p.stations[p.station];
                    if (station && p.subStation) {
                      const subStation = station[p.subStation];
                      if (subStation) station = subStation;
                    }
                    const pendingRecords = (station ? station.failed : undefined);
                    return <>{pendingRecords}</>;
                  },
                  payloadFailed: (p) => p.status === STATION_STATUS.FAILED,
                }, {
                  title: 'Record Counts',
                  dataKey: FIELDS.TOTAL_RECORDS,
                  type: 'number',
                  payloadCellRenderer: (p) => (<>{p.count}</>),
                  payloadFailed: (p) => p.status === STATION_STATUS.FAILED,
                },
              ]}
            />
          </div>
        </>
        )
      }
    </div>
  );
}

NavigationByPayload.defaultProps = {
  payloadsSelectable: true,
  listsSelectable: false,
  multiListSelect: false,
  extraHeaderComponent: null,
  extraPayloadComponentRenderer: null,
  lockRecordType: null,
};

NavigationByPayload.propTypes = {
  ...withStylesPropTypes,
  payloadsSelectable: PropTypes.bool,
  listsSelectable: PropTypes.bool,
  multiListSelect: PropTypes.bool,
  extraHeaderComponent: PropTypes.node,
  extraPayloadComponentRenderer: PropTypes.func,
  lockRecordType: PropTypes.string,
};

const selectAnyListsLoaded = createSelector(
  [selectListIds],
  (listIds) => !!listIds.length,
);

const mapStateToProps = (state, props) => ({
  listsLoading: selectListsLoading(state, props),
  listsError: selectListsError(state, props),
  anyListsLoaded: selectAnyListsLoaded(state, props),
});

export default connect(mapStateToProps, null)(withStyles(useStyles)(NavigationByPayload));
