import React, {useState, useContext} from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {HomeScreen} from '../../pages/home';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { CancelOutlined } from '@material-ui/icons';

const useStyles = ((theme) => ({
  button: {
    borderRadius: 0,
    boxShadow: 'none',
    height: '80%',
    whiteSpace: "nowrap",
  },
  selected: {
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  hidden: {
    display: 'none !important',
  },
}));

function FieldToMatchQA({
  classes, 
  fieldKey, 
  title,  
}) {

  const {singleColumn, setSingleColumn, allColumns, setAllColumns, isSubmit, addAll, setAddAll, qaTableColumns} = useContext(HomeScreen)   
  const handleClick = () => {
      if (singleColumn.includes(fieldKey))
      {
        let copyTrial = singleColumn;
        let fieldKeyIndex = copyTrial.indexOf(fieldKey);
        copyTrial.splice(fieldKeyIndex,1);
        setSingleColumn([...copyTrial]);
        if(allColumns.includes(fieldKey)){
          let copyArr = allColumns;
          let fieldArrKeyIndex = copyArr.indexOf(fieldKey);
          copyArr.splice(fieldArrKeyIndex,1);
          setAllColumns([...copyArr]);
        }
      }
      else{
        setSingleColumn([...singleColumn,fieldKey]);
      }
  };

  const handleAddAll = () => {
    setAddAll(!addAll);
    if(addAll){
      setAllColumns([]);
      setSingleColumn([]);
    }
    else{
      const filtered = qaTableColumns.reduce((a, o) => (o.title !== '' && a.push(o.title), a), []);   
      setAllColumns([...filtered]);
      setSingleColumn([...filtered]);
    }
  }

  return (
    <>
    {title !== '' &&
    <>
      { (singleColumn.includes(fieldKey)) ? <CancelOutlined onClick={handleClick}/> : <AddCircleOutlineIcon color='primary' onClick={handleClick}/>}
      </>
    }
    
      {title === '' &&
      <Button 
        color="primary"
        size="small"
        classes={{
          root: isSubmit ? clsx(classes.hidden): clsx(classes.button) ,
        }}
        onClick={handleAddAll}
      >
          {addAll ? 'REMOVE ALL -' : 'ADD ALL +'}
      </Button>

    }
    </>
  );
}



export default (withStyles(useStyles)(FieldToMatchQA));