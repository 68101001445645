import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { useTheme, withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import { VariableSizeList } from 'react-window';
import { connect } from 'react-redux';
import { selectLists } from '../../app/listSelectors';
import { withStylesPropTypes } from '../../helper/misc';


const useStyles = ((theme) => ({
  input: {
    display: 'block',
    '&>label': {
      color: theme.palette.primary.main,
      fontStyle: 'italic',
    },
    '&>label.MuiInputLabel-shrink': {
      display: 'none',
    },
    '&>label.MuiInputLabel-marginDense': {
      transform: 'translate(0, 5px) scale(1)',
    },
    '& input': {
      color: theme.palette.primary.main,
    },
    '&>.MuiInput-formControl': {
      marginTop: 0,
    },
    '& .MuiAutocomplete-popupIndicator': {
      color: theme.palette.primary.main,
    },
    '& .MuiAutocomplete-popupIndicatorOpen': {
      transform: 'none',
    },
  },
  option: {
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

const LISTBOX_PADDING = 0; // px

function renderRow(props) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <div ref={ref} {...props} {...outerProps} />;
});

const ListboxComponent = React.forwardRef((props, ref) => {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = () => itemSize;

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          key={itemCount}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
          style={{ overflowX: 'hidden' }}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.node.isRequired,
};

function ListNameAutocomplete({
  lists: initialLists,
  classes,
  onChange,
}) {
  const [lists, setLists] = useState(initialLists);

  useEffect(() => {
    let l = initialLists;
    if (l) {
      l = l.slice();
      l.sort((a, b) => (a.shortName || '').localeCompare(b.shortName || ''));
    }
    setLists(l);
  }, [initialLists]);

  return (
    <Autocomplete
      onChange={(event, value) => onChange(value)}
      options={lists}
      autoHighlight
      blurOnSelect
      autoComplete
      autoSelect
      getOptionLabel={(opt) => opt.shortName + " (" + opt.listId + ")"}
      ListboxComponent={ListboxComponent}
      classes={{
        option: classes.option,
      }}
      popupIcon={<SearchIcon />}
        // eslint-disable-next-line react/jsx-props-no-spreading
        renderInput={(params) => <TextField {...params} size="small" variant="standard" label="Search Lists" margin="none" className={classes.input} />}
    />
  );
}

ListNameAutocomplete.defaultProps = {
  onChange: () => { },
};

ListNameAutocomplete.propTypes = {
  ...withStylesPropTypes,
  lists: PropTypes.arrayOf(PropTypes.shape).isRequired,
  onChange: PropTypes.func,
};

const mapStateToProps = (state) => ({
  lists: selectLists(state),
});

export default connect(mapStateToProps, null)(withStyles(useStyles)(ListNameAutocomplete));
