import React from 'react';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { withStylesPropTypes, formatDate } from '../../helper/misc';
import Loading from '../common/loading';
import Error from '../common/error';


const useStyles = ((theme) => ({
  listName: {
    marginLeft: `-${theme.spacing(3)}px`,
    '& .MuiIconButton-root': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  listNameButton: {
    padding: 0,
    fontSize: '1em',
    fontWeight: 'bold',
    textTransform: 'unset',
    justifyContent: 'left',
    textAlign: 'left',
    overflow: 'hidden',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
  },
  listRow: {
    color: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'row',
    '&$expanded': {
      '& $icon': {
        transform: 'rotateX(180deg)',
      },
    },
  },
  icon: {
    width: theme.spacing(3),
    height: theme.spacing(4),
    alignSelf: 'start',
  },
  listCheckbox: {
    alignSelf: 'start',
    height: theme.spacing(4),
    padding: 0,
    color: 'inherit',
  },
  expanded: {
  },
  cell: {
    width: `calc(100% - ${theme.spacing(3)}px)`,
    '&>div[role="button"]': {
      lineHeight: `${theme.spacing(4)}px`,
      minHeight: `${theme.spacing(4)}px`,
    },
  },
  payloadCell: {
    lineHeight: `${theme.spacing(3)}px`,
    minHeight: `${theme.spacing(3)}px`,
    height: `${theme.spacing(3)}px`,
    color: theme.palette.text.primary,
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  error: {
    color: theme.palette.error.main,
  },
}));

function Cell({
  cellData, rowData, columnIndex, rowIndex, payloadsSelectable, multiListSelect, extraPayloadComponentRenderer, classes, onListExpand, onListSelect, onPayloadSelect, payloadRenderer, payloadFailed,
}) {
  return (
    <div className={clsx(classes.listRow, columnIndex === 0 && classes.listName, rowData.expanded && classes.expanded)}>
      {columnIndex === 0 && (
        <>
          <IconButton disabled={rowData.locked} size="small" onClick={() => onListExpand({ rowIndex, columnIndex })} classes={{ root: classes.icon }} color="primary">
            <KeyboardArrowDownIcon />
          </IconButton>
          { multiListSelect && (
            <Checkbox
              color="primary"
              size="small"
              disabled={rowData.locked}
              classes={{ root: classes.listCheckbox }}
              disableRipple
            />
          )}
        </>
      )}
      <div className={classes.cell}>
        <Button size="small" color="primary" onClick={() => onListSelect({ rowIndex, columnIndex })} disabled={rowData.locked} classes={{ root: classes.listNameButton }}>{cellData || ''}</Button>
        {
          rowData.expanded && rowData.payloadsLoading && columnIndex === 0 && (<Loading inline />)
        }
        {
          rowData.expanded && !rowData.payloadsLoading && rowData.payloadsError && columnIndex === 0 && (<div style={{ position: 'absolute' }}><Error message={rowData.payloadsError.message} inline /></div>)
        }
        {
          (rowData.expanded && !rowData.payloadsLoading && !rowData.payloadsError && !!extraPayloadComponentRenderer) && (
            <div style={{ height: 32 }}>
              {
                columnIndex === 0 && extraPayloadComponentRenderer(rowData)
              }
            </div>
          )
        }
        {
          rowData.expanded && !rowData.isLoading && !rowData.payloadsError && rowData.payloads && columnIndex === 0 && rowData.payloads.map((p) => (payloadsSelectable ? (
            <div key={`p-${p._id}-0`} className={classes.payloadCell}>
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    size="small"
                    disableRipple
                    checked={p.selected}
                    onChange={(event) => onPayloadSelect(p, event.target.checked)}
                  />
                  )}
                label={formatDate(p.scrapedAt)}
              />
            </div>
          ) : (
            <div key={`p-${p._id}-0`} className={classes.payloadCell}>{formatDate(p.scrapedAt)}</div>
          )))
        }
        {
          rowData.expanded && !rowData.isLoading && !rowData.payloadsError && rowData.payloads && rowData.payloads.map((p) => (
            <div key={`p-${p._id}-${columnIndex}`} className={clsx(classes.payloadCell, payloadFailed(p) && classes.error)}>{payloadRenderer(p)}</div>
          ))
        }
      </div>
    </div>
  );
}

Cell.defaultProps = {
  cellData: null,
  payloadsSelectable: true,
  multiListSelect: false,
  extraPayloadComponentRenderer: null,
  onListExpand: () => { },
  onListSelect: () => { },
  onPayloadSelect: () => { },
  payloadRenderer: () => (<></>),
  payloadFailed: () => false,
};

Cell.propTypes = {
  ...withStylesPropTypes,
  cellData: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  rowData: PropTypes.shape().isRequired,
  columnIndex: PropTypes.number.isRequired,
  rowIndex: PropTypes.number.isRequired,
  payloadsSelectable: PropTypes.bool,
  multiListSelect: PropTypes.bool,
  onListExpand: PropTypes.func,
  onListSelect: PropTypes.func,
  onPayloadSelect: PropTypes.func,
  extraPayloadComponentRenderer: PropTypes.func,
  payloadRenderer: PropTypes.func,
  payloadFailed: PropTypes.func,
};

export default withStyles(useStyles)(Cell);
