export const RULES = {
  CONTAIN: 'contain',
  NOT_CONTAIN: 'not-contain',
  DUPLICATE: 'duplicate',
  NOT_DUPLICATE: 'not-duplicate',
};

const getRangeValuesCount = (dataProvider, range, lvalue) => dataProvider.cache('getRangeValuesCount', range, () => {
  const map = {};
  dataProvider.enumerateRangeCells(range).forEach((cellCoordinate) => {
    const lCellValue = (dataProvider.getEvaluatedCellValue(cellCoordinate) || '').toLowerCase();
    const count = map[lCellValue];
    map[lCellValue] = (count || 0) + 1;
  });
  return map;
})[lvalue];

export const processConditions = (cellCoordinate, conditions, dataProvider) => {
  let ret = null;

  if (conditions) {
    conditions.forEach((condition) => {
      let match = null;
      const lvalue = (dataProvider.getEvaluatedCellValue(cellCoordinate) || '').toLowerCase();

      condition.rules.forEach((rule) => {
        let ruleMatch = false;
        switch (rule.rule) {
          case RULES.CONTAIN:
            ruleMatch = lvalue.indexOf((rule.ruleText || '').toLowerCase()) >= 0;
            break;
          case RULES.NOT_CONTAIN:
            ruleMatch = lvalue.indexOf((rule.ruleText || '').toLowerCase()) < 0;
            break;
          case RULES.DUPLICATE:
            ruleMatch = getRangeValuesCount(dataProvider, condition.range, lvalue) > 1;
            break;
          case RULES.NOT_DUPLICATE:
            ruleMatch = getRangeValuesCount(dataProvider, condition.range, lvalue) <= 1;
            break;
          default:
            ruleMatch = false;
            break;
        }
        if (match === null) {
          match = ruleMatch;
        } else {
          switch (rule.operator.toLowerCase()) {
            case 'or':
              match = match || ruleMatch;
              break;
            case 'and':
              match = match && ruleMatch;
              break;
            default:
              break;
          }
        }
      });

      if (match) {
        ret = { ...ret, ...condition.style };
      }
    });
  }

  return ret;
};
