import * as React from "react"

function Folder(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 100 100"
      {...props}
    >
      <path
        d="M6 38.009A8.004 8.004 0 0114.009 30H61.99A8.004 8.004 0 0170 38.009V85.99A8.004 8.004 0 0161.991 94H14.01A8.004 8.004 0 016 85.991V38.01zM14 38h48v48H14V38zm8 20v8h32v-8H22zm64 12c4.418 0 8-3.59 8-8v-8h-8v8h-8v8h8zm8-56c0-4.418-3.59-8-8-8h-8v8h8v8h8v-8zM38 6c-4.418 0-8 3.59-8 8v8h8v-8h8V6h-8zm16 0h16v8H54V6zm32 24h8v16h-8V30z"
        fill="#000"
      />
    </svg>
  )
}

export default Folder
