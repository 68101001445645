import { createSelector } from '@reduxjs/toolkit';

export const selectALIMatchesLoading = (state) => state.aliMatches.isLoading && { progress: state.aliMatches.loadingProgress };

export const selectALIMatchesError = (state) => state.aliMatches.error;

export const selectALIMatchesSaving = (state) => state.aliMatches.isSaving;

export const selectALIMatchesParams = (state) => state.aliMatches.params;

export const selectSelectedALIMatchIds = (state) => state.aliMatches.selectedIds;

export const selectLockedListIds = (state) => state.aliMatches.lockedListIds;

export const mergingLogsDownloadUrl = (state) => state.aliMatches.mergingLogsDownloadUrl;


export const selectALIMatches = createSelector(
  [selectALIMatchesParams],
  (params) => (params ? params.allIds.map((id) => params.byId[id]).filter((p) => !!p) : []),
);

export const selectSelectedALIMatches = createSelector(
  [selectSelectedALIMatchIds, selectALIMatchesParams],
  (aliMatchIDs, params) => params && Object.keys(aliMatchIDs).map((id) => params.byId[id]).filter((p) => !!p),
);

export const selectCurrentGroupIndex = createSelector(
  [selectALIMatchesParams],
  (params) => (params || {}).groupIndex || 0,
);

export const selectShowCoreColumns = createSelector(
  [selectALIMatchesParams],
  (params) => (params || {}).showCoreColumns || false,
);

export const selectMatchingId = createSelector(
  [selectALIMatchesParams],
  (params) => (params || {}).matchingId,
);

export const selectCortexAutoMerge = createSelector(
  [selectALIMatchesParams],
  (params) => (params || {}).cortexAutoMerge,
);
