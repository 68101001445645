import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { withStylesPropTypes } from '../../helper/misc';
import { setSelectedPolygonVertex } from '../../app/geofencesSlice';

const useStyles = (() => ({
  button: {
    padding: 0,
    fontSize: 'inherit',
    color: 'inherit',
    textTransform: 'inherit',
    textDecoration: 'underline',
  },
}));

function GeofenceMessage({
  classes,
  message,
  children,
  fnSetSelectedPolygonVertex,
}) {
  const handleClick = () => {
    const { polygonIndex, pointIndex: vertexIndex } = message;
    fnSetSelectedPolygonVertex({ polygonIndex, vertexIndex });
  };

  return message.polygonIndex >= 0 ? (<Button onClick={handleClick} size="small" variant="text" classes={{ root: classes.button }}>{ children }</Button>) : children;
}

GeofenceMessage.defaultProps = {
  message: undefined,
  children: undefined,
};

GeofenceMessage.propTypes = {
  ...withStylesPropTypes,
  message: PropTypes.shape(),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default connect(undefined, {
  fnSetSelectedPolygonVertex: setSelectedPolygonVertex,
})(withStyles(useStyles)(GeofenceMessage));
