import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import { withStylesPropTypes } from '../../helper/misc';

const useStyles = ((theme) => ({
  listLabel: {
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    opacity: '1 !important',
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  percent5: {
    color: '#a2a2a4',
  },
  percent10: {
    color: '#e01109',
  },
  percent50: {
    color: '#b4b438',
  },
  percent80: {
    color: '#8fba4e',
  },
  percent100: {
    color: '#1fb049',
  },
}));

function PayloadFieldMatch({
  classes,
  title,
  matchingHeaders,
  onSelect,
}) {
  const getItemClassName = (percent) => {
    if (percent < 5) return classes.percent5;
    if (percent < 10) return classes.percent10;
    if (percent < 50) return classes.percent50;
    if (percent < 80) return classes.percent80;
    return classes.percent100;
  };

  const handleSelect = (e) => {
    const { value } = e.target;
    onSelect(value);
  };

  return (
    <Select
      defaultValue=""
      displayEmpty
      renderValue={() => <>{title}</>}
      disableUnderline
      fullWidth
      prefix="Match By"
      onChange={handleSelect}
    >
      <MenuItem disabled value="" classes={{ root: classes.listLabel }}>Match By</MenuItem>
      {
        matchingHeaders.map((h) => (
          <MenuItem key={h.header} value={h.header} classes={{ root: classes.listItem }}>
            <div>{h.header}</div>
            <div className={getItemClassName(Math.round((h.matchCount * 100) / h.total))}>
              {Math.round((h.matchCount * 100) / h.total)}
              %
            </div>
          </MenuItem>
        ))
      }
    </Select>
  );
}

PayloadFieldMatch.defaultProps = {
  title: null,
  matchingHeaders: [],
  onSelect: () => {},
};

PayloadFieldMatch.propTypes = {
  ...withStylesPropTypes,
  title: PropTypes.string,
  matchingHeaders: PropTypes.arrayOf(PropTypes.shape()),
  onSelect: PropTypes.func,
};

export default withStyles(useStyles)(PayloadFieldMatch);
