import { removeQuotes, formatDateToFileNameTimestamp } from './misc';
import STANDARD_HEADERS, { DEFAULT_HEADERS_ORDER } from '../const/headers';
import { STATION_ORDER, PREPROCESSED_STATIONS } from '../const/stations';

export const getHeaderMap = (headers) => Object.keys(headers).reduce((m, k) => { m[headers[k]] = k; return m; }, {});

export const getStandardHeaderMap = () => ({
  [STANDARD_HEADERS.LATITUDE]: 'latitude',
  [STANDARD_HEADERS.LONGITUDE]: 'longitude',
  [STANDARD_HEADERS.ADDRESS]: 'address',
  [STANDARD_HEADERS.ADDRESS_2]: 'addressLine2',
  [STANDARD_HEADERS.CITY]: 'city',
  [STANDARD_HEADERS.STATE]: 'state',
  [STANDARD_HEADERS.ZIP]: 'zipCode',
  [STANDARD_HEADERS.COUNTRY]: 'county',
});

export const getLocationCoordinates = (location, headerMap) => {
  let ret;
  const latField = headerMap[STANDARD_HEADERS.LATITUDE];
  const lngField = headerMap[STANDARD_HEADERS.LONGITUDE];
  if (latField && lngField) {
    const vlat = location[latField];
    const vlng = location[lngField];
    const isDef = (v) => v !== undefined && v !== null && v !== '';
    if (isDef(vlat) && isDef(vlng)) {
      const lat = Number(vlat);
      const lng = Number(vlng);
      if (!Number.isNaN(lat) && !Number.isNaN(lng)) ret = { lat, lng };
    }
  }
  return ret;
};

export const getLocationAddress = (location, headerMap) => {
  const addressField = headerMap[STANDARD_HEADERS.ADDRESS];
  const address2Field = headerMap[STANDARD_HEADERS.ADDRESS_2];
  const address3Field = headerMap[STANDARD_HEADERS.ADDRESS_3];
  const cityField = headerMap[STANDARD_HEADERS.CITY];
  const stateField = headerMap[STANDARD_HEADERS.STATE];
  const zipField = headerMap[STANDARD_HEADERS.ZIP];
  const countryField = headerMap[STANDARD_HEADERS.COUNTRY];
  const countryCodeField = headerMap[STANDARD_HEADERS.COUNTRY_CODE];

  const addressLines = [];
  if (addressField && location[addressField]) addressLines.push(location[addressField]);
  if (address2Field && location[address2Field]) addressLines.push(location[address2Field]);
  if (address3Field && location[address3Field]) addressLines.push(location[address3Field]);
  const address = addressLines.length ? addressLines.join('\n') : undefined;

  const city = cityField && location[cityField];
  const state = cityField && location[stateField];
  const zip = cityField && location[zipField];

  let country = countryField && location[countryField];
  if (!country) country = countryCodeField && location[countryCodeField];

  return (address || city || state || zip || country) && {
    address, city, state, zip, country,
  };
};

export const locationAddressToString = (address) => {
  const addr = [];
  if (address.address) addr.push(address.address);
  if (address.city) addr.push(address.city);
  if (address.state) addr.push(address.state);
  if (address.zip) addr.push(address.zip);
  if (address.county) addr.push(address.county);
  return addr.join(' ');
};

const condition = (payload, app) => {
  if(app === 'alimatching') {
    return (payload && payload.headers && payload.headersOrder && payload.status === 'published');
  }
  return (payload && payload.headers && payload.headersOrder);
}

export const combinePayloadHeaders = (payloadList, app = 'qa') => {
  const list = [];
  if (payloadList) {
    const payloads = payloadList.filter((payload) => condition(payload, app));
    const fieldKey = (f, m) => m || f;
    const ancestorMapCount = {};
    const headerMap = {};
    let ancestor = '';
    payloads.forEach((payload) => {
      payload.headersOrder.filter((h) => !['ali', 'geofenceStatus'].includes(h)).forEach((field) => {
        const match = payload.headers[field];
        const key = fieldKey(field, match);
        let map = ancestorMapCount[ancestor];
        if (!map) {
          map = {};
          ancestorMapCount[ancestor] = map;
        }
        if (map[key]) map[key] += 1;
        else map[key] = 1;

        let headerList = headerMap[key];
        if (!headerList) {
          headerList = [];
          headerMap[key] = headerList;
        }
        let header = headerList.find((h) => h.field === field && h.match === match);
        if (!header) {
          header = { field, match, payloads: [] };
          headerList.push(header);
        }
        if (header.payloads.indexOf(payload._id) < 0) header.payloads.push(payload._id);

        ancestor = key;
      });
    });

    const used = {};
    const addHeader = (key) => {
      const headerList = headerMap[key];
      used[key] = true;
      if (headerList) {
        headerList.forEach((h) => {
          list.push({
            title: h.field, match: h.match, key: removeQuotes(h.field), payloadIds: h.payloads,
          });
        });
      }
    };

    const traceHeaders = (ancestorKey, parentMap) => {
      const map = { ...ancestorMapCount[ancestorKey] };
      Object.keys(parentMap).forEach((f) => {
        const c = parentMap[f];
        if (map[f]) map[f] += c;
        else map[f] = c;
      });
      if (map && Object.keys(map).length) {
        const counts = Object.keys(map).map((key) => ({ key, count: map[key] })).filter((c) => !used[c.key]);
        if (counts.length) {
          counts.sort((a, b) => {
            let ret = b.count - a.count;
            if (ret === 0) ret = b.key.localeCompare(a.key);
            return ret;
          });
          const header = counts[0];
          addHeader(header.key);
          delete map[header.key];
          traceHeaders(header.key, map);
        }
      }
    };

    traceHeaders('', {});
  }
  return list;
};

export const combineCorePayloadHeaders = (payloadList, app = 'qa') => {
  const list = [];
  if (payloadList) {
    const map = {};
    const payloads = payloadList.filter((payload) => condition(payload, app));
    payloads.forEach((payload) => {
      payload.headersOrder.forEach((field) => {
        const match = payload.headers[field];
        if (match) {
          let fields = map[match];
          if (!fields) {
            fields = {};
            map[match] = fields;
          }
          let payloadIds = fields[field];
          if (!payloadIds) {
            payloadIds = [];
            fields[field] = payloadIds;
          }
          if (payloadIds.indexOf(payload._id) < 0) payloadIds.push(payload._id);
        }
      });
    });
    DEFAULT_HEADERS_ORDER.forEach((match) => {
      const fields = map[match];
      if (fields) {
        Object.keys(fields).forEach((field) => {
          const payloadIds = fields[field];
          list.push({
            title: field, match, key: removeQuotes(field), payloadIds,
          });
        });
      }
    });
  }
  return list;
};

export const getLocationFieldStationData = (location, field, selectedStation) => {
  const ret = [];
  if (selectedStation && location && location.stations) {
    const stationData = location.stations[selectedStation.station];
    if (stationData) {
      Object.keys(stationData).forEach((substation) => {
        if (!selectedStation.substation || selectedStation.substation === substation) {
          const fields = stationData[substation];
          ret.push(...fields.filter((f) => f.field === field).map((f) => ({
            ...f, value: location[field], station: selectedStation.station, substation,
          })));
        }
      });
    }
  }
  return ret;
};

export const isFieldValueApproved = (fieldValue) => {
  const { value, approved } = fieldValue;
  if (approved === undefined || approved === null) return false;
  return (value || '') === (approved || '');
};

export const getListSignatures = (list) => {
  const stubsMap = {};
  const { stubs } = list;
  if (stubs) {
    stubs.split('\n').forEach((s) => {
      const [, stub] = s.split(',');
      if (stub) stubsMap[stub] = true;
    });
  }
  const signatures = Object.keys(stubsMap);
  if (!signatures.length) {
    const stub = (list.shortName || '').toLowerCase()
      .replace(/&/g, ' and ')
      .replace(/[_]+/g, ' ')
      .replace(/[^a-z0-9\s-]/g, '')
      .trim()
      .replace(/\s+/g, '_');
    signatures.push(stub);
  }
  return signatures;
};

export const getListSignature = (list) => {
  const [signature] = getListSignatures(list);
  return signature;
};

export const parsePayloadFileName = (fileName) => {
  const m = /^([a-z0-9\-_]+)_(\d{2})-(\d{2})-(\d{2})\.csv$/.exec(fileName);
  if (m) {
    return {
      listName: m[1],
      mm: Number(m[2]),
      dd: Number(m[3]),
      yy: Number(m[4]),
    };
  }
  return null;
};

export const parsePayloadFileNameForExcel = (fileName) => {
  const m = /^([a-z0-9\-_]+)-(\d+)-([a-z0-9]+)-(\d+)\.csv$/.exec(fileName);
  if (m) {
    return {
      listName: m[1],
      listId: Number(m[2]),
      payloadId: m[3],
      timestamp: Number(m[4]),
    };
  }
  return null;
};

export const isPostStation = (payloads, station) => {
  const singleStation = typeof station === 'string';

  const stations = singleStation ? [station] : station;

  const minStationIdx = payloads.reduce((minIdx, payload) => {
    const idx = STATION_ORDER.indexOf(payload.station);
    return idx >= 0 && idx < minIdx ? idx : minIdx;
  }, Number.MAX_SAFE_INTEGER);

  const result = {};
  stations.forEach((s) => {
    const stationIdx = STATION_ORDER.indexOf(s);
    result[s] = (minStationIdx !== Number.MAX_SAFE_INTEGER && minStationIdx >= stationIdx);
  });

  return singleStation ? result[station] : result;
};

export const getPayloadFileName = (list, payload) => `${getListSignature(list)}_${formatDateToFileNameTimestamp(payload.scrapedAt)}`;

export const getPayloadFileNameForExcel = (list, payload) => `${getListSignature(list)}-${list.listId}-${payload._id}-${(new Date()).getTime()}`;

export const isCurrentSubstation = (payload, currentStation, currentSubStation) => {
  let ret = false;
  const { station } = payload;
  if (station === currentStation) {
    let { subStation } = payload;
    if (!subStation) {
      const ps = PREPROCESSED_STATIONS.find((s) => s.key === station);
      if (ps && ps.substations && ps.substations.length) {
        subStation = ps.substations[0].key;
      }
    }
    ret = currentSubStation === subStation;
  }
  return ret;
};
