import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { commonDefaulProps, commonPropTypes } from '../common';

window.google = window.google || {};
const { google } = window;

const Marker = ({
  mapRef,
  position,
  zIndex,
  icon,
  iconSize,
  editable,
  data,
  onEdited,
}) => {
  const [markerRef, setMarkerRef] = useState();

  useEffect(() => {
    const marker = new google.maps.Marker({
      map: mapRef,
      icon: {
        url: icon,
        size: new google.maps.Size(iconSize, iconSize),
        anchor: new google.maps.Point(iconSize / 2, iconSize),
      },
    });
    setMarkerRef(marker);

    return () => {
      marker.setMap(null);
    };
  }, [mapRef, icon, iconSize]);

  useEffect(() => {
    if (markerRef) {
      markerRef.setPosition(position);
    }
  }, [markerRef, position]);

  useEffect(() => {
    if (markerRef) {
      markerRef.setOptions({ zIndex, draggable: editable });
    }
  }, [markerRef, zIndex, editable]);

  const handleDragEnd = useCallback(() => {
    const pos = markerRef.getPosition();
    onEdited({
      data,
      position: { lat: pos.lat(), lng: pos.lng() },
    });
  }, [onEdited, data, markerRef]);

  useEffect(() => {
    if (markerRef) {
      google.maps.event.addListener(markerRef, 'dragend', handleDragEnd);
      return () => {
        google.maps.event.clearListeners(markerRef, 'dragend');
      };
    }
    return undefined;
  }, [markerRef, handleDragEnd]);
  return (
    <></>
  );
};


Marker.defaultProps = {
  ...commonDefaulProps,
  zIndex: 100,
  mapRef: undefined,
  icon: undefined,
};

Marker.propTypes = {
  ...commonPropTypes,
  position: PropTypes.shape().isRequired,
  mapRef: PropTypes.shape(),
  zIndex: PropTypes.number,
  icon: PropTypes.string,
  iconSize: PropTypes.number.isRequired,
};

export default Marker;
