import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import listsReducer from './listSlice';
import payloadsReducer from './payloadSlice';
import locationsReducer from './locationsSlice';
import historyReducer from './historySlice';
import aliMatchesReducer from './aliMatchesSlice';
import lockRecordReducer from './lockRecordSlice';
import geofencesReducer from './geofencesSlice';

export default configureStore({
  reducer: {
    list: listsReducer,
    payload: payloadsReducer,
    locations: locationsReducer,
    history: historyReducer,
    aliMatches: aliMatchesReducer,
    lockRecord: lockRecordReducer,
    geofences: geofencesReducer,
  },
  middleware: [
    ...getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }),
  ],
});
