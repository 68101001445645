export const daysInMonth = (month, year) => new Date(year, month, 0).getDate();

export const yyToYYYY = (yy) => {
  const cy = (new Date()).getFullYear();
  return Math.floor((cy - ((cy % 100) < yy ? 100 : 0)) / 100) * 100 + yy;
};

export const isValidDate = (mm, dd, yyyy) => (mm >= 1 && mm <= 12 && dd >= 1 && dd <= daysInMonth(mm, yyyy));

export const MAX_DATE = new Date(8640000000000000);
