import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withStylesPropTypes, useMountEffect } from '../../../../helper/misc';
import { leafletToGoogleBounds, googlePathToCoordinates } from '../helper';
import Config from '../../../../config';


window.google = window.google || {};
const { google } = window;

const useStyles = (() => ({
  root: {
    width: '100%',
    height: '100%',
  },
}));

const MapView = ({
  classes,
  center,
  zoom,
  bounds,
  drawing,
  children,
  onNewPolygon,
}) => {
  const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);
  const [divRef, setDivRef] = useState();
  const [mapRef, setMapRef] = useState();
  const [snapPoints, setSnapPoints] = useState([]);

  useMountEffect(() => {
    if (google.maps) {
      setGoogleMapsLoaded(true);
    } else {
      window.initMap = () => setGoogleMapsLoaded(true);
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${Config.googleKey}&libraries=drawing,places&callback=initMap`;
      window.document.body.appendChild(script);
    }
  });

  const handleNewPolygon = useCallback((polygon) => {
    polygon.setMap(undefined);
    const coordinates = googlePathToCoordinates(polygon.getPath());
    onNewPolygon(coordinates);
  }, [onNewPolygon]);

  useEffect(() => {
    if (divRef && googleMapsLoaded) {
      const map = new google.maps.Map(divRef, {
        mapTypeId: google.maps.MapTypeId.HYBRID,
        imageDateControl: true,
        rotateControl: true,
        gestureHandling: 'greedy',
      });
      map.setTilt(0);
      setMapRef(map);
    }
  }, [divRef, googleMapsLoaded]);

  useEffect(() => {
    if (mapRef && center) {
      mapRef.setCenter(new google.maps.LatLng(center.lat, center.lng));
    }
  }, [center, mapRef]);

  useEffect(() => {
    if (mapRef && zoom) {
      mapRef.setZoom(zoom);
    }
  }, [zoom, mapRef]);

  useEffect(() => {
    if (mapRef && bounds) {
      mapRef.fitBounds(leafletToGoogleBounds(bounds));
    }
  }, [bounds, mapRef]);

  useEffect(() => {
    const points = [];
    if (children && mapRef) {
      children.forEach((c) => {
        if (c.props.coordinates) {
          points.push(...c.props.coordinates.map(([lng, lat]) => new google.maps.LatLng(lat, lng)));
        }
      });
    }
    setSnapPoints(points);
  }, [children, mapRef]);

  useEffect(() => {
    if (mapRef && drawing) {
      const drawingModes = [];
      if (drawing.polygon) drawingModes.push(google.maps.drawing.OverlayType.POLYGON);

      const drawingManager = new google.maps.drawing.DrawingManager({
        map: mapRef,
        drawingControl: true,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes,
        },
        circleOptions: {
          fillColor: '#ffff00',
          fillOpacity: 1,
          strokeWeight: 5,
          clickable: false,
          zIndex: 1,
          editable: true,
        },
        snappingCallback: ({ latLng, overlay }) => {
          let ret;
          if (typeof overlay.getPath === 'function') {
            // const overlays = [...this.polygons];
            const p1 = mapRef.getProjection().fromLatLngToPoint(latLng);
            const pixelSize = 2 ** -mapRef.getZoom();
            for (let i = 0; i < snapPoints.length; i += 1) {
              const pt = snapPoints[i];
              const p2 = mapRef.getProjection().fromLatLngToPoint(pt);
              const d = Math.sqrt((p1.x - p2.x) * (p1.x - p2.x) + (p1.y - p2.y) * (p1.y - p2.y)) / pixelSize;
              if (d < 10) {
                ret = pt;
                break;
              }
            }
          }
          return ret || latLng;
        },
      });

      google.maps.event.addListener(drawingManager, 'overlaycomplete', (event) => {
        drawingManager.setOptions({
          drawingMode: null,
        });
        switch (event.type) {
          case google.maps.drawing.OverlayType.POLYGON:
            handleNewPolygon(event.overlay);
            break;
          default:
            break;
        }
      });

      return () => {
        google.maps.event.clearListeners(drawingManager, 'clioverlaycompleteck');
        drawingManager.setMap(undefined);
      };
    }
    return undefined;
  }, [drawing, mapRef, snapPoints, handleNewPolygon]);

  return (
    <div className={classes.root} ref={(ref) => setDivRef(ref)}>
      {
        googleMapsLoaded && children && React.Children.toArray(children).map((c) => React.cloneElement(c, { mapRef }))
      }
    </div>
  );
};


MapView.defaultProps = {
  center: undefined,
  zoom: undefined,
  children: undefined,
  bounds: undefined,
  drawing: undefined,
};

MapView.propTypes = {
  ...withStylesPropTypes,
  center: PropTypes.shape(),
  zoom: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  bounds: PropTypes.shape(),
  drawing: PropTypes.shape({
    polygon: PropTypes.bool,
  }),
  onNewPolygon: PropTypes.func.isRequired,
};

export default withStyles(useStyles)(MapView);
