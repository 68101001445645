import { createSlice } from '@reduxjs/toolkit';
import api from '../api/geofence';
import { updateLocationFields } from './locationsSlice';
import { updatePayload } from './payloadSlice';
import STATIONS from '../const/stations';

export const slice = createSlice({
  name: 'geofences',
  initialState: {
    location: undefined,
    property: undefined,
    isLoading: false,
    isSaving: false,
    error: false,
    modified: false,
    selectedPolygonVertex: undefined,
    original: undefined,
    validationResult: undefined,
  },
  reducers: {
    getGeofencesStart: (state) => {
      state.isLoading = true;
      state.error = null;
      state.validationResult = undefined;
    },
    getGeofencesSuccess: (state, { payload }) => {
      const { location, property } = payload;
      state.property = property;
      state.location = { ...location };
      state.error = null;
      state.isLoading = false;
      state.modified = false;
      state.original = {
        location: { ...location },
        property: { ...property },
      };
      const geofenceErrors = ((((location || {}).stations || {})[STATIONS.GEOFENCE] || {})[STATIONS.GEOFENCE] || []).find((f) => f.field === 'geofenceStatus' && f.error);
      if (geofenceErrors) state.validationResult = geofenceErrors.error;
    },
    getGeofencesFailed: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload.error;
    },
    saveGeofencesStart: (state) => {
      state.isSaving = true;
      state.error = null;
      state.validationResult = undefined;
    },
    saveGeofencesSuccess: (state, { payload }) => {
      const { validation, property, location } = payload;
      state.isSaving = false;
      state.error = null;
      state.validationResult = validation;
      if (property) {
        state.property = property;
        state.modified = false;
        state.original.property = { ...property };
      }
      if (location) {
        state.location = location;
        state.original.location = location;
      }
    },
    saveGeofencesFailed: (state, { payload }) => {
      state.isSaving = false;
      state.error = payload.error;
    },
    setLocationCoordinates: (state, { payload }) => {
      const { latitude, longitude } = payload;
      state.location = {
        ...state.location,
        latitude,
        longitude,
      };
      state.modified = true;
    },
    setGeofence: (state, { payload }) => {
      const { index, geofence } = payload;
      const { property } = state;
      if (property) {
        property.geofences = property.geofences || [];
        if (property.geofences.length > index) {
          property.geofences[index] = geofence;
          state.modified = true;
        } else if (index === undefined) {
          property.geofences.push(geofence);
          state.modified = true;
        }
      } else {
        state.property = {
          geofences: [],
        };
        state.property.geofences.push(geofence);
        state.modified = true;
      }
    },
    modifyProperty: (state, { payload }) => {
      state.property = { ...state.property, ...payload };
      state.modified = true;
    },
    setSelectedPolygonVertex: (state, { payload }) => {
      const { vertexIndex, polygonIndex } = payload;
      state.selectedPolygonVertex = { polygonIndex, vertexIndex };
    },
    discardChanges: (state) => {
      const { location, property } = state.original;
      state.location = { ...location };
      state.property = { ...property };
      state.selectedPolygonVertex = undefined;
      state.modified = false;
      state.validationResult = undefined;
    },
  },
});

export const {
  getGeofencesStart,
  getGeofencesSuccess,
  getGeofencesFailed,
  saveGeofencesStart,
  saveGeofencesSuccess,
  saveGeofencesFailed,
  setLocationCoordinates,
  setGeofence,
  modifyProperty,
  setSelectedPolygonVertex,
  discardChanges,
} = slice.actions;

export default slice.reducer;

export const fetchGeofences = (location) => (dispatch) => {
  if (location.propertyId) {
    dispatch(getGeofencesStart());
    api.fetchGeofences(location.propertyId).then((property) => {
      dispatch(getGeofencesSuccess({
        location, property,
      }));
    }).catch((error) => {
      dispatch(getGeofencesFailed({ error }));
    });
  } else {
    dispatch(getGeofencesSuccess({ location }));
  }
};

export const saveLocationGeofences = (force) => (dispatch, getState) => {
  const { location, property, original } = getState().geofences;
  const { latitude, longitude, _id: locationId } = location;
  const { location: originalLocation } = original;
  const locationChanges = (originalLocation.latitude !== latitude || originalLocation.longitude !== longitude)
    ? { latitude, longitude } : undefined;

  const {
    geofences = [], _id: propertyId, requestFlag, requestNames,
  } = property;

  const propertyChanges = {
    _id: propertyId,
    requestFlag,
    reqeustNames: requestNames && requestFlag ? requestNames.filter((n) => !!n) : [],
  };

  const geofenceChanges = {
    deleted: [],
    added: [],
    updated: [],
  };
  geofences.forEach(({ flags, ...geofence }) => {
    if (flags && flags.deleted) {
      if (geofence._id) {
        geofenceChanges.deleted.push(geofence._id);
      }
    } else if (geofence._id) {
      geofenceChanges.updated.push(geofence);
    } else {
      geofenceChanges.added.push(geofence);
    }
  });

  dispatch(saveGeofencesStart());
  api.saveLocationGeofences(locationId, propertyChanges, locationChanges, geofenceChanges, force).then((result) => {
    dispatch(saveGeofencesSuccess(result));
    const { location: newLocation, payload: newPayload } = result;
    if (newLocation) dispatch(updateLocationFields(newLocation));
    if (newPayload) dispatch(updatePayload(newPayload));
  }).catch((error) => {
    dispatch(saveGeofencesFailed({ error }));
  });
};
