import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import {
  withStylesPropTypes, resolve, resolveAndSet, deepClone,
} from '../../helper/misc';
import KeyboardHandle from '../../helper/keyboard';


const useStyles = ((theme) => ({
  root: {
  },
  column: {
    fontWeight: 'bold',
  },
  textInput: {
    color: theme.palette.primary.main,
  },
  select: {
    color: theme.palette.primary.main,
  },
  buttons: {
    paddingTop: theme.spacing(1),
  },
}));

function PayloadFieldsEditor({
  classes,
  records: initialRecords,
  createNewRecord,
  validateRecord,
  saveRecords,
  fields,
}) {
  const [records, setRecords] = useState(initialRecords);

  useEffect(() => {
    setRecords(initialRecords);
  }, [initialRecords]);

  const handleAdd = () => {
    const newRecords = records.slice();
    newRecords.push({
      ...createNewRecord(), modified: true, valid: false,
    });
    setRecords(newRecords);
  };

  const handleChange = (e, index) => {
    const { value, name } = e.target;
    const newRecords = records.slice();
    const af = deepClone(newRecords[index]);
    resolveAndSet(af, name, value);
    af.modified = true;
    af.valid = validateRecord(af);
    newRecords[index] = af;
    setRecords(newRecords);
  };

  const handleDelete = (index) => {
    const newRecords = records.slice();
    newRecords.splice(index, 1);
    saveRecords(newRecords);
  };

  const handleApply = (index) => {
    if (records[index].valid) {
      const newRecords = records.slice();
      const af = deepClone(newRecords[index]);
      af.modified = false;
      newRecords[index] = af;
      saveRecords(newRecords);
    }
  };

  const handleKeyDown = (e, index) => {
    KeyboardHandle(e, {
      onEnter: () => handleApply(index),
    });
  };

  return (
    <div className={classes.root}>
      { records && records.length > 0 && (
        <>
          <Grid container spacing={1}>
            {
              // eslint-disable-next-line react/no-array-index-key
              fields.map((f) => <Grid item xs={f.size} key={f.key}><span className={classes.column}>{f.title}</span></Grid>)
            }
          </Grid>
          {
            records.map((rec, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <Grid container key={idx} spacing={1}>
                {
                    fields.map((f) => (
                      <Grid item xs={f.size} key={f.key}>
                        {
                          (!f.isHidden || !f.isHidden(rec)) && (
                            <>
                              { !!f.select && (
                                <Select displayEmpty value={resolve(rec, f.key) || ''} fullWidth classes={{ root: classes.select }} name={f.key} onChange={(e) => handleChange(e, idx)}>
                                  {
                                    f.select.map((s) => <MenuItem key={s[0]} value={s[0]}>{s[1]}</MenuItem>)
                                  }
                                </Select>
                              )}
                              { f.text && (
                              <Input
                                fullWidth
                                value={resolve(rec, f.key) || ''}
                                classes={{ input: classes.textInput }}
                                name={f.key}
                                onChange={(e) => handleChange(e, idx)}
                                onKeyDown={(e) => handleKeyDown(e, idx)}
                              />
                              )}
                              {
                                !f.text && !f.select && <span>{f.title}</span>
                              }
                            </>
                          )
                        }
                      </Grid>
                    ))
                }
                <Grid item xs={1}>
                  <IconButton size="small" color="secondary" title="Delete" onClick={() => handleDelete(idx)}><CancelIcon /></IconButton>
                  { rec.modified && <IconButton size="small" color="primary" title="Apply" disabled={!rec.valid} onClick={() => handleApply(idx)}><CheckCircleIcon /></IconButton> }
                </Grid>
              </Grid>
            ))
          }
        </>
      )}
      <div className={classes.buttons}>
        <Button type="button" variant="contained" color="primary" size="small" onClick={handleAdd}>Add New</Button>
      </div>
    </div>
  );
}

PayloadFieldsEditor.defaultProps = {
  records: [],
};

PayloadFieldsEditor.propTypes = {
  ...withStylesPropTypes,
  records: PropTypes.arrayOf(PropTypes.shape()),
  createNewRecord: PropTypes.func.isRequired,
  validateRecord: PropTypes.func.isRequired,
  saveRecords: PropTypes.func.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

const mapStateToProps = () => ({
});

export default connect(mapStateToProps, {
})(withStyles(useStyles)(PayloadFieldsEditor));
