import PropTypes from 'prop-types';

export const commonDefaulProps = {
  data: undefined,
  editable: false,
  onEdited: () => {},
};

export const commonPropTypes = {
  data: PropTypes.shape(),
  editable: PropTypes.bool.isRequired,
  onEdited: PropTypes.func.isRequired,
};
