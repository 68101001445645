import React, { useState, useEffect, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { withStyles } from '@material-ui/core/styles';
import { withStylesPropTypes } from '../helper/misc';
import Loading from '../features/common/loading';
import Error from '../features/common/error';
import Sheet from '../features/common/sheet/sheet';
import API from '../api/eventslog';

const useStyles = ((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  levels: {
    textAlign: 'right',
  },
  logs: {
    flexGrow: 1,
    paddingTop: theme.spacing(1),
  },
  header: {
    lineHeight: '32px',
    padding: `0px ${theme.spacing(1)}px`,
    backgroundColor: theme.palette.background.header,
  },
}));

const STATIONS = {
  INGESTION: 'ingestion',
  UI: 'ui',
};

const LEVELS = {
  INFO: 'info',
  ERROR: 'error',
};

const TABLE_COLUMS = [
  {
    title: 'Date',
    data: 'createdAt',
    width: 200,
  },
  {
    title: 'Level',
    data: 'level',
    width: 150,
  },
  {
    title: 'Status',
    data: 'status',
    width: 150,
  },
  {
    title: 'Key',
    data: 'key',
    width: 300,
  },
  {
    title: 'Message',
    data: 'message',
    width: 1500,
  },
];

function Logs({
  classes,
}) {
  const [station, setStation] = useState(STATIONS.INGESTION);
  const [level, setLevel] = useState(LEVELS.ERROR);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [records, setRecords] = useState([]);

  useEffect(() => {
    setLoading(true);
    setError();
    setRecords([]);

    let loaded = [];
    const fnFetch = (page) => API.fetchEventsLog(station, level, page)
      .then((result) => {
        loaded = [...loaded, ...result.docs];
        setRecords(loaded);
        if (result.offset + result.docs.length < result.totalDocs) {
          fnFetch((page || 0) + 1);
        } else {
          setLoading(false);
        }
      })
      .catch((e) => {
        setLoading(false);
        setError(e);
      });
    fnFetch(0);
  }, [station, level]);

  const getCellValue = (row, columnDef) => row[columnDef.data];

  const getRowKey = useCallback((row) => row._id, []);

  const getColumnKey = useCallback((column) => column.data, []);

  return (
    <div className={classes.root}>
      { loading && (<Loading />) }
      { error && (<Error message={error.message} />) }

      <Grid container>
        <Grid item xs={6}>
          <ToggleButtonGroup
            value={station}
            exclusive
            onChange={(e, v) => setStation(v)}
          >
            <ToggleButton value={STATIONS.INGESTION}>
              Ingestions
            </ToggleButton>
            <ToggleButton value={STATIONS.UI}>
              UI
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item xs={6} classes={{ root: classes.levels }}>
          <ToggleButtonGroup
            value={level}
            exclusive
            onChange={(e, v) => setLevel(v)}
          >
            <ToggleButton value={LEVELS.ERROR}>
              Error
            </ToggleButton>
            <ToggleButton value={LEVELS.INFO}>
              INFO
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
      <div className={classes.logs}>
        <Sheet
          rows={records}
          columns={TABLE_COLUMS}
          headerHeight={32}
          searchEnabled
          getCellValue={useCallback(({ row, column }) => getCellValue(row, column), [])}
          getRowKey={getRowKey}
          getColumnKey={getColumnKey}
          headerRenderer={({ column }) => (
            <div className={classes.header}>{column.title}</div>
          )}

        />
      </div>
    </div>
  );
}

Logs.propTypes = {
  ...withStylesPropTypes,
};

export default withStyles(useStyles)(Logs);
