class Clipboard {
  constructor() {
    this.lastText = null;
    this.lastHTML = null;
  }

  static isCopySupported() {
    return document.queryCommandSupported != null && document.queryCommandSupported('copy');
  }

  static makeSelectable(element) {
    element.style.overflow = 'hidden';
    element.style.height = '0px';
    element.style.setProperty('-moz-user-select', 'all');
    element.style.setProperty('-ms-user-select', 'all');
    element.style.setProperty('user-select', 'all');
  }

  copyCells(rows) {
    const table = document.createElement('table');
    Clipboard.makeSelectable(table);

    rows.forEach((row) => {
      const tr = table.appendChild(document.createElement('tr'));
      row.forEach((cell) => {
        const td = tr.appendChild(document.createElement('td'));
        td.textContent = cell.value;
        td.setAttribute('data-sheets-value', cell.value);
        if (cell.formula) td.setAttribute('data-sheets-formula', cell.formula);
      });
    });

    const tsv = rows.map((row) => row.map((cell) => cell.value).join('\t')).join('\n');
    return this.copy(table, tsv);
  }

  pasteCells(event) {
    return new Promise((resolve) => {
      let rows;
      this.paste(event).then(({ text, html }) => {
        if (html) {
          const wrapper = document.createElement('div');
          wrapper.innerHTML = html;
          const table = wrapper.querySelector('table');
          if (table) {
            rows = [];
            table.querySelectorAll('tr').forEach((tr) => {
              const row = [];
              rows.push(row);
              tr.querySelectorAll('td').forEach((td) => {
                let value = td.getAttribute('data-sheets-value');
                if (!value) value = td.innerHTML;
                const formula = td.getAttribute('data-sheets-formula');
                row.push({ value, formula });
              });
            });
          }
        }

        if (!rows && text) {
          rows = text.split('\n').map((line) => {
            const items = line.split('\t').map((item) => ({ value: item.trim() }));
            return items;
          });
        }
        resolve(rows);
      });
    });
  }

  copy(element, text) {
    if (!Clipboard.isCopySupported()) {
      return false;
    }

    document.body.appendChild(element);
    try {
      window.getSelection().selectAllChildren(element);

      if (text != null) {
        element.addEventListener('copy', (e) => {
          e.preventDefault();
          const clipboardData = e.clipboardData || window.clipboardData;
          if (clipboardData != null) {
            clipboardData.setData('text/plain', text);
            clipboardData.setData('text/html', element.outerHTML);
            this.lastText = text;
            this.lastHTML = element.outerHTML;
          }
        });
      }

      return document.execCommand('copy');
    } catch (err) {
      return false;
    } finally {
      document.body.removeChild(element);
    }
  }

  paste(event) {
    return new Promise((resolve) => {
      if (event && event.clipboardData) {
        this.lastHTML = event.clipboardData.getData('text/html');
        this.lastText = event.clipboardData.getData('text/plain');
        resolve({ html: this.lastHTML, text: this.lastText });
      } else {
        navigator.clipboard.readText().then((text) => {
          if (text !== this.lastText) this.lastHTML = null;
          this.lastText = text;
          resolve({ html: this.lastHTML, text: this.lastText });
        });
      }
    });
  }
}

export default new Clipboard();
