const STATIONS = {
  HEADERS: 'headers',
  NEW_HEADERS: 'new_headers',
  DROPPED_HEADERS: 'dropped_headers',
  UNCOMMON_HEADERS: 'uncommon_headers',

  REPLACE: 'replace',
  REPLACE_COMMON: 'common',
  REPLACE_LETTER_CASE: 'letter_case',
  STATE_ABBREVIATION: 'state_abbreviation',
  REPLACE_TRANSLATE: 'translate',

  FORMAT: 'format',

  GEOGRAPHY: 'geography',
  GEOGRAPHY_PARSING: 'parsing',
  GEOGRAPHY_POPULATION: 'population',
  GEOGRAPHY_GEOCODE: 'geocode',
  GEOGRAPHY_VALIDATION: 'validation',

  AUTOGEN: 'autogen',

  FORMATTED_DEDUP: 'formatted_dedup',

  FLAGS: 'flags',

  ALI: 'ali',

  PHONE_FORMAT: 'phoneNumberFormat',

  GEOFENCE: 'geofence',

  FINAL: 'final',

  STAGING: 'staging',

  PUBLISHED: 'published',
};

export const STATION_ORDER = [
  STATIONS.HEADERS,
  STATIONS.REPLACE,
  STATIONS.FORMAT,
  STATIONS.GEOGRAPHY,
  STATIONS.AUTOGEN,
  STATIONS.FORMATTED_DEDUP,
  STATIONS.PHONE_FORMAT,
  STATIONS.FLAGS,
  STATIONS.ALI,
  STATIONS.GEOFENCE,
  STATIONS.FINAL,
  STATIONS.STAGING,
  STATIONS.PUBLISHED,
];

export const STATION_STATUS = {
  FAILED: 'failed',
  AUTOFIX: 'autofix',
  MANUAL: 'manual',
  PROCESSING: 'processing',
  CLEAN: 'clean',
  DONE: 'done',
  ERROR: 'error',
};

export const isProcessingStatus = (status) => ![STATION_STATUS.FAILED, STATION_STATUS.DONE, STATION_STATUS.ERROR].includes(status);

export const isErrorStatus = (status) => status === STATION_STATUS.ERROR;

export const PREPROCESSED_STATIONS = [
  {
    key: STATIONS.HEADERS,
    title: 'Headers',
    substations: [
      { key: STATIONS.NEW_HEADERS, title: 'New Headers' },
      { key: STATIONS.DROPPED_HEADERS, title: 'Dropped Headers' },
      { key: STATIONS.UNCOMMON_HEADERS, title: 'Uncommon Headers' },
    ],
  },
  {
    key: STATIONS.REPLACE,
    title: 'Replace',
    substations: [
      { key: STATIONS.REPLACE_COMMON, title: 'Common' },
      { key: STATIONS.REPLACE_LETTER_CASE, title: 'Letter Case' },
      { key: STATIONS.STATE_ABBREVIATION, title: 'State Abbreviation' },
      // { key: STATIONS.REPLACE_TRANSLATE, title: 'Translate' }, AGG-720 remove tranlate substation
    ],
  },
  {
    key: STATIONS.GEOGRAPHY,
    title: 'Geographic Enrichment',
    substations: [
      { key: STATIONS.GEOGRAPHY_PARSING, title: 'Parsing' },
      { key: STATIONS.GEOGRAPHY_POPULATION, title: 'Population' },
      { key: STATIONS.GEOGRAPHY_GEOCODE, title: 'Geocode' },
      { key: STATIONS.GEOGRAPHY_VALIDATION, title: 'Validation' },
    ],
  },
  {
    key: STATIONS.PHONE_FORMAT,
    title: 'Phone Number',
    substations: [
      { key: STATIONS.PHONE_FORMAT, title: 'Format' },
    ],
  },
  {
    key: STATIONS.FLAGS,
    title: 'Flags',
    substations: [
      { key: STATIONS.FLAGS, title: 'Flags' },
    ],
  },
  // {
  //   key: STATIONS.ALI,
  //   title: 'ALI',
  //   substations: [
  //     { key: STATIONS.ALI },
  //   ],
  // }, {
  //   key: STATIONS.GEOFENCE,
  //   title: 'Geofence',
  //   substations: [
  //     { key: STATIONS.GEOFENCE },
  //   ],
  // },
];

export default STATIONS;
