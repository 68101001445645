/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { MAP_TYPE } from './mapview';
import GooglePolygon from './google/polygon';
import LeafletPolygon from './leaflet/polygon';
import { commonDefaulProps, commonPropTypes } from './common';

export const ICON_SIZE = 40;

const Polygon = ({
  mapType,
  ...params
}) => {
  switch (mapType) {
    case MAP_TYPE.GOOGLE:
      return <GooglePolygon {...params} />;
    case MAP_TYPE.LEAFLET:
      return <LeafletPolygon {...params} />;
    default:
      return <></>;
  }
};


Polygon.defaultProps = {
  ...commonDefaulProps,
  mapType: '',
  coordinates: [],
  strokeColor: '#000000',
  strokeOpacity: 1,
  strokeWeight: 3,
  fillColor: '#000000',
  fillOpacity: 0,
  zIndex: 100,
  selectedVertexIndex: undefined,
  onClick: () => {},
};

Polygon.propTypes = {
  ...commonPropTypes,
  mapType: PropTypes.string,
  zIndex: PropTypes.number,
  strokeColor: PropTypes.string,
  strokeOpacity: PropTypes.number,
  strokeWeight: PropTypes.number,
  fillColor: PropTypes.string,
  fillOpacity: PropTypes.number,
  coordinates: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.number),
  ),
  selectedVertexIndex: PropTypes.number,
  onClick: PropTypes.func,
};

export default Polygon;
