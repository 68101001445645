export const getEffectiveCellStyle = (style, defaultStyle) => {
  const effectiveStyle = {};
  if (style) {
    Object.keys(style).forEach((key) => {
      const thisValue = style[key];
      const defaultValue = defaultStyle[key];
      if (!defaultValue || defaultValue !== thisValue) {
        effectiveStyle[key] = thisValue;
      }
    });
  }
  return effectiveStyle;
};

export const getCommonStyle = (styles, defaultStyle) => {
  const combinedStyle = styles.reduce((style, cellStyle) => {
    Object.keys(cellStyle).forEach((styleName) => {
      let styleValues = style[styleName];
      if (!styleValues) {
        styleValues = {};
        style[styleName] = styleValues;
      }
      styleValues[cellStyle[styleName]] = true;
    });
    return style;
  }, {});

  const style = {};
  Object.keys(combinedStyle).forEach((styleName) => {
    const styleValues = Object.keys(combinedStyle[styleName]);
    if (styleValues.length === 1) {
      [style[styleName]] = styleValues;
    } else {
      style[styleName] = defaultStyle[styleName];
    }
  });

  return style;
};

export const equalStyle = (s1, s2) => s1.color === s2.color && s1.backgroundColor === s2.backgroundColor;
