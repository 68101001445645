import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { selectCurrentLocationWithPayload } from '../../app/locationsSelectors';
import { selectSelectedPayloadsWithHistoryAndSelectedStation } from '../../app/payloadSelectors';
import {
  getHeaderMap, getLocationCoordinates, getLocationAddress, isPostStation,
} from '../../helper/payloadmisc';
import { withStylesPropTypes } from '../../helper/misc';
import Loading from '../common/loading';
import Error from '../common/error';
import GeocoderAPI from '../../api/geocoder';
import MapView from '../common/map/mapview';
import Marker from '../common/map/marker';
import MapGeofence from './mapgeofence';
import STATIONS from '../../const/stations';


const DEFAULT_MAP_POSITION = {
  center: { lat: 37.0902, lng: -95.7129 },
  zoom: 5,
};

const useStyles = (() => ({
  root: {
  },
}));

function MapWidgetQA({
  currentLocation,
  payloads,
}) {
  const [isGeocoding, setGeocoding] = useState(false);
  const [error, setError] = useState(null);

  const [isPostGeofence, setIsPostGeofence] = useState(false);

  const [mapPosition, setMapPosition] = useState(DEFAULT_MAP_POSITION);
  const [markerPosition, setMarkerPosition] = useState();

  useEffect(() => {
    setError(null);
    let hasCoordinates = false;
    if (currentLocation && currentLocation.payload) {
      const headerMap = getHeaderMap(currentLocation.payload.headers);
      const coord = getLocationCoordinates(currentLocation, headerMap);
      if (coord) {
        hasCoordinates = true;
        setMapPosition({ center: coord, zoom: 18 });
        setMarkerPosition(coord);
      } else {
        const address = getLocationAddress(currentLocation, headerMap);
        if (address) {
          hasCoordinates = true;
          const addr = [];
          if (address.address) addr.push(address.address);
          if (address.city) addr.push(address.city);
          if (address.state) addr.push(address.state);
          if (address.zip) addr.push(address.zip);
          if (address.country) addr.push(address.country);
          setGeocoding(true);
          GeocoderAPI.geocode(addr.join(' '), address.country || address.countryCode)
            .then((r) => {
              setGeocoding(false);
              setMapPosition({ center: r.location, zoom: 16 });
              setMarkerPosition(r.location);
            })
            .catch((e) => {
              setGeocoding(false);
              setMapPosition(DEFAULT_MAP_POSITION);
              setError(e);
            });
        }
      }
    }

    if (!hasCoordinates) {
      setMapPosition(DEFAULT_MAP_POSITION);
    }
  }, [currentLocation]);

  useEffect(() => {
    setIsPostGeofence(isPostStation(payloads, STATIONS.GEOFENCE));
  }, [payloads]);

  const isLoading = isGeocoding;
  const errorMessage = error ? error.message : undefined;

  return isPostGeofence ? (
    <MapGeofence location={currentLocation} />
  ) : (
    <>
      { isLoading && (<Loading />) }
      { errorMessage && (<Error message={errorMessage} />) }

      <MapView center={mapPosition.center} zoom={mapPosition.zoom}>
        { markerPosition && (<Marker position={markerPosition} />)}
      </MapView>
      )

    </>
  );
}


MapWidgetQA.defaultProps = {
  currentLocation: null,
};

MapWidgetQA.propTypes = {
  ...withStylesPropTypes,
  currentLocation: PropTypes.shape(),
};

const mapStateToProps = (state, props) => ({
  currentLocation: selectCurrentLocationWithPayload(state, props),
  payloads: selectSelectedPayloadsWithHistoryAndSelectedStation(state, props),
});

export default connect(mapStateToProps, {
})(withStyles(useStyles)(MapWidgetQA));
