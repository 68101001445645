import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { withStylesPropTypes } from '../../helper/misc';

const useStyles = ((theme) => ({
  label: {
    color: theme.palette.primary.main,
  },
}));

function Pager({
  classes,
  current: defaultCurrent,
  total,
  label,
  onChange,
}) {
  const [current, setCurrent] = useState(defaultCurrent);
  useEffect(() => {
    setCurrent(defaultCurrent);
  }, [defaultCurrent]);

  const handleChange = (cur) => {
    if (cur >= 1 && cur <= total) {
      if (onChange) onChange(cur);
      else setCurrent(cur);
    }
  };

  return (
    <>
      <IconButton onClick={() => handleChange(current - 1)} variant="text" size="small" color="primary" disabled={current <= 1}>
        <ArrowLeftIcon />
      </IconButton>
      <span className={classes.label}>
        {current}
        {' '}
        /
        {' '}
        {total}
        {' '}
        {label}
      </span>
      <IconButton onClick={() => handleChange(current + 1)} variant="text" size="small" color="primary" disabled={current >= total}>
        <ArrowRightIcon />
      </IconButton>
    </>
  );
}

Pager.defaultProps = {
  current: 0,
  total: 0,
  label: null,
  onChange: null,
};

Pager.propTypes = {
  ...withStylesPropTypes,
  current: PropTypes.number,
  total: PropTypes.number,
  label: PropTypes.string,
  onChange: PropTypes.func,
};

export default withStyles(useStyles)(Pager);
