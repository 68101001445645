import React from 'react';
import PropTypes from 'prop-types';
import CellInput from './cellinput';

export const defaultCellRenderer = ({ value }) => <>{ value }</>;
defaultCellRenderer.propTypes = {
  value: PropTypes.string.isRequired,
};

export const defaultHeaderRenderer = ({ column }) => <>{ column.title || '' }</>;
defaultHeaderRenderer.propTypes = {
  column: PropTypes.shape({
    title: PropTypes.string,
  }).isRequired,
};


export const defaultCellEditRenderer = ({
  value, appendedValue, selection, onCancel, onCommit, onEditRange, onChange, autoFocus,
}) => (
  <CellInput autoFocus={autoFocus} value={value} appendedValue={appendedValue} selection={selection} onCancel={onCancel} onCommit={onCommit} onEditRange={onEditRange} onChange={onChange} />
);

defaultCellEditRenderer.defaultProps = {
  appendedValue: null,
  selection: null,
  autoFocus: false,
  onEditRange: () => { },
  onChange: () => { },
};

defaultCellEditRenderer.propTypes = {
  value: PropTypes.string.isRequired,
  appendedValue: PropTypes.string,
  autoFocus: PropTypes.bool,
  selection: PropTypes.shape(),
  onCancel: PropTypes.func.isRequired,
  onCommit: PropTypes.func.isRequired,
  onEditRange: PropTypes.func,
  onChange: PropTypes.func,
};
