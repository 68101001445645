import { createSelector } from '@reduxjs/toolkit';
import { selectListById, selectSelectedListId } from './listSelectors';

export const selectStandardHeadersByPayloadId = (state) => state.payload.standardHeadersByPayloadId;

export const selectStandardHeadersLoading = (state) => state.payload.isHeadersLoading;

export const selectStandardHeadersSaving = (state) => state.payload.isHeadersSaving;

export const selectStandardHeadersError = (state) => state.payload.headersError;

export const selectPayloadSaving = (state) => state.payload.isPayloadSaving;

export const selectPayloadError = (state) => state.payload.payloadError;

export const selectSelectedPayloadIds = (state) => state.payload.selectedPayloadIds;

export const selectPayloadById = (state) => state.payload.byId;

export const selectPayloadsByListId = (state) => state.payload.byListId;

export const selectSelectedPayloadHistory = (state) => state.payload.selectedPayloadHistory;

export const selectSelectedPayloadStation = (state) => state.payload.selectedPayloadStation;

export const selectLastHistoryLoading = (state) => state.payload.isLastHistoryLoading;

export const selectLastHistoryError = (state) => state.payload.lastHistoryError;

export const selectPayloadLastHistoryById = (state) => state.payload.payloadLastHistoryRecordById;

export const selectLastUpdated = (state) => state.payload.lastUpdated;

export const selectSelectedPayloads = createSelector(
  [selectSelectedPayloadIds, selectPayloadById],
  (selectedPayloadIds, payloadById) => Object.keys(selectedPayloadIds).map((id) => payloadById[id]).filter((p) => !!p),
);

export const selectSelectedListPayloads = createSelector(
  [selectSelectedListId, selectPayloadsByListId, selectPayloadById],
  (listId, payloadsByListId, payloadById) => payloadsByListId[listId] && payloadsByListId[listId].allIds && payloadsByListId[listId].allIds.map((id) => payloadById[id]).filter((p) => !!p),
);

export const selectSelectedPayloadsWithHistory = createSelector(
  [selectSelectedPayloads, selectSelectedPayloadHistory, selectPayloadLastHistoryById],
  (selectedPayloads, selectedPayloadHistory, payloadLastHistoryById) => {
    let ret = null;
    if (selectedPayloadHistory) {
      ret = selectedPayloads
        .filter((p) => p._id === selectedPayloadHistory.payloadId)
        .map((p) => ({ ...p, ...selectedPayloadHistory }));
    } else {
      ret = selectedPayloads;
    }
    if (Object.keys(payloadLastHistoryById).length) {
      ret = ret.map((p) => ({ ...p, lastHistoryRecord: payloadLastHistoryById[p._id] }));
    }
    return ret;
  },
);

export const selectSelectedPayloadsWithHistoryAndSelectedStation = createSelector(
  [selectSelectedPayloadsWithHistory, selectSelectedPayloadStation],
  (selectedPayloadsWithHistory, selectedPayloadStation) => {
    let ret = selectedPayloadsWithHistory;
    if (selectedPayloadsWithHistory && selectedPayloadStation) {
      const idx = selectedPayloadsWithHistory.findIndex((p) => p._id === selectedPayloadStation.payloadId);
      if (idx >= 0) {
        ret = ret.slice();
        ret[idx] = { ...ret[idx], selectedStation: selectedPayloadStation };
      }
    }
    return ret;
  },
);


export const selectSelectedPayloadsWithList = createSelector(
  [selectSelectedPayloads, selectListById],
  (payloads, listById) => payloads.map((payload) => ({ ...payload, list: listById[payload.listId] })),
);
