import { createSlice } from '@reduxjs/toolkit';
import api from '../api/locations';
import normalize from '../helper/normalize';
import { locationDataFields } from './locationsSelectors';

export const slice = createSlice({
  name: 'locations',
  initialState: {
    byId: {},
    byPayloadId: {},
    historyByPayloadId: {},
    selectedLocationIds: {},
    isLoading: false,
    isSaving: false,
    error: false,
    currentLocationId: null,
    currentField: null,
  },
  reducers: {
    getLocationsStart: (state) => {
      state.error = null;
      state.isLoading = true;
    },
    getLocationsSuccess: (state, { payload }) => {
      state.error = null;
      state.isLoading = false;
      const [allIds, byId] = normalize(payload.locations, '_id');
      state.byId = { ...state.byId, ...byId };
      state.byPayloadId[payload.payloadId] = { allIds, historyId: payload.historyId };
    },
    getLocationsFailed: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload.error;
    },
    invalidateLocationsByPayload: (state, { payload }) => {
      const { payloadId } = payload;
      const locationsByPayload = state.byPayloadId[payloadId];
      if (locationsByPayload && !locationsByPayload.historyId) {
        locationsByPayload.dirty = true;
      }
    },
    resetLocationSelection: (state) => {
      state.selectedLocationIds = {};
    },
    setLocationSelected: (state, { payload }) => {
      if (payload.select !== undefined && !payload.select) delete state.selectedLocationIds[payload.id];
      else state.selectedLocationIds[payload.id] = true;
    },
    setCurrentLocation: (state, { payload }) => {
      const { id, field } = payload;
      state.currentLocationId = id;
      state.currentField = field;
    },
    updateLocationsStart: (state, { payload }) => {
      state.isSaving = true;
      state.error = null;
      payload.forEach((record) => {
        if(record)
        {
          Object.assign(state.byId[record._id], record);
        }
      });
    },
    updateLocationsSuccess: (state, { payload }) => {
      state.isSaving = false;
      state.error = null;
      const { updated } = payload;
      updated.forEach((record) => {
        const current = state.byId[record._id];
        locationDataFields(record).forEach((key) => {
          const updatedValue = record[key];
          let currentValue = current[key];
          if (currentValue && currentValue.value !== undefined && currentValue.value !== null) currentValue = currentValue.value;
          if (currentValue !== updatedValue) current[key] = updatedValue;
        });
      });
    },
    updateLocationsFailed: (state, { payload }) => {
      state.isSaving = false;
      state.error = payload.error;
    },
    deleteLocationsStart: (state, { payload }) => {
      state.isSaving = true;
      state.error = null;
      const { locationIds } = payload;
      locationIds.forEach((id) => {
        const location = state.byId[id];
        if (location && location.payloadId) {
          const byPayload = state.byPayloadId[location.payloadId];
          const { allIds } = byPayload;
          if (allIds) {
            byPayload.allIds = allIds.filter((i) => i !== id);
          }
        }
        delete state.byId[id];
      });
    },
    deleteLocationsSuccess: (state) => {
      state.isSaving = false;
      state.error = null;
    },
    deleteLocationsFailed: (state, { payload }) => {
      state.isSaving = false;
      state.error = payload.error;
    },
    approveLocationsStart: (state) => {
      state.isSaving = true;
      state.error = null;
    },
    approveLocationsSuccess: (state, { payload }) => {
      state.isSaving = false;
      state.error = null;
      const { updated } = payload;
      updated.forEach((record) => {
        state.byId[record._id] = record;
      });
    },
    approveLocationsFailed: (state, { payload }) => {
      state.isSaving = false;
      state.error = payload.error;
    },
    updateLocationFields: (state, { payload }) => {
      const { _id: id, ...changes } = payload;
      const location = state.byId[id];
      if (location) {
        state.byId[id] = { ...location, ...changes };
      }
    },
  },
});

export const {
  getLocationsStart,
  getLocationsSuccess,
  getLocationsFailed,
  setLocationSelected,
  resetLocationSelection,
  setCurrentLocation,
  updateLocationsStart,
  updateLocationsSuccess,
  updateLocationsFailed,
  deleteLocationsStart,
  deleteLocationsSuccess,
  deleteLocationsFailed,
  approveLocationsStart,
  approveLocationsSuccess,
  approveLocationsFailed,
  invalidateLocationsByPayload,
  updateLocationFields,
} = slice.actions;

export default slice.reducer;

export const fetchLocations = (payloadId, historyId) => (dispatch) => {
  dispatch(getLocationsStart());
  api.fetchLocations(payloadId, historyId).then((result) => {
    dispatch(getLocationsSuccess({ payloadId, historyId, locations: result }));
  }).catch((error) => {
    dispatch(getLocationsFailed({ error }));
  });
};

export const updateLocations = (action, changesToPost, tempChanges) => (dispatch) => new Promise((resolve, reject) => {
  const changesForApi = changesToPost.map((record) => {
    const newRecord = { _id: record._id };
    locationDataFields(record).forEach((key) => {
      const val = record[key];
      newRecord[key] = val.value !== undefined && val.value !== null ? val.value : val;
    });
    return newRecord;
  });

  dispatch(updateLocationsStart([...changesToPost, ...tempChanges]));
  if (changesForApi.length) {
    api.updateLocations(action, changesForApi).then((result) => {
      dispatch(updateLocationsSuccess({ updated: result }));
      resolve();
    }).catch((error) => {
      dispatch(updateLocationsFailed({ error }));
      reject(error);
    });
  } else {
    dispatch(updateLocationsSuccess({ updated: [] }));
    resolve();
  }
});

export const deleteLocations = (locationIds) => (dispatch) => {
  dispatch(deleteLocationsStart({ locationIds }));
  api.deleteLocations(locationIds).then(() => {
    dispatch(deleteLocationsSuccess());
  }).catch((error) => {
    dispatch(deleteLocationsFailed({ error }));
  });
};
