import React, { useState, useCallback, useEffect, useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import ErrorIcon from '@material-ui/icons/Error';
import { connect } from 'react-redux';
import { withStyles, useTheme } from '@material-ui/core/styles';
import {
  updateLocations, deleteLocations, setCurrentLocation,
} from '../../app/locationsSlice';
import {
  deleteHeader, addHeader, pushHeader, approveLocations, logCsvDownload,
} from '../../app/payloadSlice';
import { selectListById } from '../../app/listSelectors';
import { resolve, formatDate, withStylesPropTypes } from '../../helper/misc';
import {
  getLocationFieldStationData, isFieldValueApproved, getPayloadFileName, getPayloadFileNameForExcel,
} from '../../helper/payloadmisc';
import Sheet from '../common/sheet/sheet';
import DataProvider from '../common/sheet/dataprovider';
import { defaultCellRenderer } from '../common/sheet/renderers';
import { getCellUpdates } from '../common/sheet/cellsmisc';
import Confirm from '../common/confirm';
import LocationColumnHeader from './locationcolumnheader';
import PayloadFieldMatch from './payloadfieldmatch';
import {
  buildFormulaEditContextMenu, buildCellContextMenu, buildColumnContextMenu, LS_MENU_COMMANDS,
} from './contextmenu';
import STATIONS, { STATION_STATUS } from '../../const/stations';
import LocationSheetQaSelect from './locationssheetqaselect';
import {HomeScreen} from '../../pages/home';
import BasicModal from '../common/modal';


const GROUP_STYLES = [
  { backgroundColor: '#f9cdca' },
  { backgroundColor: '#f9f8ca' },
  { backgroundColor: '#caf8db' },
  { backgroundColor: '#dae0f8' },
];

const WHITE_STYLES = [
  { backgroundColor: '#ffffff'},
]


const useStyles = ((theme) => ({
  cellStation: {
    display: 'block',
    position: 'absolute',
    right: -theme.spacing(0.5),
    top: -theme.spacing(0.5),
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    zIndex: 100,
    '&>*': {
      width: theme.spacing(1.5),
      height: theme.spacing(1.5),
    },
  },
  cellStationFailed: {
    color: theme.palette.secondary.main,
  },
  cellStationAutofix: {
    color: theme.palette.primary.main,
  },
  cellStationManual: {
    color: theme.palette.warning.main,
  },
  cellTitleCheckbox: {
    display : "flex",
    justifyContent : "center",
  },
  cellPushed: {
    color: theme.palette.warning.main
  }

}));

const CORE_FIELDS = ['address','addressLine2','city','state','zipCode','country','countryCode','latitude','longitude']

const LS_ACTIONS = {
  RESTORE: 'Restore',
};

const DOWNLOAD_OPTIONS = {
  QAE: 'qae',
  EXCEL: 'excel',
};

function LocationsSheetQA({
  classes,
  exportDataProvider,
  tableColumns,
  locations: originalLocations,
  payloads,
  listsById,
  snapshotCell,
  standardHeaders,
  droppedHeaders,
  fnUpdateLocations,
  fnDeleteLocations,
  fnApproveLocations,
  fnSetCurrentLocation,
  fnDeleteHeader,
  fnAddHeader,
  fnPushHeader,
  fnLogCsvDownload,
  dimensions,
}) {
  const defaultTheme = useTheme();

  const defaultCellStyle = {
    color: defaultTheme.palette.text.primary,
    backgroundColor: defaultTheme.palette.background.paper,
  };

  const newHeaderCellStyle = {
    color: defaultTheme.palette.success.main,
  };

  const uncommonHeaderCellStyle = {
    color: defaultTheme.palette.warning.main,
  };

  const droppedHeaderCellStyle = {
    color: defaultTheme.palette.secondary.main,
    textDecoration: 'line-through',
  };

  const {isSubmit, groupArray, locationsView,qaTableColumns, appMode, selectedLocation, setSelectedLocation, deleteLocationIds, 
    setDeleteLocationIds , duplicateGroupCounts, handleDedupeSubmit, editingCellContent, actionsList, setActionsList, updatesPostList, setUpdatesPostList, 
    tempUpdatesList, setTempUpdatesList, locationToApproveList,setLocationToApproveList, dedupeModal, setDedupeModal, handleCheckboxLocationSelect,
    handleChangeSync,dataToApproveValues,setDataToApproveValues,headerToPushValues,setHeaderToPushValues,pushHeaderPayloadId,setPushHeaderPayloadId, homeSheetRef} = useContext(HomeScreen)

  const [effectiveListId, setEffectiveListId] = useState();
  const [droppedHeadersMatch, setDroppedHeadersMatch] = useState([]);
  const [droppedHeadersMatchField, setDroppedHeadersMatchField] = useState();
  const [locations, setLocations] = useState(originalLocations);
  const [deleteLocationsConfirm, setDeleteLocationsConfirm] = useState();
  const [deleteColumnsConfirm, setDeleteColumnsConfirm] = useState();
  const [hiddenColumns, setHiddenColumns] = useState({});
  const [finalTableColumns, setFinalTableColumns] = useState(qaTableColumns);
  const {setGeoCodePassed,geoCodePassed} = useContext(HomeScreen);

  const {editForSync, setEditForSync, syncEditValues, setSyncEditValues} = useContext(HomeScreen);

  useEffect(() => {
    const listIds = payloads.filter((p) => !p.historyId)
      .map((payload) => payload.listId)
      .filter((value, index, self) => self.indexOf(value) === index);
    setEffectiveListId(listIds.length === 1 ? listIds[0] : undefined);
  }, [payloads]);

  useEffect(() => {
    if (payloads && payloads.length === 1) {
      const [payload] = payloads;
      const { stations } = payload;
      const processedStations = Object.keys(stations || {}).reduce((m, i) => {
        m[i] = true;
        return m;
      }, {});
      if("geography" in processedStations && processedStations['geography']) setGeoCodePassed(true);
    }
  }, [payloads]);

  useEffect(() => {
    if (droppedHeaders && droppedHeaders.payload.headersOrder && locations && tableColumns) {
      const countLocationValues = (list, header) => list.reduce((m, l) => {
        const value = l[header];
        if (value) {
          if (m[value]) m[value] += 1;
          else m[value] = 1;
        }
        return m;
      }, {});

      const currentPayloadHeaders = tableColumns.filter((c) => c.isPayloadColumn).reduce((m, c) => { m[c.data] = true; return m; }, {});
      const matchingFields = droppedHeaders.payload.headersOrder
        .filter((h) => currentPayloadHeaders[h])
        .map((h) => {
          const locationValuesMap = countLocationValues(locations, h);
          const matchedLocationsMap = countLocationValues(droppedHeaders.locations, h);
          const matchCount = Object.keys(matchedLocationsMap).filter((v) => matchedLocationsMap[v] === 1 && locationValuesMap[v] === 1).length;
          return {
            header: h,
            matchCount,
            total: locations.length,
          };
        })
        .filter((m) => m.matchCount > 0);
      matchingFields.sort((a, b) => b.matchCount - a.matchCount);
      setDroppedHeadersMatch(matchingFields);
    }
  }, [droppedHeaders, locations, tableColumns]);

  useEffect(() => {
    let columns = qaTableColumns;
    setFinalTableColumns(columns);
  }, [qaTableColumns, hiddenColumns,isSubmit, selectedLocation]);


  useEffect(() => {
    if (droppedHeadersMatchField && droppedHeaders && droppedHeaders.locations && tableColumns) {
      const droppedHeaderColumns = tableColumns.filter((c) => c.stationHeader === STATIONS.DROPPED_HEADERS);
      const map = droppedHeaders.locations.reduce((m, l) => {
        const key = l[droppedHeadersMatchField];
        if (key) {
          if (m[key] || m[key] === null) m[key] = null;
          else m[key] = l;
        }
        return m;
      }, {});

      const headersLookup = Object.keys(droppedHeaders.payload.headers).reduce((m, h) => {
        m[droppedHeaders.payload.headers[h]] = h;
        return m;
      }, {});

      const newLocations = originalLocations.map((l) => {
        const location = { ...l };
        const key = location[droppedHeadersMatchField];
        const matchedLocation = map[key];
        if (matchedLocation) {
          droppedHeaderColumns.forEach((col) => {
            if (matchedLocation) {
              location[col.data] = matchedLocation[headersLookup[col.data]];
            }
          });
        }
        return location;
      });
      setLocations(newLocations);
    } else {
      setLocations(originalLocations);
    }
  }, [droppedHeadersMatchField, droppedHeaders, originalLocations, tableColumns]);

  const getCellValue = useCallback(({ row, column }) => {
    let ret = resolve(row, column.data);
    if (column.formatter) {
      ret = column.formatter(ret);
    } else if (column.type && column.type === 'date') {
      ret = formatDate(ret);
    }
    return ret;
  }, []);

  const getRowKey = useCallback((row) => row._id, []);

  const getColumnKey = useCallback((column) => column.data, []);

  const getCellStyle = useCallback(({ column,data }) => {
    if (column) {
      if(headerToPushValues && headerToPushValues[column.match])
      {
        return undefined;
      } 
      switch (column.stationHeader) {
        case STATIONS.NEW_HEADERS: return newHeaderCellStyle;
        case STATIONS.UNCOMMON_HEADERS: return uncommonHeaderCellStyle;
        case STATIONS.DROPPED_HEADERS: return droppedHeaderCellStyle;
        default: break;
      }
    }    
    if (appMode===0 && data && data.group) {
        if(duplicateGroupCounts[(data.group-1)]===1)
        {
          return WHITE_STYLES[0];
        }
        else
        {
          return GROUP_STYLES[data.group % GROUP_STYLES.length];
        }
    }
    return undefined;
  }, [newHeaderCellStyle, uncommonHeaderCellStyle, droppedHeaderCellStyle,groupArray]);

  const createExportDataProvider = useCallback((option, fileName) => new Promise((presolve) => {
    const exportTableColumns = tableColumns.slice();
    let exportLocations = originalLocations.slice();
    if (option === DOWNLOAD_OPTIONS.EXCEL) {
      exportTableColumns.splice(0, 0, {
        data: 'id',
      });
      exportLocations = exportLocations.map((l) => ({ ...l, id: l._id }));
      const [payload] = payloads;
      fnLogCsvDownload(payload._id, `${fileName}.csv`);
    }
    presolve(new DataProvider(exportLocations, exportTableColumns, {}, {}, getCellValue, ({ row, column }) => `${getRowKey(row)}/${getColumnKey(column)}`));
  }), [originalLocations, tableColumns, getCellValue, getRowKey, getColumnKey, payloads, fnLogCsvDownload]);

  useEffect(() => {
    if (exportDataProvider) {
      exportDataProvider({
        getDownloadOptions: () => [
          { title: 'To Continue QA in Excel', key: DOWNLOAD_OPTIONS.EXCEL },
          { title: 'To Upload Payload To QAE', key: DOWNLOAD_OPTIONS.QAE },
        ],
        getDataProvider: createExportDataProvider,
        hasData: () => payloads && payloads.length === 1 && originalLocations && originalLocations.length && listsById && listsById[payloads[0].listId],
        getFileName: (option) => {
          let ret;
          const [payload] = payloads;
          const list = listsById && listsById[payload.listId];
          switch (option) {
            case DOWNLOAD_OPTIONS.EXCEL:
              ret = getPayloadFileNameForExcel(list, payload);
              break;
            case DOWNLOAD_OPTIONS.QAE:
              ret = getPayloadFileName(list, payload);
              break;
            default:
              ret = `export-${list.listId}`;
              break;
          }
          return ret;
        },
      });
    }
  }, [exportDataProvider, payloads, listsById, originalLocations, createExportDataProvider]);

  const cellRenderer = ({ row, column, value }) => {
    let failed = false;
    let autofix = false;
    let manual = false;
    if (payloads.length === 1) {
      const [payload] = payloads;
      const fieldData = getLocationFieldStationData(row, column.data, payload.selectedStation);
      failed = fieldData.some((d) => d.status === STATION_STATUS.FAILED);
      if (!failed) {
        manual = fieldData.some((d) => d.status === STATION_STATUS.MANUAL);
        if (!manual) {
          autofix = fieldData.some((d) => d.status === STATION_STATUS.AUTOFIX);
        }
      }
    }
    const key = row['_id']+"_"+column["title"]
    if (failed || autofix || manual) {
      return (
        <>
          {value}
          <span
          // eslint-disable-next-line react/prop-types
            className={clsx(classes.cellStation, failed && (syncEditValues[key]===value || (dataToApproveValues && dataToApproveValues[key])) ? classes.cellStationManual: failed && classes.cellStationFailed, autofix && classes.cellStationAutofix, manual && classes.cellStationManual)}
          >
            <ErrorIcon />
          </span>
        </>
      );
    }
    return defaultCellRenderer({ value });
  };

  cellRenderer.defaultProps = {
    row: {},
    column: {},
    value: '',
  };

  cellRenderer.propTypes = {
    row: PropTypes.shape,
    column: PropTypes.shape,
    value: PropTypes.shape,
  };

  const addLocationsDataToApprove = (locationsToApprove, location, field, value) => {
    if (payloads.length === 1) {
      const [payload] = payloads;

      const fieldData = getLocationFieldStationData(location, field, payload.selectedStation)
        .filter((d) => !!d.error && !isFieldValueApproved(d));
      fieldData.forEach((d) => {
        let la = locationsToApprove[location._id];
        if (!la) {
          la = {};
          locationsToApprove[location._id] = la;
        }
        let station = la[d.station];
        if (!station) {
          station = {};
          la[d.station] = station;
        }
        let substation = station[d.substation];
        if (!substation) {
          substation = {};
          station[d.substation] = substation;
        }
        substation[field] = value;
      });
    }
  };


  const updateRows = async (action, updates) => {
    if (updates) {
      const tempFields = tableColumns.filter((c) => !c.match).reduce((m, e) => { m[e.data] = true; return m; }, {});
      const tempUpdates = [];
      const updatesToPost = [];

      const locationsToApprove = {};

      let updateEditSyncValues = {}
      setEditForSync(true);
      updates.forEach((update) => {
        let temp;
        let post;

        const rowKey = update.row._id;

        Object.keys(update.changes).forEach((columnKey) => {
          const key = rowKey+"_"+columnKey;
          updateEditSyncValues[key] = update.changes[columnKey]
        });

        Object.keys(update.changes).forEach((field) => {
          if(CORE_FIELDS.indexOf(field)>=0 && geoCodePassed){
            const geoAccuracyPost = {geoAccuracy: ''};
            updatesToPost.push({ _id: update.row._id, ...geoAccuracyPost });
          }
          let collection;
          if (tempFields[field]) {
            if (!temp) temp = {};
            collection = temp;
          } else {
            if (!post) post = {};
            collection = post;
            if (payloads.length === 1) {
              const val = update.changes[field];
              addLocationsDataToApprove(locationsToApprove, update.row, field, val.value !== undefined && val.value !== null ? val.value : val);
            }
          }
          collection[field] = update.changes[field];
        });
        if (temp) tempUpdates.push({ _id: update.row._id, ...temp });
        if (post) updatesToPost.push({ _id: update.row._id, ...post });
      });      
      setActionsList([...actionsList,action]);
      setUpdatesPostList([...updatesPostList,updatesToPost]);
      setTempUpdatesList([...tempUpdatesList,tempUpdates]);
      setLocationToApproveList([...locationToApproveList,locationsToApprove]);
      setSyncEditValues({...syncEditValues, ...updateEditSyncValues})
    }
  };
  const isHistoricalSnapshot = () => !!snapshotCell;

  const canModifyColumns = () => (payloads.length === 1 && !isHistoricalSnapshot());

  const canMatchTo = (matchTo) => !tableColumns.some((c) => c.match === matchTo);

  const getDataToApprove = (range, dataProvider) => {
    let result;
    if (payloads.length === 1) {
      const locationsToApprove = {};
      dataProvider.enumerateRangeCells(range).forEach(({ row: rrow, col: rcol }) => {
        const column = dataProvider.getRawColumn(rcol);
        const location = dataProvider.getRawRow(rrow);
        addLocationsDataToApprove(locationsToApprove, location, column.data, dataProvider.getEvaluatedCellValue({ row: rrow, col: rcol }));
      });
      result = Object.keys(locationsToApprove).map((id) => ({
        _id: id,
        stations: locationsToApprove[id],
      }));
      if (result.length === 0) result = undefined;
    }
    return result;
  };

  const buildContextMenu = (range, { col, row }, dataProvider, formulaEditMode) => {

    if (formulaEditMode) {
      return buildFormulaEditContextMenu(col);
    }
    if (row === undefined) {
      const menu = buildColumnContextMenu(range, col, dataProvider, defaultCellStyle, {
        modify: canModifyColumns(),
      });
      if (payloads.length === 1) {
        const canPush = dataProvider.enumerateColumns(range).some((colIdx) => {
          const column = dataProvider.getRawColumn(colIdx);
          return headerToPushValues && headerToPushValues[column.match] ? false : column && column.stationHeader;
        });
        if (canPush) {
          menu.push({ id: LS_MENU_COMMANDS.PUSH_COLUMN, title: 'Push' });
        }
      }
      return menu;
    }
    const menu = buildCellContextMenu(range, col, dataProvider, defaultCellStyle, {
      restore: isHistoricalSnapshot(),
      deleteLocation: !isHistoricalSnapshot(),
    });

    const dataToApprove = getDataToApprove(range, dataProvider);
    const key = (dataToApprove && col) && dataToApprove[0]['_id']+"_"+tableColumns[col]['title']
    if (dataToApprove && !(dataToApproveValues && dataToApproveValues[key])) {
      menu.push({ id: LS_MENU_COMMANDS.PUSH_CELLS, title: 'Push', data: dataToApprove });
    }

    return menu;
  };

  const handleColumnInsert = ({ col }, before, name, temporary) => {
    if (canModifyColumns()) {
      let position = payloads[0].headersOrder.indexOf(tableColumns[col].payloadKey);
      if (position >= 0 && !before) position += 1;
      else if (position < 0) position = undefined;
      fnAddHeader(payloads[0]._id, name, position, temporary);
    }
  };

  const handleColumnDelete = (columns) => {
    if (canModifyColumns()) {
      const columnsToDelete = columns.map(({ column }) => ({ name: column.payloadKey, temporary: !column.match }));
      setDeleteColumnsConfirm(columnsToDelete);
    }
  };

  const handleDeleteColumnsConfirm = (answer) => {
    if (answer === 'Yes') {
      fnDeleteHeader(payloads[0]._id, deleteColumnsConfirm);
    }
    setDeleteColumnsConfirm();
  };

  const handleRangeRestore = (range, dataProvider) => {
    const updates = getCellUpdates(
      dataProvider.enumerateRangeCells(range),
      (cell) => dataProvider.getEvaluatedCellValue(cell),
      ({ row }) => dataProvider.getRawRow(row),
      ({ col }) => dataProvider.getRawColumn(col),
      getColumnKey,
      (column) => column && column.isPayloadColumn,
    );
    updateRows(LS_ACTIONS.RESTORE, updates);
  };

  const handleDeleteLocations = (range, dataProvider) => {
    const rows = dataProvider.enumerateRangeCells(range).reduce((m, { row }) => {
      m[row] = true;
      return m;
    }, {});

    const locationIds = Object.keys(rows).map((row) => dataProvider.getRawRow(row)).filter((l) => !!l).map((l) => l._id);
    if (locationIds.length) setDeleteLocationsConfirm(locationIds);
  };

  const handleDeleteLocationsConfirm = (answer) => {
    if (answer === 'Yes') {
      fnDeleteLocations(deleteLocationsConfirm);
    }
    setDeleteLocationsConfirm();
  };

  const handlePushColumn = (range, dataProvider) => {
    if (payloads.length === 1) {
      const names = dataProvider.enumerateColumns(range)
        .map((colIdx) => dataProvider.getRawColumn(colIdx))
        .filter((col) => !!col && col.stationHeader)
        .map((col) => col.match || col.data);
      const [payload] = payloads;
      setEditForSync(true)
      let tempHeaderToPushValues = {};
      names.forEach((name)=>{
        tempHeaderToPushValues[name] = name
      })
      setPushHeaderPayloadId(payload._id)
      setHeaderToPushValues({...headerToPushValues,...tempHeaderToPushValues});
    }
  };


  const handlePushCells = async (dataToApprove) => {
    setEditForSync(true);
    let tempDataToApproveValues = {};
    if(dataToApprove.length>0)
    {
      for(let index = 0; index<dataToApprove.length;index++)
      {
        const columns = Object.keys(await getColumnNameFromDataToApprove(dataToApprove[index],dataToApprove[index]['stations']));
        columns.forEach((column)=>
        {
          const key =dataToApprove[index]['_id']+"_"+column
          tempDataToApproveValues[key] = dataToApprove[index];
        })
        
      }
    }
    setDataToApproveValues({...dataToApproveValues,...tempDataToApproveValues});
  };


const getColumnNameFromDataToApprove = (dataToApproveObject, dataToApprove) =>{
  if (typeof dataToApprove !== 'object'){
    return dataToApproveObject;
  }
  let keys = Object.keys(dataToApprove)
  for (let index=0 ; index<keys.length;index++)
  {
    return getColumnNameFromDataToApprove(dataToApprove,dataToApprove[keys[index]])
  }
}

  const handleContextMenuCommand = (item, subItem, cell, range, dataProvider) => {
    if (item.id === LS_MENU_COMMANDS.RESTORE) handleRangeRestore(range, dataProvider);
    else if (item.id === LS_MENU_COMMANDS.DELETE_LOCATION) handleDeleteLocations(range, dataProvider);
    else if (item.id === LS_MENU_COMMANDS.PUSH_COLUMN) handlePushColumn(range, dataProvider);
    else if (item.id === LS_MENU_COMMANDS.PUSH_CELLS) handlePushCells(item.data);
  };

  const handleLocationSelect = (location, field) => {
    fnSetCurrentLocation({ id: location._id, field });
  };

  return (
    <>
      {
        deleteLocationsConfirm && (
          <Confirm
            text={`Are you sure you want to delete ${deleteLocationsConfirm.length} location${deleteLocationsConfirm.length > 1 ? 's' : ''}?`}
            buttons={['Yes', 'No']}
            focusedButton="Yes"
            onClose={(answer) => handleDeleteLocationsConfirm(answer)}
          />
        )
      }
      {
        deleteColumnsConfirm && (
          <Confirm
            text={`Are you sure you want to delete ${deleteColumnsConfirm.length} column${deleteColumnsConfirm.length > 1 ? 's' : ''}?`}
            buttons={['Yes', 'No']}
            focusedButton="Yes"
            onClose={(answer) => handleDeleteColumnsConfirm(answer)}
          />
        )
      }
     {
        dedupeModal.open && (
          <BasicModal
            text={dedupeModal.message}
            buttons={['Close']}
            focusedButton="Yes"
            onClose={() => setDedupeModal({open: false, message: ''})}
          />
        )
      }
      <Sheet
        defaultCellStyle={defaultCellStyle}
        selectedCellKey={snapshotCell}
        rows={(isSubmit && appMode===0) ? locationsView : locations}
        columns={appMode === 0 ? (isSubmit ? finalTableColumns.map((c)=>{if (c.data === '_id') {
          return {
            ...c,
            renderer: ({ value, keyDownEvent }) => <div onClick={()=>handleCheckboxLocationSelect(value)}><LocationSheetQaSelect value={value} selected={selectedLocation.indexOf(value)>=0 ? true : false} keyDownEvent={keyDownEvent}/></div>
          };
        }
        return c;}): finalTableColumns) : tableColumns}

        fixedColumnCount={1}
        onRowsUpdated={(action, updates) => updateRows(action, updates)}
        headerRenderer={({
          column, columnIndex, selectColumn, sort, filter, selected,
        }) => (
          <LocationColumnHeader
            sortable={column.sortable}
            title={column.title}
            match={column.match}
            hasBorder={!!column.payloadKey}
            columnIndex={columnIndex}
            columnKey={column.data}
            columnMatch={!!column.isPayloadColumn}
            selectColumn={selectColumn}
            standardHeaders={standardHeaders}
            payloadIds={column.payloadIds}
            listId={effectiveListId}
            canEdit={!!column.payloadKey && canModifyColumns()}
            canMatchTo={(match) => canMatchTo(match)}
            stationHeader={(headerToPushValues&&headerToPushValues[column.match])? undefined :  column.stationHeader}
            titleComponent = {appMode === 0 ? (column.data==='_id' && isSubmit) && <div style={{marginLeft:"37%", marginTop:"5%"}} onClick={()=>handleCheckboxLocationSelect('all')}><LocationSheetQaSelect value={'all'} selected={selectedLocation.indexOf('all')>=0 ?true:false}/></div> : column.stationHeader === STATIONS.DROPPED_HEADERS && <PayloadFieldMatch title={column.title} matchingHeaders={droppedHeadersMatch} onSelect={setDroppedHeadersMatchField}/>} 
            sort={!!sort}
            filter={filter}
            selected={selected}
            columnWeightPlaceholder={true}
            columnWeight={!!column.isPayloadColumn || column.data === '_id'}
            fullWidth={column.data === '_id'}
          />
        )}
        getCellValue={getCellValue}
        getRowKey={getRowKey}
        getColumnKey={getColumnKey}
        getCellStyle={getCellStyle}
        cellRenderer={({
          row, column, value, keyDownEvent, rowIndex,
        }) => (column.renderer ? column.renderer({
          row, value, keyDownEvent, rowIndex,
        }) : cellRenderer({
          row, column, value,
        }))}
        contextMenuBuilder={(range, cell, dataProvider, formulaEditMode) => buildContextMenu(range, cell, dataProvider, formulaEditMode)}
        onContextMenuSelected={(item, subItem, cell, range, dataProvider) => handleContextMenuCommand(item, subItem, cell, range, dataProvider)}
        onColumnInsert={({ col }, before, name, temporary) => handleColumnInsert({ col }, before, name, temporary)}
        onColumnDelete={(columns) => handleColumnDelete(columns)}
        onSelected={(cell, { row, column }) => handleLocationSelect(row, column)}
        formulaBar
        dimensions = {dimensions}
        handleChangeSync = {handleChangeSync}
        ref={homeSheetRef}
      />
    </>
  );
}

LocationsSheetQA.defaultProps = {
  locations: [],
  tableColumns: [],
  payloads: [],
  snapshotCell: null,
  standardHeaders: [],
  exportDataProvider: null,
  droppedHeaders: null,
};

LocationsSheetQA.propTypes = {
  ...withStylesPropTypes,
  locations: PropTypes.arrayOf(PropTypes.shape),
  tableColumns: PropTypes.arrayOf(PropTypes.shape),
  payloads: PropTypes.arrayOf(PropTypes.shape),
  standardHeaders: PropTypes.arrayOf(PropTypes.string),
  snapshotCell: PropTypes.shape(),
  exportDataProvider: PropTypes.func,
  droppedHeaders: PropTypes.shape({
    payload: PropTypes.shape(),
    locations: PropTypes.arrayOf(PropTypes.shape()),
  }),
};

const mapStateToProps = (state, props) => ({
  listsById: selectListById(state, props),
});

export default connect(mapStateToProps, {
  fnSetCurrentLocation: setCurrentLocation,
  fnUpdateLocations: updateLocations,
  fnDeleteLocations: deleteLocations,
  fnApproveLocations: approveLocations,
  fnDeleteHeader: deleteHeader,
  fnAddHeader: addHeader,
  fnPushHeader: pushHeader,
  fnLogCsvDownload: logCsvDownload,
})(withStyles(useStyles)(LocationsSheetQA));
