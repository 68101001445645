import { Parser as FormulaParser } from 'hot-formula-parser';
import { resetRange, addCellsToRange, enumerateCells, enumerateColumns } from './cellsrange';
import { processConditions } from './conditionsmisc';
import { isFormula, restoreFormulaRangesFromRelative } from './formulamisc';

export default class DataProvider {
  constructor(rows, columns, cellsFormat, columnsFilter, fnGetCellValue, fnGetCellKey) {
    this.getRawCellValue = fnGetCellValue.bind(this);
    this.getRawCellKey = fnGetCellKey.bind(this);
    this.rows = rows;
    this.columns = columns;
    this.cellsFormat = cellsFormat;
    this.columnsFilter = columnsFilter;
    this.cacheMap = {};

    const cellRefToCellCoordinate = (cellRef) => ({ row: cellRef.row.index, col: cellRef.column.index });

    this.parser = new FormulaParser();
    this.parser.on('callCellValue', (cellRef, done) => {
      const value = this.getEvaluatedCellValue(cellRefToCellCoordinate(cellRef));
      done(value);
    });

    this.parser.on('callRangeValue', (startCellRef, endCellRef, done) => {
      const range = addCellsToRange(resetRange(), cellRefToCellCoordinate(startCellRef), cellRefToCellCoordinate(endCellRef));
      const fragment = this.enumerateRangeCells(range).map((cell) => this.getEvaluatedCellValue(cell) || null);
      done(fragment);
    });
  }

  cache(funcName, key, fnGetValue, invalidate) {
    let cache = this.cacheMap[funcName];
    if (!cache) {
      cache = {};
      this.cacheMap[funcName] = cache;
    }
    let result = cache[key];
    if (!result || invalidate) {
      result = fnGetValue(key);
      cache[key] = result;
    }
    return result;
  }

  getRawRow(rowIndex) {
    return this.rows[rowIndex];
  }

  getRawColumn(colIndex) {
    return this.columns[colIndex];
  }

  getRawCell(coord) {
    return { row: this.rows[coord.row], column: this.columns[coord.col] };
  }

  getCellValue(coord) {
    const rawCell = this.getRawCell(coord);
    return rawCell && rawCell.row && rawCell.column ? this.getRawCellValue(rawCell) : undefined;
  }

  getCellKey(coord) {
    const rawCell = this.getRawCell(coord);
    return rawCell && rawCell.row && rawCell.column ? this.getRawCellKey(rawCell) : undefined;
  }

  enumerateRangeCells(range) {
    return enumerateCells({ range, totalColumns: this.columns.length, totalRows: this.rows.length });
  }

  enumerateColumns(range) {
    return enumerateColumns({ range, totalColumns: this.columns.length });
  }


  getCellStyle(coord) {
    return this.cache('getCellStyle', this.getCellKey(coord), (cellKey) => {
      const cellFormat = cellKey && this.cellsFormat[cellKey];
      return cellFormat ? { ...cellFormat.style, ...processConditions(coord, cellFormat.conditions, this) } : {};
    });
  }

  getEvaluatedCellValue(coord) {
    return this.evaluateCellValue(this.getCellValue(coord), coord);
  }

  evaluateCellValue(value, coord, recalculate) {
    const key = this.getCellKey(coord);
    return this.cache('evaluateCell', key, () => {
      let result = String(value || '');
      if (isFormula(result)) {
        const formula = restoreFormulaRangesFromRelative(coord, result).substr(1);
        if (formula.length) {
          const parseResult = this.parser.parse(formula);
          if (parseResult.error) result = parseResult.error;
          else result = parseResult.result.toString();
        }
      }
      return result;
    }, recalculate);
  }
}
