import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { withStylesPropTypes } from '../../../helper/misc';
import KeyboardHandle from '../../../helper/keyboard';

const useStyles = ((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

function FilterByTextDlg({
  classes,
  visible,
  onClose,
}) {
  const [text, setText] = useState('');
  const handleClose = () => {
    onClose();
  };

  const handleOK = () => {
    onClose(text);
  };

  const handleKeyDown = (e) => {
    KeyboardHandle(e, {
      onEnter: () => handleOK(),
      onEsc: () => handleClose(),
    });
  };

  return visible && (
  <Dialog open onClose={() => onClose()} maxWidth="xs" fullWidth classes={{ paper: classes.paper }}>
    <DialogTitle>
      <Typography>Filter By Text</Typography>
      <IconButton aria-label="close" className={classes.closeButton} onClick={() => onClose()}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent>
      <TextField
        label="Text"
        autoFocus
        fullWidth
        size="small"
        variant="outlined"
        value={text}
        onChange={(e) => setText(e.target.value)}
        onKeyDown={(e) => handleKeyDown(e)}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={handleOK} color="primary">
        OK
      </Button>
      <Button onClick={handleClose} color="primary">
        Cancel
      </Button>
    </DialogActions>
  </Dialog>
  );
}

FilterByTextDlg.defaultProps = {
  onClose: () => { },
  visible: false,
};

FilterByTextDlg.propTypes = {
  ...withStylesPropTypes,
  onClose: PropTypes.func,
  visible: PropTypes.bool,
};

export default withStyles(useStyles)(FilterByTextDlg);
