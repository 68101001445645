import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { selectSelectedALIMatches } from '../../app/aliMatchesSelectors';
import {
  getStandardHeaderMap, getLocationCoordinates, getLocationAddress, locationAddressToString,
} from '../../helper/payloadmisc';
import { withStylesPropTypes } from '../../helper/misc';
import Loading from '../common/loading';
import Error from '../common/error';
import GeocoderAPI from '../../api/geocoder';
import MapView from '../common/map/mapview';
import Marker from '../common/map/marker';
import { latLngBoundsFromPoints } from '../common/map/helper';

const DEFAULT_MAP_POSITION = {
  center: { lat: 37.0902, lng: -95.7129 },
  zoom: 5,
};

const useStyles = (() => ({
}));

function MapWidgetALIMatching({
  aliMatches,
}) {
  const [isGeocoding, setGeocoding] = useState(false);
  const [error, setError] = useState(null);

  const [bounds, setBounds] = useState();
  const [locations, setLocations] = useState([]);
  const [markers, setMarkers] = useState([]);


  useEffect(() => {
    setError(null);
    if (aliMatches) {
      const headerMap = getStandardHeaderMap();
      const toGeocode = [];
      const geocoded = [];
      aliMatches.forEach((m) => {
        const coord = getLocationCoordinates(m, headerMap);
        if (coord) {
          geocoded.push(coord);
        } else {
          const address = getLocationAddress(m, headerMap);
          if (address) toGeocode.push({ address: locationAddressToString(address), country: address.country || address.countryCode });
        }
      });
      setLocations(geocoded);

      if (toGeocode.length) {
        setGeocoding(true);
        Promise.all(toGeocode.map((rec) => GeocoderAPI.geocode(rec.address, rec.country)))
          .then((results) => {
            setLocations([...geocoded, ...results.map((r) => r.location)]);
            setGeocoding(false);
          })
          .catch((e) => {
            setError(e);
            setGeocoding(false);
          });
      }
    }
  }, [aliMatches]);

  useEffect(() => {
    const map = {};
    if (locations) {
      locations.forEach((l) => {
        const key = `${l.lat}/${l.lng}`;
        map[key] = l;
      });
    }
    setMarkers(Object.values(map));
  }, [locations]);

  useEffect(() => {
    setBounds(latLngBoundsFromPoints(markers));
  }, [markers]);


  const isLoading = isGeocoding;
  const errorMessage = error ? error.message : undefined;

  return (
    <>
      { isLoading && (<Loading />) }
      { errorMessage && (<Error message={errorMessage} />) }

      <MapView center={DEFAULT_MAP_POSITION.center} zoom={DEFAULT_MAP_POSITION.zoom} bounds={bounds}>
        { markers.map((m) => <Marker key={`${m.lat}/${m.lng}`} position={m} />) }
      </MapView>
    </>
  );
}


MapWidgetALIMatching.defaultProps = {
  aliMatches: [],
};

MapWidgetALIMatching.propTypes = {
  ...withStylesPropTypes,
  aliMatches: PropTypes.arrayOf(PropTypes.shape()),
};

const mapStateToProps = (state, props) => ({
  aliMatches: selectSelectedALIMatches(state, props),
});

export default connect(mapStateToProps, {
})(withStyles(useStyles)(MapWidgetALIMatching));
