import React, { useCallback } from 'react';
import Alert from '@material-ui/lab/Alert';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withStylesPropTypes } from '../../helper/misc';
import { setCortexMatchType, setMatchDistance, setMatchFilter } from '../../app/listSlice';
import {
  selectSelectedListId, selectSelectedListCortexMatchType, selectSelectedListMatchDistance, selectSelectedListMatchFilter,
} from '../../app/listSelectors';
import { setCurrentMatchGroupIndex, setShowCoreColumns } from '../../app/aliMatchesSlice';
import { selectALIMatches, selectCurrentGroupIndex, selectShowCoreColumns } from '../../app/aliMatchesSelectors';
import AliMatching from './alimatching';

const useStyles = ((theme) => ({
  alertRoot: {
    padding: `0px ${theme.spacing(3)}px`,
  },
}));

function ALIMatchingFilter({
  classes,
  matchFilter,
  cortexMatchType,
  matchDistance,
  selectedListId,
  currentGroupIndex,
  showCoreColumns,
  aliMatches,
  fnSetCortexMatchType,
  fnSetMatchDistance,
  fnSetCurrentMatchGroupIndex,
  fnSetShowCoreColumns,
  fnSetMatchFilter,
}) {
  const handleMatchFilterChange = useCallback((value) => {
    fnSetMatchFilter({ listId: selectedListId, matchFilter: value });
  }, [selectedListId, fnSetMatchFilter]);

  const handleCortexMatchTypeChange = useCallback((value) => {
    fnSetCortexMatchType({ listId: selectedListId, matchType: value });
  }, [selectedListId, fnSetCortexMatchType]);

  const handleMatchDistanceChange = useCallback((value) => {
    fnSetMatchDistance({ listId: selectedListId, distance: value });
  }, [selectedListId, fnSetMatchDistance]);

  const handleCurrentGroupIndexChange = useCallback((groupIndex) => {
    fnSetCurrentMatchGroupIndex({ groupIndex });
  }, [fnSetCurrentMatchGroupIndex]);

  const handleShowCoreColumnsChange = useCallback((checked) => {
    fnSetShowCoreColumns({ set: checked });
  }, [fnSetShowCoreColumns]);


  return selectedListId ? (
    <AliMatching
      label="Group By"
      matchFilter={matchFilter}
      onMatchFilterChange={handleMatchFilterChange}
      cortexMatchType={cortexMatchType}
      onCortextMatchTypeChange={handleCortexMatchTypeChange}
      matchDistance={matchDistance}
      onMatchDistanceChange={handleMatchDistanceChange}
      currentGroupIndex={currentGroupIndex}
      onCurrentGroupIndexChange={handleCurrentGroupIndexChange}
      showCoreColumns={showCoreColumns}
      onShowCoreColumnsChange={handleShowCoreColumnsChange}
      aliMatches={aliMatches}
    />
  ) : (
    <Alert severity="warning" classes={{ root: classes.alertRoot }}>Please select a list first</Alert>
  );
}


ALIMatchingFilter.propTypes = {
  ...withStylesPropTypes,
};


const mapStateToProps = (state, props) => ({
  matchFilter: selectSelectedListMatchFilter(state, props),
  cortexMatchType: selectSelectedListCortexMatchType(state, props),
  matchDistance: selectSelectedListMatchDistance(state, props),
  selectedListId: selectSelectedListId(state, props),
  aliMatches: selectALIMatches(state, props),
  currentGroupIndex: selectCurrentGroupIndex(state, props),
  showCoreColumns: selectShowCoreColumns(state, props),
});

export default connect(mapStateToProps, {
  fnSetCortexMatchType: setCortexMatchType,
  fnSetMatchDistance: setMatchDistance,
  fnSetCurrentMatchGroupIndex: setCurrentMatchGroupIndex,
  fnSetShowCoreColumns: setShowCoreColumns,
  fnSetMatchFilter: setMatchFilter,
})(withStyles(useStyles)(ALIMatchingFilter));
