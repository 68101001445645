import client from './client';
import { error, getQueryString } from '../helper/misc';

const findMatchesByListId = (listId, matchingId, mode, customFields) => new Promise((resolve, reject) => {
  client.post(`aliMatches/${listId}/${matchingId}`, { mode, customFields })
    .then((result) => resolve(result.data))
    .catch((err) => reject(error(err)));
});

const findMatchesByPayloadId = (payloadId, matchingId, mode, customFields) => new Promise((resolve, reject) => {
  client.post(`aliMatches/payload/${payloadId}/${matchingId}`, { mode, customFields })
    .then((result) => resolve(result.data))
    .catch((err) => reject(error(err)));
});

const fetchMatchesByMatchingId = (matchingId, page) => new Promise((resolve, reject) => {
  const params = {};
  if (page) params.page = page;
  client.get(`aliMatches/${matchingId}/matchResult${getQueryString(params)}`)
    .then((result) => resolve(result.data))
    .catch((err) => reject(error(err)));
});

const fetchAllByListId = (listId, showHidden, groupBy, page) => new Promise((resolve, reject) => {
  const params = {};
  if (page) params.page = page;
  if (showHidden) params.showHidden = true;
  client.get(`historyLocations/${listId}${groupBy ? `/${groupBy}` : ''}${getQueryString(params)}`)
    .then((result) => resolve(result.data))
    .catch((err) => reject(error(err)));
});

const fetchAllMergedByListId = (listId, page) => new Promise((resolve, reject) => {
  const params = {};
  if (page) params.page = page;
  client.get(`historyLocations/${listId}/merged${getQueryString(params)}`)
    .then((result) => resolve(result.data))
    .catch((err) => reject(error(err)));
});

const findMatchesByDistance = (listId, matchingId, distance) => new Promise((resolve, reject) => {
  client.post(`aliMatches/${listId}/${matchingId}/distance/${distance}`)
    .then((result) => resolve(result.data))
    .catch((err) => reject(error(err)));
});

const findMatchesByPayloadIdDistance = (payloadId, matchingId, distance) => new Promise((resolve, reject) => {
  client.post(`aliMatches/payload/${payloadId}/${matchingId}/distance/${distance}`)
    .then((result) => resolve(result.data))
    .catch((err) => reject(error(err)));
});

const findMatchesByPayloadIdField = (payloadId, matchingId, groupBy) => new Promise((resolve, reject) => {
  client.post(`aliMatches/payload/${payloadId}/${matchingId}/${groupBy}`)
    .then((result) => resolve(result.data))
    .catch((err) => reject(error(err)));
});

const mergeMatches = (alis, targetAli, listId, mergedAtStation, matchingId) => new Promise((resolve, reject) => {
  client.post('backfillAlis', {
    alis, targetAli, mergedAtStation, matchingId, listId,
  })
    .then((result) => resolve(result.data))
    .catch((err) => reject(error(err)));
});

const unmerge = (currentAli, originalAlis, listId) => new Promise((resolve, reject) => {
  client.post('backfillAlis/unmerge', {
    currentAli, originalAlis, listId,
  })
    .then((result) => resolve(result.data))
    .catch((err) => reject(error(err)));
});
const autoMerge = (listId, matchingId, mergedAtStation) => new Promise((resolve, reject) => {
  client.post('backfillAlis/autoMerge', { listId, matchingId, mergedAtStation })
    .then((result) => resolve(result.data))
    .catch((err) => reject(error(err)));
});
const appendLocationAli = (ali, locationIds, subStation, matchingId) => new Promise((resolve, reject) => {
  client.post('locations/ali', {
    locationIds, ali, subStation, matchingId,
  })
    .then((result) => resolve(result.data))
    .catch((err) => reject(error(err)));
});
const autoAppendAlis = (matchingId, subStation) => new Promise((resolve, reject) => {
  client.post('locations/ali/autoappend', { matchingId, subStation })
    .then((result) => resolve(result.data))
    .catch((err) => reject(error(err)));
});

const mergingLogs = (fromDate, toDate) => new Promise((resolve, reject) => {
  const params = { fromDateStr: fromDate.toISOString().split('T')[0], toDateStr: toDate.toISOString().split('T')[0] };
  client.get(`mergingLogs${getQueryString(params)}`)
    .then((result) => resolve(result.data))
    .catch((err) => reject(error(err)));
});

export default {
  findMatchesByListId,
  findMatchesByPayloadId,
  findMatchesByDistance,
  findMatchesByPayloadIdDistance,
  findMatchesByPayloadIdField,
  fetchMatchesByMatchingId,
  fetchAllByListId,
  fetchAllMergedByListId,
  mergeMatches,
  unmerge,
  autoMerge,
  appendLocationAli,
  autoAppendAlis,
  mergingLogs
};
