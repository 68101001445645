import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { withStylesPropTypes, compareSimpleArrays } from '../../helper/misc';
import {
  fetchALIMatches, fetchAll, fetchAllMerged, fetchALIMatchesByDistance, resetALIMatchSelection,
} from '../../app/aliMatchesSlice';
import {
  selectALIMatchesLoading, selectALIMatchesSaving, selectALIMatchesError, selectALIMatchesParams, selectALIMatches, selectShowCoreColumns,
} from '../../app/aliMatchesSelectors';
import { fetchPayloads } from '../../app/payloadSlice';
import { selectSelectedListPayloads, selectPayloadsByListId } from '../../app/payloadSelectors';
import {
  selectSelectedList, selectSelectedListMatchFilter, selectSelectedListCortexMatchType, selectSelectedListMatchDistance,
} from '../../app/listSelectors';
import { watchRecordLock, stopWatchRecordLock } from '../../app/lockRecordSlice';
import { selectLockRecordByRecordType } from '../../app/lockRecordSelectors';
import { combinePayloadHeaders, combineCorePayloadHeaders } from '../../helper/payloadmisc';
import Loading from '../common/loading';
import Error from '../common/error';
import ALIMatchSelect from './alimatchselect';
import LocationsSheetALIMatching from './locationssheetalimatching';
import {
  MATCH_TYPE_DEFAULT, GROUP_BY, GROUP_BY_DEFAULT, DISTANCE,
} from '../../const/alimatch';
import LOCK_RECORD_TYPE from '../../const/lockrecordtype';

const useStyles = ((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    '& *': {
      boxSizing: 'border-box',
    },
  },
  lock: {
    position: 'absolute',
    right: '0px',
    bottom: '0px',
    top: '0px',
    left: '0px',
    backgroundColor: theme.palette.border.primary,
    zIndex: 5000,
    opacity: 0.5,
  },
}));

const standardTableColumns = [
  {
    title: 'Selection',
    data: '_id',
    width: 95,
    // eslint-disable-next-line react/prop-types
    renderer: ({ value, keyDownEvent }) => <ALIMatchSelect value={value} keyDownEvent={keyDownEvent} />,
  },
  {
    title: 'First Scraped',
    data: 'scrapedAt',
    width: 130,
    type: 'date',
  },
  {
    title: 'Last',
    data: 'lastScrapedAt',
    width: 120,
    type: 'date',
  },
  {
    title: 'ALI',
    data: 'ali',
    width: 100,
  },
];

function LocationsWidgetALIMatching({
  classes,
  exportDataProvider,
  headers,
  aliMatchesLoading,
  isALIMatchesSaving,
  aliMatchesError,
  aliMatchesParams,
  aliMatches,
  payloadsByListId,
  selectedList,
  selectedListLocked,
  selectedMatchFilter,
  selectedCortexMatchType,
  selectedMatchDistance,
  fnWatchRecordLock,
  fnStopWatchRecordLock,
  fnResetALIMatchSelection,
  fnFetchALIMatches,
  fnFetchAll,
  fnFetchAllMerged,
  fnFetchALIMatchesByDistance,
  fnFetchPayloads,
  dimensions
}) {
  const [tableColumns, setTableColumns] = useState(standardTableColumns);

  useEffect(() => {
    fnWatchRecordLock(LOCK_RECORD_TYPE.BACKFILL_LIST, (selectedList || {}).listId);
    return () => fnStopWatchRecordLock(LOCK_RECORD_TYPE.BACKFILL_LIST);
  }, [selectedList, fnWatchRecordLock, fnStopWatchRecordLock]);

  useEffect(() => {
    fnResetALIMatchSelection();
  }, [selectedMatchFilter, fnResetALIMatchSelection]);

  useEffect(() => {
    if (selectedList && !selectedListLocked && [GROUP_BY.PHONE, GROUP_BY.STORE_NUMBER, GROUP_BY.UNGROUP].indexOf(selectedMatchFilter) >= 0) {
      const loadedMatchFilter = (aliMatchesParams || {}).matchFilter || GROUP_BY_DEFAULT;
      const loadedListId = (aliMatchesParams || {}).listId;
      if (!aliMatchesParams || loadedMatchFilter !== selectedMatchFilter || loadedListId !== selectedList.listId) {
        fnResetALIMatchSelection();
        fnFetchAll(selectedList.listId, selectedMatchFilter);
      }
    }
  }, [selectedList, selectedListLocked, selectedMatchFilter, aliMatchesParams, fnFetchAll, fnResetALIMatchSelection]);

  useEffect(() => {
    if (selectedList && !selectedListLocked && selectedMatchFilter === GROUP_BY.CORTEX) {
      const loadedMatchFilter = (aliMatchesParams || {}).matchFilter || GROUP_BY_DEFAULT;
      const loadedListId = (aliMatchesParams || {}).listId;
      const selectedCustomFields = selectedList.fieldsToMatch && Object.keys(selectedList.fieldsToMatch);
      const loadedCortexMatchType = (aliMatchesParams || {}).cortexMatchType || MATCH_TYPE_DEFAULT;
      const loadedCustomFields = (aliMatchesParams || {}).customFields;
      if (!aliMatchesParams || loadedMatchFilter !== selectedMatchFilter || loadedCortexMatchType !== selectedCortexMatchType || !compareSimpleArrays(selectedCustomFields, loadedCustomFields) || loadedListId !== selectedList.listId) {
        fnResetALIMatchSelection();
        fnFetchALIMatches(selectedList.listId, selectedCortexMatchType, selectedCustomFields);
      }
    }
  }, [selectedList, selectedListLocked, selectedMatchFilter, selectedCortexMatchType, aliMatchesParams, fnFetchALIMatches, fnResetALIMatchSelection]);


  useEffect(() => {
    if (selectedList && !selectedListLocked && selectedMatchFilter === GROUP_BY.DISTANCE) {
      const loadedMatchFilter = (aliMatchesParams || {}).matchFilter || GROUP_BY_DEFAULT;
      const loadedListId = (aliMatchesParams || {}).listId;
      const loadedMatchDistance = (aliMatchesParams || {}).matchDistance || DISTANCE.MIN;
      if (!aliMatchesParams || loadedMatchFilter !== selectedMatchFilter || selectedMatchDistance !== loadedMatchDistance || loadedListId !== selectedList.listId) {
        fnResetALIMatchSelection();
        fnFetchALIMatchesByDistance(selectedList.listId, selectedMatchDistance);
      }
    }
  }, [selectedList, selectedListLocked, selectedMatchFilter, selectedMatchDistance, aliMatchesParams, fnFetchALIMatchesByDistance, fnResetALIMatchSelection]);


  useEffect(() => {
    if (selectedList && !selectedListLocked && selectedMatchFilter === GROUP_BY.MERGED) {
      const loadedMatchFilter = (aliMatchesParams || {}).matchFilter || GROUP_BY_DEFAULT;
      const loadedListId = (aliMatchesParams || {}).listId;
      if (!aliMatchesParams || loadedMatchFilter !== selectedMatchFilter || loadedListId !== selectedList.listId) {
        fnResetALIMatchSelection();
        fnFetchAllMerged(selectedList.listId);
      }
    }
  }, [selectedList, selectedListLocked, selectedMatchFilter, selectedMatchDistance, aliMatchesParams, fnFetchAllMerged, fnResetALIMatchSelection]);


  useEffect(() => {
    if (selectedList && !payloadsByListId[selectedList.listId]) fnFetchPayloads(selectedList.listId);
  }, [selectedList, payloadsByListId, fnFetchPayloads]);


  useEffect(() => {
    const columns = standardTableColumns.slice();
    if (selectedMatchFilter === GROUP_BY.CORTEX) {
      columns.splice(1, 0, {
        data: 'matchingScore',
        title: 'Match Score',
        width: 95,
      });
    }
    headers.forEach((h) => {
      columns.push({
        title: h.title,
        match: h.match,
        data: h.key,
        payloadKey: h.key,
        payloadIds: h.payloadIds,
        width: 150,
        sortable: false,
        searchable: true,
        isPayloadColumn: true,
        editable: false,
      });
    });
    setTableColumns(columns);
  }, [headers, selectedMatchFilter]);

  const isSaving = isALIMatchesSaving && !aliMatchesLoading;
  const error = selectedListLocked ? { message: 'Selected list is currently locked by another user' } : aliMatchesError;

  return (
    <>
      { aliMatchesLoading && (<Loading progress={aliMatchesLoading.progress} />) }
      { isSaving && (<Loading text="Saving..." />) }
      { error && (<Error message={error.message} />) }
      <div className={classes.root}>
        <LocationsSheetALIMatching
          locations={aliMatches}
          tableColumns={tableColumns}
          exportDataProvider={exportDataProvider}
          dimensions = {dimensions}
        />
      </div>
      { selectedListLocked && <div className={classes.lock} />}
    </>
  );
}

LocationsWidgetALIMatching.defaultProps = {
  exportDataProvider: null,
};

LocationsWidgetALIMatching.propTypes = {
  ...withStylesPropTypes,
  exportDataProvider: PropTypes.func,
};

const selectIsListLocked = createSelector(
  [selectSelectedList, selectLockRecordByRecordType],
  (selectedList, lockedRecordByReportType) => !!(selectedList && (lockedRecordByReportType[LOCK_RECORD_TYPE.BACKFILL_LIST] || {})[selectedList.listId]),
);


const selectHeaders = createSelector(
  [selectSelectedListPayloads, selectShowCoreColumns],
  (payloads, showCoreColumns) => (showCoreColumns ? combineCorePayloadHeaders(payloads, 'alimatching') : combinePayloadHeaders(payloads, 'alimatching')),
);

const mapStateToProps = (state, props) => ({
  aliMatchesLoading: selectALIMatchesLoading(state, props),
  isALIMatchesSaving: selectALIMatchesSaving(state, props),
  aliMatchesError: selectALIMatchesError(state, props),
  selectedList: selectSelectedList(state, props),
  selectedListLocked: selectIsListLocked(state, props),
  selectedMatchFilter: selectSelectedListMatchFilter(state, props),
  selectedCortexMatchType: selectSelectedListCortexMatchType(state, props),
  selectedMatchDistance: selectSelectedListMatchDistance(state, props),
  aliMatchesParams: selectALIMatchesParams(state, props),
  aliMatches: selectALIMatches(state, props),
  payloadsByListId: selectPayloadsByListId(state, props),
  headers: selectHeaders(state, props),
});


export default connect(mapStateToProps, {
  fnWatchRecordLock: watchRecordLock,
  fnStopWatchRecordLock: stopWatchRecordLock,
  fnResetALIMatchSelection: resetALIMatchSelection,
  fnFetchALIMatches: fetchALIMatches,
  fnFetchAll: fetchAll,
  fnFetchAllMerged: fetchAllMerged,
  fnFetchALIMatchesByDistance: fetchALIMatchesByDistance,
  fnFetchPayloads: fetchPayloads,
})(withStyles(useStyles)(LocationsWidgetALIMatching));
