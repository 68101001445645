import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const MAX_STEP = 10;

function SheetScrollWrapper({
  parent,
  startingPoint,
  onEnd,
  onScroll,
}) {
  const [timer, setTimer] = useState();

  const resetTimer = () => {
    if (timer) {
      clearInterval(timer);
      setTimer(undefined);
    }
  };

  const handleMouseUp = () => {
    resetTimer();
    onEnd();
  };

  const handleScroll = ({ col, row }) => {
    onScroll({ col, row });
  };

  const handleMouseMove = (e) => {
    resetTimer();

    const { pageX, pageY } = e;

    const elements = document.elementsFromPoint(pageX, pageY);
    const inSheet = (!!elements.find((el) => el && typeof el.className === 'string' && el.className.indexOf('ReactVirtualized__Grid__innerScrollContainer') >= 0));
    if (inSheet) {
      const cell = elements.find((el) => el && el.attributes && el.attributes.role && el.attributes.role.value === 'sheet-cell');
      if (cell) {
        const event = new Event('mouseover', { bubbles: true });
        cell.dispatchEvent(event);
      }
    } else {
      const { x, y } = startingPoint;
      const deltaX = pageX - x;
      const deltaY = pageY - y;

      const isXDir = Math.abs(deltaX) > Math.abs(deltaY);
      const rect = parent.getBoundingClientRect();

      let step;
      if (pageX >= rect.left && pageX <= rect.right && pageY >= rect.top && pageY < rect.bottom) {
        if (isXDir) {
          step = deltaX > 0 ? 1 : -1;
        } else {
          step = deltaY > 0 ? 1 : -1;
        }
      } else {
        let max;
        let min;
        let val;
        let mul;
        if (isXDir) {
          val = pageX;
          if (deltaX > 0) {
            max = window.innerWidth;
            min = rect.right;
            mul = 1;
          } else {
            max = 0;
            min = rect.left;
            mul = -1;
          }
        } else {
          val = pageY;
          if (deltaY > 0) {
            max = window.innerHeight;
            min = rect.bottom;
            mul = 1;
          } else {
            max = 0;
            min = rect.top;
            mul = -1;
            if (val > min) val = min;
          }
        }

        const total = Math.abs(max - min);
        const cur = Math.abs(val - min);
        step = Math.round((MAX_STEP * cur) / total) + 1;
        if (step > MAX_STEP) step = MAX_STEP;
        else if (step < -MAX_STEP) step = -MAX_STEP;
        step *= mul;
      }

      const p = { [isXDir ? 'col' : 'row']: step };
      setTimer(setInterval(() => {
        handleScroll(p);
      }, 100));
    }
  };

  return ReactDOM.createPortal(
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      style={{
        left: 0, right: 0, top: 0, bottom: 0, position: 'absolute', zIndex: 1000000,
      }}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
    />,
    document.body,
  );
}

SheetScrollWrapper.defaultProps = {
  parent: undefined,
  onEnd: () => {},
  onScroll: () => {},
};

SheetScrollWrapper.propTypes = {
  startingPoint: PropTypes.shape().isRequired,
  parent: PropTypes.shape(),
  onEnd: PropTypes.func,
  onScroll: PropTypes.func,
};

export default SheetScrollWrapper;
