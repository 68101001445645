import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withStyles, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import { resetALIMatchSelection } from '../../app/aliMatchesSlice';
import {
  selectALIMatches, selectALIMatchesLoading, selectALIMatchesError, selectSelectedALIMatches, selectCurrentGroupIndex,
} from '../../app/aliMatchesSelectors';
import Confirm from '../common/confirm';
import Sheet from '../common/sheet/sheet';
import { defaultCellRenderer } from '../common/sheet/renderers';
import {
  resolve, formatDate, compareObjects, withStylesPropTypes,
} from '../../helper/misc';
import KeyboardHandle from '../../helper/keyboard';
import LocationColumnHeader from './locationcolumnheader';
import { buildFormulaEditContextMenu, buildCellContextMenu, buildColumnContextMenu } from './contextmenu';
import ALIMatchSelect from './alimatchselect';
import { GROUP_BY, AUTOMERGE_MAX_DISTANCE } from '../../const/alimatch';

const useStyles = (() => ({
  mergeButton: {
    height: '100%',
  },
  autoMergeButton: {
    height: '100%',
  },
}));

const minDate = '1900-01-01';

const LOCAL_MENU_COMMANDS = {
  RESTORE_COLUMN: 'restore_columns',
};

const GROUP_STYLES = [
  { backgroundColor: '#f9cdca' },
  { backgroundColor: '#f9f8ca' },
  { backgroundColor: '#caf8db' },
  { backgroundColor: '#dae0f8' },
];

function ALIMatchingSheet({
  classes,
  tableColumns: initialTableColumns,
  standardHeaders,
  aliMatches,
  listId,
  selectedALIMatches,
  currentGroupIndex,
  matchFilter,
  matchDistance,
  cortexAutoMerge,
  aliMatchesLoading,
  aliMatchesError,
  fnResetALIMatchSelection,
  onUpdate,
  onMerge,
  onUnmerge,
  onAutoMerge,
  onValidateMatchSelection,
}) {
  const defaultTheme = useTheme();

  const defaultCellStyle = {
    color: defaultTheme.palette.text.primary,
    backgroundColor: defaultTheme.palette.background.paper,
  };

  const [colorMap, setColorMap] = useState({});
  const [groups, setGroups] = useState([]);
  const [mostRecentRecord, setMostRecentRecord] = useState({});
  const [locationsView, setLocationsView] = useState(aliMatches);
  const [colored, setColored] = useState(true);
  const [tableColumns, setTableColumns] = useState(initialTableColumns);
  const [hiddenColumns, setHiddenColumns] = useState({});
  const [finalTableColumns, setFinalTableColumns] = useState(initialTableColumns);
  const [history, setHistory] = useState([]);
  const [showAutoMergeConfimed, setShowAutoMergeConfrmed] = useState(false);
  const [showMergeConfirm, setShowMergeConfirm] = useState(false);
  const [warningMessage, setWarningMessage] = useState();
  const [canMerge, setCanMerge] = useState(false);
  const [lastMergeAction, setLastMergeAction] = useState();

  useEffect(() => {
    setTableColumns(initialTableColumns);
  }, [initialTableColumns]);

  useEffect(() => {
    let columns = tableColumns;
    if (columns && hiddenColumns && Object.keys(hiddenColumns).length) {
      columns = columns.filter((c) => !hiddenColumns[c.data]);
    }
    columns = columns.map((c) => {
      if (c.data === '_id') {
        return {
          ...c,
          // eslint-disable-next-line react/prop-types
          renderer: ({ value, keyDownEvent }) => <ALIMatchSelect value={value} keyDownEvent={keyDownEvent} />,
        };
      }
      return c;
    });
    setFinalTableColumns(columns);
  }, [tableColumns, hiddenColumns]);

  useEffect(() => {
    const groupList = [];
    const groupMostRecentRecord = {};
    if (aliMatches) {
      aliMatches.forEach((l) => {
        const { group = '', lastScrapedAt = minDate } = l;
        if (!groupMostRecentRecord[group]) {
          groupMostRecentRecord[group] = l;
          groupList.push(group);
        } else {
          const { lastScrapedAt: date = minDate } = groupMostRecentRecord[group];
          if (date.localeCompare(lastScrapedAt) < 0) groupMostRecentRecord[group] = l;
        }
      });
    }
    setGroups(groupList);
    setMostRecentRecord(groupMostRecentRecord);
  }, [aliMatches]);

  useEffect(() => {
    fnResetALIMatchSelection();
  }, [currentGroupIndex, fnResetALIMatchSelection]);


  useEffect(() => {
    if (currentGroupIndex >= 1 && currentGroupIndex <= groups.length) {
      const currentGroup = groups[currentGroupIndex - 1];
      setLocationsView(aliMatches.filter((l) => l.group === currentGroup));
      setColored(false);
    } else {
      setLocationsView(aliMatches);
      let lastGroup = null;
      for (let i = 0; i < aliMatches.length; i += 1) {
        if (!aliMatches[i].group || (lastGroup && aliMatches[i].group !== lastGroup)) break;
        lastGroup = aliMatches[i].group;
      }
      setColored(!!lastGroup);
    }
  }, [aliMatches, currentGroupIndex, groups]);

  useEffect(() => {
    let ok = false;
    let warning;

    if (selectedALIMatches && selectedALIMatches.length >= 2) {
      if (colored) {
        let group;
        ok = !selectedALIMatches.some((l) => {
          if (group && group !== l.group) return true;
          group = l.group;
          return false;
        });
      }
      if (ok) {
        const result = onValidateMatchSelection(selectedALIMatches);
        if (result === false) {
          ok = false;
        } if (typeof result === 'string') {
          ok = false;
          warning = result;
        }
      }
    }

    if (!warningMessage || warningMessage.message !== warning) {
      setWarningMessage(warning ? { message: warning, show: true } : undefined);
    }
    setCanMerge(ok);
    // eslint-disable-next-line
  }, [selectedALIMatches, colored, onValidateMatchSelection]);

  useEffect(() => {
    setHistory([]);
    setLastMergeAction();
  }, [matchFilter]);

  const handleRowColors = (rows) => {
    const groupColorMap = {};
    let colorIndex = 0;
    rows.forEach((r) => {
      const { group = '' } = r;
      if (groupColorMap[group] === undefined) {
        groupColorMap[group] = colorIndex;
        colorIndex += 1;
      }
    });
    if (!compareObjects(groupColorMap, colorMap)) setColorMap(groupColorMap);
    return rows;
  };

  const getCellStyle = ({ data }) => {
    let ret;
    if (data) {
      if (colored) {
        const colorIndex = colorMap[data.group];
        if (colorIndex >= 0) ret = GROUP_STYLES[colorIndex % GROUP_STYLES.length];
      }
      if (matchFilter === GROUP_BY.MERGED && !data.originalAli) {
        ret = { fontWeight: 'bold', ...ret };
      }
    }
    return ret;
  };

  const getCellValue = useCallback(({ row, column }) => {
    let ret = resolve(row, column.data);
    if (column.formatter) {
      ret = column.formatter(ret);
    } else if (column.type && column.type === 'date') {
      ret = formatDate(ret);
    }
    return ret;
  }, []);

  const getRowKey = useCallback((row) => row._id, []);

  const getColumnKey = useCallback((column) => column.data, []);

  const updateRows = (action, updates) => {
    if (updates) {
      const updatesToPost = updates.filter((u) => Object.keys(u.changes).length)
        .map((u) => ({ _id: u.row._id, ...u.changes }));
      onUpdate(updatesToPost);
      // fnUpdateLocations(action, updatesToPost);
    }
  };

  const handleColumnDelete = (columns) => {
    const newHiddenColumns = { ...hiddenColumns };
    columns.forEach(({ column }) => {
      newHiddenColumns[column.data] = true;
    });
    setHiddenColumns(newHiddenColumns);
  };

  const handleColumnRestore = (data) => {
    const updatedHiddenColumns = { ...hiddenColumns };
    delete updatedHiddenColumns[data];
    setHiddenColumns(updatedHiddenColumns);
  };

  const buildContextMenu = (range, { col, row }, dataProvider, formulaEditMode) => {
    if (formulaEditMode) {
      return buildFormulaEditContextMenu();
    }
    if (row >= 0) {
      return buildCellContextMenu(range, col, dataProvider, defaultCellStyle, {
        sortByColor: !colored,
        format: !colored,
        restore: false,
        textToColumns: false,
      });
    }
    const columnMenu = buildColumnContextMenu(range, col, dataProvider, defaultCellStyle, {
      sortByColor: !colored,
      format: !colored,
      insert: false,
      delete: true,
      textToColumns: false,
    }, {
      deleteColumn: 'Hide Column',
    });
    if (Object.keys(hiddenColumns).length) {
      columnMenu.push({
        id: LOCAL_MENU_COMMANDS.RESTORE_COLUMN,
        title: 'Restore Column',
        children: Object.keys(hiddenColumns).map((data) => ({
          id: data,
          title: initialTableColumns.find((c) => c.data === data).title,
        })),
      });
    }
    return columnMenu;
  };

  const handleContextMenuCommand = (item, subItem) => {
    if (item.id === LOCAL_MENU_COMMANDS.RESTORE_COLUMN) handleColumnRestore(subItem.id);
  };


  const canMatchTo = (matchTo) => !tableColumns.some((c) => c.match === matchTo);


  const handleMergeALIs = (needConfirm) => {
    if (canMerge) {
      if (needConfirm) {
        setShowMergeConfirm(true);
      } else {
        const merge = onMerge(selectedALIMatches.slice());
        if (merge) {
          setHistory([...history, { merge }]);
          setLastMergeAction(merge);
        }
      }
    }
  };

  const getSeletedUnmergeAlis = () => {
    let ret;
    if (selectedALIMatches) {
      let currentAli;
      selectedALIMatches.some((l) => {
        if (currentAli && currentAli !== l.ali) {
          currentAli = undefined;
          return true;
        }
        currentAli = l.ali;
        return false;
      });

      if (currentAli) {
        const originalAlis = selectedALIMatches.map((l) => l.originalAli).filter((ali) => !!ali);
        if (originalAlis.length) ret = { currentAli, originalAlis };
      }
    }

    return ret;
  };

  const handleUnmergeALIs = () => {
    const unmergeAlis = getSeletedUnmergeAlis();
    if (unmergeAlis) {
      const { currentAli, originalAlis } = unmergeAlis;
      onMerge(currentAli, originalAlis);
      // fnUnmerge(currentAli, originalAlis, selectedListId);
    }
  };

  const handleMergeConfirm = (confirmed) => {
    setShowMergeConfirm(false);
    if (confirmed) handleMergeALIs();
  };

  const handleDistanceAutoMerge = () => {
    onAutoMerge();
  };

  const handleCortexAutoMerge = () => {
    if (cortexAutoMerge) {
      onAutoMerge();
      setShowAutoMergeConfrmed(true);
    }
  };

  const handleUndo = (change) => {
    if (change.merge) {
      const { alis, targetAli, ids } = change.merge;
      onUnmerge(targetAli, alis, ids);
      // fnUnmerge(targetAli, alis, selectedListId, ids);
    }
  };

  const handleUndoLastMerge = () => {
    if (lastMergeAction) {
      const undo = { merge: lastMergeAction };
      handleUndo(undo);
      const updatedHistory = history.filter((h) => !compareObjects(h, undo, { ids: true }));
      setHistory(updatedHistory);
      setLastMergeAction();
    }
  };

  const handleUndoKeyDown = (e) => {
    KeyboardHandle(e, {
      onUndo: () => handleUndoLastMerge(),
    });
  };


  const groupSortFunction = ({ value: value1, row: row1 }, { value: value2, row: row2 }, column) => {
    const { group: group1 } = row1;
    const { group: group2 } = row2;

    let v1;
    let v2;
    if (group1 === group2) {
      v1 = value1;
      v2 = value2;
    } else {
      const r1 = mostRecentRecord[group1];
      const r2 = mostRecentRecord[group2];
      if (r1 && r2) {
        v1 = r1[column.data] || '';
        v2 = r2[column.data] || '';
        if (column.type === 'date') {
          v1 = new Date(v1);
          v2 = new Date(v2);
        }
      }
    }

    let ret = 0;
    if (v1 !== undefined && v2 !== undefined) {
      if (v1 < v2) ret = -1;
      else if (v1 > v2) ret = 1;
    }
    return ret;
  };


  return (
    <>
      {
        (cortexAutoMerge && matchFilter === GROUP_BY.CORTEX) && (
          <Confirm
            title="Information"
            text={`You have merged all records with a Cortex Match Score of ${cortexAutoMerge.matchingScore}. Would you like to auto-merge the remaining ${cortexAutoMerge.remaining} that have a higher Cortex Match Score?`}
            buttons={['Yes', 'No']}
            focusedButton="Yes"
            onClose={(res) => { if (res === 'Yes') handleCortexAutoMerge(); }}
          />
        )
      }
      {
        showAutoMergeConfimed && (
          <Confirm
            title="Thank you!"
            text="Please remember to check the other Match Types (ie Phone Number, Distance, etc.)"
            buttons={['OK']}
            focusedButton="OK"
            onClose={() => setShowAutoMergeConfrmed(false)}
          />
        )
      }
      {
        showMergeConfirm && (
          <Confirm
            title="Warning"
            text={`Are you sure you want to merge ${selectedALIMatches.length} records?`}
            buttons={['Yes', 'No']}
            focusedButton="Yes"
            onClose={(res) => handleMergeConfirm(res === 'Yes')}
          />
        )
      }
      {
        (warningMessage && warningMessage.show) && (
          <Confirm
            title="Warning"
            text={warningMessage.message}
            buttons={['OK']}
            focusedButton="OK"
            onClose={() => setWarningMessage({ ...warningMessage, show: false })}
          />
        )
      }
      { lastMergeAction && (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open
          autoHideDuration={5000}
          onClose={(event) => {
            if (event && event.type !== 'click') setLastMergeAction();
          }}
          message={`Merged ${lastMergeAction.alis.length} ALIs`}
          action={(
            <>
              <Button color="secondary" size="small" onClick={handleUndoLastMerge} autoFocus onKeyDown={handleUndoKeyDown}>
                UNDO
              </Button>
              <IconButton size="small" aria-label="close" color="inherit" onClick={() => setLastMergeAction()}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          )}
        />
      )}
      <Sheet
        keyDownHandlers={{
          onCtrlM: () => handleMergeALIs(true),
        }}
        defaultCellStyle={defaultCellStyle}
        rows={locationsView}
        columns={finalTableColumns}
        fixedColumnCount={1}
        headerHeight={64}
        onRowsUpdated={(action, updates) => updateRows(action, updates)}
        headerRenderer={({
          column, columnIndex, selectColumn, sort, filter,
        }) => {
          let columnWeightComponent = (<></>);
          switch (matchFilter) {
            case GROUP_BY.CORTEX:
              if (column.data !== '_id') columnWeightComponent = undefined;
              break;
            case GROUP_BY.DISTANCE:
              if (column.data === '_id' && matchDistance <= AUTOMERGE_MAX_DISTANCE) {
                columnWeightComponent = (
                  <Button
                    variant="contained"
                    className={classes.autoMergeButton}
                    fullWidth
                    color="secondary"
                    onClick={() => handleDistanceAutoMerge()}
                  >
                    Automerge
                  </Button>
                );
              }
              break;
            default:
              break;
          }
          return (
            <LocationColumnHeader
              sortable={column.sortable}
              title={column.title}
              match={column.match}
              hasBorder={!!column.payloadKey}
              columnIndex={columnIndex}
              columnKey={column.data}
              columnWeightPlaceholder
              columnWeight={!!column.isPayloadColumn || column.data === '_id'}
              columnMatch={false}
              selectColumn={selectColumn}
              payloadIds={column.payloadIds}
              listId={listId}
              canMatchTo={(match) => canMatchTo(match)}
              standardHeaders={standardHeaders}
              fullWidth={column.data === '_id'}
              sort={!!sort}
              filter={filter}
              columnWeightComponent={columnWeightComponent}
              titleComponent={column.data === '_id'
              && (
                matchFilter === GROUP_BY.MERGED
                  ? (
                    <Button
                      variant="contained"
                      className={classes.mergeButton}
                      fullWidth
                      color="primary"
                      disabled={!getSeletedUnmergeAlis()}
                      onClick={() => handleUnmergeALIs()}
                    >
                      Unmerge
                    </Button>
                  )
                  : (
                    <Tooltip title={warningMessage && warningMessage.message ? warningMessage.message : ''}>
                      <div style={{ width: '100%' }}>
                        <Button
                          variant="contained"
                          className={classes.mergeButton}
                          fullWidth
                          color="primary"
                          disabled={!canMerge}
                          onClick={() => handleMergeALIs()}
                        >
                          Merge
                        </Button>
                      </div>
                    </Tooltip>
                  )
              )}
            />
          );
        }}
        replaceEnabled={false}
        getCellStyle={getCellStyle}
        getCellValue={getCellValue}
        getRowKey={getRowKey}
        getColumnKey={getColumnKey}
        cellRenderer={({
          row, column, value, keyDownEvent,
        }) => (column.renderer ? column.renderer({ row, value, keyDownEvent }) : defaultCellRenderer({ value }))}
        contextMenuBuilder={(range, cell, dataProvider, formulaEditMode) => buildContextMenu(range, cell, dataProvider, formulaEditMode)}
        onColumnDelete={(columns) => handleColumnDelete(columns)}
        onColumnsUpdated={(columns) => setTableColumns(columns)}
        onContextMenuSelected={handleContextMenuCommand}
        formulaBar
        sortFunction={colored ? groupSortFunction : undefined}
        onAfterPrepareRows={handleRowColors}
        history={history}
        onHistoryChange={(h) => setHistory(h)}
        onUndo={handleUndo}
      />
      {(aliMatches && !aliMatches.length && !aliMatchesLoading && !aliMatchesError) && (
        <Alert severity="warning">No Matches</Alert>
      )}
    </>
  );
}

ALIMatchingSheet.defaultProps = {
  tableColumns: [],
  standardHeaders: [],
  listId: undefined,
  matchFilter: undefined,
  matchDistance: undefined,
  onUpdate: () => {},
  onMerge: () => {},
  onUnmerge: () => {},
  onAutoMerge: () => {},
  onValidateMatchSelection: () => null,
};

ALIMatchingSheet.propTypes = {
  ...withStylesPropTypes,
  tableColumns: PropTypes.arrayOf(PropTypes.shape),
  standardHeaders: PropTypes.arrayOf(PropTypes.string),
  listId: PropTypes.number,
  matchFilter: PropTypes.string,
  matchDistance: PropTypes.number,
  onUpdate: PropTypes.func,
  onMerge: PropTypes.func,
  onUnmerge: PropTypes.func,
  onAutoMerge: PropTypes.func,
  onValidateMatchSelection: PropTypes.func,
};

const mapStateToProps = (state, props) => ({
  aliMatchesLoading: selectALIMatchesLoading(state, props),
  selectedALIMatches: selectSelectedALIMatches(state, props),
  aliMatchesError: selectALIMatchesError(state, props),
  aliMatches: selectALIMatches(state, props),
  currentGroupIndex: selectCurrentGroupIndex(state, props),
});

export default connect(mapStateToProps, {
  fnResetALIMatchSelection: resetALIMatchSelection,
})(withStyles(useStyles)(ALIMatchingSheet));
