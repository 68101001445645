import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// eslint-disable-next-line import/no-unresolved
import NestedMenuItem from 'material-ui-nested-menu-item';

function ContextMenu({
  anchor, items, onItemSelected, onClose,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (anchor) setAnchorEl(anchor);
  }, [anchor]);

  const handleClose = () => {
    setAnchorEl(null);
    onClose();
  };

  const handleMenuItem = (item, child) => {
    handleClose();
    onItemSelected(item, child);
  };

  return (
    <div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
      >
        {
          items.map((item) => (item.children ? (
            <NestedMenuItem key={item.id} label={item.title} parentMenuOpen={!!anchorEl}>
              {
                item.children.map((child) => <MenuItem key={`${item.id}-${child.id}`} onClick={() => handleMenuItem(item, child)}>{ child.title }</MenuItem>)
              }
            </NestedMenuItem>
          ) : (
            <MenuItem key={item.id} disabled={!!item.disabled} onClick={() => handleMenuItem(item)}>{ item.title }</MenuItem>
          )))
        }
      </Menu>
    </div>
  );
}

ContextMenu.defaultProps = {
  anchor: null,
  items: [],
  onItemSelected: () => { },
  onClose: () => { },
};

ContextMenu.propTypes = {
  anchor: PropTypes.shape(),
  items: PropTypes.arrayOf(PropTypes.shape),
  onItemSelected: PropTypes.func,
  onClose: PropTypes.func,
};

export default ContextMenu;
