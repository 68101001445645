import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {
  selectGeofencesProperty, selectGeofencesLocation, selectSelectedPolygonVertex, selectGeofencesLoading, selectGeofencesError, selectGeofencesSaving,
} from '../../app/geofencesSelectors';
import {
  fetchGeofences, setLocationCoordinates, setGeofence, setSelectedPolygonVertex,
} from '../../app/geofencesSlice';
import { withStylesPropTypes, hashCode } from '../../helper/misc';
import { getColor } from '../../helper/polygoncolor';
import Loading from '../common/loading';
import Error from '../common/error';
import MapView from '../common/map/mapview';
import Marker from '../common/map/marker';
import Polygon from '../common/map/polygon';

const DEFAULT_MAP_POSITION = {
  center: { lat: 37.0902, lng: -95.7129 },
  zoom: 5,
};

const useStyles = (() => ({
  root: {
  },
}));

function MapGeofence({
  location,
  geofencesLocation,
  property,
  selectedPolygonVertex,
  isGeofencesLoading,
  isGeofencesSaving,
  geofencesError,
  fnFetchGeofences,
  fnSetLocationCoordinates,
  fnSetGeofence,
  fnSetSelectedPolygonVertex,
}) {
  const [mapPosition, setMapPosition] = useState(DEFAULT_MAP_POSITION);
  const [markerPosition, setMarkerPosition] = useState();

  const getLocationLatLng = (l) => ({ lat: l.latitude, lng: l.longitude });

  const isVisibleGeofence = (g) => {
    const flags = g.flags || {};
    return !flags.hidden && !flags.deleted;
  };

  useEffect(() => {
    if (location && location.latitude && location.longitude) {
      const coordinates = getLocationLatLng(location);
      setMapPosition({ center: coordinates, zoom: 18 });
      setMarkerPosition(coordinates);
    } else {
      setMapPosition(DEFAULT_MAP_POSITION);
      setMarkerPosition(undefined);
    }
  }, [location]);

  useEffect(() => {
    if (geofencesLocation) {
      const coordinates = getLocationLatLng(geofencesLocation);
      setMarkerPosition(coordinates);
    }
  }, [geofencesLocation]);

  useEffect(() => {
    if (location._id && (!property || (property._id !== location.propertyId))) {
      fnFetchGeofences(location);
    }
  }, [location, property, fnFetchGeofences]);

  const handleMarkerMoved = ({ position }) => {
    setMarkerPosition({ ...position });
    const { lat, lng } = position;
    fnSetLocationCoordinates({ latitude: lat, longitude: lng });
  };

  const handlePolygonEdited = ({ data, coordinates }) => {
    const index = property.geofences.indexOf(data);
    if (index >= 0) {
      fnSetGeofence({
        index,
        geofence: {
          ...property.geofences[index],
          geometry: {
            ...property.geofences[index].geometry,
            coordinates: [coordinates],
          },
        },
      });
    }
  };

  const handlePolygonSelected = useCallback(({ data, vertexIndex }) => {
    if (property) {
      const polygonIndex = property.geofences.indexOf(data);
      fnSetSelectedPolygonVertex(polygonIndex >= 0 ? { polygonIndex, vertexIndex } : undefined);
    }
  }, [property, fnSetSelectedPolygonVertex]);

  const handleNewPolygonCreated = (coordinates) => {
    fnSetGeofence({
      geofence: {
        properties: {},
        geometry: {
          type: 'Polygon',
          coordinates: [coordinates],
        },
      },
    });
  };

  return (
    <>
      { isGeofencesLoading && (<Loading />) }
      { !isGeofencesLoading && isGeofencesSaving && (<Loading text="Saving..." />) }
      { geofencesError && (<Error message={geofencesError.message} />) }

      <MapView
        center={mapPosition.center}
        zoom={mapPosition.zoom}
        drawing={{
          polygon: true,
        }}
        onNewPolygon={handleNewPolygonCreated}
      >
        { markerPosition
        && (
        <Marker
          position={markerPosition}
          editable
          onEdited={handleMarkerMoved}
        />
        )}
        {
          property && property.geofences && property.geofences.map((g, index) => isVisibleGeofence(g) && (
            <Polygon
              key={(g._id || hashCode((g.geometry || {}).coordinates))}
              coordinates={g.geometry.coordinates[0]}
              strokeColor={getColor(index)}
              data={g}
              editable={selectedPolygonVertex && selectedPolygonVertex.polygonIndex === index}
              selectedVertexIndex={selectedPolygonVertex && selectedPolygonVertex.polygonIndex === index ? selectedPolygonVertex.vertexIndex : undefined}
              onEdited={handlePolygonEdited}
              onClick={handlePolygonSelected}
            />
          ))
        }
      </MapView>
    </>
  );
}


MapGeofence.defaultProps = {
  location: null,
};

MapGeofence.propTypes = {
  ...withStylesPropTypes,
  location: PropTypes.shape(),
};


const mapStateToProps = (state, props) => ({
  property: selectGeofencesProperty(state, props),
  geofencesLocation: selectGeofencesLocation(state, props),
  selectedPolygonVertex: selectSelectedPolygonVertex(state, props),
  isGeofencesLoading: selectGeofencesLoading(state, props),
  isGeofencesSaving: selectGeofencesSaving(state, props),
  geofencesError: selectGeofencesError(state, props),
});

export default connect(mapStateToProps, {
  fnFetchGeofences: fetchGeofences,
  fnSetLocationCoordinates: setLocationCoordinates,
  fnSetGeofence: setGeofence,
  fnSetSelectedPolygonVertex: setSelectedPolygonVertex,
})(withStyles(useStyles)(MapGeofence));
