import { createSelector } from '@reduxjs/toolkit';

export const selectHistoryLoading = (state) => state.history.isLoading;

export const selectHistoryRestoring = (state) => state.history.isRestoring;

export const selectHistoryError = (state) => state.history.error;

export const selectHistoryIds = (state) => state.history.allIds;

export const selectHistoryById = (state) => state.history.byId;

export const selectHistory = createSelector(
  [selectHistoryIds, selectHistoryById],
  (historyIds, historyById) => historyIds.map((id) => historyById[id]),
);
