const normalize = (list, idField) => {
  const allIds = [];
  const byId = {};
  if (list) {
    list.forEach((item) => {
      const id = item[idField];
      allIds.push(id);
      byId[id] = item;
    });
  }
  return [allIds, byId];
};

export default normalize;
