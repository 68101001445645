import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { DraggableCore } from 'react-draggable';
import { withStyles } from '@material-ui/core/styles';
import { withStylesPropTypes } from '../../../helper/misc';
import KeyboardHandle from '../../../helper/keyboard';

const useStyles = ((theme) => ({
  cell: {
    color: theme.palette.text.primary,
    // overflow: 'hidden',
    whiteSpace: 'nowrap',
    border: `solid 1px ${theme.palette.border.primary}`,
    '&:focus': {
      outline: 'none',
    },
  },
  cellWrapper: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  editableCell: {
    cursor: 'cell',
  },
  editingCell: {
    padding: '1px',
  },
  spreadCell: {
    cursor: 'move',
  },
  currentCell: {
    backgroundColor: '#0000ff !important',
  },
  selectedCell: {
    backgroundColor: '#0088ff',
  },
  cursorCell: {
    width: '100%',
    height: '100%',
    opacity: 0.2,
    position: 'absolute',
    pointerEvents: 'none',
  },
  textarea: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    opacity: 0,
    cursor: 'pointer',
    pointerEvents: 'none',
  },
  anchor: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: theme.spacing(0.5),
    height: theme.spacing(0.5),
    backgroundColor: '#000000',
    cursor: 'move',
  },
}));


function SheetCell({
  style,
  classes,
  children,
  spreadAnchor,
  editable,
  current,
  focused,
  selected,
  editing,
  spread,
  tabIndex,
  onCellSelect,
  onStartEdit,
  onCellKeyDown,
  onMouseDown,
  onMouseUp,
  onMouseOver,
  onNavigate,
  onContextMenu,
  onSpreadStart,
  onSpreadEnd,
  onSpreadOver,
}) {
  let textAreaRef;

  const onKeyDown = (e) => {
    KeyboardHandle(e, {
      onEnter: (event) => onNavigate({ event, deltaRow: 1 }),
      onShiftEnter: (event) => onNavigate({ event, deltaRow: -1 }),
      onDown: (event) => onNavigate({ event, deltaRow: 1 }),
      onUp: (event) => onNavigate({ event, deltaRow: -1 }),
      onRight: (event) => onNavigate({ event, deltaCol: 1 }),
      onLeft: (event) => onNavigate({ event, deltaCol: -1 }),
      onF2: () => editable && onStartEdit(),
      default: [(event) => { event.persist(); onCellKeyDown(event); }, false, false],
    });
  };

  const fnCellSelect = ({ event }) => {
    if (!editing) {
      onCellSelect({ event });
    }
  };

  const handleKeyUp = () => {
    onCellKeyDown();
  };

  const handleMouseDown = (event) => {
    if (event.nativeEvent.which === 1) {
      if (!event.shiftKey) fnCellSelect({ event });
      onMouseDown(event);
    }
  };

  const handleMouseUp = (event) => {
    if (event.nativeEvent.which === 1) onMouseUp(event);
  };

  const handleMouseOver = (event) => {
    if (spread) onSpreadOver(event);
    else onMouseOver(event);
  };

  const handleKeyPress = (event) => {
    if (editable && !editing) {
      event.stopPropagation();
      event.preventDefault();
      onStartEdit(String.fromCharCode(event.charCode));
    }
  };

  const handleContextMenu = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onContextMenu(event);
  };

  useEffect(() => {
    if (focused && textAreaRef) {
      textAreaRef.focus();
    }
  }, [focused, textAreaRef]);

  return (
    <div
      style={style}
      className={clsx(classes.cell, editable && classes.editableCell, editing && classes.editingCell, spread && classes.spreadCell)}
      onContextMenu={(event) => handleContextMenu(event)}
      onMouseDown={(event) => handleMouseDown(event)}
      onMouseUp={(e) => handleMouseUp(e)}
      onMouseEnter={(e) => handleMouseOver(e)}
      onKeyDown={(e) => onKeyDown(e)}
      onKeyUp={(e) => handleKeyUp(e)}
      onKeyPress={(e) => handleKeyPress(e)}
      onDoubleClick={() => editable && onStartEdit()}
      tabIndex={tabIndex}
      role="button"
    >
      { ((selected || current) && !editing) && <div className={clsx(classes.cursorCell, selected && classes.selectedCell, current && classes.currentCell)} /> }
      { /* eslint-disable-next-line jsx-a11y/aria-role */ }
      <div className={classes.cellWrapper} role="sheet-cell">
        { focused && !editing && <textarea ref={(ref) => { textAreaRef = ref; }} className={classes.textarea} /> }
        { children }
      </div>
      {
        spreadAnchor && (
        <DraggableCore
          onMouseDown={(e) => { e.stopPropagation(); e.preventDefault(); }}
          onStart={() => onSpreadStart()}
          onStop={() => onSpreadEnd()}
        >
          <div className={classes.anchor} />
        </DraggableCore>
        )
      }
    </div>
  );
}


SheetCell.defaultProps = {
  onCellSelect: () => { },
  onNavigate: () => { },
  onCellKeyDown: () => { },
  onStartEdit: () => { },
  onMouseDown: () => { },
  onMouseUp: () => { },
  onMouseOver: () => { },
  onContextMenu: () => {},
  onSpreadStart: () => {},
  onSpreadEnd: () => {},
  onSpreadOver: () => {},
  current: false,
  focused: false,
  editing: false,
  editable: false,
  selected: false,
  tabIndex: 0,
  children: null,
  spread: false,
  spreadAnchor: false,
};

SheetCell.propTypes = {
  ...withStylesPropTypes,
  style: PropTypes.shape().isRequired,
  onCellSelect: PropTypes.func,
  onNavigate: PropTypes.func,
  onCellKeyDown: PropTypes.func,
  onStartEdit: PropTypes.func,
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func,
  onMouseOver: PropTypes.func,
  onContextMenu: PropTypes.func,
  onSpreadStart: PropTypes.func,
  onSpreadEnd: PropTypes.func,
  onSpreadOver: PropTypes.func,
  current: PropTypes.bool,
  focused: PropTypes.bool,
  editing: PropTypes.bool,
  selected: PropTypes.bool,
  editable: PropTypes.bool,
  tabIndex: PropTypes.number,
  children: PropTypes.node,
  spread: PropTypes.bool,
  spreadAnchor: PropTypes.bool,
};

export default withStyles(useStyles)(SheetCell);
