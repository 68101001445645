export const MATCH_TYPES = {
  TIGHT: 'tight',
  LOOSE: 'loose',
  MEDIUM: 'medium',
};

export const MATCH_TYPES_LIST = [
  MATCH_TYPES.TIGHT,
  MATCH_TYPES.MEDIUM,
  MATCH_TYPES.LOOSE,
];

export const MATCH_TYPE_DEFAULT = MATCH_TYPES.MEDIUM;

export const GROUP_BY = {
  ANY: 'any',
  CORTEX: 'cortex',
  PHONE: 'phone',
  STORE_NUMBER: 'store_number',
  POLYGON: 'polygon',
  DISTANCE: 'distance',
  UNGROUP: 'ungroup',
  MERGED: 'merged',
};

export const GROUP_BY_DEFAULT = GROUP_BY.CORTEX;

export const DISTANCE = {
  MIN: 0.05,
  MAX: 1,
};

export const AUTOMERGE_MAX_DISTANCE = 0.1;

export const DISTANCE_LIST = [
  DISTANCE.MIN,
  0.1,
  0.25,
  0.5,
  0.75,
  DISTANCE.MAX,
];
