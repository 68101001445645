import React, { useEffect, useState, createContext, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { ReflexContainer, ReflexSplitter, ReflexElement } from 'react-reflex';
import 'react-reflex/styles.css';
import { Grid } from '@material-ui/core';
import { withStylesPropTypes } from '../helper/misc';
import { fetchLists, expandList, setSelectedListId } from '../app/listSlice';
import { fetchPayloads, setPayloadSelected, approveLocations,pushHeader} from '../app/payloadSlice';
import { mergingLogs } from '../app/aliMatchesSlice';
import StationsBoardWidgetQA from '../features/stationsboard/stationsboardwidgetqa';
import DatesWidget from '../features/common/widgets/dates';
import StationsBoardWidgetALIMatching from '../features/stationsboard/stationsboardwidgetalimatching';
import LocationsWidgetQA from '../features/locations/locationswidgetqa';
import LocationsWidgetALIMatching from '../features/locations/locationswidgetalimatching';
import SummaryWidgetQA from '../features/summary/summarywidgetqa';
import MapWidget from '../features/map/mapwidget';
import { dataProviderToCSV } from '../features/common/sheet/dataproviderexport';
import Config from '../config';
import CSVIcon from '../resources/icons/csvicon';
import CalendarIcon from '../resources/icons/calendar-icon';
import { selectSelectedListId } from '../app/listSelectors';
import { mergingLogsDownloadUrl } from '../app/aliMatchesSelectors';
import User from '../helper/user';
import { GROUP_BY } from '../const/alimatch';
import { groupDuplicates } from '../api/dedupe';
import { deleteLocations, updateLocations} from '../app/locationsSlice';
import { selectSelectedPayloadIds } from '../app/payloadSelectors';
import BasicModal from '../features/common/modal'

const useStyles = ((theme) => ({
  appBar: {
    backgroundColor: '#00203c',
  },
  appBarSpacer: {
    flexGrow: 0,
    ...theme.mixins.toolbar,
  },
  title: {
    flexGrow: 1,
    '&>span': {
      fontWeight: 600,
    },
  },
  topLinks: {
    textAlign: 'center',
  },
  topButtons: {
    textAlign: 'right',
  },
  toolbarLink: {
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
  },
  active: {
    color: theme.palette.success.light,
  },
  icon: {
    color: theme.palette.success.light,
  },
  iconDisabled: {
    color: `${theme.palette.text.disabledContrast} !important`,
  },
  hidden: {
    display: 'none !important',
  },
}));

const DEFAULTS = Config.isDevMode ? {
  listId: 3369,
  payloadId: '5fd8a5185237dd5e4d9a670c',
} : null;

const APP_MODE = {
  QA: 0,
  GEOFENCER: 1,
  ALI_MATCHING: 2,
};

export const HomeScreen = createContext()

const RenderLocationsWidgetQa = ({appMode, setExportDataProvider, dimensions}) => {
  if (appMode === APP_MODE.QA){
  return(
    <LocationsWidgetQA exportDataProvider={setExportDataProvider} dimensions={dimensions}/>
  )
  }
  if (appMode === APP_MODE.GEOFENCER){
    return(
      <LocationsWidgetQA exportDataProvider={setExportDataProvider} dimensions={dimensions} />
    )
  }
  if (appMode === APP_MODE.ALI_MATCHING){
    return(
      <LocationsWidgetALIMatching exportDataProvider={setExportDataProvider} dimensions={dimensions} />
    )
  } 
}

const Home = ({
  classes, fnFetchLists, fnExpandList, fnSetSelectedListId, fnFetchPayloads, fnSetPayloadSelected, fnMergingLogs, selectedListId, mergingLogsCsvUrl, 
  openingsAndClosingsCsvFileUrl, selectedMatchFilter, selectedPayloadIds, fnDeleteLocations,  fnUpdateLocations, fnApproveLocations,fnPushHeader,
}) => {
  const [appMode, setAppMode] = useState(APP_MODE.QA);
  const [exportDataProvider, setExportDataProvider] = useState();
  const [menu, setMenu] = useState();
  const [openDatesWidget, setOpenDatesWidget] = useState(false);
  const [sheetFullScreen,setSheetFullScreen] = useState(false);
  const [customDedupe, setCustomDedupe] = useState(false)
  const [singleColumn,setSingleColumn] = useState([]);
  const [allColumns,setAllColumns] = useState([]);
  const [groupArray,setGroupArray] = useState([]);
  const [isSubmit,setIsSubmit] = useState(false);
  const [duplicateMessage,setDuplicateMessage] = useState('0');
  const [locationsView,setLocationsView] = useState([]);
  const [addAll,setAddAll] = useState(false);
  const [qaTableColumns, setQaTableColumns] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [deleteLocationIds,setDeleteLocationIds] = useState([]);
  const [duplicateCounts,setDuplicateCounts] = useState(0);
  const [duplicateGroupCounts, setduplicateGroupCounts] = useState([]);
  const [removeDuplicateModal, setRemoveDuplicateModal] = useState(false);
  const [noDuplicateModal, setNoDuplicateModal] = useState(false);
  const [geoCodePassed,setGeoCodePassed] = useState(false);
  const [editingCellContent, setEditingCellContent] = useState([]);
  const [editForSync, setEditForSync] = useState(false);
  const [syncEditValues, setSyncEditValues] = useState({})
  const [syncModal, setSyncModal] = useState(false)
  const [actionsList, setActionsList] = useState([]);
  const [updatesPostList, setUpdatesPostList] = useState([]);
  const [tempUpdatesList, setTempUpdatesList] = useState([]);
  const [locationToApproveList,setLocationToApproveList] = useState([]);
  const [dataToApproveValues,setDataToApproveValues]= useState([]);
  const [headerToPushValues,setHeaderToPushValues] = useState([]);
  const [dedupeModal, setDedupeModal] = useState({'open': false, 'message':''});
  const [currentSort, setCurrentSort] = useState(null);
  const [pushHeaderPayloadId,setPushHeaderPayloadId] = useState(null);

  const homeSheetRef = useRef(null)

  useEffect(() => {
    fnFetchLists();
    if (DEFAULTS) {
      if (DEFAULTS.payloadId) {
        fnFetchPayloads(DEFAULTS.listId);
        fnExpandList({ id: DEFAULTS.listId });
        fnSetPayloadSelected(DEFAULTS.payloadId);
      } else {
        fnSetSelectedListId({ id: DEFAULTS.listId });
      }
    }
  }, [fnFetchLists, fnExpandList, fnSetSelectedListId, fnFetchPayloads, fnSetPayloadSelected]);

  useEffect(() => {
    setExportDataProvider();
  }, [appMode]);

  useEffect(() => {
    if (editForSync) handleChangeSync()
  },[selectedPayloadIds])

  useEffect(() => {
    const filtered = qaTableColumns.reduce((a, o) => (o.title !== '' && a.push(o.title), a), []);
    if(filtered.length !== singleColumn.length)
    {
      setAddAll(false);
    }
    else
    {
      setAddAll(true);
    }
  }, [qaTableColumns,singleColumn]);

  useEffect(() => {
    setExportDataProvider();
  }, [appMode]);

  const handleCSVExport = (event, downloadOption) => {
    if (exportDataProvider) {
      const { target } = event;
      if (exportDataProvider.getDownloadOptions && !downloadOption) {
        const options = exportDataProvider.getDownloadOptions();
        setMenu({
          anchor: target,
          options,
        });
      } else {
        const fileName = exportDataProvider.getFileName ? exportDataProvider.getFileName(downloadOption) : 'export';
        exportDataProvider.getDataProvider(downloadOption, fileName).then((dataProvider) => dataProviderToCSV(dataProvider, fileName));
      }
    }
  };

  const handleMenuClick = (event, key) => {
    setMenu();
    if (key) handleCSVExport(event, key);
  };

  const handleDatesClick = () => {
    setOpenDatesWidget(!openDatesWidget);
  };

  const handleDatesSubmit = (dateFrom, dateTo) => {
    setOpenDatesWidget(!openDatesWidget);
    fnMergingLogs(dateFrom, dateTo);
  };

  const handleSheetFullScreen = () => {
    if (editForSync && sheetFullScreen) handleChangeSync();
    setSheetFullScreen(!sheetFullScreen)  
  }

  const handleDeleteLocationIds = async(answer) => {
    if (answer === 'Yes') { 
    await fnDeleteLocations(deleteLocationIds);
    if(duplicateCounts!==deleteLocationIds.length)
    {
      setSelectedLocation([]);
      setDeleteLocationIds([]);
      await handleDedupeSubmit();
    }
    else
    {
      resetCustomDedupe();
    }
  }
  setRemoveDuplicateModal(false);
  }

  const resetCustomDedupe = () => {
    setCustomDedupe(false)
    setSingleColumn([])
    setAllColumns([])
    setIsSubmit(false)
    setGroupArray([])
    setDuplicateMessage('0')
    setAddAll(false)
    setSelectedLocation([]);
    setLocationsView([]);
    setDeleteLocationIds([]);
    setDuplicateCounts(false);
    setRemoveDuplicateModal(false);
  }

  const refreshDedupeSubmit = () => {
    if(singleColumn.length>=1)
    {
    const id = Object.keys(selectedPayloadIds)[0]
    groupDuplicates({id,headers:singleColumn},(data)=>{
      setDuplicateCounts(Number(data.message));
      setDuplicateMessage(data.message);
      });
    }
    else
    {
      setDuplicateCounts(Number(0));
      setDuplicateMessage(0);
    }
  }

  const handleDedupeSubmit = async() => {
    const id = Object.keys(selectedPayloadIds)[0]
    await groupDuplicates({id,headers:singleColumn},(data)=>{
      let locations = [];
      let groupNumber = 1;
      let groupNumberCount = 0
      let groupDuplicateCountTemp = [];
      for(let i = 0;i<data.groupedLocations.length;i++)
      {
        locations.push({...data.groupedLocations[i],group:data.groupArray[i]})
        if(groupNumber === data.groupArray[i])
        {
          groupNumberCount = groupNumberCount +1;
        }
        else
        {
          groupDuplicateCountTemp.push(groupNumberCount);
          groupNumberCount = 1;
        }
        groupNumber = data.groupArray[i];
        if(i===(data.groupedLocations.length-1)) groupDuplicateCountTemp.push(groupNumberCount);
      }
      setduplicateGroupCounts([...groupDuplicateCountTemp]);
      if(Number(data.message) > 0)
      {
        setGroupArray([...data.groupArray]);
        setLocationsView([...locations]);
      }
      else
      {
        setIsSubmit(false);
        setNoDuplicateModal(true);
      }
      setDuplicateCounts(Number(data.message));
      setDuplicateMessage(data.message);
      });
  }

  const handleCustomDedupe = async() => {
    if (editForSync){
      await handleChangeSync()
      setCustomDedupe(!customDedupe)
    }
    else
      setCustomDedupe(!customDedupe)
  }

  const checkAllSelected=()=>
  {
    let maxGroup = locationsView[locationsView.length-1].group;
    let value = selectedLocation.length === locationsView.length - maxGroup - 1 ? true : false;
    return value;
  }

  const checkAllInGroupSelected = async(value) =>
  {
    let group = await locationsView.find((location)=>{return location._id===value}).group;
    let groupElements = locationsView.filter((location)=>location.group===group);
    let unSelectedCount = 0;
    groupElements.forEach((element)=>{
      if(selectedLocation.indexOf(element._id)<0)
      unSelectedCount = unSelectedCount+1;
    })
    return unSelectedCount>1 ? false : true
  }

  const handleCheckboxLocationSelect = async (value) => {
    if(value === 'all')
    {
      if (selectedLocation.indexOf('all')>=0)
      {
        setSelectedLocation([]);
        setDeleteLocationIds([]);
      }
      else{
        let allSelectLocation = [];
        let previousCount = 0;
        if (currentSort){
          console.log("sort")
          homeSheetRef.current.currentRows.forEach((location)=>{
            previousCount === location.group ? allSelectLocation.push(location._id) : previousCount = previousCount+1
          });
        }else{

        
        locationsView.forEach((location)=>{
          previousCount === location.group ? allSelectLocation.push(location._id) : previousCount = previousCount+1
        });
        }
        // locationsView.forEach((location)=>{
        //   previousCount === location.group ? allSelectLocation.push(location._id) : previousCount = previousCount+1
        // });
        setSelectedLocation(['all',...allSelectLocation]);
        setDeleteLocationIds([...allSelectLocation]);
      }
    }
    else{
      if(selectedLocation.indexOf(value)>=0)
      {
        let newSelectedLocation = [...selectedLocation]
        newSelectedLocation.splice(newSelectedLocation.indexOf(value),1);
        if(newSelectedLocation.indexOf('all')>=0)
        {
          newSelectedLocation.splice(newSelectedLocation.indexOf('all'),1);
        }
        setSelectedLocation([...newSelectedLocation]);
        setDeleteLocationIds([...newSelectedLocation]);
      }
      else
      {
        if(selectedLocation.indexOf('all')>=0)
        {
          setDedupeModal({'open': true,'message':'You are not allowed to check all locations in a single duplicate group'});
        }
        else
        {
          if(await checkAllInGroupSelected(value))
          { 
            setDedupeModal({'open': true,'message':'You are not allowed to check all locations in a single duplicate group'});
          }
          else
          {
            if(checkAllSelected())
            {
              setSelectedLocation(['all',...selectedLocation,value]);
              setDeleteLocationIds([...selectedLocation,value]);
              
            }
            else
            {
              setSelectedLocation([...selectedLocation,value]);
              setDeleteLocationIds([...selectedLocation,value]);
            }
            
          } 
        }
        
      }

    }
  }

  const handleChangeSync = async () => {
    for(var index=0; index<actionsList.length; index++)
    {
      let action = actionsList[index];
      let updatesToPost = updatesPostList[index];
      let tempUpdates = tempUpdatesList[index];
      let locationsToApprove = locationToApproveList[index];
      await fnUpdateLocations(action, updatesToPost, tempUpdates).then(() => {
        const dataToApprove = Object.keys(locationsToApprove).map((id) => ({
          _id: id,
          stations: locationsToApprove[id],
        }));
        if (dataToApprove.length) fnApproveLocations(dataToApprove);       
        if (appMode === 0 && locationsView.length > 0 && locationsView[editingCellContent[index]] && isSubmit)
        {
          let value = locationsView[editingCellContent[index]]._id;
          if(selectedLocation.indexOf(value) >= 0)
          {           
            handleCheckboxLocationSelect(value);
          }
        }
      });
    }
    setActionsList([]);
    setUpdatesPostList([]);
    setTempUpdatesList([]);
    setSyncEditValues({});
    const dataToApproveList = [...new Set(Object.values(dataToApproveValues))];
    if(dataToApproveList.length>0)
    {
      fnApproveLocations(dataToApproveList)
    }
    const names =[...new Set(Object.values(headerToPushValues))];
    if(names.length>0)
    {
      await fnPushHeader(pushHeaderPayloadId,names);
    }
    setDataToApproveValues({});
    setHeaderToPushValues({});
    setEditForSync(false);
    setPushHeaderPayloadId();
    if(isSubmit) handleDedupeSubmit(null);
  }

  onkeydown = (e) => {
    if(e.ctrlKey && e.altKey &&(e.key === "f" || e.key === "F"))
      handleSheetFullScreen();
  }

  return (
    <HomeScreen.Provider value={{sheetFullScreen ,handleSheetFullScreen, customDedupe, handleCustomDedupe, appMode, singleColumn, setSingleColumn, allColumns,
      setAllColumns, groupArray, setGroupArray, isSubmit, setIsSubmit, duplicateMessage, setDuplicateMessage, handleDedupeSubmit, resetCustomDedupe, locationsView, 
      addAll, setAddAll, qaTableColumns, setQaTableColumns, selectedLocation, setSelectedLocation, deleteLocationIds, setDeleteLocationIds , handleDeleteLocationIds,
      duplicateGroupCounts, setduplicateGroupCounts, setRemoveDuplicateModal, refreshDedupeSubmit,geoCodePassed, setGeoCodePassed,editingCellContent, setEditingCellContent,
      editForSync, setEditForSync, syncEditValues , setSyncEditValues, setSyncModal, actionsList, setActionsList, updatesPostList, setUpdatesPostList, 
      tempUpdatesList, setTempUpdatesList, locationToApproveList,setLocationToApproveList, dedupeModal, setDedupeModal, handleCheckboxLocationSelect, handleChangeSync,
      currentSort,setCurrentSort,dataToApproveValues,setDataToApproveValues,headerToPushValues,setHeaderToPushValues,pushHeaderPayloadId,setPushHeaderPayloadId, homeSheetRef
      }}>
      { menu && (
        <Menu
          anchorEl={menu.anchor}
          open
          onClose={(e) => handleMenuClick(e)}
        >
          {
            menu.options.map((opt) => (
              <MenuItem key={opt.key} onClick={(e) => handleMenuClick(e, opt.key)}>{ opt.title }</MenuItem>
            ))
          }
        </Menu>
      )}
      {
        removeDuplicateModal && (
          <BasicModal
            text={"Are you sure you would like to delete "+deleteLocationIds.length+" duplicate locations?"}
            buttons={['Yes', 'No']}
            focusedButton="Yes"
            onClose={(answer) => handleDeleteLocationIds(answer)}
          />
        )
      }
      {
        noDuplicateModal && (
          <BasicModal
            text={"No duplicates found"}
            buttons={['Close']}
            focusedButton="Yes"
            onClose={() => setNoDuplicateModal(false)}
          />
        )
      }
      <DatesWidget open={openDatesWidget} onClose={handleDatesClick} handleDatesSubmit={handleDatesSubmit} />
      <ReflexContainer orientation="horizontal">
        <ReflexElement flex={0} className={classes.appBarSpacer}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Grid container>
                <Grid item xs={3}>
                  <Typography component="h1" variant="h6" noWrap className={classes.title}>
                    <span>QA</span>
                    ggData
                  </Typography>
                </Grid>
                <Grid item xs={6} className={classes.topLinks}>
                  <Button variant="text" className={clsx(classes.toolbarLink, appMode === APP_MODE.QA && classes.active)} onClick={() => setAppMode(APP_MODE.QA)}>QA</Button>
                  <Button variant="text" className={clsx(classes.toolbarLink, appMode === APP_MODE.GEOFENCER && classes.active)} onClick={() => setAppMode(APP_MODE.GEOFENCER)}>Geofencer</Button>
                  {/* <Button variant="text" className={clsx(classes.toolbarLink, appMode === APP_MODE.ALI_MATCHING && classes.active)} onClick={() => setAppMode(APP_MODE.ALI_MATCHING)}>ALI Matching</Button> */}
                </Grid>
                <Grid item xs={3} className={classes.topButtons}>
                  { mergingLogsCsvUrl && User.isAdmin() && (appMode === APP_MODE.ALI_MATCHING)
                    && <IconButton classes={{ root: classes.icon, disabled: classes.iconDisabled }} onClick={() => window.open(mergingLogsCsvUrl)}><CSVIcon /></IconButton> }
                  { User.isAdmin()
                    && <IconButton disabled={!(appMode === APP_MODE.ALI_MATCHING)} classes={{ root: classes.icon, disabled: classes.iconDisabled }} onClick={(e) => handleDatesClick(e)}><CalendarIcon /></IconButton> }
                  <IconButton disabled={!(exportDataProvider && exportDataProvider.hasData())} classes={{ root: classes.icon, disabled: classes.iconDisabled }} onClick={(e) => handleCSVExport(e)}><CSVIcon /></IconButton>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
        </ReflexElement>
        <ReflexElement flex={1}>
          <ReflexContainer orientation="vertical">
            <ReflexElement className={clsx(sheetFullScreen && classes.hidden)} flex={1} minSize={300}>
              <ReflexContainer orientation="horizontal">
                <ReflexElement flex={1}>
                  { appMode === APP_MODE.QA && <StationsBoardWidgetQA /> }
                  { appMode === APP_MODE.GEOFENCER && <StationsBoardWidgetQA /> }
                  { appMode === APP_MODE.ALI_MATCHING && <StationsBoardWidgetALIMatching /> }
                </ReflexElement>
                <ReflexSplitter propagate />
                <ReflexElement flex={1}>
                  { appMode === APP_MODE.QA && <SummaryWidgetQA /> }
                </ReflexElement>
              </ReflexContainer>
            </ReflexElement>
            {!sheetFullScreen && <ReflexSplitter propagate /> }
            <ReflexElement flex={1} minSize={350}>
              <ReflexContainer orientation="horizontal">
                <ReflexElement flex={1} propagateDimensionsRate={200} propagateDimensions={true} >
                   <RenderLocationsWidgetQa appMode={appMode} setExportDataProvider={setExportDataProvider}/>
                </ReflexElement>
                {!sheetFullScreen && <ReflexSplitter propagate /> }
                <ReflexElement className={clsx(sheetFullScreen && classes.hidden)} flex={1}>
                  { appMode === APP_MODE.QA && <MapWidget /> }
                  { appMode === APP_MODE.GEOFENCER && <MapWidget /> }
                  { appMode === APP_MODE.ALI_MATCHING && <MapWidget aliMatchesMode /> }
                </ReflexElement>
              </ReflexContainer>
            </ReflexElement>
          </ReflexContainer>
        </ReflexElement>
      </ReflexContainer>
    </HomeScreen.Provider>
  );
}

Home.propTypes = {
  ...withStylesPropTypes,
  fnDeleteLocations: PropTypes.func,
};

Home.defaultProps = {
  fnDeleteLocations: deleteLocations,
};

const mapStateToProps = (state, props) => ({
  selectedListId: selectSelectedListId(state, props),
  mergingLogsCsvUrl: mergingLogsDownloadUrl(state, props),
  selectedPayloadIds: selectSelectedPayloadIds(state, props),
});

export default connect(mapStateToProps, {
  fnFetchLists: fetchLists,
  fnExpandList: expandList,
  fnSetSelectedListId: setSelectedListId,
  fnFetchPayloads: fetchPayloads,
  fnSetPayloadSelected: setPayloadSelected,
  fnMergingLogs: mergingLogs,
  fnDeleteLocations: deleteLocations,
  fnUpdateLocations: updateLocations,
  fnApproveLocations: approveLocations,
  fnPushHeader: pushHeader,
})(withStyles(useStyles)(Home));
