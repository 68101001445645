import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withStyles, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import { selectSelectedListId, selectSelectedListMatchFilter, selectSelectedListMatchDistance } from '../../app/listSelectors';
import {
  resetALIMatchSelection, mergeMatches, unmerge, autoMerge, getALIMatchesStart, getALIMatchesProgress, getALIMatchesSuccess, getALIMatchesFailed,
} from '../../app/aliMatchesSlice';
import {
  selectALIMatchesLoading, selectSelectedALIMatches, selectCurrentGroupIndex, selectCortexAutoMerge, selectMatchingId,
} from '../../app/aliMatchesSelectors';
import Confirm from '../common/confirm';
import Sheet from '../common/sheet/sheet';
import { defaultCellRenderer } from '../common/sheet/renderers';
import DataProvider from '../common/sheet/dataprovider';
import {
  resolve, formatDate, compareObjects, withStylesPropTypes,
} from '../../helper/misc';
import KeyboardHandle from '../../helper/keyboard';
import LocationColumnHeader from './locationcolumnheader';
import { buildFormulaEditContextMenu, buildCellContextMenu, buildColumnContextMenu } from './contextmenu';
import ALIMatchesAPI from '../../api/alimatches';
import { GROUP_BY, AUTOMERGE_MAX_DISTANCE } from '../../const/alimatch';

const useStyles = (() => ({
  mergeButton: {
    height: '100%',
  },
  autoMergeButton: {
    height: '100%',
  },
}));

const minDate = '1900-01-01';

const LOCAL_MENU_COMMANDS = {
  RESTORE_COLUMN: 'restore_columns',
};

const GROUP_STYLES = [
  { backgroundColor: '#f9cdca' },
  { backgroundColor: '#f9f8ca' },
  { backgroundColor: '#caf8db' },
  { backgroundColor: '#dae0f8' },
];

function LocationsSheetALIMatching({
  classes,
  tableColumns: initialTableColumns,
  standardHeaders,
  locations,
  selectedListId,
  selectedALIMatches,
  currentGroupIndex,
  listMatchFilter,
  listMatchDistance,
  cortexAutoMerge,
  currentMatchingId,
  aliMatchesLoading,
  fnResetALIMatchSelection,
  fnMergeMatches,
  fnUnmerge,
  fnAutoMerge,
  fnGetALIMatchesStart,
  fnGetALIMatchesProgress,
  fnGetALIMatchesSuccess,
  fnGetALIMatchesFailed,
  exportDataProvider,
  dimensions
}) {
  const defaultTheme = useTheme();

  const defaultCellStyle = {
    color: defaultTheme.palette.text.primary,
    backgroundColor: defaultTheme.palette.background.paper,
  };

  const [colorMap, setColorMap] = useState({});
  const [groups, setGroups] = useState([]);
  const [mostRecentRecord, setMostRecentRecord] = useState({});
  const [locationsView, setLocationsView] = useState(locations);
  const [colored, setColored] = useState(true);
  const [tableColumns, setTableColumns] = useState(initialTableColumns);
  const [hiddenColumns, setHiddenColumns] = useState({});
  const [finalTableColumns, setFinalTableColumns] = useState(initialTableColumns);
  const [history, setHistory] = useState([]);
  const [showAutoMergeConfimed, setShowAutoMergeConfrmed] = useState(false);
  const [showMergeConfirm, setShowMergeConfirm] = useState(false);
  const [warningMessage, setWarningMessage] = useState();
  const [canMerge, setCanMerge] = useState(false);
  const [lastMergeAction, setLastMergeAction] = useState();

  useEffect(() => {
    setTableColumns(initialTableColumns);
  }, [initialTableColumns]);

  useEffect(() => {
    let columns = tableColumns;
    if (columns && hiddenColumns && Object.keys(hiddenColumns).length) {
      columns = columns.filter((c) => !hiddenColumns[c.data]);
    }
    setFinalTableColumns(columns);
  }, [tableColumns, hiddenColumns]);

  useEffect(() => {
    const groupList = [];
    const groupMostRecentRecord = {};
    if (locations) {
      locations.forEach((l) => {
        const { group = '', lastScrapedAt = minDate } = l;
        if (!groupMostRecentRecord[group]) {
          groupMostRecentRecord[group] = l;
          groupList.push(group);
        } else {
          const { lastScrapedAt: date = minDate } = groupMostRecentRecord[group];
          if (date.localeCompare(lastScrapedAt) < 0) groupMostRecentRecord[group] = l;
        }
      });
    }
    setGroups(groupList);
    setMostRecentRecord(groupMostRecentRecord);
  }, [locations]);

  useEffect(() => {
    fnResetALIMatchSelection();
  }, [currentGroupIndex, fnResetALIMatchSelection]);


  useEffect(() => {
    if (currentGroupIndex >= 1 && currentGroupIndex <= groups.length) {
      const currentGroup = groups[currentGroupIndex - 1];
      setLocationsView(locations.filter((l) => l.group === currentGroup));
      setColored(false);
    } else {
      setLocationsView(locations);
      let lastGroup = null;
      for (let i = 0; i < locations.length; i += 1) {
        if (!locations[i].group || (lastGroup && locations[i].group !== lastGroup)) break;
        lastGroup = locations[i].group;
      }
      setColored(!!lastGroup);
    }
  }, [locations, currentGroupIndex, groups]);

  useEffect(() => {
    let ok = false;
    let warning;

    if (selectedALIMatches && selectedALIMatches.length >= 2) {
      const seenPayloadIds = {};
      ok = !selectedALIMatches.some((l) => {
        if (l.payloads) {
          if (l.payloads.some((payloadId) => !!seenPayloadIds[payloadId])) return true;
          l.payloads.forEach((payloadId) => {
            seenPayloadIds[payloadId] = true;
          });
        }
        return false;
      });

      if (!ok) {
        switch (listMatchFilter) {
          case GROUP_BY.CORTEX:
            warning = 'You\'ve selected two ALIs from the same scrape, try adding more fields or try tighter settings';
            break;
          case GROUP_BY.DISTANCE:
            warning = 'You\'ve selected 2 ALIs from the same scrape, either they are nearby locations or the payload has a duplicate';
            break;
          case GROUP_BY.PHONE:
            warning = 'You\'ve selected 2 ALIs from the same scrape, Phone Number may not be a reliable check for this list';
            break;
          case GROUP_BY.STORE_NUMBER:
            warning = 'You\'ve selected 2 ALIs from the same scrape, Store Number may not be a reliable check for this list';
            break;
          case GROUP_BY.UNGROUP:
            warning = '"You\'ve selected 2 ALIs from the same scrape, please be careful!';
            break;
          default:
            break;
        }
      } else if (colored) {
        let group;
        ok = !selectedALIMatches.some((l) => {
          if (group && group !== l.group) return true;
          group = l.group;
          return false;
        });

        if (!ok) {
          warning = 'You\'ve selected ALIs from separate Matches, please only select ALIs from one Match at a time';
        }
      }
    }

    if (!warningMessage || warningMessage.message !== warning) {
      setWarningMessage(warning ? { message: warning, show: true } : undefined);
    }
    setCanMerge(ok);
    // eslint-disable-next-line
  }, [selectedALIMatches, colored, listMatchFilter]);

  useEffect(() => {
    setHistory([]);
    setLastMergeAction();
  }, [selectedListId, listMatchFilter]);

  const handleRowColors = (rows) => {
    const groupColorMap = {};
    let colorIndex = 0;
    rows.forEach((r) => {
      const { group = '' } = r;
      if (groupColorMap[group] === undefined) {
        groupColorMap[group] = colorIndex;
        colorIndex += 1;
      }
    });
    if (!compareObjects(groupColorMap, colorMap)) setColorMap(groupColorMap);
    return rows;
  };

  const getCellStyle = ({ data }) => {
    let ret;
    if (data) {
      if (colored) {
        const colorIndex = colorMap[data.group];
        if (colorIndex >= 0) ret = GROUP_STYLES[colorIndex % GROUP_STYLES.length];
      }
      if (listMatchFilter === GROUP_BY.MERGED && !data.originalAli) {
        ret = { fontWeight: 'bold', ...ret };
      }
    }
    return ret;
  };

  const getCellValue = useCallback(({ row, column }) => {
    let ret = resolve(row, column.data);
    if (column.formatter) {
      ret = column.formatter(ret);
    } else if (column.type && column.type === 'date') {
      ret = formatDate(ret);
    }
    return ret;
  }, []);

  const getRowKey = useCallback((row) => row._id, []);

  const getColumnKey = useCallback((column) => column.data, []);

  const createExportDataProvider = useCallback(() => new Promise((presolve, preject) => {
    fnGetALIMatchesStart();
    const rows = [];
    const fnFetch = (page) => {
      ALIMatchesAPI.fetchAllByListId(selectedListId, true, undefined, page).then((result) => {
        result.docs.forEach((d) => {
          rows.push({ ...d, aliFinal: d.ali, ali: d.originalAli || d.ali });
        });
        fnGetALIMatchesProgress({ totalPages: result.totalPages, page: result.page });
        if (result.offset + result.docs.length < result.totalDocs) {
          fnFetch((page || 0) + 1);
        } else {
          const exportTableColumns = [...tableColumns];
          const addedColumns = [
            { data: 'aliFinal' },
            { data: 'mergedAtStation' },
          ];
          const aliIndex = exportTableColumns.findIndex((c) => c.data === 'ali');
          if (aliIndex >= 0) {
            exportTableColumns.splice(aliIndex + 1, 0, ...addedColumns);
          }
          fnGetALIMatchesSuccess();
          presolve(new DataProvider(rows, exportTableColumns, {}, {}, getCellValue, ({ row, column }) => `${getRowKey(row)}/${getColumnKey(column)}`));
        }
      }).catch((error) => {
        fnGetALIMatchesFailed({ error });
        preject(error);
      });
    };
    fnFetch(0);
  }), [selectedListId, tableColumns, getRowKey, getColumnKey, getCellValue, fnGetALIMatchesStart, fnGetALIMatchesProgress, fnGetALIMatchesSuccess, fnGetALIMatchesFailed]);

  useEffect(() => {
    if (exportDataProvider) {
      exportDataProvider({
        getDataProvider: createExportDataProvider,
        hasData: () => !!selectedListId,
        getFileName: () => `backfill-${selectedListId}`,
      });
    }
  }, [exportDataProvider, selectedListId, createExportDataProvider]);

  const handleColumnDelete = (columns) => {
    const newHiddenColumns = { ...hiddenColumns };
    columns.forEach(({ column }) => {
      newHiddenColumns[column.data] = true;
    });
    setHiddenColumns(newHiddenColumns);
  };

  const handleColumnRestore = (data) => {
    const updatedHiddenColumns = { ...hiddenColumns };
    delete updatedHiddenColumns[data];
    setHiddenColumns(updatedHiddenColumns);
  };

  const buildContextMenu = (range, { col, row }, dataProvider, formulaEditMode) => {
    if (formulaEditMode) {
      return buildFormulaEditContextMenu();
    }
    if (row >= 0) {
      return buildCellContextMenu(range, col, dataProvider, defaultCellStyle, {
        sortByColor: !colored,
        format: !colored,
        restore: false,
        textToColumns: false,
      });
    }
    const columnMenu = buildColumnContextMenu(range, col, dataProvider, defaultCellStyle, {
      sortByColor: !colored,
      format: !colored,
      insert: false,
      delete: true,
      textToColumns: false,
    }, {
      deleteColumn: 'Hide Column',
    });
    if (Object.keys(hiddenColumns).length) {
      columnMenu.push({
        id: LOCAL_MENU_COMMANDS.RESTORE_COLUMN,
        title: 'Restore Column',
        children: Object.keys(hiddenColumns).map((data) => ({
          id: data,
          title: initialTableColumns.find((c) => c.data === data).title,
        })),
      });
    }
    return columnMenu;
  };

  const handleContextMenuCommand = (item, subItem) => {
    if (item.id === LOCAL_MENU_COMMANDS.RESTORE_COLUMN) handleColumnRestore(subItem.id);
  };


  const canMatchTo = (matchTo) => !tableColumns.some((c) => c.match === matchTo);


  const handleMergeALIs = (needConfirm) => {
    if (canMerge) {
      if (needConfirm) {
        setShowMergeConfirm(true);
      } else {
        const selectedLocations = selectedALIMatches.slice();
        selectedLocations.sort((l1, l2) => (l2.lastScrapedAt || minDate).localeCompare(l1.lastScrapedAt || minDate));
        const targetAli = selectedLocations[0].ali;
        const alis = selectedLocations.map((l) => l.ali);
        const merge = {
          alis,
          targetAli,
          matchingId: currentMatchingId,
          ids: locations.map((l) => l._id),
        };
        fnMergeMatches(alis, targetAli, selectedListId, listMatchFilter, currentMatchingId);
        setHistory([...history, { merge }]);
        setLastMergeAction(merge);
      }
    }
  };

  const getSeletedUnmergeAlis = () => {
    let ret;
    if (selectedALIMatches) {
      let currentAli;
      selectedALIMatches.some((l) => {
        if (currentAli && currentAli !== l.ali) {
          currentAli = undefined;
          return true;
        }
        currentAli = l.ali;
        return false;
      });

      if (currentAli) {
        const originalAlis = selectedALIMatches.map((l) => l.originalAli).filter((ali) => !!ali);
        if (originalAlis.length) ret = { currentAli, originalAlis };
      }
    }

    return ret;
  };

  const handleUnmergeALIs = () => {
    const unmergeAlis = getSeletedUnmergeAlis();
    if (unmergeAlis) {
      const { currentAli, originalAlis } = unmergeAlis;
      fnUnmerge(currentAli, originalAlis, selectedListId);
    }
  };

  const handleMergeConfirm = (confirmed) => {
    setShowMergeConfirm(false);
    if (confirmed) handleMergeALIs();
  };

  const handleDistanceAutoMerge = () => {
    fnAutoMerge(selectedListId, currentMatchingId, listMatchFilter);
  };

  const handleCortexAutoMerge = () => {
    if (cortexAutoMerge) {
      fnAutoMerge(selectedListId, cortexAutoMerge.matchingId, listMatchFilter);
      setShowAutoMergeConfrmed(true);
    }
  };

  const handleUndo = (change) => {
    if (change.merge) {
      const { alis, targetAli, ids } = change.merge;
      fnUnmerge(targetAli, alis, selectedListId, ids);
    }
  };

  const handleUndoLastMerge = () => {
    if (lastMergeAction) {
      const undo = { merge: lastMergeAction };
      handleUndo(undo);
      const updatedHistory = history.filter((h) => !compareObjects(h, undo, { ids: true }));
      setHistory(updatedHistory);
      setLastMergeAction();
    }
  };

  const handleUndoKeyDown = (e) => {
    KeyboardHandle(e, {
      onUndo: () => handleUndoLastMerge(),
    });
  };


  const groupSortFunction = ({ value: value1, row: row1 }, { value: value2, row: row2 }, column) => {
    const { group: group1 } = row1;
    const { group: group2 } = row2;

    let v1;
    let v2;
    if (group1 === group2) {
      v1 = value1;
      v2 = value2;
    } else {
      const r1 = mostRecentRecord[group1];
      const r2 = mostRecentRecord[group2];
      if (r1 && r2) {
        v1 = r1[column.data] || '';
        v2 = r2[column.data] || '';
        if (column.type === 'date') {
          v1 = new Date(v1);
          v2 = new Date(v2);
        }
      }
    }

    let ret = 0;
    if (v1 !== undefined && v2 !== undefined) {
      if (v1 < v2) ret = -1;
      else if (v1 > v2) ret = 1;
    }
    return ret;
  };


  return (
    <>
      {
        (cortexAutoMerge && listMatchFilter === GROUP_BY.CORTEX) && (
          <Confirm
            title="Information"
            text={`You have merged all records with a Cortex Match Score of ${cortexAutoMerge.matchingScore}. Would you like to auto-merge the remaining ${cortexAutoMerge.remaining} that have a higher Cortex Match Score?`}
            buttons={['Yes', 'No']}
            focusedButton="Yes"
            onClose={(res) => { if (res === 'Yes') handleCortexAutoMerge(); }}
          />
        )
      }
      {
        showAutoMergeConfimed && (
          <Confirm
            title="Thank you!"
            text="Please remember to check the other Match Types (ie Phone Number, Distance, etc.)"
            buttons={['OK']}
            focusedButton="OK"
            onClose={() => setShowAutoMergeConfrmed(false)}
          />
        )
      }
      {
        showMergeConfirm && (
          <Confirm
            title="Warning"
            text={`Are you sure you want to merge ${selectedALIMatches.length} records?`}
            buttons={['Yes', 'No']}
            focusedButton="Yes"
            onClose={(res) => handleMergeConfirm(res === 'Yes')}
          />
        )
      }
      {
        (warningMessage && warningMessage.show) && (
          <Confirm
            title="Warning"
            text={warningMessage.message}
            buttons={['OK']}
            focusedButton="OK"
            onClose={() => setWarningMessage({ ...warningMessage, show: false })}
          />
        )
      }
      { lastMergeAction && (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open
          autoHideDuration={5000}
          onClose={(event) => {
            if (event && event.type !== 'click') setLastMergeAction();
          }}
          message={`Merged ${lastMergeAction.alis.length} ALIs`}
          action={(
            <>
              <Button color="secondary" size="small" onClick={handleUndoLastMerge} autoFocus onKeyDown={handleUndoKeyDown}>
                UNDO
              </Button>
              <IconButton size="small" aria-label="close" color="inherit" onClick={() => setLastMergeAction()}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          )}
        />
      )}
      <Sheet
        keyDownHandlers={{
          onCtrlM: () => handleMergeALIs(true),
        }}
        defaultCellStyle={defaultCellStyle}
        rows={locationsView}
        columns={finalTableColumns}
        fixedColumnCount={1}
        headerHeight={64}
        headerRenderer={({
          column, columnIndex, selectColumn, sort, filter,
        }) => (
          <LocationColumnHeader
            sortable={column.sortable}
            title={column.title}
            match={column.match}
            hasBorder={!!column.payloadKey}
            columnIndex={columnIndex}
            columnKey={column.data}
            columnWeightPlaceholder
            columnWeight={!!column.isPayloadColumn || column.data === '_id'}
            columnMatch={false}
            selectColumn={selectColumn}
            payloadIds={column.payloadIds}
            listId={selectedListId}
            canMatchTo={(match) => canMatchTo(match)}
            standardHeaders={standardHeaders}
            fullWidth={column.data === '_id'}
            sort={!!sort}
            filter={filter}
            // eslint-disable-next-line no-nested-ternary
            columnWeightComponent={column.data === '_id'
              ? (listMatchFilter === GROUP_BY.DISTANCE
              && listMatchDistance <= AUTOMERGE_MAX_DISTANCE
                ? (
                  <Button
                    variant="contained"
                    className={classes.autoMergeButton}
                    fullWidth
                    color="secondary"
                    onClick={() => handleDistanceAutoMerge()}
                  >
                    Automerge
                  </Button>
                ) : <></>) : undefined}
            titleComponent={column.data === '_id'
              && (
                listMatchFilter === GROUP_BY.MERGED
                  ? (
                    <Button
                      variant="contained"
                      className={classes.mergeButton}
                      fullWidth
                      color="primary"
                      disabled={!getSeletedUnmergeAlis()}
                      onClick={() => handleUnmergeALIs()}
                    >
                      Unmerge
                    </Button>
                  )
                  : (
                    <Tooltip title={warningMessage && warningMessage.message ? warningMessage.message : ''}>
                      <div style={{ width: '100%' }}>
                        <Button
                          variant="contained"
                          className={classes.mergeButton}
                          fullWidth
                          color="primary"
                          disabled={!canMerge}
                          onClick={() => handleMergeALIs()}
                        >
                          Merge
                        </Button>
                      </div>
                    </Tooltip>
                  )
              )}
          />
        )}
        replaceEnabled={false}
        getCellStyle={getCellStyle}
        getCellValue={getCellValue}
        getRowKey={getRowKey}
        getColumnKey={getColumnKey}
        cellRenderer={({ column, value, keyDownEvent }) => (column.renderer ? column.renderer({ value, keyDownEvent }) : defaultCellRenderer({ value }))}
        contextMenuBuilder={(range, cell, dataProvider, formulaEditMode) => buildContextMenu(range, cell, dataProvider, formulaEditMode)}
        onColumnDelete={(columns) => handleColumnDelete(columns)}
        onColumnsUpdated={(columns) => setTableColumns(columns)}
        onContextMenuSelected={handleContextMenuCommand}
        formulaBar
        sortFunction={colored ? groupSortFunction : undefined}
        onAfterPrepareRows={handleRowColors}
        history={history}
        onHistoryChange={(h) => setHistory(h)}
        onUndo={handleUndo}
        dimensions = {dimensions}
      />
      {(selectedListId && locations && !locations.length && !aliMatchesLoading) && (
        <Alert severity="warning">No Matches</Alert>
      )}
    </>
  );
}

LocationsSheetALIMatching.defaultProps = {
  locations: [],
  tableColumns: [],
  standardHeaders: [],
  exportDataProvider: null,
};

LocationsSheetALIMatching.propTypes = {
  ...withStylesPropTypes,
  locations: PropTypes.arrayOf(PropTypes.shape),
  tableColumns: PropTypes.arrayOf(PropTypes.shape),
  standardHeaders: PropTypes.arrayOf(PropTypes.string),
  exportDataProvider: PropTypes.func,
};

const mapStateToProps = (state, props) => ({
  aliMatchesLoading: selectALIMatchesLoading(state, props),
  selectedListId: selectSelectedListId(state, props),
  selectedALIMatches: selectSelectedALIMatches(state, props),
  currentGroupIndex: selectCurrentGroupIndex(state, props),
  listMatchFilter: selectSelectedListMatchFilter(state, props),
  listMatchDistance: selectSelectedListMatchDistance(state, props),
  cortexAutoMerge: selectCortexAutoMerge(state, props),
  currentMatchingId: selectMatchingId(state, props),
});

export default connect(mapStateToProps, {
  fnResetALIMatchSelection: resetALIMatchSelection,
  fnMergeMatches: mergeMatches,
  fnUnmerge: unmerge,
  fnAutoMerge: autoMerge,
  fnGetALIMatchesStart: getALIMatchesStart,
  fnGetALIMatchesProgress: getALIMatchesProgress,
  fnGetALIMatchesSuccess: getALIMatchesSuccess,
  fnGetALIMatchesFailed: getALIMatchesFailed,
})(withStyles(useStyles)(LocationsSheetALIMatching));
