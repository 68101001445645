import React, { useEffect, useState, useContext } from 'react';
import Alert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { withStylesPropTypes, useMountEffect, compareSimpleArrays } from '../../helper/misc';
import { combinePayloadHeaders, combineCorePayloadHeaders, isPostStation } from '../../helper/payloadmisc';
import { selectListById } from '../../app/listSelectors';
import { fetchLocations, resetLocationSelection } from '../../app/locationsSlice';
import {
  selectSelectedPayloadLocations, selectLocationsLoading, selectLocationsSaving, selectLocationsError, selectLocationIdsByPayloadId, selectLocationsById,
} from '../../app/locationsSelectors';
import { setSelectedPayloadHistory, fetchStandardHeaders } from '../../app/payloadSlice';
import {
  selectSelectedPayloadsWithHistoryAndSelectedStation, selectSelectedPayloadIds, selectStandardHeadersByPayloadId, selectStandardHeadersLoading, selectStandardHeadersSaving, selectStandardHeadersError, selectPayloadsByListId, selectPayloadById, selectLastHistoryLoading, selectLastHistoryError,
} from '../../app/payloadSelectors';
import {
  fetchALIMatchesByPayloadId, fetchALIMatchesByPayloadIdDistance, fetchALIMatchesByPayloadIdField, resetALIMatchSelection, appendLocationAli, autoAppendAlis,
} from '../../app/aliMatchesSlice';
import {
  selectALIMatchesLoading, selectALIMatchesSaving, selectALIMatchesError, selectALIMatchesParams, selectShowCoreColumns,
} from '../../app/aliMatchesSelectors';
import Loading from '../common/loading';
import Error from '../common/error';
import LocationsSheetQA from './locationssheetqa';
import ALIMatchingSheet from './alimatchingsheet';
import STATIONS, { STATION_STATUS } from '../../const/stations';
import {
  DISTANCE, GROUP_BY, GROUP_BY_DEFAULT, MATCH_TYPE_DEFAULT,
} from '../../const/alimatch';
import HISTORY_ACTIONS from '../../const/history';
import {HomeScreen} from '../../pages/home';

const useStyles = ((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    '& *': {
      boxSizing: 'border-box',
    },
  },
  snapshotTitle: {
    position: 'absolute',
    zIndex: 500,
    lineHeight: `${theme.spacing(4)}px`,
    border: `solid 1px ${theme.palette.border.primary}`,
    backgroundColor: theme.palette.background.paper,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    boxShadow: `${theme.spacing(0.25)}px ${theme.spacing(0.5)}px ${theme.spacing(0.5)}px ${theme.palette.text.primary}`,
  },
  alertRoot: {
    padding: `0px ${theme.spacing(3)}px`,
    position: 'absolute',
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 1000,
  },

}));

const standardTableColumns = [
  {
    title: '',
    data: '_id',
    width: 75,
    sortable: false,
    // eslint-disable-next-line react/prop-types
    renderer: ({ rowIndex }) => <div style={{ textAlign: 'center' }}>{rowIndex}</div>,
  },
];

const standardPostALITableColumns = [
  {
    title: 'Matching Score',
    data: 'matchingScore',
    width: 120,
    sortable: true,
    searchable: true,
    type: 'number',
  },
  {
    title: 'ALI',
    data: 'ali',
    width: 100,
    sortable: true,
    searchable: true,
  },
];

const standardPostGeofenceTableColumns = [{
  title: 'Geofence',
  data: 'geofenceStatus',
  width: 100,
  sortable: true,
  searchable: true,
}];

function LocationsWidgetQA({
  classes,
  exportDataProvider,
  headers,
  locations,
  listsById,
  payloads,
  standardHeaders,
  locationsByPayload,
  droppedHeadersPayload,
  droppedHeadersPayloadLocations,
  isLocationsLoading,
  isLocationsSaving,
  locationsError,
  standardHeadersByPayloadId,
  isStandardHeadersLoading,
  isStandardHeadersSaving,
  standardHeadersError,
  isLastHistoryLoading,
  lastHistoryError,
  isALIMatchesLoading,
  isALIMatchesSaving,
  aliMatchesError,
  aliMatchesParams,
  fnResetLocationSelection,
  fnFetchLocations,
  fnSetSelectedPayloadHistory,
  fnFetchStandardHeaders,
  fnFetchALIMatchesByPayloadId,
  fnFetchALIMatchesByPayloadIdDistance,
  fnFetchALIMatchesByPayloadIdField,
  fnResetALIMatchSelection,
  fnAppendLocationAli,
  fnAutoAppendAlis,
  dimensions,
}) {
  const [tableColumns, setTableColumns] = useState(standardTableColumns);

  const [snapshotCell, setSnapshotCell] = useState(null);
  const [snapshotTitle, setSnapshotTitle] = useState(null);

  const [isPostAliStation, setIsPostAliStation] = useState(false);
  const [isPostGeofenceStation, setIsPostGeofenceStation] = useState(false);

  const [warningMessage, setWarningMessage] = useState();

  const [aliMatchingMode, setAliMatchingMode] = useState(false);

  const [currentList, setCurrentList] = useState();

  const {appMode, qaTableColumns, setQaTableColumns, setCurrentSort} = useContext(HomeScreen);   

  useMountEffect(() => {
    fnResetLocationSelection();
  });

  useEffect(()=>
  {
    if(appMode === 0)
    {
      setQaTableColumns(standardTableColumns);
    }
    else
    {
      setQaTableColumns([]);
    }
    
  },[standardTableColumns]);

  useEffect(() => {
    let cell = null;
    let title = null;
    if (payloads && payloads.length === 1) {
      const payload = payloads[0];
      cell = payload.editCell;
      if (payload.editTimestamp) title = (new Date(payload.editTimestamp)).toLocaleString();
    }
    setSnapshotCell(cell);
    setSnapshotTitle(title);

    const postStations = isPostStation(payloads, [STATIONS.ALI, STATIONS.GEOFENCE]);
    setIsPostAliStation(postStations[STATIONS.ALI]);
    setIsPostGeofenceStation(postStations[STATIONS.GEOFENCE]);

    let warning;
    if (payloads) {
      const users = payloads.filter((p) => p.lastHistoryRecord && p.lastHistoryRecord.action === HISTORY_ACTIONS.DOWNLOAD_CSV)
        .map((p) => p.lastHistoryRecord.userName);
      if (users.length) {
        warning = `This payload has been downloaded by ${users.join(', ')} for the offline Excel work and hasn't been uploaded yet back. If you edit the payload another user won't be able to upload his work back.`;
      }
    }
    setWarningMessage(warning);
  }, [payloads]);

  useEffect(() => {
    if (payloads && payloads.length === 1 && listsById) {
      const [payload] = payloads;
      if (payload) setCurrentList(listsById[payload.listId]);
    }
  }, [payloads, listsById]);

  useEffect(() => {
    setAliMatchingMode(false);
    if (payloads && payloads.length === 1) {
      const [payload] = payloads;
      if (payload.matchFilter) {
        setAliMatchingMode(true);
        const loadedMatchFilter = (aliMatchesParams || {}).matchFilter || GROUP_BY_DEFAULT;
        const matchFilterChanged = !aliMatchesParams || loadedMatchFilter !== payload.matchFilter;
        fnResetALIMatchSelection();
        switch (payload.matchFilter) {
          case GROUP_BY.CORTEX: {
            const selectedCustomFields = currentList && currentList.fieldsToMatch && Object.keys(currentList.fieldsToMatch);
            const loadedCortexMatchType = (aliMatchesParams || {}).cortexMatchType || MATCH_TYPE_DEFAULT;
            const cortexMatchTypeChanged = loadedCortexMatchType !== payload.cortexMatchType;
            const loadedCustomFields = (aliMatchesParams || {}).customFields;
            const customFieldsChanged = !compareSimpleArrays(selectedCustomFields, loadedCustomFields);
            if (matchFilterChanged || cortexMatchTypeChanged || customFieldsChanged) {
              fnFetchALIMatchesByPayloadId(payload._id, payload.cortexMatchType, selectedCustomFields);
            }
          }
            break;
          case GROUP_BY.DISTANCE: {
            const loadedDistance = (aliMatchesParams || {}).matchDistance || DISTANCE.MIN;
            const distanceChanged = loadedDistance !== payload.matchDistance;
            if (matchFilterChanged || distanceChanged) {
              fnFetchALIMatchesByPayloadIdDistance(payload._id, payload.matchDistance);
            }
          }
            break;
          default:
            if (matchFilterChanged) {
              fnFetchALIMatchesByPayloadIdField(payload._id, payload.matchFilter);
            }
            break;
        }
      }
    }
  }, [payloads, currentList, aliMatchesParams, fnFetchALIMatchesByPayloadId, fnFetchALIMatchesByPayloadIdDistance, fnResetALIMatchSelection, fnFetchALIMatchesByPayloadIdField]);

  useEffect(() => {
    if (droppedHeadersPayload && !droppedHeadersPayloadLocations) {
      fnFetchLocations(droppedHeadersPayload._id);
    }
  }, [droppedHeadersPayload, droppedHeadersPayloadLocations, fnFetchLocations]);


  useEffect(() => {
    payloads.forEach((payload) => {
      const lp = locationsByPayload[payload._id];
      if (!lp || lp.historyId !== payload.historyId || lp.dirty) {
        fnFetchLocations(payload._id, payload.historyId);
      }
    });
  }, [payloads, locationsByPayload, fnFetchLocations]);

  useEffect(() => {
    payloads.forEach((payload) => {
      if (!standardHeadersByPayloadId[payload._id]) {
        fnFetchStandardHeaders(payload._id);
      }
    });
  }, [payloads, standardHeadersByPayloadId, fnFetchStandardHeaders]);


  useEffect(() => {
    const columns = standardTableColumns.slice();
    if (isPostAliStation) columns.push(...standardPostALITableColumns);
    if (isPostGeofenceStation) columns.push(...standardPostGeofenceTableColumns);
    const standardColumnsCount = columns.length;

    let stationHeadersMap = {};
    let stationExtraHeadersMap = {};
    if (payloads) {
      if (payloads.length > 1) {
        columns.splice(2, 0, {
          title: 'Payload',
          data: 'payload.scrapedAt',
          width: 90,
          sortable: true,
          searchable: true,
          type: 'date',
        });
      }
      if (payloads.length === 1) {
        const [payload] = payloads;
        if (payload.stations && payload.selectedStation && payload.stations[payload.selectedStation.station]) {
          const currentStation = payload.stations[payload.selectedStation.station];
          if (payload.selectedStation.station === STATIONS.HEADERS) {
            [STATIONS.UNCOMMON_HEADERS, STATIONS.NEW_HEADERS, STATIONS.DROPPED_HEADERS].forEach((substation) => {
              if (!payload.selectedStation.substation || payload.selectedStation.substation === substation) {
                const currentSubstation = currentStation[substation];
                if (currentSubstation && currentSubstation.names) {
                  stationHeadersMap = currentSubstation.names.reduce((m, n) => { m[n] = substation; return m; }, stationHeadersMap);
                }
              }
            });
          }

          if (payload.selectedStation.station === STATIONS.GEOGRAPHY
            && (!payload.selectedStation.substation || payload.selectedStation.substation === STATIONS.GEOGRAPHY_PARSING)) {
            const currentSubstation = currentStation[STATIONS.GEOGRAPHY_PARSING];
            if (currentSubstation && currentSubstation.names) {
              stationExtraHeadersMap = currentSubstation.names.reduce((m, n) => {
                m[n] = {
                  substation: STATIONS.GEOGRAPHY_PARSING,
                };
                return m;
              }, stationExtraHeadersMap);
            }
          }

          if (payload.selectedStation.station === STATIONS.ALI) {
            stationExtraHeadersMap['ALI Substation'] = {
              substation: STATIONS.ALI,
              index: standardColumnsCount,
              // eslint-disable-next-line react/prop-types
              renderer: ({ row }) => {
                // eslint-disable-next-line react/prop-types
                const [aliField] = (((row.stations || {}).ali || {}).ali || []);
                return <>{ (aliField || {}).subStation || ''}</>;
              },
            };
          }
        }
      }
    }

    headers.forEach((h) => {
      columns.push({
        title: h.title,
        match: h.match,
        data: h.key,
        payloadKey: h.key,
        payloadIds: h.payloadIds,
        width: 150,
        sortable: true,
        searchable: true,
        isPayloadColumn: true,
        editable: !snapshotTitle,
        stationHeader: stationHeadersMap[h.match],
      });
    });

    const droppedHeaders = Object.keys(stationHeadersMap).filter((key) => stationHeadersMap[key] === STATIONS.DROPPED_HEADERS);
    if (droppedHeaders.length) {
      droppedHeaders.forEach((key) => {
        columns.push({
          title: key,
          data: key,
          width: 150,
          sortable: true,
          searchable: true,
          stationHeader: STATIONS.DROPPED_HEADERS,
        });
      });
    }

    const extraHeaders = Object.keys(stationExtraHeadersMap);
    if (extraHeaders.length) {
      extraHeaders.forEach((key) => {
        const { stationHeader, index, renderer } = stationExtraHeadersMap[key];
        const col = {
          title: key,
          data: key,
          width: 150,
          sortable: true,
          searchable: true,
          stationHeader,
          renderer,
        };
        if (index >= 0 && index < columns.length) columns.splice(index, 0, col);
        else columns.push(col);
      });
    }

    if (columns.length === 1) {
      columns.push({
        title: '',
        data: '_id',
        width: 400,
        sortable: false,
        renderer: () => <></>,
      });
    }
    setTableColumns(columns);
    setQaTableColumns(columns);
  }, [headers, payloads, snapshotTitle, isPostAliStation, isPostGeofenceStation]);

  const getMergeDetails = (aliMatches) => {
    let ali;
    let locationId;
    aliMatches.forEach((m) => {
      if (m.locationId) {
        if (locationId === undefined) locationId = m.locationId;
        else if (locationId !== m.locationId) locationId = null;
      }
      if (m.ali) {
        if (ali === undefined) ali = m.ali;
        else if (ali !== m.ali) ali = null;
      }
    });
    return (ali && locationId && { ali, locationId });
  };

  const handleValidateALIMatchSelection = (selection) => {
    const mergeDetails = getMergeDetails(selection);
    return !!mergeDetails;
  };

  const handleALIMerge = (selection) => {
    const details = getMergeDetails(selection);
    if (details) {
      const { ali, locationId } = details;
      const { matchFilter, matchingId } = aliMatchesParams;
      fnAppendLocationAli(ali, [locationId], matchFilter, matchingId);
    }
  };

  const handleALIAutoMerge = () => {
    const { matchFilter, matchingId } = aliMatchesParams;
    fnAutoAppendAlis(matchingId, matchFilter);
  };

  const isLoading = isLocationsLoading || isStandardHeadersLoading || isLastHistoryLoading || (aliMatchingMode && isALIMatchesLoading);
  const isSaving = (isLocationsSaving || isStandardHeadersSaving || (aliMatchingMode && isALIMatchesSaving)) && !isLoading;
  const error = locationsError || standardHeadersError || lastHistoryError || (aliMatchingMode && aliMatchesError);

  return (
    <>
      { warningMessage && (<Alert severity="warning" classes={{ root: classes.alertRoot }}>{ warningMessage }</Alert>) }
      { isLoading && (<Loading />) }
      { isSaving && (<Loading text="Saving..." />) }
      { error && (<Error message={error.message} />) }
      {
        snapshotTitle && (
          <div className={classes.snapshotTitle}>
            { snapshotTitle }
            <IconButton aria-label="close" color="secondary" onClick={() => fnSetSelectedPayloadHistory()}>
              <CancelIcon />
            </IconButton>
          </div>
        )
      }
      <div className={classes.root}>
        { aliMatchingMode && (
          <ALIMatchingSheet
            tableColumns={tableColumns}
            listId={currentList && currentList.listId}
            onValidateMatchSelection={handleValidateALIMatchSelection}
            onMerge={handleALIMerge}
            onAutoMerge={handleALIAutoMerge}
            matchFilter={aliMatchesParams && aliMatchesParams.matchFilter}
            matchDistance={aliMatchesParams && aliMatchesParams.matchDistance}
          />
        )}
        { !aliMatchingMode && (
          <LocationsSheetQA
            locations={locations}
            tableColumns={tableColumns}
            payloads={payloads}
            standardHeaders={standardHeaders}
            snapshotCell={snapshotCell}
            droppedHeaders={(droppedHeadersPayload && droppedHeadersPayloadLocations) && {
              payload: droppedHeadersPayload,
              locations: droppedHeadersPayloadLocations,
            }}
            exportDataProvider={exportDataProvider}
            dimensions={dimensions}
          />
        )}
      </div>
    </>
  );
}

LocationsWidgetQA.defaultProps = {
  locations: [],
  exportDataProvider: null,
};

LocationsWidgetQA.propTypes = {
  ...withStylesPropTypes,
  locations: PropTypes.arrayOf(PropTypes.shape),
  exportDataProvider: PropTypes.func,
};


const selectHeaders = createSelector(
  [selectSelectedPayloadsWithHistoryAndSelectedStation, selectShowCoreColumns],
  (payloads, showCoreColumns) => (payloads.length === 1 && payloads[0].matchFilter && showCoreColumns ? combineCorePayloadHeaders(payloads) : combinePayloadHeaders(payloads)),
);

const selectStandardHeaders = createSelector(
  [selectSelectedPayloadIds, selectStandardHeadersByPayloadId, selectSelectedPayloadsWithHistoryAndSelectedStation],
  (selectedPayloadIds, standardHeadersByPayloadId, payloads) => {
    const set = {};
    Object.keys(selectedPayloadIds).forEach((payloadId) => {
      const list = standardHeadersByPayloadId[payloadId];
      if (list) {
        list.forEach((h) => { set[h] = 1; });
      }
    });
    payloads.forEach((p) => {
      if (p.stations && p.stations.headers && p.stations.headers.dropped_headers && p.stations.headers.dropped_headers.names) {
        p.stations.headers.dropped_headers.names.forEach((h) => {
          set[h] = 1;
        });
      }
    });
    const ret = Object.keys(set);
    ret.sort((a, b) => a.localeCompare(b));
    return ret;
  },
);

const selectDroppedHeadersPayload = createSelector(
  [selectPayloadsByListId, selectPayloadById, selectSelectedPayloadsWithHistoryAndSelectedStation],
  (payloadsByListId, payloadsById, selectedPayloads) => {
    let ret;
    if (payloadsByListId && selectedPayloads.length === 1) {
      const [currentPayload] = selectedPayloads;
      if (currentPayload.selectedStation
        && currentPayload.selectedStation.station === STATIONS.HEADERS
        && (!currentPayload.selectedStation.substation || currentPayload.selectedStation.substation === STATIONS.DROPPED_HEADERS)
      ) {
        const byList = payloadsByListId[currentPayload.listId];
        if (byList) {
          const listPayloads = byList.allIds.map((id) => payloadsById[id]).filter((p) => !!p);
          listPayloads.sort((a, b) => {
            let comp = a.scrapedAt.localeCompare(b.scrapedAt);
            if (comp === 0 && a.updatedAt && b.updatedAt) comp = a.updatedAt.localeCompare(b.updatedAt);
            return comp;
          });
          const index = listPayloads.findIndex((p) => p._id === selectedPayloads[0]._id);
          if (index > 0) ret = listPayloads[index - 1];
        }
      }
    }
    return ret;
  },
);

const seletDroppedHeadersPayloadLocations = createSelector(
  [selectDroppedHeadersPayload, selectLocationIdsByPayloadId, selectLocationsById],
  (payload, locationsByPayloadId, locationsById) => {
    let ret;
    if (payload) {
      const lp = locationsByPayloadId[payload._id];
      if (lp && !lp.dirty && !lp.historyId && lp.allIds) {
        ret = lp.allIds.map((id) => locationsById[id]).filter((l) => !!l);
      }
    }
    return ret;
  },
);

const selectSelectedPayloadStationStatusLocations = createSelector(
  [selectSelectedPayloadLocations, selectSelectedPayloadsWithHistoryAndSelectedStation],
  (locations, payloads) => {
    let ret = locations;
    if (payloads.length === 1) {
      const [payload] = payloads;
      const { selectedStation } = payload;
      if (selectedStation) {
        const { station, substation, status } = selectedStation;
        if (status && station && substation) {
          ret = locations.filter((l) => {
            const locationStatus = (((l.stationStatus || {})[station] || {})[substation] || STATION_STATUS.CLEAN);
            return status === locationStatus;
          });
        }
      }
    }
    return ret;
  },
);

const mapStateToProps = (state, props) => ({
  droppedHeadersPayload: selectDroppedHeadersPayload(state, props),
  droppedHeadersPayloadLocations: seletDroppedHeadersPayloadLocations(state, props),
  payloads: selectSelectedPayloadsWithHistoryAndSelectedStation(state, props),
  listsById: selectListById(state, props),
  locationsByPayload: selectLocationIdsByPayloadId(state, props),
  locations: selectSelectedPayloadStationStatusLocations(state, props),
  headers: selectHeaders(state, props),
  standardHeaders: selectStandardHeaders(state, props),
  isLocationsLoading: selectLocationsLoading(state, props),
  isLocationsSaving: selectLocationsSaving(state, props),
  isALIMatchesLoading: selectALIMatchesLoading(state, props),
  isALIMatchesSaving: selectALIMatchesSaving(state, props),
  aliMatchesError: selectALIMatchesError(state, props),
  aliMatchesParams: selectALIMatchesParams(state, props),
  showCoreColumns: selectShowCoreColumns(state, props),
  locationsError: selectLocationsError(state, props),
  standardHeadersByPayloadId: selectStandardHeadersByPayloadId(state, props),
  isStandardHeadersLoading: selectStandardHeadersLoading(state, props),
  isStandardHeadersSaving: selectStandardHeadersSaving(state, props),
  standardHeadersError: selectStandardHeadersError(state, props),
  isLastHistoryLoading: selectLastHistoryLoading(state, props),
  lastHistoryError: selectLastHistoryError(state, props),
});


export default connect(mapStateToProps, {
  fnResetLocationSelection: resetLocationSelection,
  fnFetchLocations: fetchLocations,
  fnSetSelectedPayloadHistory: setSelectedPayloadHistory,
  fnFetchStandardHeaders: fetchStandardHeaders,
  fnFetchALIMatchesByPayloadId: fetchALIMatchesByPayloadId,
  fnFetchALIMatchesByPayloadIdDistance: fetchALIMatchesByPayloadIdDistance,
  fnFetchALIMatchesByPayloadIdField: fetchALIMatchesByPayloadIdField,
  fnResetALIMatchSelection: resetALIMatchSelection,
  fnAppendLocationAli: appendLocationAli,
  fnAutoAppendAlis: autoAppendAlis,
})(withStyles(useStyles)(LocationsWidgetQA));
