import { ExportToCsv } from 'export-to-csv';

export const dataProviderToCSV = (dataProvider, fileName) => {
  const options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useTextFile: false,
    useKeysAsHeaders: true,
    filename: fileName,
  };

  const toExport = dataProvider.rows.map((row, rowIdx) => {
    const result = {};
    dataProvider.columns.forEach((col, colIdx) => {
      if (!col.renderer) {
        result[col.match || col.data] = dataProvider.getEvaluatedCellValue({ row: rowIdx, col: colIdx });
      }
    });
    return result;
  });

  const csvExporter = new ExportToCsv(options);
  csvExporter.generateCsv(toExport);
};

export default { dataProviderToCSV };
