import React, { Fragment, useState, useEffect,useContext } from 'react';
import clsx from 'clsx';
import Alert from '@material-ui/lab/Alert';
import CreateIcon from '@material-ui/icons/Create';
import BuildIcon from '@material-ui/icons/Build';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import RemoveIcon from '@material-ui/icons/Remove';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Confirm from '../common/confirm';
import { withStylesPropTypes, useMountEffect } from '../../helper/misc';
import { resetPayloadViewSpecificSelection, setSelectedPayloadStation, publishQAE, approve } from '../../app/payloadSlice';
import { selectSelectedPayloads } from '../../app/payloadSelectors';
import STATIONS, {
  isProcessingStatus, isErrorStatus, STATION_STATUS, PREPROCESSED_STATIONS,
} from '../../const/stations';
import AliMatchingQA from './alimatchingqa';
import { HomeScreen } from '../../pages/home';

const useStyles = ((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    ...theme.mixins.header,
    fontSize: '1.1rem',
    flexGrow: 0,
    '&>*': {
      height: '100%',
      '&>span': {
        display: 'inline-block !important',
      },
      '&::before': {
        content: '\'\'',
        display: 'inline-block',
        height: '100%',
        verticalAlign: 'middle',
      },
    },
  },
  body: {
    flexGrow: 1,
    overflowY: 'auto',
    '& $bodyRow': {
      padding: `0px ${theme.spacing(3)}px`,
    },
  },
  colStatus: {
  },
  bodyRow: {
    display: 'flex',
    lineHeight: `${theme.spacing(4)}px`,
    '&>:first-child': {
      flexGrow: 0.4,
      flexBasis: 0,
      padding: `0px ${theme.spacing(1)}px 0px 0px`,
    },
    '&>:not(:first-child)': {
      flexGrow: 0.15,
      flexBasis: 0,
      borderLeft: `solid 1px ${theme.palette.border.primary}`,
    },
    '&>:not(:first-child)>span': {
      margin: `0px ${theme.spacing(1)}px`,
      display: 'flex',
      alignItems: 'center',
    },
    '&>$colStatus': {
      flexGrow: 0.6,
    },
    '& .MuiButton-label': {
      textTransform: 'none !important',
    },
    '& .MuiButton-root': {
      fontWeight: 'inherit',
      fontSize: 'inherit',
      padding: 'inherit',
      minWidth: 'inherit',
    },
  },
  disabledBodyRow: {
    color: theme.palette.text.disabled,
  },
  errorBodyRow: {
    color: theme.palette.error.main,
    '& .MuiButton-label': {
      color: theme.palette.error.main,
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: '0.8em',
  },
  publishRow: {
    paddingBottom: `${theme.spacing(1)}px !important`,
  },
  sectionTitle: {
    borderBottom: `solid 1px ${theme.palette.border.primary}`,
    '&>div': {
      textTransform: 'uppercase',
    },
    '&>div:first-child': {
      color: theme.palette.primary.main,
      '&>span': {
        marginLeft: `-${theme.spacing(3)}px`,
      },
    },
  },
  sectionCollapsed: {
    '& $expandIcon': {
      transform: 'rotateX(180deg)',
    },
  },
  stationComponent: {
    padding: `0px ${theme.spacing(3)}px`,
  },
  expandIcon: {
    width: theme.spacing(3),
    height: theme.spacing(4),
    alignSelf: 'start',
  },
  substationTitle: {
    marginLeft: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
  },
  collapsed: {
    display: 'none',
  },
  alertRoot: {
    padding: `0px ${theme.spacing(3)}px`,
  },
  clean: {
    color: theme.palette.success.main,
  },
  autoFix: {
    color: theme.palette.primary.main,
  },
  fail: {
    color: theme.palette.secondary.main,
  },
  manual: {
    color: theme.palette.warning.main,
  },
  stats: {
    '& .MuiButton-root': {
      fontWeight: 'inherit',
      fontSize: 'inherit',
      padding: 'inherit',
      minWidth: 'inherit',
    },
  },
}));

function Editing({
  classes,
  selectedPayloads,
  fnSetSelectedPayloadStation,
  fnResetPayloadViewSpecificSelection,
  fnPublishQAE,
  fnApprove,
}) {
  const [showProcessed, setShowProcessed] = useState(true);
  const [collapsedStations, setCollapsedStations] = useState({});
  const [canPublish, setCanPublish] = useState(false);
  const [confirmDlg, setConfirmDlg] = useState();

  const [showSkipStationConfirm, setShowSkipStationConfirm] = useState(false);
  const { handleChangeSync, editForSync } = useContext(HomeScreen);


  useMountEffect(() => {
    fnResetPayloadViewSpecificSelection();
  });

  useEffect(() => {
    if (selectedPayloads && selectedPayloads.length === 1) {
      const newCollapsedStations = {};
      const [payload] = selectedPayloads;
      setCanPublish(payload.status === STATION_STATUS.DONE);
      const { stations } = payload;
      const processedStations = Object.keys(stations || {}).reduce((m, i) => {
        m[i] = true;
        return m;
      }, {});
      PREPROCESSED_STATIONS.forEach((s) => {
        if (!processedStations[s.key]) newCollapsedStations[s.key] = true;
      });
      setCollapsedStations(newCollapsedStations);
    }
  }, [selectedPayloads]);

  const handleCollapsedStationsToggle = (station) => {
    const s = { ...collapsedStations };
    if (s[station]) delete s[station];
    else s[station] = true;
    setCollapsedStations(s);
  };

  const handlePublishClick = () => {
    if (editForSync) handleChangeSync();
    setConfirmDlg({
      title: 'Warning',
      text: 'Are you sure you want to publish this payload?',
      buttons: ['OK', 'Cancel'],
      focusedButton: 'Cancel',
      onClose: (answer) => {
        setConfirmDlg();
        if (answer === 'OK') {
          if (selectedPayloads && selectedPayloads.length === 1) {
            const [payload] = selectedPayloads;
            fnPublishQAE([payload._id]);
          }
        }
      },
    });
  };

  const handleApprove = (confirmed) => {
    setShowSkipStationConfirm(false);
    if (!confirmed) {
      return;
    }
    if (selectedPayloads && selectedPayloads.length === 1) {
      const [payload] = selectedPayloads;
      fnApprove([payload._id], payload.station, payload.subStation);
    }
  };

  const handleSkipStationWarning = () => {
    setShowSkipStationConfirm(true);
  };

  const handleStationClick = (station, substation, status) => {
    if (editForSync) handleChangeSync();
    if (selectedPayloads && selectedPayloads.length === 1) {
      const [payload] = selectedPayloads;
      fnSetSelectedPayloadStation({
        payloadId: payload._id,
        station,
        substation,
        status,
      });
    }
  };

  const getStationTotal = (stationDef, key) => {
    let ret = 0;
    if (selectedPayloads && selectedPayloads.length === 1) {
      const { stations = null } = selectedPayloads[0];
      if (stations) {
        const station = stations[stationDef.key];
        if (station) {
          if (stationDef.substations) {
            stationDef.substations.forEach((substationDef) => {
              const substation = station[substationDef.key];
              if (substation) {
                const val = substation[key];
                if (val) ret += val;
              }
            });
          }
        }
      }
    }
    return ret;
  };

  const getSubStationTotal = (stationDef, substationDef, key) => {
    let ret = 0;

    if (selectedPayloads && selectedPayloads.length === 1) {
      const { stations = null } = selectedPayloads[0];
      if (stations) {
        const station = stations[stationDef.key];
        if (station) {
          const substation = station[substationDef.key];
          if (substation) ret = substation[key] || 0;
        }
      }
    }

    return ret;
  };

  return (
    <>
      <div className={classes.root}>
        <div className={clsx(classes.header, classes.bodyRow)}>
          <div>Filtering</div>
          <div className={classes.colStatus}><span>Status</span></div>
        </div>
        { selectedPayloads.length !== 1 && <Alert severity="warning" classes={{ root: classes.alertRoot }}>Please select a single payload first</Alert> }
        { selectedPayloads.length === 1 && (
          <div className={classes.body}>
            <div className={clsx(classes.bodyRow, classes.sectionTitle, !showProcessed && classes.sectionCollapsed)}>
              <div>
                <span>
                  <IconButton size="small" className={classes.expandIcon} onClick={() => setShowProcessed(!showProcessed)}>
                    <KeyboardArrowDownIcon />
                  </IconButton>
                  Preprocessed
                </span>
              </div>
              <div>
                <span className={classes.clean}>
                  <CheckCircleIcon />
                  Clean
                </span>
              </div>
              <div>
                <span className={classes.autoFix}>
                  <CreateIcon />
                  Auto Fix
                </span>
              </div>
              <div>
                <span className={classes.fail}>
                  <CancelIcon />
                  Fail
                </span>
              </div>
              <div>
                <span className={classes.manual}>
                  <BuildIcon />
                  Manual
                </span>
              </div>
            </div>
            <div className={clsx(!showProcessed && classes.collapsed)}>
              {
                PREPROCESSED_STATIONS.map((station) => {
                  const [currentPayload] = selectedPayloads;
                  const isStationProcessed = currentPayload.stations && currentPayload.stations[station.key];
                  const {
                    station: currentStation, subStation: currentSubstation, status: currentStatus, _id: currentPayloadId,
                  } = currentPayload;
                  const isLocationsStation = (station.key !== STATIONS.HEADERS);
                  const isCurrentStation = (station.key === currentStation);
                  const errorMessage = isCurrentStation && isErrorStatus(currentStatus) && currentPayload.statusMessage;
                  const totalClean = getStationTotal(station, STATION_STATUS.CLEAN);
                  const totalAutofix = getStationTotal(station, STATION_STATUS.AUTOFIX);
                  const totalFailed = getStationTotal(station, STATION_STATUS.FAILED);
                  const totalManual = getStationTotal(station, STATION_STATUS.MANUAL);

                  return (
                    <Fragment key={`preprocessed_${station.key}`}>
                      <div className={clsx(classes.bodyRow, collapsedStations[station.key] && classes.sectionCollapsed, !isStationProcessed && classes.disabledBodyRow, errorMessage && collapsedStations[station.key] && classes.errorBodyRow)}>
                        <div>
                          <IconButton size="small" className={classes.expandIcon} onClick={() => handleCollapsedStationsToggle(station.key)}>
                            <KeyboardArrowDownIcon />
                          </IconButton>
                          <Button
                            variant="text"
                            onClick={() => handleStationClick(station.key)}
                            disabled={!isStationProcessed}
                          >
                            {station.title}
                          </Button>
                          {
                            isCurrentStation && collapsedStations[station.key] && isProcessingStatus(currentStatus) && (
                              <CircularProgress size="1em" />
                            )
                          }
                        </div>
                        {
                          station.key === STATIONS.ALI ? (
                            <>
                              <div className={classes.stats}><span>{totalClean > 0 && isLocationsStation ? (<Button onClick={() => handleStationClick(station.key, station.key, STATION_STATUS.CLEAN)} className={classes.clean}>{totalClean}</Button>) : totalClean}</span></div>
                              <div className={classes.stats}><span>{totalAutofix > 0 && isLocationsStation ? (<Button onClick={() => handleStationClick(station.key, station.key, STATION_STATUS.AUTOFIX)} className={classes.autoFix}>{totalAutofix}</Button>) : totalAutofix}</span></div>
                              <div className={classes.stats}><span>{totalFailed > 0 && isLocationsStation ? (<Button onClick={() => handleStationClick(station.key, station.key, STATION_STATUS.FAILED)} className={classes.fail}>{totalFailed}</Button>) : totalFailed}</span></div>
                              <div className={classes.stats}><span>{totalManual > 0 && isLocationsStation ? (<Button onClick={() => handleStationClick(station.key, station.key, STATION_STATUS.MANUAL)} className={classes.manual}>{totalManual}</Button>) : totalManual}</span></div>
                            </>
                          ) : (
                            <>
                              <div><span>{totalClean}</span></div>
                              <div><span>{totalAutofix}</span></div>
                              <div><span>{totalFailed}</span></div>
                              <div><span>{totalManual}</span></div>
                            </>
                          )
                        }
                      </div>
                      {
                        station.key === STATIONS.ALI && isStationProcessed && (
                          <div className={clsx(classes.stationComponent, collapsedStations[station.key] && classes.collapsed)}>
                            <AliMatchingQA
                              payloadId={currentPayloadId}
                            />
                            <div className={clsx(classes.bodyRow, classes.publishRow)}>
                              <Button variant="contained" color="primary" onClick={handleSkipStationWarning} disabled={(currentPayload.station !== STATIONS.ALI) || (currentPayload.status === STATION_STATUS.DONE) }>Skip Station</Button>
                            </div>
                          </div>
                        )
                      }
                      {
                        station.key !== STATIONS.ALI && station.substations && (
                        <div className={clsx(collapsedStations[station.key] && classes.collapsed)}>
                          {
                            station.substations.map((substation, sidx) => {
                              const isCurrentSubstation = (station.key === currentStation && (substation.key === currentSubstation || (!currentSubstation && sidx === 0)));
                              const isSubstationProcessed = isStationProcessed && currentPayload.stations[station.key][substation.key];
                              const clean = getSubStationTotal(station, substation, STATION_STATUS.CLEAN);
                              const autofix = getSubStationTotal(station, substation, STATION_STATUS.AUTOFIX);
                              const failed = getSubStationTotal(station, substation, STATION_STATUS.FAILED);
                              const manual = getSubStationTotal(station, substation, STATION_STATUS.MANUAL);
                              return (
                                <div className={clsx(classes.bodyRow, isCurrentSubstation && errorMessage && classes.errorBodyRow, !isSubstationProcessed && classes.disabledBodyRow)} key={`preprocessed_${station.key}_sub_${substation.key}`}>
                                  <div>
                                    <span className={classes.substationTitle}>
                                      <RemoveIcon />
                                      <Button
                                        variant="text"
                                        onClick={() => handleStationClick(station.key, substation.key)}
                                        disabled={!isSubstationProcessed}
                                      >
                                        {substation.title}
                                      </Button>
                                      {
                                        isCurrentSubstation && isProcessingStatus(currentStatus) && (
                                          <CircularProgress size="1em" />
                                        )
                                      }
                                      {
                                        isCurrentSubstation && errorMessage && (
                                          <span className={classes.errorMessage}>
                                            (
                                            { errorMessage }
                                            )
                                          </span>
                                        )
                                      }
                                    </span>
                                  </div>
                                  <div className={classes.stats}><span>{clean > 0 && isLocationsStation ? (<Button onClick={() => handleStationClick(station.key, substation.key, STATION_STATUS.CLEAN)} className={classes.clean}>{clean}</Button>) : clean}</span></div>
                                  <div className={classes.stats}><span>{autofix > 0 && isLocationsStation ? (<Button onClick={() => handleStationClick(station.key, substation.key, STATION_STATUS.AUTOFIX)} className={classes.autoFix}>{autofix}</Button>) : autofix}</span></div>
                                  <div className={classes.stats}><span>{failed > 0 && isLocationsStation ? (<Button onClick={() => handleStationClick(station.key, substation.key, STATION_STATUS.FAILED)} className={classes.fail}>{failed}</Button>) : failed}</span></div>
                                  <div className={classes.stats}><span>{manual > 0 && isLocationsStation ? (<Button onClick={() => handleStationClick(station.key, substation.key, STATION_STATUS.MANUAL)} className={classes.manual}>{manual}</Button>) : manual}</span></div>
                                </div>
                              );
                            })
                          }
                          {
                            station.key === STATIONS.GEOFENCE && isStationProcessed && (
                              <div className={clsx(classes.bodyRow, classes.publishRow)}>
                                <Button variant="contained" color="primary" onClick={handleSkipStationWarning} disabled={(currentPayload.station !== STATIONS.GEOFENCE) || (currentPayload.status === STATION_STATUS.DONE)}  >Skip Station</Button>
                              </div>
                            )
                          }
                        </div>
                        )
                    }
                    </Fragment>
                  );
                })
              }
            </div>
            <div className={clsx(classes.bodyRow, classes.publishRow)}>
              <Button variant="contained" color="primary" onClick={handlePublishClick} disabled={!canPublish}>Publish</Button>
            </div>
          </div>
        )}
      </div>
      {confirmDlg && <Confirm {...confirmDlg} />}
      {
        showSkipStationConfirm && (
          <Confirm
            title="Warning"
            text="Are you sure you want to Skip this station?"
            buttons={['Yes', 'No']}
            focusedButton="Yes"
            onClose={(res) => handleApprove(res === 'Yes')}
          />
        )
      }
    </>
  );
}


Editing.propTypes = {
  ...withStylesPropTypes,
};

const mapStateToProps = (state, props) => ({
  selectedPayloads: selectSelectedPayloads(state, props),
});

export default connect(mapStateToProps, {
  fnResetPayloadViewSpecificSelection: resetPayloadViewSpecificSelection,
  fnSetSelectedPayloadStation: setSelectedPayloadStation,
  fnPublishQAE: publishQAE,
  fnApprove: approve,
})(withStyles(useStyles)(Editing));
