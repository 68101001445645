import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import CloseIcon from '@material-ui/icons/Close';
import { withStylesPropTypes, hashCode } from '../../helper/misc';
import { getColor } from '../../helper/polygoncolor';
import { selectListById } from '../../app/listSelectors';
import {
  selectGeofencesProperty, selectGeofencesLocation, selectGeofencesModified, selectSelectedPolygonVertex, selectValidationResult, selectGeofencesLoading, selectGeofencesSaving,
} from '../../app/geofencesSelectors';
import {
  modifyProperty, setGeofence, setSelectedPolygonVertex, discardChanges, saveLocationGeofences,
} from '../../app/geofencesSlice';
import Industries from '../../const/industry';
import Confirm from '../common/confirm';
import StationErrorList from './stationerrorlist';
import GeofenceMessage from './geofencemessage';

const useStyles = ((theme) => ({
  root: {
  },
  sectionTitle: {
    fontSize: '1em',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    lineHeight: `${theme.spacing(2)}px`,
    position: 'relative',
    '&:before': {
      content: '\'\'',
      position: 'absolute',
      borderBottom: `solid 1px ${theme.palette.border.primary}`,
      width: '100%',
      top: theme.spacing(1),
      zIndex: 1,
    },
    '&>span': {
      zIndex: 2,
      backgroundColor: theme.palette.background.paper,
      position: 'absolute',
      top: 0,
      marginLeft: theme.spacing(2),
      padding: `0px ${theme.spacing(1)}px`,
      textTransform: 'uppercase',
    },
  },
  section: {
    padding: `${theme.spacing(3)}px`,
  },
  polygonTitleBar: {
    display: 'flex',
    alignItems: 'center',
    '&>div': {
      flexGrow: 0,
    },
    '&>$line': {
      flexGrow: 1,
      borderBottom: `solid 1px ${theme.palette.border.primary}`,
    },
  },
  line: {
  },
  actions: {
    float: 'right',
  },
  buttons: {
    textAlign: 'right',
    '& .MuiButton-root': {
      marginLeft: `${theme.spacing(1)}px`,
    },
  },
  validation: {
    paddingTop: theme.spacing(1),
  },
  polygon: {
    borderLeftWidth: theme.spacing(0.5),
    borderLeftStyle: 'solid',
    transition: 'background-color 0.2s linear',
    padding: `${theme.spacing(1)}px 0px`,
  },
  polygonName: {
    fontWeight: 'bold',
    fontSize: '1em',
    color: theme.palette.primary.main,
    lineHeight: `${theme.spacing(2)}px`,
    marginLeft: theme.spacing(1.5),
    padding: `0px ${theme.spacing(1)}px`,
  },
  polygonSection: {
    padding: `0px ${theme.spacing(2.5)}px`,
  },
  polygonToolbar: {
    '&>.MuiIconButton-root': {
      padding: `0px ${theme.spacing(0.5)}px`,
    },
  },
  headersRows: {
    fontWeight: 'bold',
  },
  value: {
    color: theme.palette.primary.main,
  },
  vertex: {
    border: `solid 1px ${theme.palette.border.primary}`,
    display: 'inline-block',
    margin: theme.spacing(0.5),
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.background.toolbar,
    '&>span': {
      fontSize: '0.7em',
      paddingLeft: `${theme.spacing(0.5)}px`,
      color: theme.palette.text.disabledContrast,
    },
  },
  selectedVertex: {
    backgroundColor: '#aaffaa',
  },
}));

function Geofences({
  classes,
  property,
  location,
  list,
  selectedPolygonVertex,
  modified,
  validationResult,
  isLoading,
  isSaving,
  fnModifyProperty,
  fnSetGeofence,
  fnSetSelectedPolygonVertex,
  fnDiscardChanges,
  fnSaveLocationGeofences,
}) {
  const [expandedCoordinates, setExpandedCoordinates] = useState({});
  const [confirm, setConfirm] = useState();
  const [validationMessage, setValidationMessage] = useState();
  const [forceSave, setForceSave] = useState(false);

  const polygonName = (geofence, index) => ((geofence.properties || {}).name) || `Polygon ${(index + 1)}`;
  const isRealEstate = () => list && list.industriesNames && list.industriesNames.includes(Industries.RETAIL_REIT);

  useEffect(() => {
    let message;
    if (modified) {
      if (property && property.requestFlag) {
        const requestNames = (property.requestNames || []).filter((n) => !!n);
        if (requestNames.length === 0) {
          message = 'At least one Custom Request Name should be entered';
        } else if (requestNames.some((n) => (/[%&]/.test(n)))) {
          message = 'Custom Request Name can\'t contain special characters like % or &';
        }
      }
    }
    setValidationMessage(message);
  }, [modified, location, property]);

  useEffect(() => {
    let force = false;
    if (validationResult) {
      let errors = false;
      let warnings = false;
      validationResult.forEach((v) => {
        switch (v.level) {
          case 'error':
            errors = true;
            break;
          case 'warning':
            warnings = true;
            break;
          default:
            break;
        }
      });
      force = !errors && warnings;
    }

    setForceSave(force);
  }, [validationResult]);

  const handlePropertyChange = (event, isSwitch = false) => {
    const { value, name, checked } = event.target;
    const changes = {
      [name]: isSwitch ? checked : value,
    };

    if (name === 'requestFlag' && checked) {
      const names = (property.requestNames || []).filter((n) => !!n);
      const namesCount = names.length;
      if (!namesCount || names[namesCount - 1]) names.push('');
      changes.requestNames = names;
    }

    fnModifyProperty(changes);
  };

  const handlePropertyRequestNameChange = (index, event) => {
    const { value } = event.target;

    let names = [...property.requestNames];
    names[index] = value;
    names = names.filter((n) => !!n);
    names.push('');

    fnModifyProperty({
      requestNames: names,
    });
  };

  const handleGeofnceChange = (index, setter) => {
    if (property.geofences && property.geofences.length > index) {
      const g = property.geofences[index];
      const changes = setter(g);
      if (changes) {
        fnSetGeofence({
          index,
          geofence: {
            ...g,
            ...changes,
          },
        });
      }
    }
  };

  const handleRenamePolygonStart = (index) => {
    handleGeofnceChange(index, (geofence) => ({
      flags: {
        ...geofence.flags,
        renaming: true,
      },
    }));
  };

  const handleRenamePolygon = (event, index) => {
    const { value } = event.target;
    handleGeofnceChange(index, (geofence) => ({
      properties: {
        ...geofence.properties,
        name: value,
      },
    }));
  };

  const handleRenamePolygonEnd = (event, index) => {
    const { type, which, target } = event;
    if (type === 'keydown') {
      if (which === 13) {
        target.blur();
      }
    } else {
      handleGeofnceChange(index, (geofence) => ({
        flags: {
          ...geofence.flags,
          renaming: undefined,
        },
      }));
    }
  };

  const handleDeletePolygon = (index) => {
    setConfirm({
      title: 'Warning',
      text: 'Are you sure you want to delete polygon?',
      buttons: ['Yes', 'No'],
      focusedButton: 'Yes',
      handler: {
        Yes: () => {
          handleGeofnceChange(index, (geofence) => ({
            flags: {
              ...geofence.flags,
              deleted: true,
            },
          }));
        },
      },
    });
  };

  const handleShowPolygon = (index) => {
    handleGeofnceChange(index, (geofence) => ({
      flags: {
        ...geofence.flags,
        hidden: undefined,
      },
    }));
  };

  const handleHidePolygon = (index) => {
    handleGeofnceChange(index, (geofence) => ({
      flags: {
        ...geofence.flags,
        hidden: true,
      },
    }));
  };

  const handlePolygonPropertyChange = (event, index, isSwitch = false) => {
    const { name, value, checked } = event.target;
    handleGeofnceChange(index, (geofence) => ({
      properties: {
        ...geofence.properties,
        [name]: isSwitch ? checked : value,
      },
    }));
  };

  const handleShowCoordinates = (index, show) => {
    const expanded = { ...expandedCoordinates };
    if (show) expanded[index] = true;
    else delete expanded[index];
    setExpandedCoordinates(expanded);
  };

  const handleVertexSelect = (polygonIndex, vertexIndex) => {
    fnSetSelectedPolygonVertex({ polygonIndex, vertexIndex });
  };

  const handleVertexDelete = (polygonIndex, vertexIndex) => {
    handleGeofnceChange(polygonIndex, (geofence) => {
      const coordinates = geofence.geometry.coordinates[0].slice();
      coordinates.splice(vertexIndex, 1);
      return {
        geometry: {
          ...geofence.geometry,
          coordinates: [coordinates],
        },
      };
    });
  };

  const handleConfirm = (result) => {
    const h = (confirm.handler || {})[result];
    setConfirm(undefined);
    if (h) h();
  };

  const handleDiscardClick = () => {
    setConfirm({
      title: 'Warning',
      text: 'Are you sure you want to discard changes?',
      buttons: ['Yes', 'No'],
      focusedButton: 'Yes',
      handler: {
        Yes: () => {
          fnDiscardChanges();
        },
      },
    });
  };

  const handleSaveClick = () => {
    fnSaveLocationGeofences(forceSave);
  };

  return (
    <div className={classes.root}>
      { confirm && (<Confirm title={confirm.title} text={confirm.text} buttons={confirm.buttons} focusedButton={confirm.focusedButton} onClose={handleConfirm} />) }
      <h3 className={classes.sectionTitle}><span>Location details:</span></h3>
      <div className={classes.section}>
        <div className={classes.actions}>
          <div className={classes.buttons}>
            <Tooltip title={validationMessage || ''}>
              <span>
                <Button color="primary" variant="contained" disabled={isLoading || isSaving || !(modified && !validationMessage)} onClick={handleSaveClick}>{ forceSave ? 'Save Anyway' : 'Save' }</Button>
              </span>
            </Tooltip>
            <Button color="secondary" variant="contained" disabled={isLoading || isSaving || !modified} onClick={handleDiscardClick}>Discard</Button>
          </div>
          { validationResult && validationResult.length && (
            <div className={classes.validation}>
              <StationErrorList issueList={validationResult} messageRenderer={GeofenceMessage} />
            </div>
          )}
        </div>
        <div>
          ALI:
          {location.ali}
        </div>
        <div>
          Latitude:
          {location.latitude}
        </div>
        <div>
          Longitude:
          {location.longitude}
        </div>
        <div>
          <FormControlLabel
            control={(
              <Switch
                name="requestFlag"
                checked={property.requestFlag || false}
                onChange={(event) => handlePropertyChange(event, true)}
                color="primary"
              />
          )}
            label="Custom Request"
          />
        </div>
        {
            (property.requestFlag) && (
              <>
                {
                (property.requestNames || []).map((rn, rnidx) => (
                  <TextField
                    required={rnidx === 0}
                  // eslint-disable-next-line react/no-array-index-key
                    key={rnidx}
                    name={`requestName${rnidx}`}
                    label={rnidx === 0 ? 'Custom Request Name' : 'Additional Request Name'}
                    placeholder="REQUEST_NAME_MM-DD-YY"
                    type="text"
                    id={`custom_request_name${rnidx}`}
                    fullWidth
                    value={rn}
                    onChange={(event) => handlePropertyRequestNameChange(rnidx, event)}
                  />
                ))
                }
              </>
            )
          }
      </div>
      {
        (property.geofences || []).map((g, index) => {
          const isPolygonSelected = selectedPolygonVertex && selectedPolygonVertex.polygonIndex === index;
          return !(g.flags || {}).deleted && (
            <div
              className={classes.polygon}
              key={(g._id || hashCode((g.geometry || {}).coordinates))}
              style={{ borderLeftColor: getColor(index), backgroundColor: getColor(index, isPolygonSelected ? 0.3 : 0.1) }}
            >
              { (g.flags || {}).renaming ? (
                <div className={classes.polygonSection}>
                  <TextField
                    required
                    name="polygon_name"
                    label="Polygon Name"
                    type="text"
                    id="polygon_name"
                    value={polygonName(g, index)}
                    autoFocus
                    fullWidth
                    onChange={(event) => handleRenamePolygon(event, index)}
                    onBlur={(event) => handleRenamePolygonEnd(event, index)}
                    onKeyDown={(event) => handleRenamePolygonEnd(event, index)}
                  />
                </div>
              )
                : (
                  <div className={classes.polygonTitleBar}>
                    <div className={classes.polygonName}>{polygonName(g, index)}</div>
                    <div className={classes.polygonToolbar}>
                      <IconButton color="primary" onClick={() => handleRenamePolygonStart(index)}><CreateIcon /></IconButton>
                      <IconButton color="secondary" onClick={() => handleDeletePolygon(index)}><DeleteIcon /></IconButton>
                      { (g.flags || {}).hidden
                        ? <IconButton onClick={() => handleShowPolygon(index)}><VisibilityIcon /></IconButton>
                        : <IconButton onClick={() => handleHidePolygon(index)}><VisibilityOffIcon /></IconButton>}
                    </div>
                    <div className={classes.line} />
                  </div>
                )}
              <div className={classes.polygonSection}>
                <Grid container spacing={1} className={classes.headersRows}>
                  <Grid item xs={8} />
                  <Grid item xs={4}>{ !g.properties.parkingLot && !isRealEstate() && (<>Suite Number</>) }</Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <FormControlLabel
                      control={(
                        <Switch
                          checked={g.properties.parkingLot || false}
                          name="parkingLot"
                          onChange={(event) => handlePolygonPropertyChange(event, index, true)}
                          color="primary"
                        />
                        )}
                      label="Parking Lot"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControlLabel
                      control={(
                        <Switch
                          checked={g.properties.nap || false}
                          name="nap"
                          onChange={(event) => handlePolygonPropertyChange(event, index, true)}
                          color="primary"
                        />
                        )}
                      label="NAP"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    {
                      !g.properties.parkingLot && !isRealEstate() && (
                        <Input
                          fullWidth
                          name="suiteNumber"
                          placeholder="Suite Number"
                          value={g.properties.suiteNumber || ''}
                          classes={{ input: classes.value }}
                          onChange={(event) => handlePolygonPropertyChange(event, index)}
                        />
                      )
                    }
                  </Grid>
                </Grid>
                { g.geometry.coordinates && g.geometry.coordinates.length && (
                  <div>
                    { expandedCoordinates[index] ? (
                      <>
                        <Button size="small" variant="text" onClick={() => handleShowCoordinates(index, false)} endIcon={<ExpandLessIcon />}>Hide Coordinates</Button>
                        <div>
                          { g.geometry.coordinates[0].filter((c, cidx) => cidx < g.geometry.coordinates[0].length - 1).map((c, cidx) => (
                          // eslint-disable-next-line react/no-array-index-key
                            <div key={cidx} className={clsx(classes.vertex, isPolygonSelected && selectedPolygonVertex.vertexIndex === cidx && classes.selectedVertex)}>
                              <span>
                                {cidx + 1}
                                :
                              </span>
                              <Button
                                variant="text"
                                size="small"
                                onClick={() => handleVertexSelect(index, cidx)}
                              >
                                {`${c[1]}, ${c[0]}`}
                              </Button>
                              { g.geometry.coordinates[0].length > 4 && (
                              <IconButton
                                color="secondary"
                                size="small"
                                onClick={() => handleVertexDelete(index, cidx)}
                              >
                                <CloseIcon />
                              </IconButton>
                              )}
                            </div>
                          )) }
                        </div>
                      </>
                    ) : (
                      <Button size="small" variant="text" onClick={() => handleShowCoordinates(index, true)} endIcon={<ExpandMoreIcon />}>Show Coordinates</Button>
                    )}
                  </div>
                ) }
              </div>
            </div>
          );
        })
      }
    </div>
  );
}

Geofences.propTypes = {
  ...withStylesPropTypes,
};

const selectLocationList = createSelector(
  [selectListById, selectGeofencesLocation],
  (listById, location) => listById && location && listById[location.listId],
);

const mapStateToProps = (state, props) => ({
  location: selectGeofencesLocation(state, props),
  property: selectGeofencesProperty(state, props),
  list: selectLocationList(state, props),
  selectedPolygonVertex: selectSelectedPolygonVertex(state, props),
  modified: selectGeofencesModified(state, props),
  validationResult: selectValidationResult(state, props),
  isLoading: selectGeofencesLoading(state, props),
  isSaving: selectGeofencesSaving(state, props),
});

export default connect(mapStateToProps, {
  fnModifyProperty: modifyProperty,
  fnSetGeofence: setGeofence,
  fnSetSelectedPolygonVertex: setSelectedPolygonVertex,
  fnDiscardChanges: discardChanges,
  fnSaveLocationGeofences: saveLocationGeofences,
})(withStyles(useStyles)(Geofences));
