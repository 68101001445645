import client from './client';
import { error } from '../helper/misc';

const fetchLocations = (payloadId, batchId) => new Promise((resolve, reject) => {
  client.get(`payloads/${payloadId}/${(batchId ? `snapshot/${batchId}` : 'locations')}`)
    .then((result) => resolve(result.data))
    .catch((err) => reject(error(err)));
});

const updateLocations = (action, locations) => new Promise((resolve, reject) => {
  client.post('/locations/update', { action, locations })
    .then((result) => resolve(result.data))
    .catch((err) => reject(error(err)));
});

const deleteLocations = (locationIds) => new Promise((resolve, reject) => {
  client.post('/locations/delete', { locations: locationIds })
    .then((result) => resolve(result.data))
    .catch((err) => reject(error(err)));
});

const approveLocations = (locations) => new Promise((resolve, reject) => {
  client.post('/locations/approve', { locations })
    .then((result) => resolve(result.data))
    .catch((err) => reject(error(err)));
});

const rejectLocationChanges = (locations, station, subStation) => new Promise((resolve, reject) => {
  client.post('/locations/reject', { locations, station, subStation })
    .then((result) => resolve(result.data))
    .catch((err) => reject(error(err)));
});

export default {
  fetchLocations, updateLocations, deleteLocations, approveLocations, rejectLocationChanges,
};
