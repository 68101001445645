import client, { rememberToken, forgetToken } from './client';
import { error } from '../helper/misc';

const login = (credentials) => new Promise((resolve, reject) => {
  client.post('auth/login', credentials).then((result) => {
    if (result.data && result.data.token) {
      rememberToken(result.data.token);
      resolve(result.data.token);
    } else {
      reject(new Error('Unexpected response from server'));
    }
  }).catch((err) => reject(error(err)));
});

const logout = () => forgetToken();

export { login, logout };
