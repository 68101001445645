import React, { useEffect, useState } from 'react';
import { Polygon, CircleMarker } from 'react-leaflet';
import PropTypes from 'prop-types';
import { commonDefaulProps, commonPropTypes } from '../common';

const LeafletPolygon = ({
  coordinates: initialCoordinates,
  strokeColor,
  strokeOpacity,
  strokeWeight,
  fillColor,
  fillOpacity,
  data,
  editable,
  selectedVertexIndex,
  onClick,
  onEdited,
}) => {
  const [coordinates, setCoordinates] = useState(initialCoordinates);

  useEffect(() => {
    setCoordinates(initialCoordinates);
  }, [initialCoordinates]);

  const handleClick = () => {
    onClick({
      data,
    });
  };

  const handleVertexClick = (vertexIndex) => {
    onClick({
      data,
      vertexIndex,
    });
  };


  const handleVertexMouseDown = (e, index) => {
    const { _map: map } = e.target;
    map.dragging.disable();
    map.on('mousemove', (event) => {
      const c = coordinates.slice();
      const { lat, lng } = event.latlng;
      c[index] = [lng, lat];
      if (index === 0) c[c.length - 1] = [lng, lat];
      else if (index === c.length - 1) c[0] = [lng, lat];
      setCoordinates(c);
    });
  };

  const handleVertexMouseUp = (e, index) => {
    const { _map: map } = e.target;
    map.dragging.enable();
    map.off('mousemove');

    onEdited({
      data,
      coordinates,
      set: { pathIdx: 0, vertexIdx: index },
    });
  };

  const handleVertexDelete = (index) => {
    if (coordinates.length > 4) {
      const c = coordinates.slice();
      c.splice(index, 1);
      onEdited({
        data,
        coordinates: c,
        remove: { pathIdx: 0, vertexIdx: index },
      });
    }
  };

  return (
    <>
      <Polygon
        stroke={strokeOpacity > 0}
        strokeOpacity={strokeOpacity}
        color={strokeColor}
        weight={strokeWeight}
        fill={fillOpacity > 0}
        fillOpacity={fillOpacity}
        fillColor={fillColor}
        positions={coordinates.map((c) => [c[1], c[0]])}
        onclick={handleClick}
      />
      {
        editable && coordinates && coordinates.map(([lng, lat], index) => {
          const selected = index === selectedVertexIndex;
          return index < coordinates.length - 1 && (
            <CircleMarker
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              center={{ lat, lng }}
              radius={selected ? 6 : 5}
              weight={selected ? 3 : 1}
              color={selected ? '#00FF00' : strokeColor}
              fillColor="#FFFFFF"
              fillOpacity={1}
              onclick={() => handleVertexClick(index)}
              onmousedown={(e) => handleVertexMouseDown(e, index)}
              onmouseup={(e) => handleVertexMouseUp(e, index)}
              ondblclick={() => handleVertexDelete(index)}
            />
          );
        })
      }
    </>
  );
};

LeafletPolygon.defaultProps = {
  ...commonDefaulProps,
};

LeafletPolygon.propTypes = {
  ...commonPropTypes,
  strokeColor: PropTypes.string.isRequired,
  strokeOpacity: PropTypes.number.isRequired,
  strokeWeight: PropTypes.number.isRequired,
  fillColor: PropTypes.string.isRequired,
  fillOpacity: PropTypes.number.isRequired,
  coordinates: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.number),
  ).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default LeafletPolygon;
