import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const withStylesPropTypes = {
  classes: PropTypes.shape().isRequired,
};

export {
  withStylesPropTypes,
};

const error = (err) => {
  if (err && err.response && err.response.data) {
    return err.response.data;
  }
  return new Error('Internal Error');
};

const getQueryString = (params) => {
  const ret = [];

  if (params) {
    Object.keys(params).forEach((p) => {
      ret.push(`${p}=${params[p]}`);
    });
  }

  return (ret.length ? `?${ret.join('&')}` : '');
};

const leadingZeros = (num, len) => {
  let ret = `${num}`;
  while (ret.length < len) {
    ret = `0${ret}`;
  }
  return ret;
};

const formatDate = (date) => {
  if (date) {
    const m = /(^\d{4})-(\d{2})-(\d{2})$/.exec(date);
    if (m) {
      return `${m[2]}/${m[3]}/${m[1]}`;
    }
    const d = new Date(date);
    const time = d.getTime();
    const off = d.getTimezoneOffset();
    if (!Number.isNaN(time) && !Number.isNaN(off)) {
      d.setTime(time + off * 60000);
      return d.toLocaleDateString();
    }
  }
  return null;
};

const formatDateToFileNameTimestamp = (date) => {
  if (date) {
    const m = /(^\d{4})-(\d{2})-(\d{2})$/.exec(date);
    if (m) {
      const yy = Number(m[1]) % 100;
      const mm = Number(m[2]);
      const dd = Number(m[3]);
      return `${leadingZeros(mm, 2)}-${leadingZeros(dd, 2)}-${leadingZeros(yy, 2)}`;
    }
    const d = new Date(date);
    const time = d.getTime();
    const off = d.getTimezoneOffset();
    if (!Number.isNaN(time) && !Number.isNaN(off)) {
      d.setTime(time + off * 60000);
      const dateTimeFormat = new Intl.DateTimeFormat('en', { year: '2-digit', month: '2-digit', day: '2-digit' });
      const [{ value: month },, { value: day },, { value: year }] = dateTimeFormat.formatToParts(date);
      return `${month}-${day}-${year}`;
    }
  }
  return null;
};

const removeQuotes = (str) => {
  let ret = (str || '').trim();
  if (ret && ret.length >= 2) {
    if (ret[0] === '"' && ret[ret.length - 1] === '"') {
      ret = ret.substr(1, ret.length - 2);
    }
  }
  return ret;
};

const resolve = (obj, path, separator = '.') => {
  const properties = Array.isArray(path) ? path : path.split(separator);
  if (properties.length === 1) return obj[properties[0]];
  return properties.reduce((prev, curr) => prev && prev[curr], obj);
};

const resolveAndSet = (obj, path, value, separator = '.') => {
  const properties = Array.isArray(path) ? path : path.split(separator);
  if (properties.length === 1) obj[properties[0]] = value;
  properties.reduce((prev, curr, idx) => {
    if (idx === properties.length - 1) prev[curr] = value;
    return prev && prev[curr];
  }, obj);
};

const deepClone = (obj) => {
  let ret;
  if (obj) {
    if (typeof obj === 'object') {
      ret = {};
      Object.keys(obj).forEach((key) => {
        const v = obj[key];
        if (typeof v === 'object') ret[key] = deepClone(v);
        else ret[key] = v;
      });
    } else {
      ret = obj;
    }
  }
  return ret;
};

const compareObjects = (o1, o2, exclude = {}) => {
  if (typeof o1 !== typeof o2) return false;
  if (typeof o1 !== 'object') return o1 === o2;

  if (Object.keys(o1).filter((p) => !exclude[p]).some((p) => !compareObjects(o1[p], o2[p]))) return false;
  if (Object.keys(o2).filter((p) => !exclude[p]).some((p) => !compareObjects(o1[p], o2[p]))) return false;
  return true;
};

const compareSimpleArrays = (a1, a2) => {
  if (!a1 && !a2) return true;
  if ((a1 && !a2) || (!a1 && a2) || (a1.length !== a2.length)) return false;
  const map = a1.reduce((m, i) => { m[i] = true; return m; }, {});
  return !a2.some((i) => !map[i]);
};

const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

const getLetterIndex = (index) => {
  let ret = '';
  let i = index;
  do {
    ret = ALPHABET.charAt(i % ALPHABET.length) + ret;
    i = Math.floor(i / ALPHABET.length) - 1;
  } while (i >= 0);
  return ret;
};

const letterToIndex = (letter) => {
  let ret = 0;
  for (let i = 0; i < letter.length; i += 1) {
    ret *= ALPHABET.length;
    const c = letter.charAt(i);
    const ind = ALPHABET.indexOf(c);
    if (ind < 0) {
      ret = 0;
      break;
    }
    ret += ind;
  }
  return ret;
};

const hashCode = (obj) => {
  let hash = 0;
  const str = JSON.stringify(obj);
  for (let i = 0; i < str.length; i += 1) {
    const char = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + char; // eslint-disable-line
    hash &= hash; // eslint-disable-line
  }
  return hash;
};


const useMountEffect = (fun) => useEffect(fun, []);

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const escapeRegex = (re) => re.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');

export {
  error,
  getQueryString,
  formatDate,
  formatDateToFileNameTimestamp,
  removeQuotes,
  resolve,
  resolveAndSet,
  deepClone,
  compareObjects,
  compareSimpleArrays,
  hashCode,
  useMountEffect,
  usePrevious,
  getLetterIndex,
  letterToIndex,
  escapeRegex,
  leadingZeros,
};
export default withStylesPropTypes;
