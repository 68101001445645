import React from 'react';
import PropTypes from 'prop-types';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { withStyles } from '@material-ui/core/styles';
import { withStylesPropTypes } from '../../helper/misc';

const useStyles = ((theme) => ({
  root: {
    color: theme.palette.primary.main,
    height: theme.spacing(4),
    lineHeight: `${theme.spacing(4)}px`,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    backgroundColor: theme.palette.background.header,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '&:focus': {
      outline: 'none',
    },
  },
}));

function HistoryColumnHeader({
  classes, title, sortColumn, columnIndex, currentSort,
}) {
  return (
    <div
      className={classes.root}
      role="button"
      tabIndex={0}
      onKeyDown={() => {}}
      onMouseDown={() => sortColumn({ col: columnIndex }, { direction: -(currentSort ? currentSort.direction : -1) })}
    >
      {title}
      { currentSort && currentSort.direction === 1 && <ArrowDownwardIcon /> }
      { currentSort && currentSort.direction === -1 && <ArrowUpwardIcon /> }
    </div>
  );
}


HistoryColumnHeader.defaultProps = {
  sortColumn: () => {},
  columnIndex: null,
  currentSort: null,
};

HistoryColumnHeader.propTypes = {
  ...withStylesPropTypes,
  title: PropTypes.string.isRequired,
  sortColumn: PropTypes.func,
  columnIndex: PropTypes.number,
  currentSort: PropTypes.oneOfType([PropTypes.shape(), PropTypes.bool]),
};


export default withStyles(useStyles)(HistoryColumnHeader);
