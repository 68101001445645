import React from 'react';
import { StyleShortcuts } from '../common/sheet/cellformatoptions';
import { MENU_COMMANDS } from '../common/sheet/sheet';
import { equalStyle } from '../common/sheet/cellsstyle';
import { isSingleCellRange, addColumnToRange, resetRange } from '../common/sheet/cellsrange';
import SUPPORTED_FORMULAS from '../../const/formulas';

export const LS_MENU_COMMANDS = {
  RESTORE: 'restore',
  DELETE_LOCATION: 'delete_location',
  PUSH_COLUMN: 'push_column',
  PUSH_CELLS: 'push_cells',
};

const getColorOption = (style, text) => (
  <div style={{
    ...style, border: `solid 1px ${style.color}`, width: '100%', minWidth: 80, padding: 4,
  }}
  >
    { text || '\u00A0' }
  </div>
);

const traceRangeStyles = (range, dataProvider, defaultCellStyle) => {
  const map = {};
  let hasNoStyle = false;
  dataProvider.enumerateRangeCells(range).forEach((cellCoord) => {
    const style = dataProvider.getCellStyle(cellCoord);
    if (Object.keys(style).length === 0 || equalStyle(style, defaultCellStyle)) {
      hasNoStyle = true;
    } else {
      const styleKey = `${style.color}/${style.bakgroundColor}`;
      map[styleKey] = style;
    }
  });
  return {
    styles: Object.values(map),
    hasNoStyle,
  };
};

const getFormatContextMenu = (defaultCellStyle) => [{
  id: MENU_COMMANDS.CELL_FORMAT,
  title: 'Format',
  children: [
    {
      id: JSON.stringify(defaultCellStyle),
      title: getColorOption(defaultCellStyle, 'Normal'),
    },
    ...StyleShortcuts.map((shortcut) => ({
      id: JSON.stringify(shortcut.style),
      title: getColorOption(shortcut.style, shortcut.title),
    })),
    {
      id: MENU_COMMANDS.CONDITIONAL_FORMAT,
      title: 'Conditional Format',
    },
  ],
}];

const getSortContextMenu = (labels) => {
  const menuLabels = {
    sortAZ: 'Sort A-Z',
    sortZA: 'Sort Z-A',
    ...labels,
  };

  return [
    { id: MENU_COMMANDS.SORT_ASC, title: menuLabels.sortAZ },
    { id: MENU_COMMANDS.SORT_DESC, title: menuLabels.sortZA },
  ];
};

const getSortByColorContextMenu = (labels, rangeFormatting, defaultCellStyle) => {
  const ret = [];

  const menuLabels = {
    noFill: 'No Fill',
    sortByColor: 'Sort By Color',
    ...labels,
  };

  if (rangeFormatting.styles.length > 1 || (rangeFormatting.hasNoStyle && rangeFormatting.styles.length)) {
    const children = [
      ...rangeFormatting.styles.map((s) => ({
        id: JSON.stringify(s),
        title: getColorOption(s),
      })),
    ];
    if (rangeFormatting.hasNoStyle) {
      children.push({
        id: JSON.stringify({}),
        title: getColorOption(defaultCellStyle, menuLabels.noFill),
      });
    }
    ret.push({
      id: MENU_COMMANDS.SORT_BY_COLOR,
      title: menuLabels.sortByColor,
      children,
    });
  }

  return ret;
};

const getFilterContextMenu = (labels, dataProvider, col, rangeFormatting, defaultCellStyle) => {
  const menuLabels = {
    resetFilter: 'Reset Filter',
    byText: 'By Text...',
    filter: 'Filter',
    noFill: 'No Fill',
    ...labels,
  };

  const ret = [];
  if (dataProvider.columnsFilter[col]) {
    ret.push({
      id: MENU_COMMANDS.FILTER_RESET,
      title: menuLabels.resetFilter,
    });
  } else {
    const filterOptions = [{
      id: MENU_COMMANDS.FILTER_BY_TEXT,
      title: menuLabels.byText,
    }];
    if (rangeFormatting.styles.length > 1 || (rangeFormatting.hasNoStyle && rangeFormatting.styles.length)) {
      rangeFormatting.styles.forEach((s) => {
        filterOptions.push({
          id: JSON.stringify(s),
          title: getColorOption(s),
        });
      });
      if (rangeFormatting.hasNoStyle) {
        filterOptions.push({
          id: JSON.stringify({}),
          title: getColorOption(defaultCellStyle, menuLabels.noFill),
        });
      }
    }
    ret.push({
      id: MENU_COMMANDS.FILTER,
      title: menuLabels.filter,
      children: filterOptions,
    });
  }

  return ret;
};

const getTextToColumnsContextMenu = (labels) => {
  const menuLabels = {
    textToColumns: 'Text To Columns...',
    ...labels,
  };
  return [
    { id: MENU_COMMANDS.TEXT_TO_COLUMNS, title: menuLabels.textToColumns },
  ];
};

export const buildColumnContextMenu = (range, col, dataProvider, defaultCellStyle, options, labels) => {
  const menuOptions = {
    sort: true,
    sortByColor: true,
    filter: true,
    format: true,
    insert: true,
    delete: true,
    textToColumns: true,
    ...options,
  };

  const menuLabels = {
    deleteColumn: 'Delete Column',
    insertColumn: 'Insert Column',
    before: 'Before',
    after: 'After',
    ...labels,
  };

  const ret = [];

  const columnDef = dataProvider.columns[col];
  const rangeFormatting = traceRangeStyles(range, dataProvider, defaultCellStyle);

  if (menuOptions.sort) {
    ret.push(...getSortContextMenu(labels));
  }

  if (menuOptions.sortByColor) {
    ret.push(...getSortByColorContextMenu(labels, rangeFormatting, defaultCellStyle));
  }

  if (menuOptions.filter) {
    ret.push(...getFilterContextMenu(labels, dataProvider, col, rangeFormatting, defaultCellStyle));
  }

  if (menuOptions.format) {
    ret.push(...getFormatContextMenu(defaultCellStyle));
  }

  if (columnDef.isPayloadColumn) {
    if (menuOptions.delete) {
      ret.push({ id: MENU_COMMANDS.DELETE_COLUMN, title: menuLabels.deleteColumn });
    }
    if (menuOptions.insert) {
      ret.push({
        id: MENU_COMMANDS.INSERT_COLUMN,
        title: menuLabels.insertColumn,
        children: [
          { id: MENU_COMMANDS.INSERT_COLUMN_BEFORE, title: menuLabels.before },
          { id: MENU_COMMANDS.INSERT_COLUMN_AFTER, title: menuLabels.after },
        ],
      });
    }
    if (menuOptions.textToColumns) {
      ret.push(...getTextToColumnsContextMenu(labels));
    }
  }

  return ret;
};

export const buildCellContextMenu = (range, col, dataProvider, defaultCellStyle, options) => {
  const menuOptions = {
    sort: true,
    sortByColor: true,
    filter: true,
    format: true,
    select: true,
    copy: true,
    paste: true,
    restore: true,
    formula: true,
    deleteLocation: false,
    textToColumns: true,
    ...options,
  };
  const ret = [];
  const columnDef = dataProvider.columns[col];

  if (menuOptions.sort) {
    ret.push(...getSortContextMenu());
  }

  const columnRange = addColumnToRange(resetRange(), { col });
  const columnFormatting = traceRangeStyles(columnRange, dataProvider, defaultCellStyle);

  if (menuOptions.sortByColor) {
    ret.push(...getSortByColorContextMenu(undefined, columnFormatting, defaultCellStyle));
  }

  if (menuOptions.filter) {
    ret.push(...getFilterContextMenu(undefined, dataProvider, col, columnFormatting, defaultCellStyle));
  }

  if (menuOptions.format) {
    ret.push(...getFormatContextMenu(defaultCellStyle));
  }

  if (menuOptions.select) {
    ret.push({
      id: MENU_COMMANDS.SELECT,
      title: 'Select',
      children: [
        { id: MENU_COMMANDS.SELECT_ALL, title: 'All' },
        { id: MENU_COMMANDS.SELECT_COLUMN, title: 'Column' },
        { id: MENU_COMMANDS.SELECT_ROW, title: 'Row' },
      ],
    });
  }

  if (menuOptions.copy) {
    ret.push({ id: MENU_COMMANDS.COPY, title: 'Copy' });
  }
  if (columnDef) {
    if (menuOptions.paste && columnDef.editable) {
      ret.push({ id: MENU_COMMANDS.CUT, title: 'Cut' });
      ret.push({ id: MENU_COMMANDS.PASTE, title: 'Paste' });
      ret.push({ id: MENU_COMMANDS.PASTE_VALUES, title: 'Paste Values' });
    }
    if (menuOptions.restore) {
      ret.push({ id: LS_MENU_COMMANDS.RESTORE, title: 'Restore' });
    }
    if (menuOptions.formula && columnDef.editable && isSingleCellRange(range)) {
      ret.push({
        id: MENU_COMMANDS.INSERT_FORMULA,
        title: 'Insert Formula',
        children: SUPPORTED_FORMULAS.map((f) => ({ id: f.template, title: f.name })),
      });
    }
    if (menuOptions.textToColumns && columnDef.editable) {
      ret.push(...getTextToColumnsContextMenu());
    }
  }

  if (menuOptions.deleteLocation) {
    ret.push({ id: LS_MENU_COMMANDS.DELETE_LOCATION, title: 'Delete Location' });
  }

  return ret;
};

export const buildFormulaEditContextMenu = () => {
  const ret = [];
  ret.push({
    id: MENU_COMMANDS.SELECT,
    title: 'Select',
    children: [
      { id: MENU_COMMANDS.SELECT_COLUMN, title: 'Column' },
      { id: MENU_COMMANDS.SELECT_ROW, title: 'Row' },
    ],
  });
  return ret;
};
