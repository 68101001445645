import React, { useState, useEffect } from 'react';
import { Marker } from 'react-leaflet';
import L, { latLng } from 'leaflet';
import PropTypes from 'prop-types';
import { commonDefaulProps, commonPropTypes } from '../common';

const LeafletMarker = ({
  position: initialPosition,
  icon,
  iconSize,
  editable,
  data,
  onEdited,
}) => {
  const [lIcon, setLIcon] = useState();
  const [position, setPosition] = useState(initialPosition);

  useEffect(() => {
    setPosition(initialPosition);
  }, [initialPosition]);

  useEffect(() => {
    const i = L.icon({
      iconUrl: icon,
      iconSize: [iconSize, iconSize],
      iconAnchor: [iconSize / 2, iconSize],
    });
    setLIcon(i);
  }, [icon, iconSize]);

  const handleDrag = (e) => {
    setPosition(e.latlng);
  };

  const handleDragEnd = () => {
    onEdited({
      data,
      position,
    });
  };

  return lIcon ? (
    <Marker
      position={latLng(position)}
      icon={lIcon}
      draggable={editable}
      ondrag={handleDrag}
      ondragend={handleDragEnd}
    />
  ) : (<></>);
};

LeafletMarker.defaultProps = {
  ...commonDefaulProps,
  icon: undefined,
};

LeafletMarker.propTypes = {
  ...commonPropTypes,
  position: PropTypes.shape().isRequired,
  icon: PropTypes.string,
  iconSize: PropTypes.number.isRequired,
};

export default LeafletMarker;
