import client from './client';
import { error } from '../helper/misc';

const lockRecord = (recordType, recordId) => new Promise((resolve, reject) => {
  const path = [recordType];
  if (recordId) path.push(recordId);
  client.get(`lockRecord/${path.join('/')}`)
    .then((result) => resolve(result.data))
    .catch((err) => reject(error(err)));
});

export default {
  lockRecord,
};
