import { createSlice } from '@reduxjs/toolkit';
import api from '../api/payload';
import normalize from '../helper/normalize';
import { batchStatusListener } from '../api/socketclient';

export const slice = createSlice({
  name: 'history',
  initialState: {
    byId: {},
    allIds: [],
    isLoading: false,
    isRestoring: false,
    error: null,
  },
  reducers: {
    getHistoryStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    getHistorySuccess: (state, { payload }) => {
      const [allIds, byId] = normalize(payload.history, '_id');
      state.byId = byId;
      state.allIds = allIds;
      state.isLoading = false;
      state.error = null;
    },
    getHistoryFailed: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload.error;
    },
    restorePayloadHistoryStart: (state) => {
      state.isRestoring = true;
      state.error = null;
    },
    restorePayloadHistorySuccess: (state) => {
      state.isRestoring = false;
      state.error = null;
    },
    restorePayloadHistoryFailed: (state, { payload }) => {
      state.isRestoring = false;
      state.error = payload.error;
    },

  },
});

export const {
  getHistoryStart, getHistorySuccess, getHistoryFailed, restorePayloadHistoryStart, restorePayloadHistorySuccess, restorePayloadHistoryFailed,
} = slice.actions;

export default slice.reducer;

export const fetchHistoryByPayloadIds = (payloadIds) => (dispatch) => {
  dispatch(getHistoryStart());
  Promise.all(payloadIds.map((payloadId) => api.fetchPayloadHistory(payloadId))).then((results) => {
    dispatch(getHistorySuccess({ history: results.flat(1) }));
  }).catch((error) => {
    dispatch(getHistoryFailed({ error }));
  });
};

export const restorePayloadHistory = (payloadId, batchId) => (dispatch) => {
  dispatch(restorePayloadHistoryStart());
  batchStatusListener(
    'restoreUpdated',
    { batchId },
    () => dispatch(restorePayloadHistorySuccess()),
    (error) => dispatch(restorePayloadHistoryFailed(error)),
  );
  api.restorePayloadHistory(payloadId, batchId)
    .catch((error) => {
      dispatch(restorePayloadHistoryFailed({ error }));
    });
};
