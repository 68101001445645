import { latLngBounds, latLng } from 'leaflet';

window.google = window.google || {};
const { google } = window;

export const googlePathToCoordinates = (path) => {
  const ret = [];
  path.forEach((p) => ret.push([p.lng(), p.lat()]));
  if (ret.length) ret.push(ret[0]);
  return ret;
};

export const coordinatesToGooglePath = (coord) => {
  const list = (coord || []);
  return list.filter((p, idx) => idx < list.length - 1).map((p) => new google.maps.LatLng(p[1], p[0]));
};

export const latLngBoundsFromPoints = (points) => (points && points.length ? latLngBounds(points.map((p) => latLng(p))) : undefined);

export const leafletToGoogleBounds = (bounds) => {
  const w = bounds.getWest();
  const e = bounds.getEast();
  const s = bounds.getSouth();
  const n = bounds.getNorth();
  return new google.maps.LatLngBounds({ lat: s, lng: w }, { lat: n, lng: e });
};

export const createMarkerImage = ({
  path, fillColor, strokeColor, strokeWeight, fillOpacity,
}, size) => {
  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  svg.setAttribute('width', size);
  svg.setAttribute('height', size);
  svg.setAttribute('viewBox', '-22 -44 44 44');

  const newpath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  newpath.setAttributeNS(null, 'd', path);
  if (strokeColor) newpath.setAttributeNS(null, 'stroke', strokeColor);
  if (strokeWeight) newpath.setAttributeNS(null, 'stroke-width', strokeWeight);
  if (fillColor) newpath.setAttributeNS(null, 'fill', fillColor);
  if (fillOpacity) newpath.setAttributeNS(null, 'opacity', fillOpacity);
  svg.appendChild(newpath);

  const svgString = new XMLSerializer().serializeToString(svg);
  const decoded = unescape(encodeURIComponent(svgString));
  const base64 = btoa(decoded);
  return `data:image/svg+xml;base64,${base64}`;
};

export default {
  latLngBoundsFromPoints,
  leafletToGoogleBounds,
  createMarkerImage,
};
