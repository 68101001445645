import JWTDecode from 'jwt-decode';
import { getToken } from '../api/client';

class User {
  constructor() {
    this.token = null;
    this.details = null;
  }

  getDetails() {
    const token = getToken();
    if (!this.token || !this.details || this.token !== token) {
      this.token = token;
      this.details = (token ? JWTDecode(token) : null);
    }
    return this.details;
  }

  getId() {
    const details = this.getDetails();
    return details ? details.sub : null;
  }

  isAdmin() {
    const details = this.getDetails();
    return details ? details.role === 'admin' : false;
  }
}

export default new User();
