export const selectGeofencesLoading = (state) => state.geofences.isLoading;

export const selectGeofencesError = (state) => state.geofences.error;

export const selectGeofencesSaving = (state) => state.geofences.isSaving;

export const selectGeofencesLocation = (state) => state.geofences.location;

export const selectGeofencesProperty = (state) => state.geofences.property;

export const selectGeofencesModified = (state) => state.geofences.modified;

export const selectSelectedPolygonVertex = (state) => state.geofences.selectedPolygonVertex;

export const selectValidationResult = (state) => state.geofences.validationResult;
