import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Map, TileLayer, FeatureGroup } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import { latLng } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { withStylesPropTypes } from '../../../../helper/misc';
import 'leaflet-draw/dist/leaflet.draw.css';

const useStyles = (() => ({
  root: {
    width: '100%',
    height: '100%',
  },
}));

const MapView = ({
  classes,
  center,
  zoom,
  bounds,
  children,
  onNewPolygon,
}) => {
  const handlePolygonCreated = (e) => {
    const { _latlngs: points } = e.layer;
    if (points && points.length) {
      const coordinates = points[0].map(({ lat, lng }) => [lng, lat]);
      coordinates.push(coordinates[0]);
      onNewPolygon(coordinates);
    }
    e.layer.remove();
  };

  return (
    <Map center={latLng(center)} zoom={zoom} className={classes.root} bounds={bounds}>
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <FeatureGroup>
        <EditControl
          position="topleft"
          onCreated={handlePolygonCreated}
          draw={{
            rectangle: false,
            marker: false,
            circle: false,
            circlemarker: false,
            polyline: false,
            edit: false,
          }}
          edit={{
            remove: false,
            edit: false,
          }}
        />
      </FeatureGroup>
      { children }
    </Map>
  );
};

MapView.defaultProps = {
  center: undefined,
  zoom: undefined,
  children: undefined,
  bounds: undefined,
};

MapView.propTypes = {
  ...withStylesPropTypes,
  center: PropTypes.shape(),
  zoom: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  bounds: PropTypes.shape(),
  onNewPolygon: PropTypes.func.isRequired,
};

export default withStyles(useStyles)(MapView);
