import React, {
  useEffect, useState, useReducer, useCallback, useRef, useContext, useImperativeHandle, forwardRef
} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { AutoSizer, MultiGrid } from 'react-virtualized';
import { withStylesPropTypes, getLetterIndex, escapeRegex } from '../../../helper/misc';
import KeyboardHandle from '../../../helper/keyboard';
import Clipboard from '../../../helper/clipboard';
import SheetColumn from './sheetcolumn';
import SheetCell from './sheetcell';
import ContextMenu from './contextmenu';
import ConditionalFormatOptionsDlg from './conditionalformatoptionsdlg';
import FilterByTextDlg from './filterbytextdlg';
import ColumnNametDlg from './columnnamedlg';
import TextToColumnsDlg from './texttocolumnsdlg';
import SearchPane, { MODE as SEARCH_MODE } from './searchpane';
import FormulaBar from './formulabar';
import { defaultCellEditRenderer, defaultCellRenderer, defaultHeaderRenderer } from './renderers';
import {
  resetRange, mergeRanges, addCellToRange, rangeReducer, RANGE_ACTIONS, isCellInRange, isColumnInRange, addColumnToRange, isSingleCellRange, addCellsToRange, getCellInRangePosition, getSimpleRangeBox, subtractRanges, simpleRangeToTextRef, addRowToRange, getColumnsMinMax,
} from './cellsrange';
import { getEffectiveCellStyle, equalStyle } from './cellsstyle';
import {
  getCells2DArray, fillCellsWith2DArray, getCellUpdates, equalCells,
} from './cellsmisc';
import {
  getFormulaRanges, isFormula, convertFormulaRangesToRelative, restoreFormulaRangesFromRelative, updateRelativeColumnReference,
} from './formulamisc';
import DataProvider from './dataprovider';
import SheetScrollWrapper from './sheetscrollwrapper';
import {HomeScreen} from '../../../pages/home';


export const SELECT_MODE = {
  CELL: 'cell',
  ROW: 'row',
};

export const MENU_COMMANDS = {
  SORT_ASC: '_sort_asc',
  SORT_DESC: '_sort_desc',
  SORT_BY_COLOR: '_sort_by_color',
  FILTER: '_filter',
  FILTER_BY_TEXT: '_filter_by_text',
  FILTER_RESET: '_filter_reset',
  CELL_FORMAT: '_cell_format',
  CONDITIONAL_FORMAT: '_conditional_format',
  SELECT: '_select',
  SELECT_ALL: '_select_all',
  SELECT_ROW: '_select_row',
  SELECT_COLUMN: '_select_column',
  COPY: '_copy',
  CUT: '_cut',
  PASTE: '_paste',
  PASTE_VALUES: '_paste_values',
  INSERT_COLUMN: '_insert_column',
  INSERT_COLUMN_BEFORE: '_insert_column_before',
  INSERT_COLUMN_AFTER: '_insert_column_after',
  DELETE_COLUMN: '_delete_column',
  INSERT_FORMULA: '_insert_formula',
  TEXT_TO_COLUMNS: '_text_to_columns',
};

export const ACTIONS = {
  UNDO: 'Undo',
  DELETE: 'Delete',
  EDIT: 'Edit',
  PASTE: 'Paste',
  TEXT_TO_COLUMNS: 'Text to Columns',
  REPLACE: 'Replace',
};

const useStyles = ((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    userSelect: 'none',
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
    msUserSelect: 'none',
    display: 'flex',
    flexDirection: 'column',
  },
  formulaBar: {
    height: theme.spacing(4),
    lineHeight: `${theme.spacing(4)}px`,
    borderBottom: `solid 1px ${theme.palette.border.primary}`,
    display: 'flex',
    flexGrow: 0,
    flexDirection: 'row',
  },
  sheet: {
    flexGrow: 1,
  },
  headerCoordinate: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fafafae5',
    fontSize: '1.2em',
  },
}));

const Sheet = forwardRef(({
  selectedCellKey,
  formulaBar,
  classes,
  fixedColumnCount,
  rows,
  columns: initialColumns,
  history: initialHistory,
  headerHeight,
  rowHeight,
  defaultColumnWidth,
  defaultCellStyle,
  getCellStyle,
  getCellValue,
  getRowKey,
  getColumnKey,
  headerRenderer,
  cellRenderer,
  cellEditRenderer,
  onRowsUpdated,
  contextMenuBuilder,
  onContextMenuSelected,
  onColumnDelete,
  onColumnInsert,
  onSelected,
  onBeforePrepareRows,
  onAfterPrepareRows,
  onColumnsUpdated,
  onHistoryChange,
  onUndo,
  lastRangeBorderStyle,
  spreadRangeBorderStyle,
  columnDragOverStyle,
  editRangeBorderStyles,
  selectMode,
  searchEnabled,
  replaceEnabled,
  cellEditValue,
  keyDownHandlers,
  exportDataProvider,
  sortFunction,
  dimensions,
  handleChangeSync,
}, ref) => {
  let gridRef;

  const sheetRef = useRef();

  const [dragOverColumn, setDragOverColumn] = useState();
  const [columnMove, setColumnMove] = useState();
  const [cellKeyDownEvent, setCellKeyDownEvent] = useState();

  const [columnsWidth, setColumnsWidth] = useState(initialColumns.map((c) => c.width));
  const [cellsFormat, setCellsFormat] = useState({});
  const [columnsFilter, setColumnsFilter] = useState({});
  const [cellsWithFormula, setCellsWithFormula] = useState({});

  const [history, setHistory] = useState(initialHistory || []);

  const [columns, setColumns] = useState(initialColumns);
  const [currentRows, setCurrentRows] = useState(rows);
  const [currentRow, setCurrentRow] = useState(null);
  const [currentCell, setCurrentCell] = useState(null);
  const [editingCell, setEditingCell] = useState(null);
  const [focusedCell, setFocusedCell] = useState(null);
  const [startFormulaRangeCell, setStartFormulaRangeCell] = useState(null);
  const [isMouseRangeSelect, setMouseRangeSelect] = useState(false);
  const [isSpreadMode, setSpreadMode] = useState(false);
  const [isFormulaRangeMode, setFormulaRangeMode] = useState(false);
  const [contextMenu, setContextMenu] = useState({ anchor: null, items: [], cell: null });
  const focusedCellRef = useRef(focusedCell);

  const [conditionalFormatOptions, setConditionalFormatOptions] = useState({ visible: false });
  const [filterByTextOptions, setFilterByTextOptions] = useState({ visible: false });
  const [columnNameOptions, setColumnNameOptions] = useState({ visible: false });
  const [textToColumnsOptions, setTextToColumnsOptions] = useState({ visible: false });
  const [searchMode, setSearchMode] = useState(null);

  const [spreadRange, setSpreadRange] = useState(null);
  const [formulaRanges, setFormulaRanges] = useState([]);
  const [lastFormulaRange, setLastFormulaRange] = useState(null);
  const [totalRange, dispatchTotalRange] = useReducer(rangeReducer, resetRange());
  const [lastRange, dispatchLastRange] = useReducer(rangeReducer, resetRange());
  const [updatedIds, setUpdatedIds] = useState([]);

  const [scrollToCell, setScrollToCell] = useState(null);

  const [scrollWrapper, setScrollWrapper] = useState();
 
  const [expand,setExpand] = useState(false);
  const [find, setFind] = useState(false);

  const {appMode, customDedupe,selectedLocation,setSelectedLocation,locationsView, editingCellContent, setEditingCellContent,editForSync, setEditForSync, syncEditValues, 
    setSyncEditValues, isSubmit, handleCheckboxLocationSelect,groupArray,currentSort, setCurrentSort} = useContext(HomeScreen);

    useImperativeHandle(ref, () => ({
      currentRows,
    }), [currentRows]);
    
  useEffect(() => setScrollToCell(currentCell), [currentCell]);
  useEffect(() => setScrollToCell(focusedCell), [focusedCell]);
  useEffect(() => setScrollToCell({ col: null, row: currentRow }), [currentRow]);
  useEffect(() => { focusedCellRef.current = focusedCell; }, [focusedCell]);

  useEffect(() => {
    if (!editingCell) {
      setFormulaRangeMode(false);
      setLastFormulaRange(null);
      setFormulaRanges([]);
    }
    else if(appMode === 0 && locationsView && locationsView[editingCell.row])
    {
      if (!(editingCellContent.includes(editingCell.row)))
        setEditingCellContent([...editingCellContent,editingCell.row]);
    }
    if(editingCell)
    {
      setEditForSync(true);
    }
    if (editingCell && locationsView.length > 0){
      let value = locationsView[editingCell.row]._id
      if (currentSort) value = currentRows[editingCell.row]._id
      if (selectedLocation.includes(value)) handleCheckboxLocationSelect(value)
      }
  }, [editingCell]);

  useEffect(() => {
    if (isSubmit && locationsView.length > 0){
      let tempSelectedLocation = [...selectedLocation]
      setSelectedLocation([...tempSelectedLocation.filter((id)=>!updatedIds.includes(id))])
      }
  }, [updatedIds]);

  useEffect(() => {
    if (gridRef) {
      gridRef.forceUpdateGrids();
      gridRef.recomputeGridSize();
    }
  }, [gridRef, currentRows, columns, columnsWidth]);

  useEffect(() => {
    if (selectedCellKey) {
      const row = currentRows.findIndex((r) => getRowKey(r) === selectedCellKey.rowKey);
      const col = columns.findIndex((c) => getColumnKey(c) === selectedCellKey.columnKey);
      if (row >= 0 && col >= 0) setCurrentCell({ row, col });
    }
  }, [selectedCellKey, getRowKey, getColumnKey, currentRows, columns]);

  useEffect(() => {
    if(!customDedupe) setColumnsWidth(initialColumns.map((c) => c.width));
    setColumns(initialColumns);
  }, [initialColumns]);

  useEffect(() => {
    setHistory(initialHistory || []);
  }, [initialHistory]);

  const selectCell = ({ row, col }) => {
    if (selectMode === SELECT_MODE.CELL) {
      if (col < columns.length && row < currentRows.length && col >= 0 && row >= 0) {
        setCurrentCell({ row, col });
        setFocusedCell({ row, col });
        setEditingCell(null);
        onSelected({ row, col }, { row: currentRows[row], column: columns[col] });
      }
    }
  };

  const selectRow = (row) => {
    if (selectMode === SELECT_MODE.ROW && row < currentRows.length && row >= 0) {
      setCurrentRow(row);
      onSelected({ row }, { row: currentRows[row] });
    }
  };

  const getFinalRange = () => mergeRanges(lastRange, totalRange);

  const isColumnInFinalRange = (col) => isColumnInRange({ range: lastRange, col }) || isColumnInRange({ range: totalRange, col });

  const isCellInFinalRange = ({ row, col }) => isCellInRange({ range: lastRange, row, col }) || isCellInRange({ range: totalRange, row, col });

  const buildRawCellKey = (rowKey, columnKey) => `[${rowKey}][${columnKey}]`;

  const getRawCellKey = useCallback(({ row, column }) => buildRawCellKey(getRowKey(row), getColumnKey(column)), [getRowKey, getColumnKey]);

  const getContextRange = (cell) => (isCellInFinalRange(cell) ? getFinalRange() : addCellToRange(resetRange(), cell));

  const createDataProvider = useCallback((rowsSubset) => new DataProvider(rowsSubset, columns, cellsFormat, columnsFilter, getCellValue, getRawCellKey), [columns, cellsFormat, columnsFilter, getCellValue, getRawCellKey]);

  const dataProvider = createDataProvider(currentRows);

  const prepareCurrentRows = useCallback(() => {
    let ret = rows.slice();
    let dp = createDataProvider(onBeforePrepareRows(rows));
    Object.keys(columnsFilter).forEach((columnIndex) => {
      const columnFilter = columnsFilter[columnIndex];
      ret = ret.filter((row, rowIndex) => {
        const cellCoordinate = { row: rowIndex, col: Number(columnIndex) };
        if (columnFilter.style) {
          const isEmpty = Object.keys(columnFilter.style).length === 0;
          const cellStyle = dp.getCellStyle(cellCoordinate);
          if (isEmpty) return Object.keys(cellStyle).length === 0;
          return equalStyle(cellStyle, columnFilter.style);
        }
        if (columnFilter.text) {
          const cellValue = dp.getEvaluatedCellValue(cellCoordinate);
          return (cellValue.toLowerCase().indexOf(columnFilter.text.toLowerCase()) >= 0);
        }
        return true;
      });
    });
    if (currentSort) {
      dp = createDataProvider(ret);
      const indexes = ret.map((v, i) => i);
      const column = columns[currentSort.columnIndex];
      if (currentSort.direction !== undefined && customDedupe && isSubmit) {
            const groupNumber = [...new Set(groupArray)]
            var tmp = []
            for (let index=0; index<groupNumber.length; index++){
              let filterRet = ret.filter((data) => data.group === groupNumber[index])
              filterRet.sort((a, b) => {
                let v1 = a[column.title],
                    v2 = b[column.title]

                if (column.type === 'date') {
                  v1 = new Date(v1);
                  v2 = new Date(v2);
                }
                let comp = 0
                if (v1 < v2) {
                  comp = -1 * currentSort.direction
                }
                if (v1 > v2) {
                  comp = 1 * currentSort.direction;;
                }
                return comp;
            });
            tmp = [...tmp, ...filterRet]
            }
        }
      else if (currentSort.direction !== undefined) {
        indexes.sort((aIdx, bIdx) => {
          let v1 = dp.getEvaluatedCellValue({ row: aIdx, col: currentSort.columnIndex });
          let v2 = dp.getEvaluatedCellValue({ row: bIdx, col: currentSort.columnIndex });
          if (column.type === 'date') {
            v1 = new Date(v1);
            v2 = new Date(v2);
          }
          let comp = 0;
          if (sortFunction) {
            comp = currentSort.direction * sortFunction({ value: v1, row: ret[aIdx] }, { value: v2, row: ret[bIdx] }, column);
          } else if (v1 < v2) comp = -1 * currentSort.direction;
          else if (v1 > v2) comp = 1 * currentSort.direction;
          return comp;
        });
      } else if (currentSort.style !== undefined) {
        const isEmpty = Object.keys(currentSort.style).length === 0;
        indexes.sort((aIdx, bIdx) => {
          const s1 = dp.getCellStyle({ row: aIdx, col: currentSort.columnIndex });
          const s2 = dp.getCellStyle({ row: bIdx, col: currentSort.columnIndex });
          if (isEmpty) {
            if (Object.keys(s1).length === 0 && Object.keys(s2).length > 0) return -1;
            if (Object.keys(s1).length > 0 && Object.keys(s2).length === 0) return 1;
          } else {
            if (equalStyle(s1, currentSort.style) && !equalStyle(s2, currentSort.style)) return -1;
            if (!equalStyle(s1, currentSort.style) && equalStyle(s2, currentSort.style)) return 1;
          }
          return 0;
        });
      }
      ret = indexes.map((i) => ret[i]);
      if (currentSort.direction !== undefined && customDedupe && isSubmit) {
        ret = tmp
      }
    }
    return onAfterPrepareRows(ret);
  },
  // // eslint-disable-next-line
  [rows, columns, currentSort, columnsFilter, createDataProvider, onBeforePrepareRows, onAfterPrepareRows, sortFunction]);

  useEffect(() => {
    setCurrentRows(prepareCurrentRows());
  }, [prepareCurrentRows]);

  useEffect(() => {
    if (exportDataProvider) {
      exportDataProvider({
        getDataProvider: () => new Promise((resolve) => { resolve(dataProvider); }),
        hasData: () => dataProvider && dataProvider.rows && dataProvider.rows.length,
      });
    }
  }, [exportDataProvider, dataProvider]);


  const getCellFormulaValue = (cell) => {
    let value = dataProvider.getCellValue(cell);
    let formula = null;
    if (isFormula(value)) {
      formula = value;
      value = dataProvider.getEvaluatedCellValue(cell);
    }
    return { formula, value };
  };

  const restoreCellFormulaValue = (cell, valueOnly) => (valueOnly ? cell.value : (cell.formula || cell.value));

  const getCurrentCellValue = useCallback(() => currentCell && restoreFormulaRangesFromRelative(currentCell, dataProvider.getCellValue(currentCell)), [currentCell, dataProvider]);

  const isCurrentCellEditable = useCallback(() => currentCell && dataProvider.columns && dataProvider.columns[currentCell.col] && dataProvider.columns[currentCell.col].editable, [currentCell, dataProvider]);

  const handleHistoryChange = (newHistory) => {
    onHistoryChange(newHistory);
    setHistory(newHistory);
  };

  const addRowsUpdateHistory = (updates) => {
    const rowsUpdate = updates.map(({ row, changes }) => {
      const curValues = {};
      Object.keys(changes).forEach((columnKey) => {
        curValues[columnKey] = getCellValue({ row, column: { data: columnKey } });
      });
      return { row, changes: curValues };
    });
    handleHistoryChange([...history, { rowsUpdate }]);
  };

  const addCellsFormatHistory = () => {
    handleHistoryChange([...history, { cellsFormat: { ...cellsFormat } }]);
  };

  const addColumnsFilterHistory = () => {
    handleHistoryChange([...history, { columnsFilter: { ...columnsFilter } }]);
  };

  const addColumnsWidthHistory = () => {
    handleHistoryChange([...history, { columnsWidth: columnsWidth.slice() }]);
  };

  const addCurrentSortHistory = () => {
    handleHistoryChange([...history, { currentSort: { ...currentSort } }]);
  };

  const handleRowsUpdate = (action, updates, cantUndo) => {
    const rowMap = [];
    currentRows.forEach((row, idx) => { rowMap[getRowKey(row)] = idx; });

    const colMap = [];
    columns.forEach((column, idx) => { colMap[getColumnKey(column)] = idx; });

    const updatedRows = currentRows.slice();
    let tempUpdatedIds = [];
    updates.forEach((update) => {
      const rowKey = getRowKey(update.row);
      const rowIdx = rowMap[rowKey];
      const row = { ...updatedRows[rowIdx] };
      tempUpdatedIds.push(rowKey);
      Object.keys(update.changes).forEach((columnKey) => {
        row[columnKey] = update.changes[columnKey];
      });
      updatedRows[rowIdx] = row;
    });
    setUpdatedIds([...updatedIds,...tempUpdatedIds])
    setCurrentRows([...updatedRows]);

    const dp = createDataProvider(updatedRows);

    const updatedCellsWithFormula = { ...cellsWithFormula };
    const seenCells = {};
    const updateMap = {};
    updates.forEach((update) => {
      const rowKey = getRowKey(update.row);
      updateMap[rowKey] = update;
      Object.keys(update.changes).forEach((columnKey) => {
        const cellKey = buildRawCellKey(rowKey, columnKey);
        seenCells[cellKey] = true;
        const value = update.changes[columnKey];
        if (isFormula(value)) {
          const row = rowMap[rowKey];
          const col = colMap[columnKey];
          const evaluatedValue = dp.evaluateCellValue(value, { row, col });
          const cellData = { value: evaluatedValue, formula: value };
          update.changes[columnKey] = cellData;
          updatedCellsWithFormula[cellKey] = { ...cellData, rowKey, columnKey };
        } else {
          delete updatedCellsWithFormula[cellKey];
        }
      });
    });

    Object.keys(updatedCellsWithFormula).filter((cellKey) => !seenCells[cellKey]).forEach((cellKey) => {
      const cellFormula = updatedCellsWithFormula[cellKey];
      const row = rowMap[cellFormula.rowKey];
      const col = colMap[cellFormula.columnKey];
      const evaluatedValue = dp.evaluateCellValue(cellFormula.formula, { row, col });
      if (cellFormula.value !== evaluatedValue) {
        cellFormula.value = evaluatedValue;
        let update = updateMap[cellFormula.rowKey];
        if (!update) {
          update = { row: currentRows[row], changes: {} };
          updateMap[cellFormula.rowKey] = update;
          updates.push(update);
        }
        update.changes[cellFormula.columnKey] = { value: evaluatedValue, formula: cellFormula.formula };
      }
    });
    setCellsWithFormula(updatedCellsWithFormula);

    if (!cantUndo) addRowsUpdateHistory(updates);
    onRowsUpdated(action, updates);
  };

  const deleteCells = (cells) => {
    const updates = getCellUpdates(cells, '', ({ row }) => currentRows[row], ({ col }) => columns[col], getColumnKey);
    handleRowsUpdate(ACTIONS.DELETE, updates);
  };

  const handleUndo = () => {
    const newHistory = history.slice();
    const change = newHistory.pop();
    if (change) {
      handleHistoryChange(newHistory);
      if (change.rowsUpdate) {
        handleRowsUpdate(ACTIONS.UNDO, change.rowsUpdate, true);
      } else if (change.cellsFormat) {
        setCellsFormat(change.cellsFormat);
      } else if (change.columnsFilter) {
        setColumnsFilter(change.columnsFilter);
      } else if (change.columnsWidth) {
        setColumnsWidth(change.columnsWidth);
      } else if (change.currentSort) {
        setCurrentSort(change.currentSort);
      } else {
        onUndo(change);
      }
    }
  };

  const handleCopy = (range) => {
    const cells = dataProvider.enumerateRangeCells(range);
    const table = getCells2DArray(cells, (cell) => getCellFormulaValue(cell));
    if (table) Clipboard.copyCells(table);
    return cells;
  };

  const handleCut = (range) => {
    const cells = handleCopy(range);
    deleteCells(cells);
  };

  const handleDelete = (range) => {
    deleteCells(dataProvider.enumerateRangeCells(range));
  };


  const handlePaste = (e, range, valuesOnly) => {
    const pasteValuesOnly = valuesOnly || (e && e.shiftKey);
    Clipboard.pasteCells(e).then((matrix) => {
      let pasteRange = range || lastRange;
      const mCols = Math.max(...matrix.map((row) => row.length));
      const mRows = matrix.length;
      const singleCellRange = isSingleCellRange(pasteRange);
      if (singleCellRange) {
        const startCell = { row: Number(singleCellRange.row), col: Number(singleCellRange.col) };
        pasteRange = addCellsToRange(resetRange(), startCell, { row: startCell.row + mRows - 1, col: startCell.col + mCols - 1 });
      }
      const data = fillCellsWith2DArray(dataProvider.enumerateRangeCells(pasteRange), matrix, mCols);
      const updates = getCellUpdates(data, (cell) => restoreCellFormulaValue(cell, pasteValuesOnly), (cell) => currentRows[cell.row], (cell) => columns[cell.col], getColumnKey);
      handleRowsUpdate(ACTIONS.PASTE, updates);
    });
  };

  const handleSelectAll = () => {
    if (!isFormulaRangeMode) {
      dispatchTotalRange({ type: RANGE_ACTIONS.RESET });
      dispatchLastRange({ type: RANGE_ACTIONS.RESET });
      for (let col = 0; col < columns.length; col += 1) {
        dispatchLastRange({ type: RANGE_ACTIONS.ADD_COLUMN, col });
      }
    }
  };

  const handleSelectRow = ({ row }) => {
    if (isFormulaRangeMode) {
      const newFormulaRange = addRowToRange(resetRange(), { row });
      setLastFormulaRange(newFormulaRange);
      setFormulaRanges([...formulaRanges, newFormulaRange]);
    } else {
      dispatchTotalRange({ type: RANGE_ACTIONS.RESET });
      dispatchLastRange({ type: RANGE_ACTIONS.RESET });
      dispatchLastRange({ type: RANGE_ACTIONS.ADD_ROW, row });
    }
  };

  const handleSelectColumn = ({ col, event }) => {
    if (isFormulaRangeMode) {
      const newFormulaRange = addColumnToRange(resetRange(), { col });
      setLastFormulaRange(newFormulaRange);
      setFormulaRanges([...formulaRanges, newFormulaRange]);
    } else if (event) {
      const isLeftClick = event.button === 0;
      if (event.ctrlKey) {
        dispatchTotalRange({ type: RANGE_ACTIONS.MERGE, range: lastRange });
        dispatchLastRange({ type: RANGE_ACTIONS.RESET });
        dispatchLastRange({ type: RANGE_ACTIONS.ADD_COLUMN, col });
      } else if (event.shiftKey) {
        const { min, max } = getColumnsMinMax(lastRange);
        let col1 = col;
        let col2 = col;
        if (min !== null && col < min) {
          col1 = col;
          col2 = min;
        } else if (max !== null && col > max) {
          col1 = max;
          col2 = col;
        }
        dispatchLastRange({ type: RANGE_ACTIONS.RESET });
        dispatchLastRange({ type: RANGE_ACTIONS.ADD_COLUMN_RANGE, col1, col2 });
      } else {
        if (isLeftClick || !isColumnInFinalRange(col)) {
          dispatchTotalRange({ type: RANGE_ACTIONS.RESET });
          dispatchLastRange({ type: RANGE_ACTIONS.RESET });
        }
        dispatchLastRange({ type: RANGE_ACTIONS.ADD_COLUMN, col });
      }
    } else {
      dispatchTotalRange({ type: RANGE_ACTIONS.RESET });
      dispatchLastRange({ type: RANGE_ACTIONS.RESET });
      dispatchLastRange({ type: RANGE_ACTIONS.ADD_COLUMN, col });
    }
  };

  const handleKeyDown = (e) => {
    KeyboardHandle(e, {
      ...keyDownHandlers,
      onCopy: () => handleCopy(getFinalRange()),
      onCut: () => handleCut(getFinalRange()),
      onPaste: () => handlePaste(e, getFinalRange()),
      onSelectAll: () => handleSelectAll(),
      onDelete: () => handleDelete(getFinalRange()),
      onUndo: () => handleUndo(),
      onSearch: () => { if (searchEnabled) setSearchMode(SEARCH_MODE.FIND); },
      onFindReplace: () => { if (replaceEnabled) setSearchMode(SEARCH_MODE.REPLACE); },
    });
  };

  const handleConditionalFormat = (range) => {
    let rangeConditions;
    const differentConditions = dataProvider.enumerateRangeCells(range).some((cell, index) => {
      const cellKey = dataProvider.getCellKey(cell);
      if (cellKey) {
        const cellFormat = cellsFormat[cellKey];
        if (cellFormat) {
          const { conditions } = cellFormat;
          if (index === 0) {
            rangeConditions = conditions;
          } else if (rangeConditions !== conditions) return true;
        }
      }
      return false;
    });
    if (differentConditions || !rangeConditions) rangeConditions = [];
    setConditionalFormatOptions({
      range,
      conditions: rangeConditions,
      visible: true,
    });
  };

  const handleConditionalFormatShowRange = (range) => {
    dispatchTotalRange({ type: RANGE_ACTIONS.RESET });
    dispatchLastRange({ type: RANGE_ACTIONS.RESET });
    dispatchLastRange({ type: RANGE_ACTIONS.MERGE, range });
    setConditionalFormatOptions({
      ...conditionalFormatOptions,
      range,
    });
  };

  const handleConditionalFormatChange = (conditions) => {
    if (conditions) {
      const { range } = conditionalFormatOptions;
      if (range) {
        const newCellsFormat = { ...cellsFormat };
        dataProvider.enumerateRangeCells(range).forEach((cell) => {
          const cellKey = dataProvider.getCellKey(cell);
          if (cellKey) {
            if (conditions.length) newCellsFormat[cellKey] = { conditions };
            else delete newCellsFormat[cellKey];
          }
        });
        addCellsFormatHistory();
        setCellsFormat(newCellsFormat);
      }
    }
    setConditionalFormatOptions({ visible: false });
  };

  const handleCellFormat = (range, newStyle) => {
    if (newStyle === MENU_COMMANDS.CONDITIONAL_FORMAT) {
      handleConditionalFormat(range);
    } else {
      const style = JSON.parse(newStyle);

      const newCellsFormat = { ...cellsFormat };
      dataProvider.enumerateRangeCells(range).forEach((cell) => {
        const cellKey = dataProvider.getCellKey(cell);
        if (cellKey) {
          const cellFormat = newCellsFormat[cellKey];
          const prevCellStyle = cellFormat ? cellFormat.style : null;
          const finalCellStyle = getEffectiveCellStyle({ ...prevCellStyle, ...style }, defaultCellStyle);
          if (Object.keys(finalCellStyle).length === 0) delete newCellsFormat[cellKey];
          else newCellsFormat[cellKey] = { style: finalCellStyle };
        }
      });
      addCellsFormatHistory();
      setCellsFormat(newCellsFormat);
    }
  };

  const handleColumnSort = ({ col }, sortOptions) => {
    addCurrentSortHistory();
    setCurrentSort({ columnIndex: col, ...sortOptions });
  };

  const handleColumnFilterReset = ({ col }) => {
    const newColumnsFilter = { ...columnsFilter };
    delete newColumnsFilter[col];
    addColumnsFilterHistory();
    setColumnsFilter(newColumnsFilter);
  };

  const handleColumnFilter = ({ col }, filter) => {
    if (filter === MENU_COMMANDS.FILTER_BY_TEXT) {
      setFilterByTextOptions({ visible: true, col });
    } else {
      const styleFilter = JSON.parse(filter);
      addColumnsFilterHistory();
      setColumnsFilter({ ...columnsFilter, [col]: { style: styleFilter } });
    }
  };

  const handleFilterByTextChange = (text) => {
    if (text) {
      const { col } = filterByTextOptions;
      addColumnsFilterHistory();
      setColumnsFilter({ ...columnsFilter, [col]: { text } });
    }
    setFilterByTextOptions({ visible: false });
  };

  const handleContextMenu = (params) => {
    const { event, ...cell } = params;
    const range = getContextRange(cell);
    const menu = contextMenuBuilder(range, cell, dataProvider, isFormulaRangeMode);
    if (menu) {
      const pasteMenuIndex = menu.findIndex((item) => item.id === MENU_COMMANDS.PASTE);
      if (pasteMenuIndex >= 0) menu[pasteMenuIndex].disabled = true;
      const pasteValuesMenuIndex = menu.findIndex((item) => item.id === MENU_COMMANDS.PASTE_VALUES);
      if (pasteValuesMenuIndex >= 0) menu[pasteValuesMenuIndex].disabled = true;

      const newContextMenu = {
        anchor: event.target, items: menu, cell, range,
      };
      setContextMenu(newContextMenu);
      if (pasteMenuIndex >= 0 || pasteValuesMenuIndex >= 0) {
        Clipboard.pasteCells(null).then((matrix) => {
          if (matrix) {
            if (pasteMenuIndex >= 0) delete newContextMenu.items[pasteMenuIndex].disabled;
            if (pasteValuesMenuIndex >= 0) {
              const hasFormulas = matrix.some((r) => r.some((c) => c.formula));
              if (!hasFormulas) newContextMenu.items.splice(pasteValuesMenuIndex, 1);
              else delete newContextMenu.items[pasteValuesMenuIndex].disabled;
            }
            setContextMenu({ ...newContextMenu });
          }
        });
      }
    }
  };

  const handleColumnInsert = ({ col }, before) => {
    setColumnNameOptions({ visible: true, col, before });
  };

  const handleColumnInsertWithName = (params) => {
    if (params) {
      const { col, before } = columnNameOptions;
      const { name, temporary } = params;
      onColumnInsert({ col }, before, name, temporary);
    }
    setColumnNameOptions({ visible: false });
  };

  const validateNewColumnName = (name) => (columns.findIndex((c) => c.payloadKey === name) >= 0 ? 'Column with the same name already exists' : null);

  const handleColumnDelete = (range) => {
    onColumnDelete(
      dataProvider.enumerateColumns(range).map((col) => ({ col, column: columns[col] })),
    );
  };

  const handleStartEdit = useCallback(({
    value, inline = true, selection, blur, ...cellCoordinate
  }) => {
    setEditingCell({
      ...cellCoordinate, selection, value, blur, inline,
    });
    
    const cellValue = restoreFormulaRangesFromRelative(cellCoordinate, value || dataProvider.getCellValue(cellCoordinate));
    const ranges = isFormula(cellValue) ? getFormulaRanges(cellValue) : [];
    setFormulaRanges(ranges);
  }, [dataProvider]);

  const handleInsertFormula = (cell, formulaItem) => {
    const pos = formulaItem.title.length + 2;
    handleStartEdit({
      value: formulaItem.id, selection: { start: pos, end: pos }, ...cell,
    });
  };

  const handleTextToColumns = (range) => {
    const values = dataProvider.enumerateRangeCells(range).map((cell) => ({ cell, value: dataProvider.getEvaluatedCellValue(cell) }));
    setTextToColumnsOptions({
      data: values,
      visible: true,
    });
  };

  const handleTextToColumnsClose = (data) => {
    setTextToColumnsOptions({ visible: false });

    const cells = [];
    const cellValues = {};
    data.forEach((item) => {
      const { cell, values } = item;
      values.forEach((v, idx) => {
        const c = { ...cell, col: cell.col + idx };
        cells.push(c);
        const key = dataProvider.getCellKey(c);
        if (key) cellValues[key] = v;
      });
    });

    const updates = getCellUpdates(cells,
      (cell) => cellValues[dataProvider.getCellKey(cell)],
      (cell) => currentRows[cell.row],
      (cell) => columns[cell.col],
      getColumnKey);

    handleRowsUpdate(ACTIONS.TEXT_TO_COLUMNS, updates);
  };

  const handleContextMenuSelected = (item, subItem, cell, range) => {
    if (item.id === MENU_COMMANDS.COPY) handleCopy(range);
    else if (item.id === MENU_COMMANDS.CUT) handleCut(range);
    else if (item.id === MENU_COMMANDS.PASTE) handlePaste(null, range);
    else if (item.id === MENU_COMMANDS.PASTE_VALUES) handlePaste(null, range, true);
    else if (item.id === MENU_COMMANDS.SELECT && subItem.id === MENU_COMMANDS.SELECT_ALL) handleSelectAll();
    else if (item.id === MENU_COMMANDS.SELECT && subItem.id === MENU_COMMANDS.SELECT_ROW) handleSelectRow(cell);
    else if (item.id === MENU_COMMANDS.SELECT && subItem.id === MENU_COMMANDS.SELECT_COLUMN) handleSelectColumn(cell);
    else if (item.id === MENU_COMMANDS.CELL_FORMAT) handleCellFormat(range, subItem.id);
    else if (item.id === MENU_COMMANDS.SORT_ASC) handleColumnSort(cell, { direction: 1 });
    else if (item.id === MENU_COMMANDS.SORT_DESC) handleColumnSort(cell, { direction: -1 });
    else if (item.id === MENU_COMMANDS.SORT_BY_COLOR) handleColumnSort(cell, { style: JSON.parse(subItem.id) });
    else if (item.id === MENU_COMMANDS.FILTER) handleColumnFilter(cell, subItem.id);
    else if (item.id === MENU_COMMANDS.FILTER_RESET) handleColumnFilterReset(cell);
    else if (item.id === MENU_COMMANDS.INSERT_COLUMN) handleColumnInsert(cell, subItem.id === MENU_COMMANDS.INSERT_COLUMN_BEFORE);
    else if (item.id === MENU_COMMANDS.DELETE_COLUMN) handleColumnDelete(range);
    else if (item.id === MENU_COMMANDS.INSERT_FORMULA) handleInsertFormula(cell, subItem);
    else if (item.id === MENU_COMMANDS.TEXT_TO_COLUMNS) handleTextToColumns(range);
    else onContextMenuSelected(item, subItem, cell, range, dataProvider);
  };

  const handleColumnResize = (columnIndex, deltaX) => {
    const newColumns = columnsWidth.slice();
    let newWidth = newColumns[columnIndex] + deltaX;
    if (newWidth < 1) newWidth = 1;
    newColumns[columnIndex] = newWidth;
    addColumnsWidthHistory();
    setColumnsWidth(newColumns);
  };

  const moveColumn = (srcIndex, dstIndex) => {
    const srcColumnMap = columns.reduce((map, col, idx) => { map[col.data] = idx; return map; }, {});

    const updatedColumns = columns.slice();
    const column = updatedColumns[srcIndex];
    updatedColumns.splice(srcIndex, 1);
    updatedColumns.splice(dstIndex, 0, column);
    setColumns(updatedColumns);

    const updatedColumnsWidth = columnsWidth.slice();
    const width = updatedColumnsWidth[srcIndex];
    updatedColumnsWidth.splice(srcIndex, 1);
    updatedColumnsWidth.splice(dstIndex, 0, width);
    setColumnsWidth(updatedColumnsWidth);

    const columnMap = updatedColumns.reduce((map, col, idx) => { map[srcColumnMap[col.data]] = idx; return map; }, {});

    if (currentSort) {
      const updatedCurrentSort = { ...currentSort, columnIndex: columnMap[currentSort.columnIndex] };
      setCurrentSort(updatedCurrentSort);
    }

    const updatedColumnsFilter = Object.keys(columnsFilter).reduce((f, idx) => {
      f[columnMap[idx]] = columnsFilter[idx];
      return f;
    }, {});
    setColumnsFilter(updatedColumnsFilter);

    if (Object.keys(cellsWithFormula).length) {
      const updatedCellsWithFormula = Object.keys(cellsWithFormula).reduce((f, key) => {
        const cf = cellsWithFormula[key];
        const srcColIdx = srcColumnMap[cf.columnKey];
        f[key] = { ...cf, formula: updateRelativeColumnReference(cf.formula, srcColIdx, columnMap) };
        return f;
      }, {});
      setCellsWithFormula(updatedCellsWithFormula);

      Object.values(updatedCellsWithFormula).forEach((cf) => {
        const row = rows.find((r) => r._id === cf.rowKey);
        if (row) row[cf.columnKey] = cf.formula;
      });
    }

    onColumnsUpdated(updatedColumns);
  };

  const handleDragOverColumn = ({ srcIndex, dstIndex }) => {
    if (!dragOverColumn || dragOverColumn.srcIndex !== srcIndex || dragOverColumn.dstIndex !== dstIndex) {
      setDragOverColumn({ srcIndex, dstIndex });
    }
  };

  const handleColumnMove = (columnIndex, event) => {
    const x = event.clientX;
    if (!columnMove || columnMove.columnIndex !== columnIndex || Math.abs(columnMove.x - x) > 1) {
      setColumnMove({
        columnIndex,
        x,
      });
    }
  };

  const handleColumnMoveStart = () => {
    setDragOverColumn();
  };

  const handleColumnMoveEnd = () => {
    if (dragOverColumn) {
      let { dstIndex } = dragOverColumn;
      const { srcIndex } = dragOverColumn;
      if (srcIndex > dstIndex) dstIndex += 1;
      moveColumn(srcIndex, dstIndex);
    }
    setColumnMove();
    setDragOverColumn();
  };


  useEffect(() => {
    if (cellEditValue) {
      handleStartEdit({
        value: cellEditValue.value, col: cellEditValue.col, row: cellEditValue.row, selection: cellEditValue.selection,
      });
    }
  },
  // eslint-disable-next-line
  [cellEditValue]);

  const handleCellEdited = ({ row: rowIdx, col, value }) => {
    const row = currentRows[rowIdx];
    const column = columns[col];
    const update = { row, changes: { [getColumnKey(column)]: value } };
    handleRowsUpdate(ACTIONS.EDIT, [update]);
    setEditingCell(null);
  };

  const handleMouseDown = ({ event, row, col }) => {
    if (selectMode === SELECT_MODE.CELL) {
      if (!isFormulaRangeMode) {
        if (event.shiftKey) {
          dispatchTotalRange({ type: RANGE_ACTIONS.MERGE, range: lastRange });
          dispatchLastRange({ type: RANGE_ACTIONS.RESET });
          dispatchLastRange({ type: RANGE_ACTIONS.ADD_CELL_RANGE, cell1: currentCell, cell2: { row, col } });
        } else {
          dispatchLastRange({ type: RANGE_ACTIONS.RESET });
          if (event.ctrlKey) {
            dispatchTotalRange({ type: RANGE_ACTIONS.MERGE, range: lastRange });
          } else {
            dispatchTotalRange({ type: RANGE_ACTIONS.RESET });
          }
          dispatchLastRange({ type: RANGE_ACTIONS.ADD_CELL, row, col });
          setMouseRangeSelect(true);
        }
      } else {
        setStartFormulaRangeCell({ row, col });
        const newFormulaRange = addCellToRange(resetRange(), { row, col });
        setLastFormulaRange(newFormulaRange);
        setFormulaRanges([...formulaRanges, newFormulaRange]);
        setMouseRangeSelect(true);
      }
    }
  };

  const handleMouseOver = ({ row, col, event }) => {
    if (selectMode === SELECT_MODE.CELL) {
      if (isMouseRangeSelect) {
        if (event && event.pageX && event.pageY) {
          setScrollWrapper({ x: event.pageX, y: event.pageY });
        }
        if (!isFormulaRangeMode) {
          dispatchLastRange({ type: RANGE_ACTIONS.RESET });
          dispatchLastRange({ type: RANGE_ACTIONS.ADD_CELL_RANGE, cell1: currentCell, cell2: { row, col } });
        } else {
          const newFormulaRanges = formulaRanges.slice();
          const newFormulaRange = addCellsToRange(resetRange(), startFormulaRangeCell, { row, col });
          setLastFormulaRange(newFormulaRange);
          if (newFormulaRanges.length) newFormulaRanges[newFormulaRanges.length - 1] = newFormulaRange;
          setFormulaRanges(newFormulaRanges);
        }
        setFocusedCell({ row, col });
      }
    }
  };

  const handleMouseUp = () => {
    if (selectMode === SELECT_MODE.CELL) {
      setMouseRangeSelect(null);
      if (isFormulaRangeMode) {
        setLastFormulaRange(null);
      }
    }
    setScrollWrapper();
  };

  const handleAutoScroll = ({ row: deltaRow, col: deltaCol }) => {
    if (focusedCellRef.current) {
      let { row, col } = focusedCellRef.current;
      if (deltaRow) {
        row += deltaRow;
        if (row < 0) row = 0;
        else if (row > dataProvider.rows.length - 1) row = dataProvider.rows.length - 1;
      }
      if (deltaCol) {
        col += deltaCol;
        if (col < 0) col = 0;
        else if (col > dataProvider.columns.length - 1) col = dataProvider.columns.length - 1;
      }
      handleMouseOver({ col, row });
    }
  };

  const handleSpreadMode = (set) => {
    if (!set && spreadRange) {
      const fillRange = subtractRanges(spreadRange, lastRange);
      const matrix = getCells2DArray(dataProvider.enumerateRangeCells(lastRange), (cell) => getCellFormulaValue(cell));
      if (matrix.length) {
        const data = fillCellsWith2DArray(dataProvider.enumerateRangeCells(fillRange), matrix, matrix[0].length);
        if (data) {
          const updates = getCellUpdates(data, (cell) => restoreCellFormulaValue(cell), (cell) => currentRows[cell.row], (cell) => columns[cell.col], getColumnKey);
          handleRowsUpdate(ACTIONS.PASTE, updates);
        }
      }
      dispatchLastRange({ type: RANGE_ACTIONS.RESET });
      dispatchLastRange({ type: RANGE_ACTIONS.MERGE, range: spreadRange });
    }
    setSpreadMode(set);
    setSpreadRange(null);
  };

  const handleSpreadOver = (cell) => {
    const box = getSimpleRangeBox(lastRange, currentRows.length, columns.length);
    if (box) {
      let deltaCol = 0;
      if (cell.col > box.cell2.col) deltaCol = cell.col - box.cell2.col;
      if (cell.col < box.cell1.col) deltaCol = box.cell1.col - cell.col;

      let deltaRow = 0;
      if (cell.row > box.cell2.row) deltaRow = cell.row - box.cell2.row;
      if (cell.row < box.cell1.row) deltaRow = box.cell1.row - cell.row;

      let minRow;
      let minCol;
      let maxRow;
      let maxCol;
      if (deltaRow === 0 && deltaCol === 0) {
        minCol = box.cell1.col;
        minRow = box.cell1.row;
        maxCol = box.cell2.col;
        maxRow = box.cell2.row;
      } else if (deltaCol >= deltaRow) {
        minRow = box.cell1.row;
        maxRow = box.cell2.row;
        minCol = Math.min(cell.col, box.cell1.col);
        maxCol = Math.max(cell.col, box.cell2.col);
      } else if (deltaCol < deltaRow) {
        minCol = box.cell1.col;
        maxCol = box.cell2.col;
        minRow = Math.min(cell.row, box.cell1.row);
        maxRow = Math.max(cell.row, box.cell2.row);
      }

      setSpreadRange(addCellsToRange(resetRange(), { col: minCol, row: minRow }, { col: maxCol, row: maxRow }));
    }
  };

  const handleCellSelect = ({ event, row, col }) => {
    if (selectMode === SELECT_MODE.CELL) {
      if (!isFormulaRangeMode) {
        selectCell({ row, col, event });
      }
    } else if (selectMode === SELECT_MODE.ROW) {
      selectRow(row);
    }
  };

  const getCellStyleByPositionInRange = (position, borderStyle) => {
    const style = {};
    if (position.left) style.borderLeft = borderStyle;
    if (position.right) style.borderRight = borderStyle;
    if (position.top) style.borderTop = borderStyle;
    if (position.bottom) style.borderBottom = borderStyle;
    return style;
  };

  const getCellInRangeStyle = (cellCoordinate, range, borderStyle) => {
    const position = getCellInRangePosition({
      range, ...cellCoordinate, totalRows: currentRows.length, totalColumns: columns.length,
    });
    return getCellStyleByPositionInRange(position, borderStyle);
  };

  const handleNavigateTo = ({
    event, row, col, deltaRow, deltaCol,
  }) => {
    if (selectMode === SELECT_MODE.CELL) {
      dispatchLastRange({ type: RANGE_ACTIONS.RESET });
      const cellTo = { row, col };
      if (event.ctrlKey && (deltaRow || deltaCol)) {
        let firstHasValue;
        let lastHasValue = false;
        let nrow = row;
        let ncol = col;
        while (nrow < currentRows.length && ncol < columns.length && nrow >= 0 && ncol >= 0) {
          const val = dataProvider.getEvaluatedCellValue({ row: nrow, col: ncol });
          if (firstHasValue === undefined) {
            firstHasValue = !!val;
          } else if (val && (!firstHasValue || (firstHasValue && !lastHasValue))) {
            break;
          } else if (firstHasValue && lastHasValue && !val && (row !== nrow - deltaRow || col !== ncol - deltaCol)) {
            nrow -= deltaRow;
            ncol -= deltaCol;
            break;
          }
          lastHasValue = !!val;
          nrow += deltaRow;
          ncol += deltaCol;
        }
        if (nrow >= currentRows.length) nrow = currentRows.length - 1;
        if (ncol >= columns.length) ncol = columns.length - 1;
        if (nrow < 0) nrow = 0;
        if (ncol < 0) ncol = 0;
        cellTo.row = nrow;
        cellTo.col = ncol;
      } else {
        cellTo.row += deltaRow;
        cellTo.col += deltaCol;
      }
      if (event.shiftKey) {
        setFocusedCell(cellTo);
        dispatchLastRange({ type: RANGE_ACTIONS.ADD_CELL_RANGE, cell1: currentCell, cell2: cellTo });
      } else {
        dispatchTotalRange({ type: RANGE_ACTIONS.RESET });
        dispatchLastRange({ type: RANGE_ACTIONS.ADD_CELL, ...cellTo });
        selectCell(cellTo);
      }
    } else if (selectMode === SELECT_MODE.ROW) {
      selectRow((currentRow || 0) + deltaRow);
    }
  };

  const handleSearchResult = (result, replaceOptions) => {
    if (result) {
      if (result.length) {
        setCurrentCell(result[0]);
        setFind(true)
      }
      if (replaceOptions) {
        const escaped = escapeRegex(replaceOptions.find);
        const regexp = new RegExp(escaped, `g${!replaceOptions.options.matchCase ? 'i' : ''}`);
        const updates = getCellUpdates(result,
          (cell) => (replaceOptions.replace.entireCells ? replaceOptions.replace : dataProvider.getEvaluatedCellValue(cell).replace(regexp, replaceOptions.replace)),
          (cell) => currentRows[cell.row],
          (cell) => columns[cell.col],
          getColumnKey);
        handleRowsUpdate(ACTIONS.REPLACE, updates);
      }
    }
  };

  const searchFunction = (text, position, direction, options) => {
    const multiCellRange = !isSingleCellRange(getFinalRange());

    const useOptions = {
      matchCase: false,
      entireCell: false,
      ...options,
    };
    const startPosition = {
      col: position ? position.col : 0,
      row: position ? position.row : 0,
    };
    let step = 1;
    if (direction) {
      startPosition.col += direction;
      step = direction;
    }

    let result = null;
    const ltext = useOptions.matchCase ? text : text.toLowerCase();
    const startRow = startPosition.row;
    let startCol = startPosition.col;
    for (let row = startRow; row >= 0 && row < currentRows.length; row += step) {
      for (let col = startCol; col >= 0 && col < columns.length; col += step) {
        const cellCoord = { row, col };
        if (!multiCellRange || isCellInFinalRange(cellCoord)) {
          const column = columns[col];
          if (column.searchable !== false) {
            const value = dataProvider.getEvaluatedCellValue(cellCoord);
            if (value) {
              const lvalue = useOptions.matchCase ? value : value.toLowerCase();
              const match = useOptions.entireCell ? lvalue === ltext : lvalue.indexOf(ltext) >= 0;
              if (match) {
                result = cellCoord;
                break;
              }
            }
          }
        }
      }
      if (result) break;
      startCol = step > 0 ? 0 : columns.length - 1;
    }
    return result;
  };

  const sheetCellRenderer = ({
    // eslint-disable-next-line react/prop-types
    columnIndex, rowIndex, key, style, coordinateClassName,
  }) => {
    const column = columns[columnIndex];
    const dragOverStyle = (dragOverColumn && dragOverColumn.dstIndex === columnIndex ? { borderRight: columnDragOverStyle } : null);
    if (rowIndex === 0) {
      const columnSelected = isColumnInFinalRange(columnIndex);
      return (
        <SheetColumn
          key={key}
          style={style}
          anchorStyle={dragOverStyle}
          moveable={columnIndex >= fixedColumnCount}
          dragOverColumn={columnMove}
          onResize={(delta) => handleColumnResize(columnIndex, delta)}
          onMove={(event, data) => handleColumnMove(columnIndex, event, data)}
          onMoveStart={() => handleColumnMoveStart()}
          onMoveEnd={() => handleColumnMoveEnd()}
          onContextMenu={(event) => handleContextMenu({ event, col: columnIndex })}
          onDragOver={(p, delta) => handleDragOverColumn({ srcIndex: p.columnIndex, dstIndex: columnIndex + delta })}
          selected={columnSelected}
        >
          { !!column && headerRenderer({
            columnIndex,
            column,
            selected: columnSelected,
            sort: (currentSort && currentSort.columnIndex === columnIndex) && currentSort,
            filter: !!(columnsFilter && columnsFilter[columnIndex]),
            selectColumn: handleSelectColumn,
            sortColumn: handleColumnSort,
          }) }
          { isFormulaRangeMode && <div className={coordinateClassName}>{ getLetterIndex(columnIndex) }</div> }
        </SheetColumn>
      );
    }

    const cellCoordinate = { row: rowIndex - 1, col: columnIndex };

    const row = currentRows[rowIndex - 1];
    let current;
    if (selectMode === SELECT_MODE.CELL) current = !!currentCell && equalCells(currentCell, cellCoordinate);
    else if (selectMode === SELECT_MODE.ROW) current = currentRow === cellCoordinate.row;
    const focused = !!focusedCell && equalCells(focusedCell, cellCoordinate);
    const editing = !!editingCell && equalCells(editingCell, cellCoordinate);
    const selected = isCellInFinalRange(cellCoordinate);

    const positionInLastRange = getCellInRangePosition({
      range: lastRange, ...cellCoordinate, totalRows: currentRows.length, totalColumns: columns.length,
    });
    const lastRangeStyle = getCellStyleByPositionInRange(positionInLastRange, lastRangeBorderStyle);
    const spreadRangeStyle = spreadRange && getCellInRangeStyle(cellCoordinate, spreadRange, spreadRangeBorderStyle);
    let formulaRangeStyle = {};
    if (isFormulaRangeMode) {
      formulaRanges.forEach((forumlaRange, forumlaRangeIdx) => {
        formulaRangeStyle = {
          ...formulaRangeStyle,
          ...getCellInRangeStyle(cellCoordinate, forumlaRange, editRangeBorderStyles[forumlaRangeIdx % editRangeBorderStyles.length]),
        };
      });
    }
    

    const getCellDisplayValue = (row,column) => {
      if(editForSync)
      {
        let key = row._id+"_"+column.title;
        let cellValue = syncEditValues[key];
        if(cellValue || cellValue==="")
        {
          
          return cellValue;
        }
        else{
          return dataProvider.getEvaluatedCellValue(cellCoordinate);
        }
      }
      else{
        return dataProvider.getEvaluatedCellValue(cellCoordinate);
      }
    }

    const cellSourceValue = getCellValue({ row, column });
    const appliedCellStyle = {
      ...getCellStyle({ ...cellCoordinate, data: row, column }), ...dataProvider.getCellStyle(cellCoordinate), ...lastRangeStyle, ...spreadRangeStyle, ...formulaRangeStyle, ...dragOverStyle, ...style,
    };

    const keyDownEvent = cellKeyDownEvent && cellKeyDownEvent.col === cellCoordinate.col && cellKeyDownEvent.row === cellCoordinate.row ? cellKeyDownEvent.event : undefined;

    return (
      // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
      <SheetCell
        key={key}
        style={appliedCellStyle}
        tabIndex={rowIndex * columns.length + columnIndex}
        current={current}
        focused={focused}
        editing={editing}
        editable={column.editable}
        selected={selected}
        spread={isSpreadMode}
        spreadAnchor={positionInLastRange.right && positionInLastRange.bottom}
        onCellSelect={({ event }) => handleCellSelect({ event, ...cellCoordinate })}
        onNavigate={({ event, deltaRow, deltaCol }) => handleNavigateTo({
          event, ...cellCoordinate, deltaRow: (deltaRow || 0), deltaCol: (deltaCol || 0),
        })}
        onCellKeyDown={(event) => setCellKeyDownEvent(event ? { event, ...cellCoordinate } : undefined)}
        onStartEdit={(value) => handleStartEdit({ value, ...cellCoordinate })}
        onMouseDown={(event) => handleMouseDown({ event, ...cellCoordinate })}
        onMouseUp={(event) => handleMouseUp({ event, ...cellCoordinate })}
        onMouseOver={(event) => handleMouseOver({ event, ...cellCoordinate })}
        onContextMenu={(event) => handleContextMenu({ event, ...cellCoordinate })}
        onSpreadStart={() => handleSpreadMode(true)}
        onSpreadEnd={() => handleSpreadMode(false)}
        onSpreadOver={() => handleSpreadOver(cellCoordinate)}
      >
        { !!row && !!column
        && (
          editing ? cellEditRenderer({
            row,
            column,
            rowIndex,
            columnIndex,
            autoFocus: editingCell && editingCell.inline,
            value: editingCell && editingCell.value != null ? editingCell.value : editForSync ? getCellDisplayValue(row,column) : restoreFormulaRangesFromRelative(cellCoordinate, cellSourceValue),
            appendedValue: editingCell && editingCell.inline ? simpleRangeToTextRef(lastFormulaRange, currentRows.length, columns.length) : null,
            selection: editingCell && editingCell.inline ? editingCell.selection : null,
            blur: editingCell.blur,
            onCancel: () => setEditingCell(null),
            onCommit: (newValue) => handleCellEdited({
              value: convertFormulaRangesToRelative(cellCoordinate, newValue), ...cellCoordinate,
            }),
            onEditRange: (range) => setFormulaRangeMode(range),
            onChange: (val) => setEditingCell({ ...editingCell, value: val }),
          }) : cellRenderer({
            row, column, rowIndex, columnIndex, value: getCellDisplayValue(row,column), keyDownEvent,
          })
        )}     
        { isFormulaRangeMode && columnIndex === 0 && <div className={coordinateClassName}>{ rowIndex }</div> }
      </SheetCell>
    );
  };

  const handleFormulaBarCellValueChange = useCallback((value) => {
    if (!editingCell) handleStartEdit({ inline: false, value, ...currentCell });
    else setEditingCell({ ...editingCell, value });
  }, [currentCell, editingCell, handleStartEdit]);

  const getEditValue = () => {
    if(currentCell){
        let currentRow = currentRows[currentCell.row];
        if(currentRow){
        let rowValueArray = Object.values(currentRow);
        let rowKeyArray = Object.keys(currentRow)
        let key = rowValueArray[0]+"_"+rowKeyArray[currentCell.col];
        let cellValue = syncEditValues[key];
        
        if(cellValue)
        {
          return cellValue;
        }
        else
        {
          return getCurrentCellValue();
        }
      }
      }
  }

  return (
    <>
    <div className={classes.root}>
      { formulaBar && (
        <div className={classes.formulaBar}>
          <FormulaBar
            cellValue={editForSync ? getEditValue() : getCurrentCellValue()}
            editedValue={editingCell && editingCell.value}
            autoFocus={editingCell && !editingCell.inline}
            appendedValue={editingCell && !editingCell.inline ? simpleRangeToTextRef(lastFormulaRange, currentRows.length, columns.length) : null}
            selection={editingCell && !editingCell.inline ? editingCell.selection : null}
            editable={isCurrentCellEditable()}
            rowCount={currentRows.length}
            columns={columns}
            currentCell={currentCell}
            onCurrentCellChange={(cell) => setCurrentCell(cell)}
            onCellValueChange={handleFormulaBarCellValueChange}
            onCellValueCancel={() => setEditingCell(null)}
            onCellValueCommit={(value) => handleCellEdited({ value: convertFormulaRangesToRelative(editingCell, value), ...editingCell })}
            onCellValueEditRange={(range) => setFormulaRangeMode(range)}
            expand = {expand}
            setExpand = {setExpand}
            dimensions = {dimensions}
            setEditingCell = {setEditingCell}
          />
        </div>
      )}
      <div
        ref={sheetRef}
        className={classes.sheet}
        tabIndex={0}
        onKeyDown={(e) => handleKeyDown(e)}
        role="grid"
        onContextMenu={(e) => { e.preventDefault(); }}
      >
        
        <AutoSizer>
          {({ width, height }) => (
            <MultiGrid
              fixedColumnCount={fixedColumnCount}
              fixedRowCount={1}
              cellRenderer={(params) => sheetCellRenderer({ ...params, coordinateClassName: classes.headerCoordinate })}
              columnWidth={({ index }) => columnsWidth[index] || defaultColumnWidth}
              columnCount={columns.length}
              rowHeight={({ index }) => (index === 0 ? headerHeight : rowHeight)}
              rowCount={[currentRows.length + 1]}
              width={width}
              height={height}
              ref={(ref) => { gridRef = ref; }}
              scrollToRow={find && (scrollToCell && scrollToCell.row + 1)}
              scrollToColumn={scrollToCell && scrollToCell.col}
            />
          )}
        </AutoSizer>
      </div>
      <ContextMenu
        anchor={contextMenu.anchor}
        items={contextMenu.items}
        cell={contextMenu.cell}
        onItemSelected={(menu, submenu) => handleContextMenuSelected(menu, submenu, contextMenu.cell, contextMenu.range)}
        onClose={() => setContextMenu({ anchor: null })}
      />
      { conditionalFormatOptions.visible
        && (
        <ConditionalFormatOptionsDlg
          visible
          conditions={conditionalFormatOptions.conditions}
          range={conditionalFormatOptions.range}
          defaultStyle={defaultCellStyle}
          onShowRange={(range) => handleConditionalFormatShowRange(range)}
          onClose={(newStyle) => handleConditionalFormatChange(newStyle)}
        />
        )}
      { filterByTextOptions.visible && (
        <FilterByTextDlg
          visible
          onClose={(text) => handleFilterByTextChange(text)}
        />
      )}
      { columnNameOptions.visible && (
        <ColumnNametDlg
          visible
          onClose={(params) => handleColumnInsertWithName(params)}
          validate={(name) => validateNewColumnName(name)}
        />
      )}
      { textToColumnsOptions.visible && (
        <TextToColumnsDlg
          visible
          data={textToColumnsOptions.data}
          onClose={(params) => handleTextToColumnsClose(params)}
        />
      )}
      { ((searchEnabled || replaceEnabled) && searchMode !== null) && (
        <SearchPane
          visible
          canReplace={replaceEnabled}
          defaultMode={searchMode}
          onClose={() => {setSearchMode(null); setFind(false)}}
          onFound={(result, replaceOptions) => handleSearchResult(result, replaceOptions)}
          startPosition={currentCell}
          searchFunction={searchFunction}
        />
      )}
      { scrollWrapper && <SheetScrollWrapper startingPoint={scrollWrapper} onEnd={handleMouseUp} onScroll={handleAutoScroll} parent={sheetRef.current} /> }
    </div>
    </>
  );
})


Sheet.defaultProps = {
  selectedCellKey: null,
  fixedColumnCount: 0,
  rows: [],
  columns: [],
  history: null,
  headerHeight: 32,
  rowHeight: 24,
  defaultColumnWidth: 100,
  defaultCellStyle: {},
  getCellStyle: () => undefined,
  getCellValue: () => '',
  getRowKey: () => null,
  getColumnKey: () => null,
  headerRenderer: defaultHeaderRenderer,
  cellRenderer: defaultCellRenderer,
  cellEditRenderer: defaultCellEditRenderer,
  onRowsUpdated: () => { },
  contextMenuBuilder: () => null,
  onContextMenuSelected: () => { },
  onColumnDelete: () => { },
  onColumnInsert: () => { },
  onSelected: () => { },
  onBeforePrepareRows: (rows) => rows,
  onAfterPrepareRows: (rows) => rows,
  onColumnsUpdated: () => { },
  onHistoryChange: () => { },
  onUndo: () => { },
  lastRangeBorderStyle: 'solid 1px #ff0000',
  spreadRangeBorderStyle: 'solid 1px #ff00ff',
  columnDragOverStyle: 'solid 2px #0000ff',
  editRangeBorderStyles: [
    'solid 1px #00ff00',
    'solid 1px #0000ff',
    'solid 1px #00ffff',
    'solid 1px #ffff00',
  ],
  selectMode: SELECT_MODE.CELL,
  searchEnabled: true,
  replaceEnabled: true,
  cellEditValue: null,
  exportDataProvider: null,
  formulaBar: false,
  keyDownHandlers: undefined,
  sortFunction: null,
};

Sheet.propTypes = {
  ...withStylesPropTypes,
  selectedCellKey: PropTypes.shape(),
  fixedColumnCount: PropTypes.number,
  defaultCellStyle: PropTypes.shape(),
  rows: PropTypes.arrayOf(PropTypes.shape),
  columns: PropTypes.arrayOf(PropTypes.shape),
  history: PropTypes.arrayOf(PropTypes.shape),
  headerHeight: PropTypes.number,
  rowHeight: PropTypes.number,
  defaultColumnWidth: PropTypes.number,
  getCellStyle: PropTypes.func,
  getCellValue: PropTypes.func,
  getRowKey: PropTypes.func,
  getColumnKey: PropTypes.func,
  headerRenderer: PropTypes.func,
  cellRenderer: PropTypes.func,
  cellEditRenderer: PropTypes.func,
  onRowsUpdated: PropTypes.func,
  onContextMenuSelected: PropTypes.func,
  onColumnDelete: PropTypes.func,
  onColumnInsert: PropTypes.func,
  onSelected: PropTypes.func,
  onBeforePrepareRows: PropTypes.func,
  onAfterPrepareRows: PropTypes.func,
  onColumnsUpdated: PropTypes.func,
  onHistoryChange: PropTypes.func,
  onUndo: PropTypes.func,
  contextMenuBuilder: PropTypes.func,
  lastRangeBorderStyle: PropTypes.string,
  spreadRangeBorderStyle: PropTypes.string,
  columnDragOverStyle: PropTypes.string,
  editRangeBorderStyles: PropTypes.arrayOf(PropTypes.string),
  selectMode: PropTypes.string,
  searchEnabled: PropTypes.bool,
  replaceEnabled: PropTypes.bool,
  cellEditValue: PropTypes.shape(),
  exportDataProvider: PropTypes.func,
  formulaBar: PropTypes.bool,
  keyDownHandlers: PropTypes.shape(),
  sortFunction: PropTypes.func,
};

export default withStyles(useStyles)(Sheet);
