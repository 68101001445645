import client from './client';
import { error, getQueryString } from '../helper/misc';

const fetchLists = (page) => new Promise((resolve, reject) => {
  const params = {};
  if (page) params.page = page;
  client.get(`lists${getQueryString(params)}`)
    .then((result) => resolve(result.data))
    .catch((err) => reject(error(err)));
});

export default { fetchLists };
