import React, { useCallback, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import CreateIcon from '@material-ui/icons/Create';
import { withStyles } from '@material-ui/core/styles';
import { withStylesPropTypes } from '../../../helper/misc';
import { selectSelectedPayloadIds, selectSelectedPayloads } from '../../../app/payloadSelectors';
import CellSelectList from './cellselectlist';
import CellInput from './cellinput';
import ArrowDropDownSharpIcon from '@material-ui/icons/ArrowDropDownSharp';
import ArrowDropUpSharpIcon from '@material-ui/icons/ArrowDropUpSharp';
import RefreshIcon from '@material-ui/icons/Refresh';
import SyncIcon from '@material-ui/icons/Sync';
import Dedupe from '../dedupe';
import Folder from '../../../Folder';
import STATIONS, {
  STATION_STATUS
} from '../../../const/stations';
import {HomeScreen} from '../../../pages/home';
import { useMediaQuery } from '@material-ui/core';


const useStyles = ((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    padding: `0px ${theme.spacing(1)}px`,
    backgroundColor: theme.palette.background.toolbar,
    zIndex : "1",
    width:'100%',
  },
  cellSelect: {
    borderRight: `solid 1px ${theme.palette.border.primary}`,
    padding: `0px 0px`,
  },
  iconDone: {
    color: theme.palette.success.main,
  },
  icon:{
    padding: `0px ${theme.spacing(1)}px`,
    width : "15%"
  },
  input:{
    width:"70%"
  },
  formula: {
    borderLeft: `solid 1px ${theme.palette.border.primary}`,
    padding: `0px ${theme.spacing(1)}px`,
    flexGrow: 1,
    display: 'flex',
    '&>*': {
      flexGrow: 1,
    },
    margin: '2px',
  },
  dedupe: {
    "&.Mui-disabled": {
      "& path": {
        fill: "gray"
      }
    },
  },
  customDedupe: {
    padding: "3px 3px",
    margin: "0px 2px"
  },
  submitCancel: {
    display: "flex",
    margin: "0px 2px"
  },
  duplicationText:{
    display: "normal"
  },
  "@media (min-width: 1594px)": {
    duplicationText: {
      display: "none"
    }
  },
  dedupe: {
    "&.Mui-disabled": {
      "& path": {
        fill: "gray"
      }
    }
  }
}));

function FormulaBar({
  classes,
  cellValue,
  editedValue,
  appendedValue,
  editable,
  rowCount,
  columns,
  currentCell,
  autoFocus,
  selection,
  selectedPayloadIds,
  selectedPayloads,
  onCurrentCellChange,
  onCellValueChange,
  onCellValueCommit,
  onCellValueCancel,
  onCellValueEditRange,
  expand,
  setExpand,
  dimensions,
  setEditingCell,
}) {

  const {customDedupe, handleCustomDedupe, isSubmit, setIsSubmit, duplicateMessage, handleDedupeSubmit, appMode, resetCustomDedupe, singleColumn, 
    handleDeleteLocationIds,setRemoveDuplicateModal, refreshDedupeSubmit, editForSync, setSyncModal, selectedLocation, handleChangeSync} = useContext(HomeScreen);

  const showText = useMediaQuery('(min-width:1594px)');

  const handleCellChange = ({ col, row }) => {
    onCurrentCellChange({ col, row });
  };

  const handleCommit = useCallback(() => {
    if (editedValue) onCellValueCommit(editedValue);
    setExpand(false)
  }, [editedValue, onCellValueCommit]);

  const handleCancel = useCallback(() => {
    onCellValueCancel();
    setExpand(false);
  }, [onCellValueCancel]);

  const handleValueChange = useCallback((value) => {
    onCellValueChange(value);
  }, [onCellValueChange]);

  const handleEditRange = useCallback((range) => {
    onCellValueEditRange(range);
  }, [onCellValueEditRange]);

  const handleDedupeClick = () => {
    setDedupeDlg({
      title: 'Remove Duplicates',
      columns: columns.filter(c => c.title),
      onClose: (answer) => {
        setDedupeDlg();
      },
    });
  };

  const handleDedupeSbt = async() => {
    if (editForSync){
      await handleChangeSync()
      setIsSubmit(true);
      setEditingCell(null);
      handleDedupeSubmit();
    }
    else{
      setIsSubmit(true);
      setEditingCell(null);
      handleDedupeSubmit();
    }
  }

  const refreshDedupeSbt = () => {
    refreshDedupeSubmit();
  }

  const handleRemoveDuplicate = async() => {
    if (editForSync){
      await handleChangeSync()
      setRemoveDuplicateModal(true)
    }
    else
      setRemoveDuplicateModal(true)
  }
  const [dedupeDlg, setDedupeDlg] = useState();
 

  return (
    <div className={classes.root}>
      {appMode===0 ? 
        <div className={classes.cellSelect}>
          {customDedupe &&
          <div style={{display: "flex"}}>
            {!isSubmit &&
          <IconButton
           size="small"
           color="primary"
           onClick={refreshDedupeSbt}
         >
           <RefreshIcon />
         </IconButton>
          }
             {!isSubmit ? 
               
                <Button variant="contained" color={singleColumn.length<1 ? "default" : "primary"}  size="small" className={classes.customDedupe} onClick={singleColumn.length<1 ? null : handleDedupeSbt}>
                  Submit
                </Button> 
             : 
                <Button variant="contained" color={selectedLocation.length<1 ? "default" : "primary"} size="small" className={classes.customDedupe} onClick={selectedLocation.length<1 ? null : handleRemoveDuplicate}>
                Remove Duplicates
                </Button>

              }
              <Button variant="outlined" color="primary" size="small" onClick={resetCustomDedupe} className={classes.customDedupe}>
                Cancel
              </Button>
              <div>
              <Folder/>
              <span className={classes.duplicationText} style={{display: (dimensions['width'] <= 855 && isSubmit) | dimensions['width'] <= 455 ? "none" : "initial"}}>Identified </span><span style={{color: "#0000FF"}}>{duplicateMessage}</span><span className={classes.duplicationText} style={{display: (dimensions['width'] <= 855 && isSubmit) | dimensions['width'] <= 455 ? "none" : "initial"}}> duplicates</span>
              </div>
              </div>
          }
        </div> :
        null
      }
      <div className={classes.cellSelect}>
        <CellSelectList rowCount={rowCount} columns={columns} currentCell={currentCell} onChange={handleCellChange} dimensions={dimensions}/>
      </div>
      <IconButton
        size="small"
        color="secondary"
        disabled={!editedValue}
        onClick={handleCancel}
        style={{display: dimensions['width'] <= 740 ? "none" : "initial"}}
      >
        <ClearIcon />
      </IconButton>
      <IconButton
        size="small"
        classes={{ root: classes.iconDone }}
        disabled={!editedValue}
        onClick={handleCommit}
        style={{display: dimensions['width'] <= 740 ? "none" : "initial"}}
      >
        <DoneIcon />
      </IconButton>
      <IconButton
        size="small"
        color="primary"
        disabled
        style={{display: dimensions['width'] <= 740 ? "none" : "initial"}}
      >
        <CreateIcon />
      </IconButton>
      <div className={classes.formula} style={{display: dimensions['width'] <= 670 ? "none" : "initial"}}>
        <CellInput
          value={editedValue != null ? editedValue : (cellValue || '')}
          appendedValue={appendedValue}
          disabled={!editable}
          autoFocus={autoFocus}
          selection={selection}
          onChange={handleValueChange}
          onEditRange={handleEditRange}
          onCancel={handleCancel}
          onCommit={handleCommit}
          expand = {expand}
        />
      </div>
      {!expand ? <ArrowDropDownSharpIcon style={{display: dimensions['width'] <= 740 ? "none" : "initial"}} onClick={()=>setExpand(true)}/> : <ArrowDropUpSharpIcon style={{display: dimensions['width'] <= 670 ? "none" : "initial"}} onClick={()=>setExpand(false)}/>}
      {!customDedupe && <IconButton
        className={classes.dedupe}
        size="small"
        onClick={handleCustomDedupe}
        disabled={!Object.keys(selectedPayloadIds).length || selectedPayloads[0].status === STATION_STATUS.PROCESSING}
        style={{display: dimensions['width'] <= 380 ? "none" : "initial"}}
        >
        <Folder/>
      </IconButton>
      }
      {dedupeDlg && <Dedupe {...dedupeDlg} /> }
      <IconButton
        size="small"
        onClick={handleChangeSync}
        disabled={!editForSync}
        color = {editForSync ? 'primary' : 'default'}
        >
        <SyncIcon />
      </IconButton>
      
    </div>
  );
}

FormulaBar.defaultProps = {
  cellValue: '',
  editedValue: null,
  appendedValue: null,
  editable: false,
  rowCount: 0,
  columns: [],
  currentCell: null,
  autoFocus: false,
  selection: null,
  onCurrentCellChange: () => { },
  onCellValueChange: () => { },
  onCellValueCommit: () => { },
  onCellValueCancel: () => { },
  onCellValueEditRange: () => { },
};

FormulaBar.propTypes = {
  ...withStylesPropTypes,
  cellValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  editedValue: PropTypes.string,
  appendedValue: PropTypes.string,
  editable: PropTypes.bool,
  rowCount: PropTypes.number,
  autoFocus: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.shape()),
  currentCell: PropTypes.shape(),
  selection: PropTypes.shape(),
  onCurrentCellChange: PropTypes.func,
  onCellValueChange: PropTypes.func,
  onCellValueCommit: PropTypes.func,
  onCellValueCancel: PropTypes.func,
  onCellValueEditRange: PropTypes.func,
};
const mapStateToProps = (state, props) => ({
  selectedPayloadIds: selectSelectedPayloadIds(state, props),
  selectedPayloads: selectSelectedPayloads(state, props),
});
export default connect(mapStateToProps, null)(withStyles(useStyles)(FormulaBar));