import { createMuiTheme, createStyles } from '@material-ui/core/styles';

const PALETTE = {
  PRIMARY_ACTION: '#2361d8',
  PRIMARY_TEXT: '#00203c',
  DISABLED_CONTRAST_TEXT: '#737373',
  ERROR_MAIN: '#e01107',
  ERROR_LIGHT: '#f8cdcb',
  HEADER_BG: '#e5e5e5',
  PAPER_BG: '#ffffff',
  TOOLBAR_BG: '#f8f8f8',
  PRIMARY_BORDER: '#ededed',
  SECONDARY_BORDER: '#dbdbdb',
};

const defaultTheme = createMuiTheme();

const theme = createMuiTheme({
  palette: {
    primary: {
      main: PALETTE.PRIMARY_ACTION,
    },

    text: {
      primary: PALETTE.PRIMARY_TEXT,
      secondary: PALETTE.PRIMARY_TEXT,
      disabledContrast: PALETTE.DISABLED_CONTRAST_TEXT,
    },

    border: {
      primary: PALETTE.PRIMARY_BORDER,
      secondary: PALETTE.SECONDARY_BORDER,
    },

    error: {
      main: PALETTE.ERROR_MAIN,
      light: PALETTE.ERROR_LIGHT,
    },

    background: {
      header: PALETTE.HEADER_BG,
      toolbar: PALETTE.TOOLBAR_BG,
      paper: PALETTE.PAPER_BG,
    },
  },

  mixins: {
    header: {
      backgroundColor: PALETTE.HEADER_BG,
      minHeight: defaultTheme.spacing(4),
      alignItems: 'center',
      display: 'flex',
      paddingLeft: defaultTheme.spacing(3),
      paddingRight: defaultTheme.spacing(3),
      color: PALETTE.PRIMARY_TEXT,
    },
  },

  overrides: {
    MuiTab: createStyles({
      root: {
        minHeight: defaultTheme.spacing(4),
        padding: 'auto',
        fontSize: 'inherit',
      },
      textColorPrimary: {
        '&$selected': {
          fontWeight: 600,
          '&>$wrapper': {
            borderBottom: `solid 4px ${PALETTE.PRIMARY_ACTION}`,
            width: 'auto',
          },
        },
      },
    }),
    MuiTabs: {
      root: {
        minHeight: defaultTheme.spacing(4),
      },
      indicator: {
        display: 'none',
      },
    },
    MuiAutocomplete: {
      root: {
        fontSize: 'inherit',
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: `solid 1px ${PALETTE.PRIMARY_ACTION}`,
        },
      },
    },
  },
});

export default theme;
