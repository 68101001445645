import { createSlice } from '@reduxjs/toolkit';
import api from '../api/lockrecord';

export const slice = createSlice({
  name: 'lockRecord',
  initialState: {
    byRecordType: {},
    error: undefined,
  },
  reducers: {
    setLockedListIds: (state, { payload }) => {
      const { recordType, ids = [] } = payload;
      if (ids) {
        state.byRecordType[recordType] = ids.reduce((m, i) => {
          m[i] = true;
          return m;
        }, {});
      } else {
        delete state.byRecordType[recordType];
      }
    },
    setLockedListFailed: (state, { payload }) => {
      const { error } = payload;
      state.error = error;
    },
  },
});

export const {
  setLockedListIds, setLockedListFailed,
} = slice.actions;

export default slice.reducer;


const timers = {};

const stopTimer = (recordType) => {
  if (timers[recordType]) {
    clearTimeout(timers[recordType]);
    delete timers[recordType];
  }
};

export const watchRecordLock = (recordType, id, timeout = 5000) => (dispatch) => {
  stopTimer(recordType);
  timers[recordType] = setTimeout(() => watchRecordLock(recordType, id, timeout)(dispatch), timeout);
  api.lockRecord(recordType, id).then((ids) => {
    dispatch(setLockedListIds({ ids, recordType }));
  }).catch((error) => {
    dispatch(setLockedListFailed({ error, recordType, id }));
  });
};

export const stopWatchRecordLock = (recordType) => (dispatch) => {
  stopTimer(recordType);
  api.lockRecord(recordType).then(() => {
    dispatch(setLockedListIds({ recordType }));
  }).catch((error) => {
    dispatch(setLockedListFailed({ error }));
  });
};
