import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import LinearProgress from '@material-ui/core/LinearProgress';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { withStyles } from '@material-ui/core/styles';
import { fetchHistoryByPayloadIds, restorePayloadHistory } from '../../app/historySlice';
import {
  selectHistoryLoading, selectHistoryRestoring, selectHistoryError, selectHistory,
} from '../../app/historySelectors';
import { setSelectedPayloadHistory, resetPayloadViewSpecificSelection, setLastUpdated } from '../../app/payloadSlice';
import { selectSelectedPayloads, selectLastUpdated } from '../../app/payloadSelectors';
import { invalidateLocationsByPayload } from '../../app/locationsSlice';
import { resolve, withStylesPropTypes, useMountEffect } from '../../helper/misc';
import Sheet, { SELECT_MODE } from '../common/sheet/sheet';
import { defaultCellRenderer } from '../common/sheet/renderers';
import HistoryColumnHeader from './historycolumnheader';
import Loading from '../common/loading';
import Error from '../common/error';


const useStyles = ((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& *': {
      boxSizing: 'border-box',
    },
  },
  oldValue: {
    color: theme.palette.error.main,
    textDecoration: 'line-through',
  },
  newValue: {
    color: theme.palette.text.primary,
  },
  expandedRecord: {
    fontStyle: 'italic',
    fontWeight: 100,
  },
}));

const HISTORY_COMMANDS = {
  RESTORE_SNAPSHOT: 'restore_snapshot',
};

function History({
  classes,
  payloads,
  history,
  isLoading,
  isRestoring,
  error,
  lastUpdatedLocations,
  fnFetchHistoryByPayloadIds,
  fnSetSelectedPayloadHistory,
  fnResetPayloadViewSpecificSelection,
  fnRestorePayloadHistory,
  fnSetLastUpdated,
  fnInvalidateLocationsByPayload,
}) {
  const [historyRecords, setHistoryRecords] = useState(history);
  const [restoreBatch, setRestoreBatch] = useState(null);

  useEffect(() => {
    setHistoryRecords(history);
  }, [history]);

  useEffect(() => {
    if (!isRestoring && restoreBatch) {
      fnSetLastUpdated({ payloadIds: [restoreBatch.payloadId] });
      fnInvalidateLocationsByPayload({ payloadId: restoreBatch.payloadId });
      setRestoreBatch(null);
    }
  }, [isRestoring, restoreBatch, fnSetLastUpdated, fnInvalidateLocationsByPayload]);

  const expandRow = (row) => {
    const {
      _id, createdAt, updatedAt, userId, userName, action, payloadId, batchId, headers,
    } = row;
    const childRecords = [];
    row.locations.forEach((location) => {
      location.changes.forEach((change) => {
        childRecords.push({
          _id: `${_id}-${childRecords.length}`,
          createdAt,
          updatedAt,
          userId,
          userName,
          action,
          payloadId,
          batchId,
          headers,
          parentId: _id,
          locations: [{
            ...location,
            changes: [{ ...change }],
          }],
        });
      });
    });
    return childRecords;
  };

  const handleExpandDetails = (e, row) => {
    e.preventDefault();
    e.stopPropagation();
    const newHistoryRecords = historyRecords.slice();
    const idx = historyRecords.findIndex((rec) => rec._id === row._id);
    if (idx >= 0) {
      newHistoryRecords[idx] = { ...newHistoryRecords[idx], expanded: !row.expanded };
      setHistoryRecords(newHistoryRecords);
    }
  };

  const detailsColumnRenderer = ({ value, row }) => {
    if (value) {
      if (value === 'expand') {
        return (
          <Button
            fullWidth
            type="button"
            color="primary"
            size="small"
            endIcon={row.expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            onClick={(e) => handleExpandDetails(e, row)}
            onMouseDown={(e) => e.stopPropagation()}
          >
            { row.expanded ? 'Collapse' : 'Expand' }
          </Button>
        );
      }
      const change = JSON.parse(value);
      if (change.original !== undefined || change.current !== undefined) {
        return (
          <div className={clsx(row.parentId && classes.expandedRecord)}>
            <span className={classes.oldValue}>{(change.original || '').toString()}</span>
            {' '}
            =&gt;
            {' '}
            <span className={classes.newValue}>{(change.current || '').toString()}</span>
          </div>
        );
      }
    }
    return <div />;
  };
  detailsColumnRenderer.propTypes = {
    ...withStylesPropTypes,
    row: PropTypes.shape().isRequired,
  };

  const defaultColumnRenderer = ({ value, row }) => (row.parentId ? <div className={classes.expandedRecord}>{ value }</div> : <>{ value }</>);
  defaultColumnRenderer.propTypes = {
    ...withStylesPropTypes,
    row: PropTypes.shape().isRequired,
  };


  const COLUMNS = [
    {
      title: 'Time',
      data: 'createdAt',
      width: 200,
      type: 'date',
      formatter: (val) => (new Date(val)).toLocaleString(),
      renderer: defaultColumnRenderer,
    },
    {
      title: 'Engineer',
      data: 'userName',
      width: 200,
      renderer: defaultColumnRenderer,
    },
    {
      title: 'Action',
      data: 'action',
      width: 100,
      renderer: defaultColumnRenderer,
    },
    {
      title: 'Field',
      data: 'field',
      width: 120,
      formatter: (val, row) => {
        let ret = '';
        if (row.locations) {
          const fieldsMap = row.locations.reduce((map, l) => {
            l.changes.forEach((c) => { map[c.field] = true; });
            return map;
          }, {});
          const fields = Object.keys(fieldsMap).sort();
          ret = fields.length > 2 ? `${fields.length}+` : fields.join(', ');
        }
        return ret;
      },
      renderer: defaultColumnRenderer,
    },
    {
      title: 'ALI(s)',
      data: 'ali',
      width: 150,
      formatter: (val, row) => {
        let ret = '';
        if (row.locations) {
          const alis = row.locations.map((l) => l.ali).filter((ali, index, self) => !!ali && self.indexOf(ali) === index).sort();
          ret = alis.length > 2 ? `${alis.length}+` : alis.join(', ');
        }
        return ret;
      },
      renderer: defaultColumnRenderer,
    },
    {
      title: 'Detail',
      data: 'changes',
      width: 200,
      formatter: (val, row) => {
        let ret = null;
        if (row.locations) {
          if (row.locations.length === 1) {
            const location = row.locations[0];
            if (location.changes) {
              if (location.changes.length === 1) {
                const change = location.changes[0];
                ret = JSON.stringify(change);
              } else if (location.changes.length > 1) {
                ret = 'expand';
              }
            }
          } else if (row.locations.length > 1) {
            ret = 'expand';
          }
        }
        return ret;
      },
      renderer: detailsColumnRenderer,
    },
  ];

  useMountEffect(() => {
    fnResetPayloadViewSpecificSelection();
  });

  useEffect(() => {
    let needUpdate = !lastUpdatedLocations;
    if (!needUpdate && lastUpdatedLocations && lastUpdatedLocations.payloadIds) {
      needUpdate = payloads.some((p) => lastUpdatedLocations.payloadIds[p._id]);
    }
    if (needUpdate) {
      fnFetchHistoryByPayloadIds(payloads.map((p) => p._id));
    }
  }, [payloads, fnFetchHistoryByPayloadIds, lastUpdatedLocations]);

  const getCellValue = (row, columnDef) => {
    let ret = resolve(row, columnDef.data);
    if (columnDef.formatter) {
      ret = columnDef.formatter(ret, row);
    }
    return ret;
  };

  const getRowKey = useCallback((row) => row._id, []);

  const getColumnKey = useCallback((column) => column.data, []);

  const handleHistoryRecordSeleted = (rec) => {
    const historyRecord = {
      payloadId: rec.payloadId, historyId: rec.batchId, editTimestamp: rec.updatedAt, headers: rec.headers,
    };
    if (rec.locations && rec.locations.length) {
      const location = rec.locations[0];
      if (location.changes && location.changes.length) {
        const change = location.changes[0];
        historyRecord.editCell = { rowKey: location._id, columnKey: change.field };
      }
    }
    fnSetSelectedPayloadHistory(historyRecord);
  };

  const buildContextMenu = (range, cell) => {
    let ret = null;
    if (cell.row >= 0) {
      ret = [{
        id: HISTORY_COMMANDS.RESTORE_SNAPSHOT,
        title: 'Restore Snapshot',
      }];
    }
    return ret;
  };

  const handleRestoreSnapshot = (cell, dataProvider) => {
    const { row } = dataProvider.getRawCell(cell);
    if (row) {
      setRestoreBatch(row);
      fnRestorePayloadHistory(row.payloadId, row.batchId);
    }
  };

  const handleContextMenuCommand = (item, cell, dataProvider) => {
    switch (item.id) {
      case HISTORY_COMMANDS.RESTORE_SNAPSHOT:
        handleRestoreSnapshot(cell, dataProvider);
        break;
      default:
        break;
    }
  };

  return (
    <>
      { (isLoading || isRestoring) && (<Loading />) }
      { error && (<Error message={error.message} />) }
      <Modal open={isRestoring}><LinearProgress /></Modal>
      <div className={classes.root}>
        <Sheet
          rows={historyRecords}
          columns={COLUMNS}
          getCellValue={useCallback(({ row, column }) => getCellValue(row, column), [])}
          getRowKey={getRowKey}
          getColumnKey={getColumnKey}
          headerRenderer={({
            column, columnIndex, sort, sortColumn,
          }) => <HistoryColumnHeader title={column.title} sortColumn={sortColumn} currentSort={sort} columnIndex={columnIndex} />}
          cellRenderer={({ column, row, value }) => (column.renderer ? column.renderer({ value, row }) : defaultCellRenderer({ value }))}
          selectMode={SELECT_MODE.ROW}
          searchEnabled={false}
          contextMenuBuilder={(range, cell) => buildContextMenu(range, cell)}
          onContextMenuSelected={(item, subItem, cell, range, dataProvider) => handleContextMenuCommand(item, cell, dataProvider)}
          onSelected={(cell, { row }) => handleHistoryRecordSeleted(row)}
          onAfterPrepareRows={(rows) => {
            const newRows = [];
            rows.forEach((row) => {
              newRows.push(row);
              if (row.expanded) newRows.push(...expandRow(row));
            });
            return newRows;
          }}
        />
      </div>
    </>
  );
}


History.propTypes = {
  ...withStylesPropTypes,
};

const mapStateToProps = (state, props) => ({
  payloads: selectSelectedPayloads(state, props),
  isLoading: selectHistoryLoading(state, props),
  isRestoring: selectHistoryRestoring(state, props),
  error: selectHistoryError(state, props),
  history: selectHistory(state, props),
  lastUpdatedLocations: selectLastUpdated(state, props),
});

export default connect(mapStateToProps, {
  fnFetchHistoryByPayloadIds: fetchHistoryByPayloadIds,
  fnSetSelectedPayloadHistory: setSelectedPayloadHistory,
  fnResetPayloadViewSpecificSelection: resetPayloadViewSpecificSelection,
  fnRestorePayloadHistory: restorePayloadHistory,
  fnSetLastUpdated: setLastUpdated,
  fnInvalidateLocationsByPayload: invalidateLocationsByPayload,
})(withStyles(useStyles)(History));
