import { createSlice } from '@reduxjs/toolkit';
import api from '../api/payload';
import locationsApi from '../api/locations';
import normalize from '../helper/normalize';
import {
  invalidateLocationsByPayload, approveLocationsStart,
  approveLocationsSuccess,
  approveLocationsFailed,
} from './locationsSlice';
import { DISTANCE, GROUP_BY, MATCH_TYPE_DEFAULT } from '../const/alimatch';

export const slice = createSlice({
  name: 'payload',
  initialState: {
    byListId: {},
    selectedPayloadIds: {},
    payloadLastHistoryRecordById: {},
    byId: {},
    selectedPayloadHistory: null,
    isPayloadSaving: false,
    payloadError: null,
    isHeadersSaving: false,
    isHeadersLoading: false,
    headersError: null,
    standardHeadersByPayloadId: {},
    selectedPayloadStation: null,
    isLastHistoryLoading: false,
    lastHistoryError: null,
    lastUpdated: null,
  },
  reducers: {
    getPayloadsStart: (state, { payload }) => {
      if(payload){
        let listData = state.byListId[payload.listId];
        if (!listData) {
          listData = { allIds: [] };
          state.byListId[payload.listId] = listData;
        }
        listData.isLoading = true;
        listData.error = null;
      }
    },
    getPayloadsSuccess: (state, { payload }) => {
      if(payload){
        const { listId, payloads } = payload;
        const listData = state.byListId[listId];
        const [allIds, byId] = normalize(payloads, '_id');
        listData.allIds = allIds;
        listData.isLoading = false;
        listData.error = null;
        state.byId = { ...state.byId, ...byId };
      }
    },
    getPayloadFailed: (state, { payload }) => {
      if(payload){
        const listData = state.byListId[payload.listId];
        listData.isLoading = false;
        listData.error = payload.error;
        listData.allIds = [];
      }
    },
    updatePayload: (state, { payload }) => {
      if(payload){
        const p = state.byId[payload._id];
        if (p) {
          state.byId[payload._id] = { ...p, ...payload };
        }
      }
    },
    updateSelectedPayloadIds: (state, { payload }) => {
      if(payload){
        const payloadIds = (payload.ids ? payload.ids.slice() : []);
        if (payload.id) payloadIds.push(payload.id);
        if (payload.select !== undefined && !payload.select) {
          payloadIds.forEach((id) => delete state.selectedPayloadIds[id]);
        } else {
          state.selectedPayloadIds = {};
          payloadIds.forEach((id) => { state.selectedPayloadIds[id] = (new Date()).getTime(); });
        }
      }
    },
    setSelectedPayloadHistory: (state, { payload }) => {
      if(payload){
        state.selectedPayloadHistory = (payload && payload.payloadId && payload.historyId ? { ...payload } : null);
      }
      else
      { 
        state.selectedPayloadHistory = null
      }
    },
    storePayloadAutoFieldsStart: (state, { payload }) => {
      if(payload){
        state.isPayloadSaving = true;
        state.payloadError = null;
        const { payloadId, autoFields } = payload;
        const selectedPayload = state.byId[payloadId];
        selectedPayload.autoFields = autoFields;
      }
    },
    storePayloadAutoFieldsSuccess: (state, { payload }) => {
      if(payload){
        const { payloadId, autoFields } = payload;
        const p = state.byId[payloadId];
        if (p) p.autoFields = autoFields;
        state.isPayloadSaving = false;
        state.payloadError = null;
      }
    },
    storePayloadAutoFieldsFailed: (state, { payload }) => {
      if(payload){
        state.isPayloadSaving = false;
        state.payloadError = payload.error;
      }
    },
    storePayloadFlagsStart: (state, { payload }) => {
      if(payload){
        state.isPayloadSaving = true;
        state.payloadError = null;
        const { payloadId, flags } = payload;
        const selectedPayload = state.byId[payloadId];
        selectedPayload.flags = flags;
      }
    },
    storePayloadFlagsSuccess: (state, { payload }) => {
      if(payload){
        const { payloadId, flags } = payload;
        const p = state.byId[payloadId];
        if (p) p.flagged = flags;
        state.isPayloadSaving = false;
        state.payloadError = null;
      }
    },
    storePayloadFlagsFailed: (state, { payload }) => {
      if(payload){
        state.isPayloadSaving = false;
        state.payloadError = payload.error;
      }
    },
    getStandardHeadersStart: (state) => {
      state.isHeadersLoading = true;
      state.headersError = null;
    },
    getStandardHeadersFailed: (state, { payload }) => {
      if(payload){
        state.isHeadersLoading = false;
        state.headersError = payload.error;
      }
    },
    getStandardHeadersSuccess: (state, { payload }) => {
      if(payload){
        state.isHeadersLoading = false;
        state.headersError = null;
        const { payloadId, headers } = payload;
        headers.sort((a, b) => a.localeCompare(b));
        state.standardHeadersByPayloadId[payloadId] = headers;
      }
    },
    renameHeaderStart: (state) => {
      state.isHeadersSaving = true;
      state.headersError = null;
    },
    renameHeaderSuccess: (state, { payload }) => {
      if(payload){
        state.isHeadersSaving = false;
        state.headersError = null;
        const { payload: updatedPayload } = payload;
        state.byId[updatedPayload._id] = updatedPayload;
      }
    },
    renameHeaderFailed: (state, { payload }) => {
      if(payload){
        state.isHeadersSaving = false;
        state.headersError = payload.error;
      }
    },
    addHeaderStart: (state) => {
      state.isHeadersSaving = true;
      state.headersError = null;
    },
    addHeaderSuccess: (state, { payload }) => {
      if(payload){
        state.isHeadersSaving = false;
        state.headersError = null;
        const {
          payload: updatedPayload, name, index, payloadId,
        } = payload;
        if (updatedPayload) {
          state.byId[updatedPayload._id] = updatedPayload;
        } else if (payloadId && name) {
          const selectedPayload = state.byId[payloadId];
          if (selectedPayload && selectedPayload.headersOrder && selectedPayload.headers) {
            if (index !== undefined && index < selectedPayload.headersOrder.length) {
              selectedPayload.headersOrder.splice(index, 0, name);
            } else {
              selectedPayload.headersOrder.push(name);
            }
            selectedPayload.headers[name] = null;
          }
        }
      }
    },
    addHeaderFailed: (state, { payload }) => {
      if(payload){
        state.isHeadersSaving = false;
        state.headersError = payload.error;
      }
    },
    deleteTemporaryHeaders: (state, { payload }) => {
      if(payload){
        const { names, payloadId } = payload;
        const selectedPayload = state.byId[payloadId];
        if (selectedPayload && selectedPayload.headersOrder && selectedPayload.headers && names) {
          names.forEach((name) => {
            const idx = selectedPayload.headersOrder.indexOf(name);
            if (idx >= 0) selectedPayload.headersOrder.splice(idx, 1);
            delete selectedPayload.headers[name];
          });
        }
      }
    },
    deleteHeaderStart: (state) => {
      state.isHeadersSaving = true;
      state.headersError = null;
    },
    deleteHeaderSuccess: (state, { payload }) => {
      state.isHeadersSaving = false;
      state.headersError = null;
      if (payload) {
        const { payload: updatedPayload } = payload;
        if (updatedPayload) {
          state.byId[updatedPayload._id] = updatedPayload;
        }
      }
    },
    deleteHeaderFailed: (state, { payload }) => {
      if(payload){
        state.isHeadersSaving = false;
        state.headersError = payload.error;
      }
    },
    pushHeaderStart: (state) => {
      state.isHeadersSaving = true;
      state.headersError = null;
    },
    pushHeaderSuccess: (state, { payload }) => {
      if(payload){
        const { payload: updatedPayload } = payload;
        state.byId[updatedPayload._id] = updatedPayload;
        state.isHeadersSaving = false;
        state.headersError = null;
      }
    },
    pushHeaderFailed: (state, { payload }) => {
      if(payload){
        state.isHeadersSaving = false;
        state.headersError = payload.error;
      }
      
    },
    publishPayloadStart: (state) => {
      state.isPayloadSaving = true;
      state.payloadError = null;
    },
    publishPayloadSuccess: (state) => {
      state.isPayloadSaving = false;
      state.payloadError = null;
    },
    publishPayloadFailed: (state, { payload }) => {
      if(payload){
        state.isPayloadSaving = false;
        state.payloadError = payload.error;
      }
    },
    approvePayloadStart: (state) => {
      state.isPayloadSaving = true;
      state.payloadError = null;
    },
    approvePayloadSuccess: (state) => {
      state.isPayloadSaving = false;
      state.payloadError = null;
    },
    approvePayloadFailed: (state, { payload }) => {
      if(payload){
        state.isPayloadSaving = false;
        state.payloadError = payload.error;
      }
    },
    setSelectedPayloadStation: (state, { payload }) => {
      state.selectedPayloadStation = payload;
      if (payload) {
        const { payloadId } = payload;
        const p = state.byId[payloadId];
        if (p) {
          delete p.cortexMatchType;
          delete p.matchDistance;
          delete p.matchFilter;
        }
      }
    },
    setCortexMatchType: (state, { payload }) => {
      if(payload){
        const { payloadId, matchType } = payload;
        const p = state.byId[payloadId];
        if (p) p.cortexMatchType = matchType;
      }
    },
    setMatchDistance: (state, { payload }) => {
      if(payload){
        const { payloadId, distance } = payload;
        const p = state.byId[payloadId];
        if (p) p.matchDistance = distance;
      }
    },
    setMatchFilter: (state, { payload }) => {
      if(payload){
        const { payloadId, matchFilter } = payload;
        const p = state.byId[payloadId];
        if (p) {
          p.matchFilter = matchFilter;
          switch (matchFilter) {
            case GROUP_BY.CORTEX:
              p.cortexMatchType = MATCH_TYPE_DEFAULT;
              break;
            case GROUP_BY.DISTANCE:
              p.matchDistance = DISTANCE.MIN;
              break;
            default:
              break;
          }
        }
      }
    },
    getPayloadHistoryLatestStart: (state) => {
      state.isLastHistoryLoading = true;
      state.lastHistoryError = null;
    },
    getPayloadHistoryLatestSuccess: (state, { payload }) => {
      if(payload){
        const { history } = payload;
        state.isLastHistoryLoading = false;
        state.lastHistoryError = null;
        state.payloadLastHistoryRecordById[history.payloadId] = history;
      }
    },
    getPayloadHistoryLatestFailed: (state, { payload }) => {
      if(payload){
        state.isLastHistoryLoading = false;
        state.lastHistoryError = payload.error;
      }
    },
    setLastUpdated: (state, { payload }) => {
      state.lastUpdated = { timestamp: (new Date()).getTime() };
      if (payload) {
        const { payloadIds } = payload;
        state.lastUpdated.payloadIds = payloadIds.reduce((m, i) => {
          m[i] = 1;
          return m;
        }, {});
      }
    },
  },
});

export const {
  getPayloadsStart,
  getPayloadsSuccess,
  getPayloadFailed,
  updateSelectedPayloadIds,
  updatePayload,
  deletePayloadHeader,
  addPayloadHeader,
  setSelectedPayloadHistory,
  storePayloadAutoFieldsStart,
  storePayloadAutoFieldsSuccess,
  storePayloadAutoFieldsFailed,
  storePayloadFlagsStart,
  storePayloadFlagsSuccess,
  storePayloadFlagsFailed,
  getStandardHeadersStart,
  getStandardHeadersFailed,
  getStandardHeadersSuccess,
  renameHeaderStart,
  renameHeaderFailed,
  renameHeaderSuccess,
  addHeaderStart,
  addHeaderFailed,
  addHeaderSuccess,
  deleteTemporaryHeaders,
  deleteHeaderStart,
  deleteHeaderFailed,
  deleteHeaderSuccess,
  pushHeaderStart,
  pushHeaderFailed,
  pushHeaderSuccess,
  publishPayloadStart,
  publishPayloadFailed,
  publishPayloadSuccess,
  approvePayloadStart,
  approvePayloadFailed,
  approvePayloadSuccess,  
  setSelectedPayloadStation,
  getPayloadHistoryLatestStart,
  getPayloadHistoryLatestSuccess,
  getPayloadHistoryLatestFailed,
  setLastUpdated,
  setCortexMatchType,
  setMatchDistance,
  setMatchFilter,
} = slice.actions;

export default slice.reducer;

export const fetchPayloads = (listId) => (dispatch) => {
  dispatch(getPayloadsStart({ listId }));
  api.fetchPayloads(listId).then((result) => {
    dispatch(getPayloadsSuccess({ listId, payloads: result }));
  }).catch((error) => {
    dispatch(getPayloadFailed({ listId, error }));
  });
};

export const storePayloadAutoFields = (payloadId, autoFields) => (dispatch) => {
  dispatch(storePayloadAutoFieldsStart({ payloadId, autoFields }));
  const autoFieldsToSave = autoFields.filter((af) => !af.modified).map(({ modified, valid, ...af }) => af);
  api.storePayloadAutoFields(payloadId, autoFieldsToSave).then(() => {
    dispatch(storePayloadAutoFieldsSuccess({ payloadId, autoFields }));
  }).catch((error) => {
    dispatch(storePayloadAutoFieldsFailed({ payloadId, error }));
  });
};

export const storePayloadFlags = (payloadId, flags) => (dispatch) => {
  dispatch(storePayloadFlagsStart({ payloadId, flags }));
  const flagsToSave = flags.filter((af) => !af.modified).map(({ modified, valid, ...af }) => af);
  api.storePayloadFlags(payloadId, flagsToSave).then(() => {
    dispatch(storePayloadFlagsSuccess({ payloadId, flags }));
  }).catch((error) => {
    dispatch(storePayloadFlagsFailed({ payloadId, error }));
  });
};


export const fetchStandardHeaders = (payloadId) => (dispatch) => {
  dispatch(getStandardHeadersStart());
  api.fetchStandardHeaders(payloadId)
    .then((result) => dispatch(getStandardHeadersSuccess({ payloadId, headers: result })))
    .catch((error) => dispatch(getStandardHeadersFailed({ error })));
};

export const renameHeader = (payloadId, fromName, toName, approve = false) => (dispatch) => {
  dispatch(renameHeaderStart());
  api.renameHeader(payloadId, fromName, toName, approve)
    .then((payload) => dispatch(renameHeaderSuccess({ payload })))
    .catch((error) => dispatch(renameHeaderFailed({ error })));
};

export const addHeader = (payloadId, name, index, temporary) => (dispatch) => {
  dispatch(addHeaderStart());
  if (temporary) {
    dispatch(addHeaderSuccess({ payloadId, name, index }));
  } else {
    api.addHeader(payloadId, name, index)
      .then((payload) => dispatch(addHeaderSuccess({ payload })))
      .catch((error) => dispatch(addHeaderFailed({ error })));
  }
};

export const deleteHeader = (payloadId, headers) => (dispatch) => {
  dispatch(deleteHeaderStart());
  const temporaryHeaders = [];
  const headersToDelete = [];
  headers.forEach((h) => {
    const { temporary, name } = h;
    if (temporary) temporaryHeaders.push(name);
    else headersToDelete.push(name);
  });
  if (temporaryHeaders.length) {
    dispatch((deleteTemporaryHeaders({ payloadId, names: temporaryHeaders })));
  }
  if (headersToDelete.length) {
    api.deleteHeader(payloadId, headersToDelete)
      .then((payload) => dispatch(deleteHeaderSuccess({ payload })))
      .catch((error) => dispatch(deleteHeaderFailed({ error })));
  } else {
    dispatch(deleteHeaderSuccess());
  }
};

export const pushHeader = (payloadId, names) => (dispatch) => {
  dispatch(pushHeaderStart());
  api.pushHeader(payloadId, names)
    .then((payload) => dispatch(pushHeaderSuccess({ payload })))
    .catch((error) => dispatch(pushHeaderFailed({ error })));
};

export const resetPayloadViewSpecificSelection = () => (dispatch) => {
  dispatch(setSelectedPayloadHistory());
  dispatch(setSelectedPayloadStation());
};

export const getPayloadHistoryLatest = (payloadId) => (dispatch) => {
  dispatch(getPayloadHistoryLatestStart());
  api.fetchPayloadHistoryLatest(payloadId).then((result) => {
    dispatch(getPayloadHistoryLatestSuccess({ history: result }));
  }).catch((error) => dispatch(getPayloadHistoryLatestFailed({ error })));
};

export const setPayloadSelected = (payloadId, select) => (dispatch) => {
  dispatch(updateSelectedPayloadIds({ id: payloadId, select }));
  if (select === undefined || select) {
    dispatch(getPayloadHistoryLatest(payloadId));
  }
};

export const logCsvDownload = (payloadId, fileName) => () => {
  api.logCsvDownload(payloadId, fileName).catch();
};

export const invalidateSelectedPayload = (payloadId, reloadLocations) => (dispatch, getState) => {
  const { selectedPayloadIds = {} } = getState().payload;
  if (selectedPayloadIds[payloadId]) {
    dispatch(setLastUpdated({ payloadIds: [payloadId] }));
    dispatch(getPayloadHistoryLatest(payloadId));
    if (reloadLocations) {
      dispatch(invalidateLocationsByPayload({ payloadId }));
    }
  }
};

export const invalidateListPayloads = (listId) => (dispatch, getState) => {
  const { byListId = {} } = getState().payload;
  const listData = byListId[listId];
  if (listData) dispatch(fetchPayloads(listId));
};

export const approveLocations = (locations) => (dispatch) => {
  dispatch(approveLocationsStart());
  locationsApi.approveLocations(locations).then((result) => {
    const { locations: updated, payload } = result;
    dispatch(approveLocationsSuccess({ updated }));
    dispatch(updatePayload(payload));
  }).catch((error) => {
    dispatch(approveLocationsFailed({ error }));
  });
};

export const rejectLocationChanges = (locations, station, subStation) => (dispatch) => {
  dispatch(approveLocationsStart());
  locationsApi.rejectLocationChanges(locations, station, subStation).then((result) => {
    const { locations: updated, payload } = result;
    dispatch(approveLocationsSuccess({ updated }));
    dispatch(updatePayload(payload));
  }).catch((error) => {
    dispatch(approveLocationsFailed({ error }));
  });
};

export const publishQAE = (payloadId) => (dispatch) => {
  dispatch(publishPayloadStart());
  api.publishQAE(payloadId).then(() => {
    dispatch(publishPayloadSuccess());
  }).catch((error) => dispatch(publishPayloadFailed({ error })));
};

export const approve = (payloadId, station, subStation) => (dispatch) => {
  dispatch(approvePayloadStart());
  api.approve(payloadId, station, subStation).then(() => {
    dispatch(approvePayloadSuccess());
  }).catch((error) => dispatch(approvePayloadFailed({ error })));
};