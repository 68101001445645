export const setLoadingProgress = (state, { payload }) => {
  const { totalPages, page } = payload;
  state.loadingProgress = totalPages ? Math.round((page * 100) / totalPages) : null;
};

export const fetchMultiPage = (loader, fnStart, fnNextPage, fnSuccess, fnError) => {
  if (fnStart) fnStart();
  const fnFetch = (page) => {
    loader(page).then((result) => {
      fnNextPage(result);
      if (result.offset + result.docs.length < result.totalDocs) {
        fnFetch((page || 0) + 1);
      } else if (fnSuccess) fnSuccess();
    }).catch((error) => {
      if (fnError) fnError(error);
    });
  };
  fnFetch(0);
};
