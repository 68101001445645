import client from './client';
import { error } from '../helper/misc';

const fetchGeofences = (propertyId) => new Promise((resolve, reject) => {
  client.get(`geofences/${propertyId}`)
    .then((result) => resolve(result.data))
    .catch((err) => reject(error(err)));
});

const saveLocationGeofences = (locationId, property, location, geofences, force) => new Promise((resolve, reject) => {
  client.post(`geofences/save/${locationId}${force ? '?force=1' : ''}`, { property, location, geofences })
    .then((result) => resolve(result.data))
    .catch((err) => reject(error(err)));
});

export default { fetchGeofences, saveLocationGeofences };
