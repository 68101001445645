import React, { useState, useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import Button from '@material-ui/core/Button';
import { useTheme, withStyles } from '@material-ui/core/styles';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import { FixedSizeList } from 'react-window';
import { withStylesPropTypes } from '../../../helper/misc';
import {HomeScreen as FullScreen} from '../../../pages/home';


const useStyles = ((theme) => ({
  root: {
    textTransform: 'none',
    '& .MuiButton-label': {
      justifyContent: 'space-between',
    },
  },
  body: {
    overflowX: 'hidden !important',
  },
  item: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  row: {
    color: theme.palette.success.main,
    fontWeight: 'bold',
  },
  col: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  fullScreen: {
    "&:hover": {
      cursor: 'pointer'
    },
    marginTop: "3px",
    marginRight: "2px"
  },
  selectCell: {
    display: 'flex'
  },
}));

const VISIBLE_OPTIONS = 5;

function CellSelectList({
  rowCount,
  columns,
  currentCell,
  onChange,
  classes,
  dimensions,
}) {
  const [options, setOptions] = useState([]);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(-1);
  const [anchor, setAnchor] = useState();

  const theme = useTheme();
  const {sheetFullScreen,handleSheetFullScreen, customDedupe} = useContext(FullScreen);
  useEffect(() => {
    const list = [];
    for (let row = 1; row <= rowCount; row += 1) {
      columns.forEach((column, columnIdx) => {
        list.push({
          title: `${row}:${column.title || column.match}`, column: column.title || column.match, row: row - 1, col: columnIdx,
        });
      });
    }
    setOptions(list);
  }, [rowCount, columns]);

  useEffect(() => {
    let index = -1;
    if (currentCell) {
      index = options.findIndex((opt) => opt.row === currentCell.row && opt.col === currentCell.col);
    }
    setSelectedOptionIndex(index);
  }, [currentCell, columns, options]);

  const handleOpen = (event) => {
    setAnchor(event.currentTarget.parentElement);
  };

  const handleClose = () => {
    setAnchor(null);
  };

  const handleItemSelect = (index) => {
    setSelectedOptionIndex(index);
    handleClose();
    onChange(options[index]);
  };

  // eslint-disable-next-line react/prop-types
  const renderRow = ({ index, style }) => (
    <ListItem button style={style} key={index}>
      <ListItemText
        primary={options[index].title}
        // eslint-disable-next-line react/prop-types
        classes={{ root: classes.item }}
        onClick={() => handleItemSelect(index)}
      />
    </ListItem>
  );

  return (
    <>
      <div className={classes.selectCell}>
       { sheetFullScreen ?  <FullscreenExitIcon className={classes.fullScreen} onClick={() => handleSheetFullScreen()}></FullscreenExitIcon> :
         <FullscreenIcon className={classes.fullScreen} onClick={() => handleSheetFullScreen()}></FullscreenIcon>
        }
        <Button
          size="small"
          variant="text"
          endIcon={<UnfoldMoreIcon />}
          onClick={handleOpen}
          fullWidth
          classes={{ root: classes.root }}
        >
          {
            selectedOptionIndex >= 0 && options.length > selectedOptionIndex && (
            <>
              <span className={classes.row}>{options[selectedOptionIndex].row + 1}</span>
              :
              <span className={classes.col}>{options[selectedOptionIndex].column}</span>
            </>
            )
          }      
          {        
            !(selectedOptionIndex >= 0) && <span style={{display: dimensions['width'] <= 455 | customDedupe ? "none" : "initial"}}>Select Cell</span>
          }
        </Button>
      </div>
      <Popover
        anchorEl={anchor}
        onClose={handleClose}
        open={!!anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        { anchor && (
          <FixedSizeList
            className={classes.body}
            height={theme.spacing(4) * VISIBLE_OPTIONS}
            width={anchor.offsetWidth}
            itemSize={theme.spacing(4)}
            itemCount={options.length}
            initialScrollOffset={(selectedOptionIndex || 0) * theme.spacing(4)}
          >
            {renderRow}
          </FixedSizeList>
        )}
      </Popover>
    </>
  );
}

CellSelectList.defaultProps = {
  onChange: () => { },
  rowCount: 0,
  columns: [],
  currentCell: null,
};

CellSelectList.propTypes = {
  ...withStylesPropTypes,
  rowCount: PropTypes.number,
  columns: PropTypes.arrayOf(PropTypes.shape()),
  currentCell: PropTypes.shape(),
  onChange: PropTypes.func,
};

export default withStyles(useStyles)(CellSelectList);
