import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import InputBase from '@material-ui/core/InputBase';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import SUPPORTED_FORMULAS, { parseFormula } from '../../../const/formulas';
import { withStylesPropTypes } from '../../../helper/misc';
import KeyboardHandle from '../../../helper/keyboard';
import { TextareaAutosize} from '@material-ui/core';


const useStyles = ((theme) => ({
  input: {
    position : 'relative',
    height: '100%',
    backgroundColor: theme.palette.background.paper,
    '&>input': {
      padding: 0,
      height: '100%',
    },
  },
  textArea: {
    position : 'absolute',
  },
  hint: {
    padding: theme.spacing(1),
  },
  highlight: {
    fontWeight: 'bold',
  },
  description: {
    marginTop: theme.spacing(1),
    fontStyle: 'italic',
    fontSize: '0.8em',
  },
}));

const MAX_AUTOSUGGEST = 10;

function CellInput({
  classes,
  value,
  appendedValue,
  autoFocus,
  disabled,
  onCancel,
  onCommit,
  onEditRange,
  onChange,
  expand,
  selection: initialSelection,
}) {
  const [inputRef, setInputRef] = useState();
  const [selection, setSelection] = useState(null);
  const [autoSuggest, setAutoSuggest] = useState();
  const [autoSuggestHighlight, setAutoSuggestHighlight] = useState(-1);

  const isFocused = useCallback(() => document.activeElement === inputRef, [inputRef]);

  const handleAutoCompleteHighlight = () => {
    if (autoSuggest && autoSuggest.length) {
      const f = parseFormula(inputRef.value);
      if (f) {
        const { func, args } = f;
        const pos = inputRef.selectionStart;
        let highlight = -1;
        if (pos >= 0 && pos <= func.length + 1) {
          highlight = 0;
        } else if (pos <= func.length + args.length + 2) {
          const startPos = func.length + 2;
          let curPos = 0;
          for (let argNum = 1; ; argNum += 1) {
            const nextPos = args.indexOf(';', curPos);
            if (nextPos === -1 || (pos >= curPos + startPos && pos < nextPos + startPos)) {
              highlight = argNum;
              break;
            }
            curPos = nextPos + 1;
          }
        }
        setAutoSuggestHighlight(highlight);
      }
    }
  };

const handleEditRange = useCallback((val) => {
  let value = val
  let variants;
  if (isFocused()) {
    if (typeof value !== 'string') {
      value = value.toString()
    } 
    const isFormula = (value && value.indexOf('=') === 0);
    onEditRange(isFormula);
    const f = parseFormula(val);
    if (f) {
      const { func } = f;
      variants = SUPPORTED_FORMULAS;
      if (func.length) variants = variants.filter((v) => v.name.toUpperCase().indexOf(func) === 0);
      variants.sort((a, b) => a.name.localeCompare(b.name));
      if (!variants.length) variants = undefined;
      else if (variants.length > MAX_AUTOSUGGEST) variants.splice(MAX_AUTOSUGGEST, variants.length);
    }
  }
  setAutoSuggest(variants);
}, [onEditRange, isFocused]);

  const handleChange = (val) => {
    onChange(val);
  };

  useEffect(() => {
    handleEditRange(value);
  }, [value, handleEditRange]);

  useEffect(() => {
    if (initialSelection) setSelection(initialSelection);
  }, [initialSelection]);

  useEffect(() => {
    if (inputRef && autoFocus) {
      const curValue = inputRef.value;
      if (appendedValue) {
        let left;
        let right;
        let selectionStart;
        if (selection) {
          left = curValue.substring(0, selection.start);
          right = curValue.substring(selection.end);
          selectionStart = selection.start;
        } else {
          selectionStart = inputRef.selectionStart || curValue.length;
          left = curValue.substring(0, selectionStart);
          right = curValue.substring(selectionStart);
        }

        handleChange([left, appendedValue, right].join(''));
        setSelection({ start: selectionStart, end: selectionStart + appendedValue.length });
      } else {
        setSelection(null);
      }
      inputRef.focus();
    }
  },
  // eslint-disable-next-line
  [appendedValue, inputRef, autoFocus]);

  useEffect(() => {
    if (inputRef && autoFocus) {
      if (selection) inputRef.setSelectionRange(selection.start, selection.end);
      else {
        const len = inputRef.value.length;
        inputRef.setSelectionRange(len, len);
      }
      inputRef.focus();
    }
  },
  [selection, inputRef, autoFocus]);

  const handleCommit = () => {
    onCommit(value);
    if (inputRef) inputRef.blur();
  };

  const handleCancel = () => {
    onCancel(value);
    if (inputRef) inputRef.blur();
  };

  const handleKeyDown = (e) => {
    KeyboardHandle(e, {
      onEsc: () => handleCancel(value),
      onEnter: [() => handleCommit(), false, false],
      onUp: [() => handleCommit(), false, false],
      onDown: [() => handleCommit(), false, false],
      default: [() => {}, true, false],
    });
  };

  const handleDropDownKeyDown =(e) => {
    KeyboardHandle(e, {
      onEsc: () => handleCancel(value),
      onEnter: [() => handleCommit(), false, false],
      default: [() => {}, true, false],
    });
  };


  const handleAutoSuggest = (event, formula) => {
    event.stopPropagation();
    event.preventDefault();
    const exp = `=${formula}()`;
    handleChange(exp);
    const idx = exp.length - 1;
    setSelection({ start: idx, end: idx });
    handleAutoCompleteHighlight();
  };

  const handleMouseDown = (e) => {
    e.stopPropagation();
  };

  const handleMouseUp = (e) => {
    handleChange(e.target.value);
    handleAutoCompleteHighlight();
  };

  return (
    <>
    {!expand && <>
      <InputBase
        fullWidth
        autoFocus={autoFocus}
        disabled={disabled}
        size="small"
        variant="standard"
        label=""
        margin="none"
        value={value}
        className={classes.input}
        onChange={(e) => handleChange(e.target.value)}
        onMouseDown={(e) => handleMouseDown(e)}
        onMouseUp={(e) => handleMouseUp(e)}
        onKeyDown={(e) => handleKeyDown(e)}
        onKeyUp={() => handleAutoCompleteHighlight()}
        inputRef={(ref) => setInputRef(ref)}
      />
      {
        autoSuggest && (
          <Popper
            open
            anchorEl={inputRef}
            placement="bottom-start"
            modifiers={{
              flip: {
                enabled: false,
              },
            }}
          >
            <Paper>
              <>
                { autoSuggest.length > 1 && autoSuggest.map((v) => (
                  <div key={v.name}>
                    <Button fullWidth size="small" onMouseDown={(e) => e.stopPropagation()} onClick={(e) => handleAutoSuggest(e, v.name)}>{v.name}</Button>
                  </div>
                ))}
                {
                  autoSuggest.length === 1 && (
                    <div className={classes.hint}>
                      <div>
                        { autoSuggest[0].parts.map((p) => (
                          <span key={p.index} className={clsx(p.index === autoSuggestHighlight && classes.highlight)}>{p.token}</span>
                        ))}
                      </div>
                      <div className={classes.description}>{autoSuggest[0].description}</div>
                    </div>
                  )
                }
              </>
            </Paper>
          </Popper>
        )}
      </>
        }
        {expand && 
        <div className = {classes.input}>
         <TextareaAutosize
         rows = {5}
         rowsMax = {5}
         style = {{width:"100%" , maxWidth:"100%" , minWidth:"100%"}}
         className = {classes.textArea}
         value={value}
         onChange={(e) => handleChange(e.target.value)}
         onKeyDown={(e) => handleDropDownKeyDown(e)}
         />
        </div>
        }
    </>
  );
}

CellInput.defaultProps = {
  value: '',
  appendedValue: null,
  selection: null,
  autoFocus: false,
  disabled: false,
  onChange: () => { },
};

CellInput.propTypes = {
  ...withStylesPropTypes,
  value: PropTypes.string,
  appendedValue: PropTypes.string,
  selection: PropTypes.shape(),
  onCancel: PropTypes.func.isRequired,
  onCommit: PropTypes.func.isRequired,
  onEditRange: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
};


export default withStyles(useStyles)(CellInput);
