import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { selectSelectedPayloadsWithHistoryAndSelectedStation } from '../../app/payloadSelectors';
import MapWidgetQA from './mapwidgetqa';
import MapWidgetALIMatching from './mapwidgetalimatching';


function MapWidget({
  payloads,
  aliMatchesMode: aliMatchesModeInitial,
}) {
  const [aliMatchesMode, setAliMatchesMode] = useState(aliMatchesModeInitial);
  useEffect(() => {
    if (aliMatchesModeInitial) {
      setAliMatchesMode(true);
    } else {
      setAliMatchesMode(payloads && payloads.length === 1 && !!payloads[0].matchFilter);
    }
  }, [payloads, aliMatchesModeInitial]);


  return aliMatchesMode ? <MapWidgetALIMatching /> : <MapWidgetQA />;
}


MapWidget.defaultProps = {
  aliMatchesMode: false,
  payloads: undefined,
};

MapWidget.propTypes = {
  aliMatchesMode: PropTypes.bool,
  payloads: PropTypes.arrayOf(PropTypes.shape()),
};

const mapStateToProps = (state, props) => ({
  payloads: selectSelectedPayloadsWithHistoryAndSelectedStation(state, props),
});

export default connect(mapStateToProps, {
})(MapWidget);
