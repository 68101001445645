import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { googlePathToCoordinates, coordinatesToGooglePath } from '../helper';
import { commonDefaulProps, commonPropTypes } from '../common';

window.google = window.google || {};
const { google } = window;

const Polygon = ({
  mapRef,
  coordinates,
  zIndex,
  strokeColor,
  strokeOpacity,
  strokeWeight,
  fillColor,
  fillOpacity,
  data,
  editable,
  selectedVertexIndex,
  onEdited,
  onClick,
}) => {
  const [polygonRef, setPolygonRef] = useState();
  const [lastEdited, setLastEdited] = useState();

  useEffect(() => {
    const polygon = new google.maps.Polygon({
      map: mapRef,
    });
    setPolygonRef(polygon);

    return () => {
      polygon.setMap(null);
    };
  }, [mapRef]);

  useEffect(() => {
    if (polygonRef) {
      const path = coordinatesToGooglePath(coordinates);
      polygonRef.setPath(path);
    }
  }, [polygonRef, coordinates]);

  useEffect(() => {
    if (polygonRef) {
      polygonRef.setOptions({
        zIndex,
        strokeColor,
        strokeOpacity,
        strokeWeight,
        fillColor,
        fillOpacity,
        draggable: editable,
        editable,
      });
    }
  }, [polygonRef, zIndex, strokeColor, strokeOpacity, strokeWeight, fillColor, fillOpacity, editable]);

  useEffect(() => {
    if (mapRef && polygonRef && selectedVertexIndex >= 0) {
      const path = polygonRef.getPath();
      if (path.getLength() > selectedVertexIndex) {
        const v = path.getAt(selectedVertexIndex);
        const marker = new google.maps.Marker({
          position: v,
          zIndex: 0,
          map: mapRef,
          icon: {
            path: google.maps.SymbolPath.CIRCLE,
            scale: 8.5,
            strokeColor: '#00FF00',
            fillColor: '#00FF00',
            fillOpacity: 0.4,
            strokeWeight: 0.4,
          },
        });
        return () => {
          marker.setMap(null);
        };
      }
    }
    return undefined;
  }, [selectedVertexIndex, polygonRef, mapRef, lastEdited]);

  const handleEdited = useCallback((change) => {
    setLastEdited((new Date()).getTime());
    onEdited({
      data,
      coordinates: googlePathToCoordinates(polygonRef.getPath()),
      ...change,
    });
  }, [onEdited, data, polygonRef]);

  const handleClick = useCallback((vertexIndex) => {
    onClick({
      data,
      vertexIndex,
    });
  }, [onClick, data]);

  const handleVertexDelete = useCallback((pathIndex, vertexIndex) => {
    if (polygonRef) {
      const path = polygonRef.getPaths().getAt(pathIndex);
      if (path.getLength() > 3) path.removeAt(vertexIndex);
    }
  }, [polygonRef]);

  useEffect(() => {
    if (polygonRef) {
      google.maps.event.addListener(polygonRef, 'dblclick', (e) => {
        const { path, vertex } = e;
        if (path >= 0 && vertex >= 0) handleVertexDelete(path, vertex);
      });
      polygonRef.getPaths().forEach((path, pathIdx) => {
        google.maps.event.addListener(path, 'remove_at', (vertexIdx) => {
          handleEdited({ remove: { pathIdx, vertexIdx } });
        });

        google.maps.event.addListener(path, 'insert_at', (vertexIdx) => {
          handleEdited({ insert: { pathIdx, vertexIdx } });
        });

        google.maps.event.addListener(path, 'set_at', (vertexIdx) => {
          handleEdited({ set: { pathIdx, vertexIdx } });
        });
      });

      return () => {
        google.maps.event.clearListeners(polygonRef, 'dblclick');
        polygonRef.getPaths().forEach((path) => {
          google.maps.event.clearListeners(path, 'insert_at');
          google.maps.event.clearListeners(path, 'remove_at');
          google.maps.event.clearListeners(path, 'set_at');
        });
      };
    }
    return undefined;
  }, [polygonRef, handleEdited, handleVertexDelete]);

  useEffect(() => {
    if (polygonRef) {
      google.maps.event.addListener(polygonRef, 'click', (e) => {
        const { vertex } = e;
        handleClick(vertex);
      });
      return () => {
        google.maps.event.clearListeners(polygonRef, 'click');
      };
    }
    return undefined;
  }, [polygonRef, handleClick]);

  return (
    <></>
  );
};


Polygon.defaultProps = {
  ...commonDefaulProps,
  mapRef: undefined,
  selectedVertexIndex: undefined,
};

Polygon.propTypes = {
  ...commonPropTypes,
  mapRef: PropTypes.shape(),
  zIndex: PropTypes.number.isRequired,
  strokeColor: PropTypes.string.isRequired,
  strokeOpacity: PropTypes.number.isRequired,
  strokeWeight: PropTypes.number.isRequired,
  fillColor: PropTypes.string.isRequired,
  fillOpacity: PropTypes.number.isRequired,
  coordinates: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.number),
  ).isRequired,
  selectedVertexIndex: PropTypes.number,
  onClick: PropTypes.func.isRequired,
};

export default Polygon;
