import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { withStylesPropTypes } from '../../helper/misc';
import { fieldToMatch } from '../../app/listSlice';
import { selectListById } from '../../app/listSelectors';
import STANDARD_HEADERS from '../../const/headers';
import { GROUP_BY } from '../../const/alimatch';


const useStyles = ((theme) => ({
  button: {
    borderRadius: 0,
    boxShadow: 'none',
    height: '100%',
    marginTop: -3,
  },
  selected: {
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
}));

function FieldToMatchWeight({
  classes, selected, canMatch, listId, fieldKey, match, canEdit, fnFieldToMatch,
}) {
  const handleClick = () => {
    fnFieldToMatch({
      listId,
      field: fieldKey,
      selected: !selected,
    });
  };

  const addressField = [
    STANDARD_HEADERS.ADDRESS,
    STANDARD_HEADERS.ADDRESS_2,
    STANDARD_HEADERS.ADDRESS_3,
    STANDARD_HEADERS.CITY,
    STANDARD_HEADERS.STATE,
    STANDARD_HEADERS.ZIP,
    STANDARD_HEADERS.COUNTRY,
    STANDARD_HEADERS.COUNTRY_CODE,
    STANDARD_HEADERS.STORE_NAME,
  ].indexOf(match) >= 0;

  return (
    <>
      <Button
        disabled={!canEdit || !listId || addressField || !match || !canMatch}
        fullWidth
        variant={selected ? 'contained' : 'text'}
        onClick={handleClick}
        color="primary"
        size="small"
        classes={{
          root: clsx(classes.button, (selected || addressField) && classes.selected),
        }}
      >
        { (selected || addressField) ? 'Added' : 'Add+' }
      </Button>
    </>
  );
}

FieldToMatchWeight.defaultProps = {
  canEdit: true,
  listId: null,
  match: null,
};

FieldToMatchWeight.propTypes = {
  ...withStylesPropTypes,
  canEdit: PropTypes.bool,
  fieldKey: PropTypes.string.isRequired,
  match: PropTypes.string,
  listId: PropTypes.number,
};


const selectFieldKey = (state, props) => props.fieldKey;
const selectListId = (state, props) => props.listId;

const selectCanMatch = createSelector(
  [selectListId, selectListById],
  (listId, listById) => {
    let ret = GROUP_BY.CORTEX;
    if (listId) {
      const list = listById[listId];
      if (list && list.matchFilter) ret = list.matchFilter;
    }
    return ret === GROUP_BY.CORTEX;
  },
);

const selectFieldToMatch = createSelector(
  [selectListId, selectListById, selectFieldKey],
  (listId, listById, fieldKey) => {
    let ret;
    if (listId) {
      const list = listById[listId];
      if (list) {
        ret = !!(list.fieldsToMatch && list.fieldsToMatch[fieldKey]);
      }
    }
    return ret;
  },
);

const mapStateToProps = (state, props) => ({
  selected: selectFieldToMatch(state, props),
  canMatch: selectCanMatch(state, props),
});

export default connect(mapStateToProps, {
  fnFieldToMatch: fieldToMatch,
})(withStyles(useStyles)(FieldToMatchWeight));
