import { cellToTextRef, textRefToCell, equalCells } from './cellsmisc';

const getMinMax = (array) => array.reduce((mm, item) => {
  const val = Number(item);
  if (mm.min === null || mm.min > val) mm.min = val;
  if (mm.max === null || mm.max < val) mm.max = val;
  return mm;
}, { min: null, max: null });


export const resetRange = () => ({ cells: {}, rows: {}, columns: {} });

export const isSingleCellRange = (range) => {
  let ret = null;
  if (Object.keys(range.columns).length === 0 && Object.keys(range.rows).length === 0) {
    const rows = Object.keys(range.cells);
    if (rows.length === 1) {
      const [row] = rows;
      const cols = Object.keys(range.cells[row]);
      if (cols.length === 1) {
        const [col] = cols;
        ret = { row, col };
      }
    }
  }
  return ret;
};

export const getRangeCounts = (range) => ({
  columns: Object.keys(range.columns).length,
  rows: Object.keys(range.rows).length,
  cells: Object.values(range.cells).reduce((sum, cellsInRow) => sum + Object.keys(cellsInRow).length, 0),
});

export const addRowToRange = (range, cell) => ({ ...range, rows: { ...range.rows, [cell.row]: true } });

export const addColumnToRange = (range, cell) => ({ ...range, columns: { ...range.columns, [cell.col]: true } });

export const addColumnsToRange = (range, col1, col2) => {
  const newRange = { ...range };
  const minCol = col1 < col2 ? col1 : col2;
  const maxCol = col1 > col2 ? col1 : col2;
  for (let colIdx = minCol; colIdx <= maxCol; colIdx += 1) {
    newRange.columns[colIdx] = true;
  }
  return newRange;
};

export const addCellToRange = (range, cell) => {
  const newRange = { ...range };
  let cellsInRow = newRange.cells[cell.row];
  if (!cellsInRow) {
    cellsInRow = {};
    newRange.cells[cell.row] = cellsInRow;
  }
  cellsInRow[cell.col] = true;
  return newRange;
};

export const addCellsToRange = (range, cell1, cell2) => {
  const newRange = { ...range };
  const minRow = cell1.row < cell2.row ? cell1.row : cell2.row;
  const maxRow = cell1.row > cell2.row ? cell1.row : cell2.row;
  const minCol = cell1.col < cell2.col ? cell1.col : cell2.col;
  const maxCol = cell1.col > cell2.col ? cell1.col : cell2.col;

  for (let row = minRow; row <= maxRow; row += 1) {
    let cellsInRow = newRange.cells[row];
    if (!cellsInRow) {
      cellsInRow = {};
      newRange.cells[row] = cellsInRow;
    }
    for (let col = minCol; col <= maxCol; col += 1) {
      cellsInRow[col] = true;
    }
  }
  return newRange;
};

export const mergeRanges = (range1, range2) => {
  const newRange = {
    columns: { ...range1.columns, ...range2.columns },
    rows: { ...range1.rows, ...range2.rows },
    cells: { ...range1.cells },
  };
  Object.keys(range2.cells).forEach((row) => {
    if (!newRange.rows[row]) {
      let cellsInRow = newRange.cells[row];
      if (!cellsInRow) {
        cellsInRow = {};
        newRange.cells[row] = cellsInRow;
      }
      Object.keys(range2.cells[row]).forEach((col) => {
        if (!newRange.columns[col]) cellsInRow[col] = true;
      });
      if (Object.keys(cellsInRow).length === 0) delete newRange.cells[row];
    }
  });
  return newRange;
};

export const subtractRanges = (range1, range2) => {
  const newRange = resetRange();

  Object.keys(range1.columns).forEach((col) => {
    if (!range2.columns[col]) newRange.columns[col] = true;
  });

  Object.keys(range1.rows).forEach((row) => {
    if (!range2.rows[row]) newRange.rows[row] = true;
  });

  Object.keys(range1.cells).forEach((row) => {
    if (!range2.cells[row]) {
      newRange.cells[row] = { ...range1.cells[row] };
    } else {
      const cellsInRow = range2.cells[row];
      const newCellsInRow = {};
      Object.keys(range1.cells[row]).forEach((col) => {
        if (!cellsInRow[col]) newCellsInRow[col] = true;
      });
      if (Object.keys(newCellsInRow).length) newRange.cells[row] = newCellsInRow;
    }
  });
  return newRange;
};

export const isCellInRange = ({
  range, col, row, totalRows, totalColumns,
}) => {
  let ret = false;

  if (range.rows[row]) {
    ret = totalColumns ? col >= 0 && col < totalColumns : true;
  } else if (range.columns[col]) {
    ret = totalRows ? row >= 0 && row < totalRows : true;
  } else {
    const cellsInRow = range.cells[row];
    ret = cellsInRow && !!cellsInRow[col];
  }
  return ret;
};

export const isColumnInRange = ({ range, col }) => !!range.columns[col];

export const getColumnsMinMax = (range) => getMinMax(Object.keys(range.columns));

export const enumerateColumns = ({ range, totalColumns }) => Object.keys(range.columns).map((idx) => Number(idx)).filter((idx) => !totalColumns || idx < totalColumns);

export const enumerateCells = ({ range, totalRows, totalColumns }) => {
  const ret = [];

  const seen = {};
  const fnUseCell = (col, row) => {
    const key = `${row},${col}`;
    if (!seen[key]) {
      seen[key] = true;
      ret.push({ row: Number(row), col: Number(col) });
    }
  };

  if (totalColumns) {
    Object.keys(range.rows).forEach((row) => {
      for (let col = 0; col < totalColumns; col += 1) {
        fnUseCell(col, row);
      }
    });
  }

  if (totalRows) {
    Object.keys(range.columns).forEach((col) => {
      for (let row = 0; row < totalRows; row += 1) {
        fnUseCell(col, row);
      }
    });
  }

  Object.keys(range.cells).forEach((row) => {
    Object.keys(range.cells[row]).forEach((col) => {
      fnUseCell(col, row);
    });
  });

  ret.sort((c1, c2) => {
    let comp = c1.row - c2.row;
    if (comp === 0) comp = c1.col - c2.col;
    return comp;
  });

  return ret;
};


export const enumerateFixedCells = ({ range, getRowKeys, getColumnKeys }) => {
  const ret = [];

  const seen = {};
  const fnUseCell = (col, row) => {
    const key = `${row},${col}`;
    if (!seen[key]) {
      seen[key] = true;
      ret.push({ row, col });
    }
  };

  if (getColumnKeys) {
    Object.keys(range.rows).forEach((row) => {
      getColumnKeys().forEach((col) => fnUseCell(col, row));
    });
  }

  if (getRowKeys) {
    Object.keys(range.columns).forEach((col) => {
      getRowKeys().forEach((row) => fnUseCell(col, row));
    });
  }

  Object.keys(range.cells).forEach((row) => {
    Object.keys(range.cells[row]).forEach((col) => {
      fnUseCell(col, row);
    });
  });
  return ret;
};

export const getSimpleRangeBox = (range, totalRows, totalColumns) => {
  const rows = Object.keys(range.rows);
  const columns = Object.keys(range.columns);
  const cellsInRow = Object.keys(range.cells);
  if ((rows.length ? 1 : 0) + (columns.length ? 1 : 0) + (cellsInRow.length ? 1 : 0) === 1) {
    if (rows.length) {
      const { min, max } = getMinMax(rows);
      return {
        cell1: { row: min, col: 0 },
        cell2: { row: max, col: totalColumns - 1 },
      };
    }

    if (columns.length) {
      const { min, max } = getMinMax(columns);
      return {
        cell1: { row: 0, col: min },
        cell2: { row: totalRows - 1, col: max },
      };
    }

    if (cellsInRow.length) {
      let minRow;
      let maxRow;
      let minCol;
      let maxCol;

      cellsInRow.forEach((row) => {
        const nrow = Number(row);
        if (minRow === undefined || minRow > nrow) minRow = nrow;
        if (maxRow === undefined || maxRow < nrow) maxRow = nrow;
        const { min, max } = getMinMax(Object.keys(range.cells[row]));
        if (minCol === undefined || minCol > min) minCol = min;
        if (maxCol === undefined || maxCol < max) maxCol = max;
      });

      return {
        cell1: { row: minRow, col: minCol },
        cell2: { row: maxRow, col: maxCol },
      };
    }
  }
  return null;
};


export const simpleRangeToTextRef = (range, totalRows, totalColumns) => {
  const ret = [];
  if (range) {
    const box = getSimpleRangeBox(range, totalRows, totalColumns);
    if (box) {
      ret.push(cellToTextRef(box.cell1));
      if (!equalCells(box.cell1, box.cell2)) {
        ret.push(cellToTextRef(box.cell2));
      }
    }
  }
  return ret.join(':');
};

export const textRefToRange = (ref) => {
  const cellRefs = ref.split(':');
  if (cellRefs.length === 1) {
    const cell = textRefToCell(cellRefs[0]);
    if (cell) return addCellToRange(resetRange(), cell);
  } else if (cellRefs.length === 2) {
    const cell1 = textRefToCell(cellRefs[0]);
    const cell2 = textRefToCell(cellRefs[1]);
    if (cell1 && cell2) return addCellsToRange(resetRange(), cell1, cell2);
  }
  return null;
};

export const getCellInRangePosition = ({
  range, col, row, totalRows, totalColumns,
}) => {
  const inside = isCellInRange({ range, col, row });
  let left = false;
  let right = false;
  let top = false;
  let bottom = false;
  if (inside) {
    left = !isCellInRange({
      range, totalRows, totalColumns, col: col - 1, row,
    });
    right = !isCellInRange({
      range, totalRows, totalColumns, col: col + 1, row,
    });
    top = !isCellInRange({
      range, totalRows, totalColumns, col, row: row - 1,
    });
    bottom = !isCellInRange({
      range, totalRows, totalColumns, col, row: row + 1,
    });
  }
  return {
    inside,
    left,
    right,
    top,
    bottom,
  };
};

export const RANGE_ACTIONS = {
  RESET: 'reset',
  ADD_ROW: 'add_row',
  ADD_COLUMN: 'add_column',
  ADD_COLUMN_RANGE: 'add_column_range',
  ADD_CELL: 'add_cell',
  ADD_CELL_RANGE: 'add_cell_range',
  MERGE: 'merge',
};


export const rangeReducer = (state, action) => {
  switch (action.type) {
    case RANGE_ACTIONS.RESET:
      return resetRange();
    case RANGE_ACTIONS.ADD_ROW:
      return addRowToRange(state, action);
    case RANGE_ACTIONS.ADD_COLUMN:
      return addColumnToRange(state, action);
    case RANGE_ACTIONS.ADD_COLUMN_RANGE:
      return addColumnsToRange(state, action.col1, action.col2);
    case RANGE_ACTIONS.ADD_CELL:
      return addCellToRange(state, action);
    case RANGE_ACTIONS.ADD_CELL_RANGE:
      return addCellsToRange(state, action.cell1, action.cell2);
    case RANGE_ACTIONS.MERGE:
      return mergeRanges(state, action.range);
    default:
      throw new Error();
  }
};
