export const parseFormula = (exp) => {
  const m = /^=(\w*)\(?(.*?)\)?$/.exec(exp);
  return m ? { func: m[1].toUpperCase(), args: m[2], exp: m[0] } : undefined;
};

const FORMULAS = [
  { template: '=COUNTBLANK([range])', description: 'Use this function to count the number of empty cells in a range of cells' },
  { template: '=COUNTIF([range];"criteria")', description: 'Use this function to count the number of cells that meet a criterion in a range of cells' },
  { template: '=IF(logical_test;"true value";"false value")', descripton: 'This function runs a logical test and returns one value for a TRUE result, and another for a FALSE result' },
  { template: '=CONCATENATE("text1";"text2";...)', description: 'Use this function to join two or more text strings into one string' },
  { template: '=LEFT("text";num_chars)', description: 'Returns the first character or characters in a text string, based on the number of characters you specify' },
  { template: '=RIGHT("text";num_chars)', description: 'Returns the last character or characters in a text string, based on the number of characters you specify' },
];

FORMULAS.forEach((f) => {
  const { func, args } = parseFormula(f.template);
  f.name = func;
  f.parts = [
    { token: '=', index: -1 },
    { token: func, index: 0 },
    { token: '(', index: -2 },
  ];
  const argList = args.split(';');
  argList.forEach((a, idx) => {
    f.parts.push({ token: a, index: idx + 1 });
    f.parts.push({ token: idx === argList.length - 1 ? ')' : ';', index: -idx - 3 });
  });
});

FORMULAS.sort((a, b) => a.name.localeCompare(b.name));

export default FORMULAS;
