import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PayloadFieldsEditor from './payloadfieldseditor';
import { storePayloadFlags } from '../../app/payloadSlice';

const RULES = {
  CONTAIN: 'contains',
  NOT_CONTAIN: 'notContains',
};


function Flags({
  payload,
  fnStorePayloadFlags,
}) {
  return (
    <PayloadFieldsEditor
      records={payload.flags}
      createNewRecord={() => ({ condition: {} })}
      validateRecord={(r) => r.name && r.condition && r.condition.field && r.condition.value && r.condition.operator}
      saveRecords={(records) => fnStorePayloadFlags(payload._id, records)}
      fields={[
        {
          title: 'Name', size: 4, key: 'name', text: true,
        },
        {
          title: 'If', size: 1, key: 'if',
        },
        {
          title: 'Field', size: 2, key: 'condition.field', select: Object.keys(payload.headers || {}).map((h) => ([h, payload.headers[h]])),
        },
        {
          title: 'Rule', size: 2, key: 'condition.operator', select: [[RULES.CONTAIN, 'Contains'], [RULES.NOT_CONTAIN, 'Doesn\'t contain']],
        },
        {
          title: 'Criteria', size: 2, key: 'condition.value', text: true,
        },
      ]}
    />
  );
}

Flags.propTypes = {
  payload: PropTypes.shape().isRequired,
  fnStorePayloadFlags: PropTypes.func.isRequired,
};

export default connect(null, {
  fnStorePayloadFlags: storePayloadFlags,
})(Flags);
