const HEADERS = {
  LATITUDE: 'latitude',
  LONGITUDE: 'longitude',
  ADDRESS: 'address',
  ADDRESS_2: 'address_line_2',
  ADDRESS_3: 'address_line_3',
  CITY: 'city',
  STATE: 'state',
  ZIP: 'zip_code',
  COUNTRY: 'country',
  COUNTRY_CODE: 'country_code',
  COUNTY: 'county',
  DISTRIBUTOR_NAME: 'distributor_name',
  STORE_TYPE: 'store_type',
  STORE_NAME: 'store_name',
  STORE_NUMBER: 'store_number',
  PHONE_NUMBER: 'phone_number',
  FAX_NUMBER: 'fax_number',
  EMAIL: 'email_address',
  GEO_ACCURACY: 'geo_accuracy',
};

export const DEFAULT_HEADERS_ORDER = [
  HEADERS.DISTRIBUTOR_NAME,
  HEADERS.STORE_TYPE,
  HEADERS.STORE_NAME,
  HEADERS.STORE_NUMBER,
  HEADERS.ADDRESS,
  HEADERS.ADDRESS_2,
  HEADERS.ADDRESS_3,
  HEADERS.CITY,
  HEADERS.STATE,
  HEADERS.ZIP,
  HEADERS.COUNTRY,
  HEADERS.COUNTRY_CODE,
  HEADERS.COUNTY,
  HEADERS.PHONE_NUMBER,
  HEADERS.FAX_NUMBER,
  HEADERS.EMAIL,
  HEADERS.GEO_ACCURACY,
  HEADERS.LATITUDE,
  HEADERS.LONGITUDE,
];

export default HEADERS;
