import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';
import { withStylesPropTypes } from '../../helper/misc';
import Pager from '../common/pager';
import {
  MATCH_TYPES, MATCH_TYPES_LIST, GROUP_BY, DISTANCE, DISTANCE_LIST,
} from '../../const/alimatch';

const useStyles = ((theme) => ({
  root: {
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
    overflowY: 'auto',
    height: '100%',
  },
  optionGroup: {
    display: 'flex',
    alignItems: 'center',
    '&>.MuiFormControlLabel-root': {
      width: 200,
    },
  },
  slider: {
    width: 200,
    '& .MuiSlider-root': {
      height: theme.spacing(1),
    },
    '& .MuiSlider-rail': {
      height: theme.spacing(1),
      borderRadius: theme.spacing(0.5),
    },
    '& .MuiSlider-track': {
      height: theme.spacing(1),
      borderRadius: theme.spacing(0.5),
    },
    '& .MuiSlider-thumb': {
      height: theme.spacing(3),
      width: theme.spacing(3),
      backgroundColor: 'currentColor',
      border: '2px solid currentColor',
      marginTop: -theme.spacing(1),
      marginLeft: -theme.spacing(1.5),
      '&:focus, &:hover': {
        boxShadow: 'inherit',
      },
    },
    '& .MuiSlider-mark': {
      height: theme.spacing(1),
      width: theme.spacing(1),
      borderRadius: theme.spacing(0.5),
      marginLeft: -theme.spacing(0.5),
    },
    '& .MuiSlider-marked': {
      marginBottom: 0,
    },
    '& .MuiSlider-markLabel': {
      display: 'none',
      color: theme.palette.primary.main,
    },
    '& .MuiSlider-markLabel:nth-child(5)': {
      display: 'block',
      top: theme.spacing(1),
      transform: 'translateX(-100%)',
      paddingRight: theme.spacing(2),
    },
    '& .MuiSlider-markLabel:nth-last-child(2)': {
      display: 'block',
      top: theme.spacing(1),
      transform: 'none',
      paddingLeft: theme.spacing(2),
    },
  },
}));


function ALIMatching({
  classes,
  label,
  hiddenStations,
  matchFilter: matchFilterInit,
  currentGroupIndex: currentGroupIndexInit,
  cortexMatchType: cortexMatchTypeInit,
  matchDistance: matchDistanceInit,
  showCoreColumns: showCoreColumnsInit,
  aliMatches,
  onMatchFilterChange,
  onCurrentGroupIndexChange,
  onCortextMatchTypeChange,
  onMatchDistanceChange,
  onShowCoreColumnsChange,
}) {
  const [matchFilter, setMatchFilter] = useState(matchFilterInit);
  const [currentGroupIndex, setCurrentGroupIndex] = useState(currentGroupIndexInit);
  const [cortexMatchType, setCortexMatchType] = useState(cortexMatchTypeInit);
  const [cortexMatchTypeCurrent, setCortexMatchTypeCurrent] = useState(0);
  const [matchDistance, setMatchDistance] = useState(matchDistanceInit);
  const [matchDistanceCurrent, setMatchDistanceCurrent] = useState(matchDistanceInit);
  const [showCoreColumns, setShowCoreColumns] = useState(showCoreColumnsInit);

  const [matchCount, setMatchCount] = useState(0);

  useEffect(() => {
    setMatchFilter(matchFilterInit);
  }, [matchFilterInit]);

  useEffect(() => {
    onMatchFilterChange(matchFilter);
  }, [matchFilter, onMatchFilterChange]);

  useEffect(() => {
    setCurrentGroupIndex(currentGroupIndexInit);
  }, [currentGroupIndexInit]);

  useEffect(() => {
    onCurrentGroupIndexChange(currentGroupIndex);
  }, [currentGroupIndex, onCurrentGroupIndexChange]);

  useEffect(() => {
    setCortexMatchType(cortexMatchTypeInit);
  }, [cortexMatchTypeInit]);

  useEffect(() => {
    onCortextMatchTypeChange(cortexMatchType);
  }, [cortexMatchType, onCortextMatchTypeChange]);

  useEffect(() => {
    let v = MATCH_TYPES_LIST.indexOf(cortexMatchType);
    if (v < 0) v = 0;
    setCortexMatchTypeCurrent(v);
  }, [cortexMatchType]);

  useEffect(() => {
    setMatchDistance(matchDistanceInit);
  }, [matchDistanceInit]);

  useEffect(() => {
    onMatchDistanceChange(matchDistance);
  }, [matchDistance, onMatchDistanceChange]);

  useEffect(() => {
    setMatchDistanceCurrent(matchDistance);
  }, [matchDistance]);

  useEffect(() => {
    setShowCoreColumns(showCoreColumnsInit);
  }, [showCoreColumnsInit]);

  useEffect(() => {
    onShowCoreColumnsChange(showCoreColumns);
  }, [showCoreColumns, onShowCoreColumnsChange]);

  useEffect(() => {
    let count = 0;
    if (aliMatches) {
      const groupMap = aliMatches.reduce((m, l) => { m[l.group] = true; return m; }, {});
      count = Object.keys(groupMap).length;
    }
    setMatchCount(count);
  }, [aliMatches]);

  const getMatchTypeLabel = (type) => {
    switch (type) {
      case MATCH_TYPES.TIGHT: return 'Tight';
      case MATCH_TYPES.MEDIUM: return 'Medium';
      case MATCH_TYPES.LOOSE: return 'Loose';
      default: return null;
    }
  };

  const handleMatchFilterChange = (event) => {
    const { value } = event.target;
    if (value !== matchFilter) {
      setMatchFilter(value);
      setCurrentGroupIndex(value !== GROUP_BY.UNGROUP && currentGroupIndex > 0 ? 1 : 0);
    }
  };

  const handleCurrentGroupIndexChange = (value) => {
    setCurrentGroupIndex(value);
  };

  const handleCortexMatchTypeChange = (event, value) => {
    setCortexMatchTypeCurrent(value);
  };

  const handleCortexMatchTypeChangeCommitted = (event, value) => {
    setCortexMatchType(MATCH_TYPES_LIST[value]);
  };

  const handleDistanceSliderChange = (event, value) => {
    setMatchDistanceCurrent(value);
  };

  const handleDistanceSliderChangeCommitted = (event, value) => {
    setMatchDistance(value);
  };

  const handleCoreColumnsChange = (event) => {
    const { checked } = event.target;
    setShowCoreColumns(checked);
  };

  return (
    <div className={classes.root}>
      <FormControl component="fieldset" fullWidth>
        { label && (<FormLabel component="legend">{ label }</FormLabel>)}
        <RadioGroup name="groupBy" value={matchFilter} onChange={handleMatchFilterChange}>
          { !hiddenStations.all && (<FormControlLabel value={GROUP_BY.ANY} control={<Radio color="primary" disabled />} label="Any" />) }
          { !hiddenStations.cortex && (
            <div className={classes.optionGroup}>
              <FormControlLabel value={GROUP_BY.CORTEX} control={<Radio color="primary" />} label="Cortex Match" />
              <div className={classes.slider}>
                <Slider
                  disabled={matchFilter !== GROUP_BY.ANY && matchFilter !== GROUP_BY.CORTEX}
                  value={cortexMatchTypeCurrent}
                  min={0}
                  max={MATCH_TYPES_LIST.length - 1}
                  valueLabelDisplay="auto"
                  marks={MATCH_TYPES_LIST.map((l, i) => ({ value: i, label: getMatchTypeLabel(l) }))}
                  valueLabelFormat={(i) => getMatchTypeLabel(MATCH_TYPES_LIST[i])}
                  onChange={handleCortexMatchTypeChange}
                  onChangeCommitted={handleCortexMatchTypeChangeCommitted}
                />
              </div>
            </div>
          )}
          { !hiddenStations.distance && (
            <div className={classes.optionGroup}>
              <FormControlLabel value={GROUP_BY.DISTANCE} control={<Radio color="primary" />} label="Distance Match" />
              <div className={classes.slider}>
                <Slider
                  disabled={matchFilter !== GROUP_BY.ANY && matchFilter !== GROUP_BY.DISTANCE}
                  value={matchDistanceCurrent}
                  min={DISTANCE.MIN}
                  max={DISTANCE.MAX}
                  step={null}
                  valueLabelDisplay="auto"
                  onChange={handleDistanceSliderChange}
                  onChangeCommitted={handleDistanceSliderChangeCommitted}
                  marks={DISTANCE_LIST.map((d) => ({ value: d, label: d }))}
                />
              </div>
            </div>
          )}
          { !hiddenStations.phone && (<FormControlLabel value={GROUP_BY.PHONE} control={<Radio color="primary" />} label="Phone Number Match" />) }
          { !hiddenStations.storeNumber && (<FormControlLabel value={GROUP_BY.STORE_NUMBER} control={<Radio color="primary" />} label="Store Number Match" />) }
          { !hiddenStations.polygon && (<FormControlLabel value={GROUP_BY.POLYGON} control={<Radio color="primary" disabled />} label="Polygon Match" />) }
          { !hiddenStations.ungroup && (<FormControlLabel value={GROUP_BY.UNGROUP} control={<Radio color="primary" />} label="Ungroup" />) }
          { !hiddenStations.merged && (<FormControlLabel value={GROUP_BY.MERGED} control={<Radio color="primary" />} label="Merged" />) }
        </RadioGroup>
        <div className={classes.optionGroup}>
          <FormControlLabel control={<Checkbox color="primary" disabled={matchCount === 0 || matchFilter === GROUP_BY.UNGROUP} checked={currentGroupIndex > 0 && matchCount > 0 && matchFilter !== GROUP_BY.UNGROUP} onChange={(e) => handleCurrentGroupIndexChange(e.target.checked ? 1 : 0)} />} label="View By Match" />
          { (currentGroupIndex > 0 && matchCount > 0 && matchFilter !== GROUP_BY.UNGROUP) && (
            <Pager label="Matches" total={matchCount} current={currentGroupIndex} onChange={handleCurrentGroupIndexChange} />
          )}
        </div>
        <FormControlLabel control={<Checkbox color="primary" checked={showCoreColumns} onChange={handleCoreColumnsChange} />} label="Core Columns" />
      </FormControl>
    </div>
  );
}

ALIMatching.defaultProps = {
  label: null,
  hiddenStations: {},
  matchFilter: GROUP_BY.CORTEX,
  cortexMatchType: MATCH_TYPES.MEDIUM,
  matchDistance: DISTANCE.MIN,
  showCoreColumns: false,
  onMatchFilterChange: () => {},
  onCurrentGroupIndexChange: () => {},
  onCortextMatchTypeChange: () => {},
  onMatchDistanceChange: () => {},
  onShowCoreColumnsChange: () => {},
};

ALIMatching.propTypes = {
  ...withStylesPropTypes,
  hiddenStations: PropTypes.shape(),
  label: PropTypes.string,
  matchFilter: PropTypes.string,
  cortexMatchType: PropTypes.string,
  matchDistance: PropTypes.number,
  showCoreColumns: PropTypes.bool,
  onMatchFilterChange: PropTypes.func,
  onCurrentGroupIndexChange: PropTypes.func,
  onCortextMatchTypeChange: PropTypes.func,
  onMatchDistanceChange: PropTypes.func,
  onShowCoreColumnsChange: PropTypes.func,
};

export default withStyles(useStyles)(ALIMatching);
