import React from 'react';
import PropTypes from 'prop-types';
import StackTrace from 'stacktrace-js';
import Config from './config';
import LogAPI from './api/eventslog';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (!Config.isDevMode) {
      StackTrace.fromError(error).then((stack) => {
        const err = {
          message: error.message,
          stack,
          components: errorInfo,
        };
        LogAPI.addLog('error', JSON.stringify(err));
      });
    }
    // eslint-disable-next-line no-console
    console.error(error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return <h1>Something went wrong</h1>;
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
