import client from './client';
import { error } from '../helper/misc';
const findDuplicates = ({id, sortOrder, sortName, selectedHeaders}, callback) => new Promise((resolve, reject) => {
  client.post(`/dedupe/${id}`, {sortOrder, sortName, headers: selectedHeaders})
    .then((result) => {
      resolve(callback(result.data));
    })
    .catch((err) => {
      reject(error(err));
    });
});

const groupDuplicates = ({id, headers},callback) => new Promise((resolve,reject) => {
  client.post(`/dedupe/groupDuplicates/${id}`,{headers : headers})
  .then((result) => {
    resolve(callback(result.data));
  })
  .catch((err) => {
    reject(error(err));
  });
})

export { findDuplicates , groupDuplicates}
