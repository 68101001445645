import { connect } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { setALIMatchSelected } from '../../app/aliMatchesSlice';
import { selectSelectedALIMatchIds } from '../../app/aliMatchesSelectors';
import RowSelect from './rowselect';


const selectAliMatchId = (state, props) => props.value;

const selectIsSelected = createSelector(
  [selectAliMatchId, selectSelectedALIMatchIds],
  (aliMatchId, selectedALIMatchIds) => !!selectedALIMatchIds[aliMatchId],
);

const mapStateToProps = (state, props) => ({
  selected: selectIsSelected(state, props),
});

export default connect(mapStateToProps, {
  fnSelectLocation: setALIMatchSelected,
})(RowSelect);
