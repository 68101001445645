/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';
import { withStyles } from '@material-ui/core/styles';
import { withStylesPropTypes } from '../../../helper/misc';

const useStyles = ((theme) => ({
  color: {
    width: theme.spacing(5),
    height: theme.spacing(4),
    borderRadius: '2px',
  },
  swatch: {
    background: '#fff',
    borderRadius: '1px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
    cursor: 'pointer',
  },
  popover: {
    position: 'absolute',
    zIndex: '100',
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
}));

function ColorPicker({
  classes, color, defaultColor, onChange, children,
}) {
  const [display, setDisplay] = useState(false);

  return (
    <div className={classes.swatch} onClick={() => setDisplay(true)}>
      <div className={classes.color} style={{ backgroundColor: color }}>{children}</div>
      { display && (
        <div className={classes.popover}>
          <div className={classes.cover} onClick={(e) => { e.preventDefault(); e.stopPropagation(); setDisplay(false); }} />
          <SketchPicker
            disableAlpha
            presetColors={[
              ...(defaultColor ? [{ title: 'Default', color: defaultColor }] : []),
              '#D0021B', '#F5A623', '#F8E71C', '#8B572A', '#7ED321', '#417505', '#BD10E0', '#9013FE', '#4A90E2', '#50E3C2', '#B8E986', '#000000', '#4A4A4A', '#9B9B9B', '#FFFFFF',
            ]}
            color={color}
            onChange={(newColor) => onChange(newColor.hex)}
          />
        </div>
      )}
    </div>
  );
}

ColorPicker.defaultProps = {
  color: '#000000',
  defaultColor: null,
  onChange: () => { },
  children: null,
};

ColorPicker.propTypes = {
  ...withStylesPropTypes,
  color: PropTypes.string,
  defaultColor: PropTypes.string,
  onChange: PropTypes.func,
  children: PropTypes.node,
};


export default withStyles(useStyles)(ColorPicker);
