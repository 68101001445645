import React, { useState } from 'react';
import 'date-fns';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import { withStylesPropTypes } from '../../../helper/misc';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
const useStyles = (() => ({
}));

function DatesWidget({
  handleDatesSubmit,
  open,
  onClose,
}) {
  const before = new Date();
  before.setMonth(before.getMonth() - 1);
  const [dateFrom, setDateFrom] = useState(before);
  const [dateTo, setDateTo] = useState(new Date());


  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>Choose Dates</DialogTitle>
      <DialogContent>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Date From"
              value={dateFrom}
              onChange={(date) => setDateFrom(date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline2"
              label="Date To"
              value={dateTo}
              onChange={(date) => setDateTo(date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
      </DialogContent>
      <DialogActions>
          <Button onClick={() => handleDatesSubmit(dateFrom, dateTo)} color="primary">Submit</Button>
          <Button onClick={onClose} color="primary">Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}

DatesWidget.defaultProps = {
  handleDatesSubmit: () => {},
  open: true,
  onClose: () => {},
};

DatesWidget.propTypes = {
  ...withStylesPropTypes,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  handleDatesSubmit: PropTypes.func,
};

export default withStyles(useStyles)(DatesWidget);


