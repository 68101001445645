/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { MAP_TYPE } from './mapview';
import GoogleMarker from './google/marker';
import LeafletMarker from './leaflet/marker';
import { createMarkerImage } from './helper';
import { commonDefaulProps, commonPropTypes } from './common';

export const ICON_SIZE = 40;

const Marker = ({
  mapType,
  fillColor,
  strokeColor,
  ...params
}) => {
  const getSymbol = useCallback(() => createMarkerImage({
    path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0',
    fillColor,
    fillOpacity: 1,
    strokeColor,
    strokeWeight: 2,
  }, ICON_SIZE), [fillColor, strokeColor]);

  switch (mapType) {
    case MAP_TYPE.GOOGLE:
      return <GoogleMarker icon={getSymbol()} {...params} iconSize={ICON_SIZE} />;
    case MAP_TYPE.LEAFLET:
      return <LeafletMarker icon={getSymbol()} {...params} iconSize={ICON_SIZE} />;
    default:
      return <></>;
  }
};


Marker.defaultProps = {
  ...commonDefaulProps,
  mapType: '',
  fillColor: '#ee0000',
  strokeColor: '#000000',
};

Marker.propTypes = {
  ...commonPropTypes,
  mapType: PropTypes.string,
  fillColor: PropTypes.string,
  strokeColor: PropTypes.string,
};

export default Marker;
