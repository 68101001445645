import client from './client';
import { error, getQueryString } from '../helper/misc';

const fetchEventsLog = (station, level, page) => new Promise((resolve, reject) => {
  const params = { station, level };
  if (page) params.page = page;
  client.get(`eventsLogs${getQueryString(params)}`)
    .then((result) => resolve(result.data))
    .catch((err) => reject(error(err)));
});

const addLog = (level, message) => new Promise((resolve, reject) => {
  client.post('eventsLogs', { level, message })
    .then(() => resolve())
    .catch((err) => reject(error(err)));
});
export default { fetchEventsLog, addLog };
