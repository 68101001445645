/* eslint-disable react/no-danger */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';
import { withStylesPropTypes } from '../../../helper/misc';
import CellFormatOptions from './cellformatoptions';
import { getRangeCounts } from './cellsrange';
import { RULES } from './conditionsmisc';


const useStyles = ((theme) => ({
  paper: {
    overflow: 'initial',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  ruleOperatorBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  ruleOperator: {
    flexGrow: 0,
    marginLeft: 'auto',
  },
  newConditionButton: {
    marginRight: 'auto',
  },
  infoPanel: {
    textAlign: 'right',
    '& > *': {
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  conditionPanel: {
    position: 'inherit',
  },
  conditionDetails: {
    flexDirection: 'column',
  },
  conditionTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  formatOptions: {
    marginTop: theme.spacing(1),
  },
}));

const isEmptyRule = (rule) => rule.rule === '';

const isEmptyCondition = (condition) => !condition.rules.some((r) => !isEmptyRule(r));


function Rule({
  classes, rule, canDelete, isFirst, onChange, onDelete,
}) {
  const ruleRequireText = () => rule.rule === RULES.CONTAIN || rule.rule === RULES.NOT_CONTAIN;

  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item xs={2}>
        <div className={classes.ruleOperatorBox}>
          { canDelete && (
            <IconButton size="small" color="secondary" onClick={onDelete}>
              <DeleteIcon />
            </IconButton>
          )}
          <div className={classes.ruleOperator}>{ isFirst ? 'IF' : rule.operator }</div>
        </div>
      </Grid>
      <Grid item xs={2}>Cell Value</Grid>
      <Grid item xs={4}>
        <Select fullWidth value={rule.rule} onChange={(e) => onChange({ ...rule, rule: e.target.value })}>
          <MenuItem value="">-</MenuItem>
          <MenuItem value={RULES.CONTAIN}>contains</MenuItem>
          <MenuItem value={RULES.NOT_CONTAIN}>doesn`t contain</MenuItem>
          <MenuItem value={RULES.DUPLICATE}>is duplicate</MenuItem>
          <MenuItem value={RULES.NOT_DUPLICATE}>is not duplicate</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={4}>
        {
        ruleRequireText() && (<TextField label="Text" fullWidth size="small" variant="outlined" value={rule.ruleText} onChange={(e) => onChange({ ...rule, ruleText: e.target.value })} />)
      }
      </Grid>
    </Grid>
  );
}

Rule.defaultProps = {
  onChange: () => {},
  onDelete: () => {},
  canDelete: false,
  isFirst: false,
};

Rule.propTypes = {
  ...withStylesPropTypes,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  rule: PropTypes.shape().isRequired,
  canDelete: PropTypes.bool,
  isFirst: PropTypes.bool,
};

const StyledRule = withStyles(useStyles)(Rule);


function Condition({
  classes,
  condition,
  onChange,
  defaultStyle,
}) {
  return (
    <>
      {
        condition.rules.map((rule, idx) => (
          <StyledRule
          // eslint-disable-next-line react/no-array-index-key
            key={idx}
            rule={rule}
            isFirst={idx === 0}
            canDelete={condition.rules.length > 1}
            onChange={(updatedRule) => onChange({ ...condition, rules: [...condition.rules.slice(0, idx), updatedRule, ...condition.rules.slice(idx + 1)] })}
            onDelete={() => onChange({ ...condition, rules: [...condition.rules.slice(0, idx), ...condition.rules.slice(idx + 1)] })}
          />
        ))
      }
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={3}>
          <FormControl fullWidth>
            <InputLabel id="cond-format-add-new-rule">Add Rule</InputLabel>
            <Select
              fullWidth
              value=""
              labelId="cond-format-add-new-rule"
              onChange={(e) => onChange({ ...condition, rules: [...condition.rules, { operator: e.target.value, rule: '', ruleText: '' }] })}
            >
              <MenuItem value="AND">AND</MenuItem>
              <MenuItem value="OR">OR</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      { !isEmptyCondition(condition) && (
        <div className={classes.formatOptions}>
          <CellFormatOptions style={condition.style} defaultStyle={defaultStyle} onChange={(newStyle) => onChange({ ...condition, style: { ...condition.style, ...newStyle } })} />
        </div>
      )}
    </>
  );
}


Condition.defaultProps = {
  onChange: () => {},
};

Condition.propTypes = {
  ...withStylesPropTypes,
  condition: PropTypes.shape().isRequired,
  onChange: PropTypes.func,
  defaultStyle: PropTypes.shape().isRequired,
};

const StyledCondition = withStyles(useStyles)(Condition);


function ConditionalFormatOptionsDlg({
  classes,
  visible,
  onClose,
  onShowRange,
  conditions,
  range,
  defaultStyle,
}) {
  const [currentConditions, setCurrentConditions] = useState(conditions);
  const [selectedCondition, setSelectedCondition] = useState(0);

  const getConditionsRange = () => {
    let conditionsRange = range;
    currentConditions.some((condition) => {
      if (condition.range) {
        conditionsRange = condition.range;
        return true;
      }
      return false;
    });
    return conditionsRange;
  };

  const getRangeText = () => {
    const rulesRange = getConditionsRange();
    const numName = (num, name) => `${num} ${name}${num > 1 ? 's' : ''}`;
    const counts = getRangeCounts(rulesRange);
    const list = [];
    if (rulesRange) {
      if (counts.columns) list.push(numName(counts.columns, 'column'));
      if (counts.rows) list.push(numName(counts.rows, 'row'));
      if (counts.cells) list.push(numName(counts.cells, 'cell'));
    }
    return list.join(', ');
  };

  const newCondition = useCallback(() => ({
    style: { ...defaultStyle },
    rules: [{
      operator: 'AND',
      rule: '',
      ruleText: '',
    }],
    range,
  }), [defaultStyle, range]);

  useEffect(() => {
    setCurrentConditions(conditions.length ? conditions : [newCondition()]);
  }, [conditions, defaultStyle, newCondition]);

  const handleClose = () => {
    onClose();
  };

  const handleOK = () => {
    onClose(currentConditions.filter((c) => !isEmptyCondition(c)));
  };

  const handleConditionChange = (changedCondition, idx) => {
    const changedConditions = [...currentConditions];
    changedConditions[idx] = changedCondition;
    setCurrentConditions(changedConditions);
  };

  const handleAddCondition = () => {
    const changedConditions = [...currentConditions];
    changedConditions.push(newCondition());
    setCurrentConditions(changedConditions);
    setSelectedCondition(changedConditions.length - 1);
  };

  const handleDeleteCondition = (idx) => {
    const changedConditions = [...currentConditions];
    changedConditions.splice(idx, 1);
    if (changedConditions.length === 0) changedConditions.push(newCondition());
    setCurrentConditions(changedConditions);
    if (idx > 0) setSelectedCondition(idx - 1);
  };

  const getConditionSummaryHTML = (condition) => {
    const summary = [];
    for (let i = 0; i < condition.rules.length; i += 1) {
      const rule = condition.rules[i];
      if (!isEmptyRule(rule)) {
        if (summary.length) summary.push(` <em>${rule.operator}</em> `);
        switch (rule.rule) {
          case RULES.CONTAIN:
            summary.push(`Cell contains <strong>${rule.ruleText ? rule.ruleText : '&lt;empty&gt;'}</strong>`);
            break;
          case RULES.NOT_CONTAIN:
            summary.push(`Cell doesn't contain <strong>${rule.ruleText ? rule.ruleText : '&lt;empty&gt;'}</strong>`);
            break;
          case RULES.DUPLICATE:
            summary.push('Cell is duplicate');
            break;
          case RULES.NOT_DUPLICATE:
            summary.push('Cell is not duplicate');
            break;
          default:
            break;
        }
      }
    }
    return summary.length ? summary.join('') : '<em>Empty</em>';
  };

  return visible && (
  <Dialog open onClose={onClose} maxWidth="sm" fullWidth classes={{ paper: classes.paper }}>
    <DialogTitle>
      <Typography>
        Conditional Format&nbsp;&nbsp;&nbsp;Range:
        <Button color="primary" variant="text" onClick={() => onShowRange(getConditionsRange())}>{ getRangeText() }</Button>
      </Typography>
      <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent>
      {
        currentConditions.map((condition, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <ExpansionPanel key={idx} classes={{ root: classes.conditionPanel }} expanded={idx === selectedCondition} onChange={() => setSelectedCondition(idx)}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <div className={classes.conditionTitle}>
                { !isEmptyCondition(condition) && (
                  <IconButton size="small" color="secondary" onClick={() => handleDeleteCondition(idx)}>
                    <DeleteIcon />
                  </IconButton>
                )}
                <div dangerouslySetInnerHTML={{ __html: getConditionSummaryHTML(condition) }} />
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails classes={{ root: classes.conditionDetails }}>
              <StyledCondition
                defaultStyle={defaultStyle}
                condition={condition}
                onChange={(r) => handleConditionChange(r, idx)}
                onShowRange={onShowRange}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))
      }
    </DialogContent>
    <DialogActions>
      {
        currentConditions.filter((c) => isEmptyCondition(c)).length === 0 && (
          <Button onClick={handleAddCondition} color="primary" className={classes.newConditionButton}>
            Add New Condition
          </Button>
        )
      }
      <Button onClick={handleOK} color="primary">
        OK
      </Button>
      <Button onClick={handleClose} color="primary">
        Cancel
      </Button>
    </DialogActions>
  </Dialog>
  );
}

ConditionalFormatOptionsDlg.defaultProps = {
  onClose: () => { },
  onShowRange: () => { },
  visible: false,
  conditions: [],
  range: null,
};

ConditionalFormatOptionsDlg.propTypes = {
  ...withStylesPropTypes,
  onClose: PropTypes.func,
  onShowRange: PropTypes.func,
  visible: PropTypes.bool,
  conditions: PropTypes.arrayOf(PropTypes.shape),
  range: PropTypes.shape(),
  defaultStyle: PropTypes.shape().isRequired,
};

export default withStyles(useStyles)(ConditionalFormatOptionsDlg);
