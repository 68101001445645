import socket from './socketclient';
import { invalidateSelectedPayload, updatePayload, invalidateListPayloads } from '../app/payloadSlice';
import { setCortexAutoMergeNotification } from '../app/aliMatchesSlice';
import { updateList } from '../app/listSlice';

class SocketListener {
  constructor() {
    this.socket = socket;
    this.dispatch = null;

    socket.on('cortexAutoMergeNotificationUpdated', (data) => this.onCortextAutoMergeNotification(data));
    socket.on('autoFeildsUpdated', (data) => this.onAutoFieldsUpdated(data));
    socket.on('locationsUpdated', (data) => this.onLocationsUpdated(data));
    socket.on('locationsDownloaded', (data) => this.onLocationsDownloaded(data));
    socket.on('uploadCsvUpdated', (data) => this.onCSVUploaded(data));
    socket.on('listUpdated', (data) => this.onListUpdated(data));
    socket.on('substationStatusUpdated', (data) => this.onSubstationStatusUpdated(data));
    socket.on('newPayloadsUpdated', (data) => this.onNewPayloadsUpdated(data));
  }

  setDispatch(dispatch) {
    this.dispatch = dispatch;
  }

  onAutoFieldsUpdated(data) {
    const { payloadId } = data;
    if (this.dispatch) this.dispatch(invalidateSelectedPayload(payloadId, true));
  }

  onLocationsUpdated(data) {
    const { payloadId } = data;
    if (this.dispatch) this.dispatch(invalidateSelectedPayload(payloadId));
  }

  onLocationsDownloaded(data) {
    const { payloadId } = data;
    if (this.dispatch) this.dispatch(invalidateSelectedPayload(payloadId));
  }

  onCSVUploaded(data) {
    const { payloadId } = data;
    if (this.dispatch) this.dispatch(invalidateSelectedPayload(payloadId, true));
  }

  onCortextAutoMergeNotification(data) {
    const { matchingId, matchingScore, remaining } = data;
    if (this.dispatch) this.dispatch(setCortexAutoMergeNotification({ matchingId, matchingScore, remaining }));
  }

  onListUpdated(list) {
    if (this.dispatch) this.dispatch(updateList(list));
  }

  onSubstationStatusUpdated(payload) {
    if(payload){
      if (this.dispatch) {
        this.dispatch(updatePayload(payload));
        this.dispatch(invalidateSelectedPayload(payload._id, true));
      }
    }
    
  }

  onNewPayloadsUpdated(payload) {
    if(payload)
    {
      if (this.dispatch) {
        const { payloads = [] } = payload;
        payloads.forEach((p) => {
          if (p.length && p.length === 3) {
            const listId = p[1];
            this.dispatch(invalidateListPayloads(listId));
          }
        });
      }
    }
  }
}

export default new SocketListener();
