import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FilterListIcon from '@material-ui/icons/FilterList';
import SortIcon from '@material-ui/icons/Sort';
import { withStylesPropTypes } from '../../helper/misc';
import { renameHeader } from '../../app/payloadSlice';
import FieldToMatch from './fieldtomatch';
import STATIONS from '../../const/stations';
import KeyboardHandle from '../../helper/keyboard';
import DataProvider from '../common/sheet/dataprovider';
import FieldToMatchQA from './fieldtomatchqa';
import {HomeScreen} from '../../pages/home';

const CUSTOM_HEADER = '__custom_standard_header_';
const ADD_HIDE = '__add_hide_text';
const REMOVE_HIDE = '__remove_hide_text';

const useStyles = ((theme) => ({
  root: {
    flexDirection:"column"
  },
  input: {
    display: 'block',
    '&>label': {
      color: theme.palette.primary.main,
      fontStyle: 'italic',
    },
    '&>label.MuiInputLabel-shrink': {
      display: 'none',
    },
    '&>label.MuiInputLabel-marginDense': {
      transform: 'translate(0, 5px) scale(1)',
    },
    '& input': {
      color: theme.palette.primary.main,
    },
    '&>.MuiInput-formControl': {
      marginTop: 0,
    },
  },
  errorTooltip: {
    color: theme.palette.error.light,
    fontSize: '1.0em',
  },
  columnWeight: {
    height: theme.spacing(4),
    lineHeight: `${theme.spacing(4)}px`,
    borderBottom: `solid 1px ${theme.palette.border.primary}`,
    backgroundColor: theme.palette.background.paper,
  },
  columnWeightBorder: {
    borderRight: `solid 1px ${theme.palette.border.primary}`,
  },
  columnTitle: {
    paddingLeft: 2,
    paddingRight: 2,
    height: theme.spacing(4) - 1,
    lineHeight: `${theme.spacing(4) - 1}px`,
    backgroundColor: theme.palette.background.header,
    '&:focus': {
      outline: 'none',
    },
  },
  columnTitlePadding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  selectedColumnTitle: {
    backgroundColor: 'inherit !important',
  },
  newHeader: {
    color: theme.palette.success.main,
  },
  uncommonHeader: {
    color: theme.palette.warning.main,
  },
  droppedHeader: {
    color: theme.palette.secondary.main,
    textDecoration: 'line-through',
  },
  headerMatch: {
    height: theme.spacing(2) - 1,
    lineHeight: `${theme.spacing(2) - 1}px`,
  },
  headerWithIcons: {
    display: 'flex',
    alignItems: 'center',
  },
  headerMatchSelect: {
    padding: 0,
  },
  selectedDedupe: {
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  hidden: {
    display: 'none !important',
  },
  cellStation: {
    display: 'block',
    position: 'absolute',
    right: -theme.spacing(0.5),
    top: -theme.spacing(0.5),
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    zIndex: 100,
    '&>*': {
      width: theme.spacing(1.5),
      height: theme.spacing(1.5),
    },
  },
  cellStationFailed: {
    color: theme.palette.secondary.main,
  },
  cellStationAutofix: {
    color: theme.palette.primary.main,
  },
  cellStationManual: {
    color: theme.palette.warning.main,
  },
  cellTitleCheckbox: {
    display : "flex",
    justifyContent : "center",
  },
  cellPushed: {
    color: theme.palette.warning.main
  }
}));

export const SORTING = {
  NONE: 0,
  ASC: 1,
  DESC: -1,
};

function LocationColumnHeader({
  classes,
  title,
  match,
  selected,
  hasBorder,
  columnKey,
  selectColumn,
  columnIndex,
  columnWeightPlaceholder,
  columnWeight,
  columnMatch,
  listId,
  payloadIds,
  standardHeaders,
  canEdit,
  canMatchTo,
  stationHeader,
  fullWidth,
  sort,
  filter,
  titleComponent,
  columnWeightComponent,
  fnRenameHeader,
}) {
  const [isEditNameMode, setEditNameMode] = useState(false);
  const [customName, setCustomName] = useState();
  const [customNameError, setCustomNameError] = useState();

  const {customDedupe, appMode,isSubmit, singleColumn ,headerToPushValues} = useContext(HomeScreen);

  const handleColumnRename = (newName, approve = false) => {
    payloadIds.forEach((payloadId) => {
      fnRenameHeader(payloadId, match || columnKey, newName, approve);
    });
  };

  const handleCustomNameColumnRename = () => {
    if (!customName) {
      setCustomNameError('Name can\'t be empty');
    } else if (!/^[a-z][a-z,_,0-9]*$/.test(customName)) {
      setCustomNameError('Name value is invalid');
    } else if (!canMatchTo(customName)) {
      setCustomNameError('This name already exists');
    } else {
      handleColumnRename(customName);
      setEditNameMode(false);
    }
  };

  const handleCustomHeaderSelect = () => {
    setCustomName('');
    setCustomNameError();
    setEditNameMode(true);
  };

  const handleCustomNameChange = (e) => {
    const { value } = e.target;
    setCustomName(value);
    setCustomNameError();
  };

  const handleAddHideToHeader = (e) => {
    const newName = 'hide_' + match;
    handleColumnRename(newName, true);
  }
  const handleRemoveHideFromHeader = (e) => {
    const newName = match.replace(/^hide_/, '');
    handleColumnRename(newName);
  }
  const handleColumnMatch = (e) => {
    const { value } = e.target;
    if (value === CUSTOM_HEADER) handleCustomHeaderSelect();
    else if (value === ADD_HIDE) handleAddHideToHeader();
    else if (value === REMOVE_HIDE) handleRemoveHideFromHeader();
    else handleColumnRename(value);
  };

  const handleEditNameKeyDown = (e) => {
    KeyboardHandle(e, {
      onEsc: () => setEditNameMode(false),
      onEnter: () => handleCustomNameColumnRename(),
    });
  };

  const getStationHeaderClassName = () => {
    if(headerToPushValues && headerToPushValues[match])
    {
      return undefined;
    }   
    switch (stationHeader) {
      case STATIONS.NEW_HEADERS: return classes.newHeader;
      case STATIONS.UNCOMMON_HEADERS: return classes.uncommonHeader;
      case STATIONS.DROPPED_HEADERS: return classes.droppedHeader;
      default: return undefined;
    }
  };

  const defaultTitleRender = () => (titleComponent || <>{title}</>);
  const defaultColumnWeightRender = () => (columnWeightComponent || <FieldToMatch fieldKey={columnKey} match={match} listId={listId} />);

  const columnWeightRenderQa = () => 
  <FieldToMatchQA 
  fieldKey={columnKey}
  match={match} 
  listId={listId} 
  title={title} 
  />

  return (
    <>
      {columnWeightPlaceholder && appMode===2 &&
        <div className={clsx(classes.columnWeight, hasBorder && classes.columnWeightBorder)}>
          {
              columnWeight && defaultColumnWeightRender()
          }
        </div>
      }
      <div
        className={(singleColumn.includes(columnKey) && appMode === 0) ? clsx(classes.selectedDedupe,classes.columnTitle, selected && classes.selectedColumnTitle, !fullWidth && classes.columnTitlePadding) : clsx(classes.columnTitle, selected && classes.selectedColumnTitle, !fullWidth && classes.columnTitlePadding, getStationHeaderClassName())}
        role="button"
        tabIndex={0}
        onKeyDown={() => {}}
        onMouseDown={customDedupe ? null : (event) => selectColumn({ event, col: columnIndex })}       
      >
        {
          columnMatch ? (
            <>
            <div style={{display:'flex', justifyContent:'space-between'}} >
              <div>
              <div className={clsx(classes.headerMatch, classes.headerWithIcons)}>
                { filter && <FilterListIcon fontSize="inherit" /> }
                { sort && <SortIcon fontSize="inherit" /> }
                {title}
              </div>          
              <div className={classes.headerMatch}>
                { isEditNameMode ? (
                  <Tooltip
                    open={!!customNameError}
                    title={customNameError || ''}
                    arrow
                    TransitionComponent={Zoom}
                    classes={{ tooltip: classes.errorTooltip }}
                  >
                    <TextField
                      size="small"
                      variant="standard"
                      label="Header Name"
                      margin="none"
                      className={classes.input}
                      autoFocus
                      value={customName}
                      onChange={(e) => handleCustomNameChange(e)}
                      onMouseDown={(e) => { e.preventDefault(); e.stopPropagation(); }}
                      onBlur={() => setEditNameMode(false)}
                      onKeyDown={(e) => handleEditNameKeyDown(e)}
                      error={!!customNameError}
                    />
                  </Tooltip>
                ) : (
                  <Select
                    size="small"
                    displayEmpty
                    fullWidth
                    classes={{ root: classes.headerMatchSelect, select: clsx(getStationHeaderClassName()) }}
                    disableUnderline
                    name={`col${columnIndex}`}
                    onMouseDown={(e) => { e.stopPropagation(); e.preventDefault(); }}
                    onChange={(e) => handleColumnMatch(e)}
                    value={match || ''}
                    disabled={!canEdit}
                  >
                    { match && <MenuItem value={match} key={match}>{match}</MenuItem> }
                    { standardHeaders.filter((h) => canMatchTo(h)).map((h) => <MenuItem value={h} key={h}>{h}</MenuItem>)}
                    { match && match.startsWith('hide_') ? (
                      <MenuItem value={REMOVE_HIDE}>&lt; remove hide_ &gt;</MenuItem>
                    ): (
                      <MenuItem value={ADD_HIDE}>&lt; add hide_ &gt;</MenuItem>
                    )}
                    <MenuItem value={CUSTOM_HEADER}>&lt; custom &gt;</MenuItem>
                  </Select>
                )}            
              </div>
              </div>
              {appMode === 0 &&
              <div style={{marginTop:"2px"}} className = {clsx(isSubmit ? classes.hidden:null)}>
      
            
              <>
                  {
                    customDedupe ?
                      columnWeight && columnWeightRenderQa()
                    :
                    null
                  }
                  </>
                </div>
                }
                </div>
            </>
          ) : (
            <div className={classes.headerWithIcons}>
              { filter && <FilterListIcon fontSize="inherit" /> }
              { sort && <SortIcon fontSize="inherit" /> }
              { defaultTitleRender() }  
              {appMode === 0 &&
              <div style={{marginTop:"2px"}} className = {clsx(isSubmit ? classes.hidden:null)}>
              <>
                  {
                    customDedupe ?
                      columnWeight && columnWeightRenderQa()
                    :
                    null
                  }
                  </>
                </div>
                }         
            </div>
          )
        }
      </div>
      {  headerToPushValues && headerToPushValues[match] &&
      <>
      <span
          // eslint-disable-next-line react/prop-types
            className={clsx(classes.cellStation,classes.cellStationManual)}
          >
            <ErrorIcon/>
      </span>
      </>
      }
    </>
  );
}


LocationColumnHeader.defaultProps = {
  selectColumn: () => {},
  title: null,
  match: null,
  columnIndex: null,
  standardHeaders: [],
  payloadIds: [],
  listId: null,
  canEdit: false,
  columnWeightPlaceholder: false,
  columnWeight: false,
  columnMatch: false,
  canMatchTo: () => false,
  stationHeader: null,
  fullWidth: false,
  titleComponent: null,
  columnWeightComponent: null,
  sort: false,
  filter: false,
  selected: false,
};

LocationColumnHeader.propTypes = {
  ...withStylesPropTypes,
  title: PropTypes.string,
  match: PropTypes.string,
  columnKey: PropTypes.string.isRequired,
  selectColumn: PropTypes.func,
  columnIndex: PropTypes.number,
  columnWeightPlaceholder: PropTypes.bool,
  columnWeight: PropTypes.bool,
  columnMatch: PropTypes.bool,
  listId: PropTypes.number,
  payloadIds: PropTypes.arrayOf(PropTypes.string),
  standardHeaders: PropTypes.arrayOf(PropTypes.string),
  canEdit: PropTypes.bool,
  canMatchTo: PropTypes.func,
  stationHeader: PropTypes.string,
  fullWidth: PropTypes.bool,
  titleComponent: PropTypes.node,
  columnWeightComponent: PropTypes.node,
  sort: PropTypes.bool,
  filter: PropTypes.bool,
  selected: PropTypes.bool,
};

export default connect(null, {
  fnRenameHeader: renameHeader,
})(withStyles(useStyles)(LocationColumnHeader));
