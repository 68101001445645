import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { withStylesPropTypes } from '../../helper/misc';

const useStyles = (() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
}));

function StationErrorList({
  classes,
  issueList,
  messageRenderer,
}) {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    setCurrentIndex(0);
  }, [issueList]);

  const handleNavigate = (step) => {
    setCurrentIndex(currentIndex + step);
  };

  const currentIssue = issueList[currentIndex] || '';

  const navigationBox = issueList.length > 1 && (
    <>
      <IconButton color="primary" disabled={currentIndex === 0} onClick={() => handleNavigate(-1)}>
        <ArrowBackIosIcon />
      </IconButton>
      <div>
        {currentIndex + 1}
        {' '}
        of
        {' '}
        {issueList.length}
      </div>
      <IconButton color="primary" disabled={currentIndex === issueList.length - 1} onClick={() => handleNavigate(1)}>
        <ArrowForwardIosIcon />
      </IconButton>
    </>
  );

  const Renderer = messageRenderer || (({ children }) => <>{children}</>);

  let messageBox;
  if (typeof currentIssue === 'string') {
    messageBox = <Renderer>{currentIssue}</Renderer>;
  } else if (currentIssue.level) {
    messageBox = (
      <Alert severity={currentIssue.level}>
        <Renderer message={currentIssue}>
          {currentIssue.message}
        </Renderer>
      </Alert>
    );
  } else {
    messageBox = <Renderer message={currentIssue}>{currentIssue.message}</Renderer>;
  }

  return (
    <div className={classes.root}>
      {messageBox}
      { navigationBox }
    </div>
  );
}

StationErrorList.defaultProps = {
  // eslint-disable-next-line react/prop-types
  messageRenderer: undefined,
};

StationErrorList.propTypes = {
  ...withStylesPropTypes,
  issueList: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape(),
    ]),
  ).isRequired,
  messageRenderer: PropTypes.shape(),
};


export default withStyles(useStyles)(StationErrorList);
