import { textRefToRange } from './cellsrange';
import { textRefToCell, cellToTextRef } from './cellsmisc';

const RE_QUOTES = /"([^"]+)"/g;
const RE_RANGE = /(\$?[A-Z]+\$?\d+)(?::(\$?[A-Z]+\$?\d+))?\b/g;
const RE_RELATIVE_RANGE = /\b(R\[?-?\d+\]?C\[?-?\d+\]?)(?::(R\[?-?\d+\]?C\[?-?\d+\]?))?/g;


export const isFormula = (value) => (value && typeof value === 'string' && value.indexOf('=') === 0);

const extractRangesUsingRE = (formula, re) => {
  const ret = [];
  const noStringFormula = formula.replace(RE_QUOTES, (m) => ' '.repeat(m.length));
  noStringFormula.replace(re, (range, cell1, cell2, offset) => {
    ret.push({
      offset,
      range,
      cell1,
      cell2,
    });
  });
  return ret;
};

export const extractFormulaRanges = (formula) => extractRangesUsingRE(formula, RE_RANGE);
export const extractFormulaRelativeRanges = (formula) => extractRangesUsingRE(formula, RE_RELATIVE_RANGE);

export const getFormulaRanges = (formula) => extractFormulaRanges(formula).map(({ range }) => textRefToRange(range));

export const cellTextRefToRelativeRef = ({ col, row }, textRef) => {
  let ret = null;
  if (textRef) {
    const cell = textRefToCell(textRef);
    const c = [];
    c.push('R');
    if (cell.rowAbsolute) c.push(cell.row);
    else c.push(`[${cell.row - row}]`);
    c.push('C');
    if (cell.colAbsolute) c.push(cell.col);
    else c.push(`[${cell.col - col}]`);
    ret = c.join('');
  }
  return ret;
};

export const relativeRefToCellTextRef = ({ col, row }, relRef) => {
  let ret = null;
  if (relRef) {
    const re = /^R(\[?-?\d+\]?)C(\[?-?\d+\]?)$/g;
    const m = re.exec(relRef);
    if (m) {
      let rowIdx = m[1];
      let rowAbsolute = true;
      let colIdx = m[2];
      let colAbsolute = true;

      if (rowIdx.indexOf('[') === 0) {
        rowAbsolute = false;
        rowIdx = Number(rowIdx.substring(1, rowIdx.length - 1)) + row;
      } else {
        rowIdx = Number(rowIdx);
      }

      if (colIdx.indexOf('[') === 0) {
        colAbsolute = false;
        colIdx = Number(colIdx.substring(1, colIdx.length - 1)) + col;
      } else {
        colIdx = Number(colIdx);
      }

      ret = cellToTextRef({
        col: colIdx, row: rowIdx, colAbsolute, rowAbsolute,
      });
    }
  }
  return ret;
};

const convertFormulaRanges = (formula, rangeExtractor, convertor) => {
  let ret = formula;
  if (isFormula(formula)) {
    const ranges = rangeExtractor(formula);
    for (let i = ranges.length - 1; i >= 0; i -= 1) {
      const range = ranges[i];
      const rcell1 = convertor(range.cell1);
      const rcell2 = convertor(range.cell2);
      const rel = [rcell1];
      if (rcell2) rel.push(rcell2);
      const relRange = rel.join(':');
      ret = [ret.substring(0, range.offset), relRange, ret.substring(range.offset + range.range.length)].join('');
    }
  }
  return ret;
};

export const convertFormulaRangesToRelative = (cell, formula) => convertFormulaRanges(formula, extractFormulaRanges, (cellRef) => cellTextRefToRelativeRef(cell, cellRef));

export const restoreFormulaRangesFromRelative = (cell, formula) => convertFormulaRanges(formula, extractFormulaRelativeRanges, (cellRef) => relativeRefToCellTextRef(cell, cellRef));

export const updateRelativeColumnReference = (formula, srcColIdx, columnMap) => convertFormulaRanges(formula, extractFormulaRelativeRanges, (relRef) => {
  let ret = relRef;
  if (relRef) {
    const re = /(^R\[?-?\d+\]?C)(\[?-?\d+\]?)$/g;
    ret = relRef.replace(re, (f, prefix, colRef) => {
      if (colRef.indexOf('[') === 0) {
        const col = Number(colRef.substring(1, colRef.length - 1));
        const refColIdx = columnMap[col + srcColIdx];
        const dstColIdx = columnMap[srcColIdx];
        const newRelCol = refColIdx - dstColIdx;
        return `${prefix}[${newRelCol}]`;
      }
      return f;
    });
  }
  return ret;
});
