import axios from 'axios';
import Config from '../config';

const TOKEN_NAME = 'qstoken';

const client = axios.create({
  baseURL: Config.apiEndpoint,
});

const getToken = () => localStorage.getItem(TOKEN_NAME);

const setupTokenHeader = () => {
  const token = getToken();
  client.defaults.headers.common.Authorization = (token ? `Bearer ${token}` : '');
};

const forgetToken = () => {
  localStorage.removeItem(TOKEN_NAME);
  setupTokenHeader();
};

const rememberToken = (token) => {
  localStorage.setItem(TOKEN_NAME, token);
  setupTokenHeader();
};


client.defaults.validateStatus = (status) => {
  if (status === 401) {
    forgetToken();
    if (window.location.pathname !== '/login') window.location.href = '/login';
  }
  return status >= 200 && status < 300;
};
setupTokenHeader();

export { rememberToken, forgetToken, getToken };
export default client;
