import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { withStylesPropTypes } from '../../../helper/misc';
import KeyboardHandle from '../../../helper/keyboard';

const useStyles = ((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

function ColumnNameDlg({
  classes,
  visible,
  onClose,
  validate,
}) {
  const [name, setName] = useState('');
  const [temporary, setTemporary] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setName('');
    setError(null);
    setTemporary(true);
  }, [visible]);

  const handleClose = () => {
    onClose();
  };

  const handleOK = () => {
    const message = validate(name);
    if (message) {
      setError(message);
    } else {
      onClose({ name, temporary });
    }
  };

  const handleKeyDown = (e) => {
    KeyboardHandle(e, {
      onEnter: () => handleOK(),
      onEsc: () => handleClose(),
    });
  };

  return visible && (
  <Dialog open onClose={() => onClose()} maxWidth="xs" fullWidth classes={{ paper: classes.paper }}>
    <DialogTitle>
      <Typography>New Column</Typography>
      <IconButton aria-label="close" className={classes.closeButton} onClick={() => onClose()}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent>
      <TextField
        label="Column Name"
        required
        autoFocus
        fullWidth
        size="small"
        variant="outlined"
        helperText={error}
        error={!!error}
        value={name}
        onChange={(e) => { setName(e.target.value); setError(null); }}
        onKeyDown={(e) => handleKeyDown(e)}
      />
      <FormControlLabel
        control={(
          <Switch
            name="requestFlag"
            checked={temporary}
            onChange={(e) => setTemporary(e.target.checked)}
            color="primary"
          />
              )}
        label="This column is temporary"
      />

    </DialogContent>
    <DialogActions>
      <Button onClick={handleOK} color="primary">
        OK
      </Button>
      <Button onClick={handleClose} color="primary">
        Cancel
      </Button>
    </DialogActions>
  </Dialog>
  );
}

ColumnNameDlg.defaultProps = {
  onClose: () => { },
  validate: () => null,
  visible: false,
};

ColumnNameDlg.propTypes = {
  ...withStylesPropTypes,
  onClose: PropTypes.func,
  validate: PropTypes.func,
  visible: PropTypes.bool,
};

export default withStyles(useStyles)(ColumnNameDlg);
