import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { withStylesPropTypes } from '../../../helper/misc';
import KeyboardHandle from '../../../helper/keyboard';

const useStyles = ((theme) => ({
  root: {
    position: 'absolute',
    right: theme.spacing(4),
    top: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    border: `solid 2px ${theme.palette.border.primary}`,
    padding: theme.spacing(1),
    boxShadow: `4px 4px 8px ${theme.palette.text.primary}`,
    zIndex: 1000,
    width: '400px',
  },
  tabBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  closeButton: {
    flexGrow: 0,
    paddingLeft: `${theme.spacing(1)}px`,
    marginLeft: 'auto',
  },
  body: {
    '& *': {
      boxSizing: 'content-box !important',
    },
    '& > .MuiTextField-root': {
      marginTop: theme.spacing(1),
    },
  },
  buttons: {
    textAlign: 'right',
  },
}));

const ID_TXT_FIND = 'dlg_findreplace_find';
const ID_TXT_REPLACE = 'dlg_findreplace_replace';
const NOT_FOUND = 'Search key not found';

export const MODE = {
  FIND: 0,
  REPLACE: 1,
};

function SearchPane({
  classes, visible, defaultMode, onClose, onFound, searchFunction, startPosition, canReplace,
}) {
  // let inputRef;
  const [mode, setMode] = useState(defaultMode || MODE.FIND);
  const [find, setFind] = useState('');
  const [replace, setReplace] = useState('');
  const [message, setMessage] = useState(null);
  const [options, setOptions] = useState({
    entireCell: false,
    matchCase: false,
  });

  useEffect(() => {
    if (defaultMode !== null) setMode(defaultMode);
  }, [defaultMode]);


  const handleFindReplace = (isReplace) => {
    const result = searchFunction(find, startPosition, 1, options);
    onFound([result], isReplace && { find, replace, options });
    setMessage(result ? null : NOT_FOUND);
  };

  const handleFindReplaceAll = (isReplace) => {
    const result = [];
    let nextResult = null;
    do {
      nextResult = searchFunction(find, nextResult, nextResult ? 1 : 0, options);
      if (nextResult) result.push(nextResult);
    } while (nextResult);
    onFound(result, isReplace && { find, replace, options });
    setMessage(result.length ? null : NOT_FOUND);
  };

  const handleKeyDown = (e) => {
    const { id } = e.target;
    KeyboardHandle(e, {
      onEnter: () => {
        if (id === ID_TXT_FIND) handleFindReplace(false);
        else if (id === ID_TXT_REPLACE) handleFindReplace(true);
      },
      onEsc: () => {
        onClose();
      },
    });
  };

  const handleOptionsChange = (e) => {
    setOptions({ ...options, [e.target.name]: e.target.checked });
  };

  return visible && (
    <div className={clsx(classes.root)}>
      <div className={classes.tabBar}>
        <Tabs
          value={mode}
          onChange={(e, newMode) => setMode(newMode)}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Find" />
          { canReplace && <Tab label="Find & Replace" /> }
        </Tabs>
        <div className={classes.closeButton}>
          <IconButton size="small" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>

      <div className={classes.body}>
        <TextField
          autoFocus
          id={ID_TXT_FIND}
          label="Search For"
          variant="outlined"
          size="small"
          fullWidth
          type="search"
          value={find}
          helperText={message}
          onChange={(e) => { setFind(e.target.value); setMessage(null); }}
          onKeyDown={(e) => handleKeyDown(e)}
        />
        {
          canReplace && mode === MODE.REPLACE && (
            <TextField
              id={ID_TXT_REPLACE}
              label="Replace With"
              variant="outlined"
              size="small"
              fullWidth
              type="search"
              value={replace}
              onChange={(e) => setReplace(e.target.value)}
              onKeyDown={(e) => handleKeyDown(e)}
            />
          )
        }
      </div>

      <FormControl component="fieldset" className={classes.options}>
        <FormGroup row>
          <FormControlLabel
            control={<Checkbox color="primary" checked={options.entireCell} onChange={handleOptionsChange} name="entireCell" />}
            label="Entire cells"
          />
          <FormControlLabel
            control={<Checkbox color="primary" checked={options.matchCase} onChange={handleOptionsChange} name="matchCase" />}
            label="Match case"
          />
        </FormGroup>
      </FormControl>

      <div className={classes.buttons}>
        {
          canReplace && mode === MODE.REPLACE && (
            <>
              <Button color="primary" onClick={() => handleFindReplace(true)} disabled={!find.length}>Replace Next</Button>
              <Button color="primary" onClick={() => { handleFindReplaceAll(true); onClose(); }} disabled={!find.length}>Replace All</Button>
            </>
          )
        }
        <Button color="primary" onClick={() => handleFindReplace(false)} disabled={!find.length}>Find Next</Button>
      </div>
    </div>
  );
}

SearchPane.defaultProps = {
  onClose: () => { },
  onFound: () => { },
  visible: false,
  searchFunction: () => null,
  startPosition: null,
  defaultMode: MODE.FIND,
  canReplace: true,
};

SearchPane.propTypes = {
  ...withStylesPropTypes,
  onClose: PropTypes.func,
  onFound: PropTypes.func,
  searchFunction: PropTypes.func,
  startPosition: PropTypes.shape(),
  visible: PropTypes.bool,
  defaultMode: PropTypes.number,
  canReplace: PropTypes.bool,
};

export default withStyles(useStyles)(SearchPane);
