import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { resetPayloadViewSpecificSelection } from '../../app/payloadSlice';
import { selectPayloadsByListId, selectPayloadLastHistoryById } from '../../app/payloadSelectors';
import { selectListById } from '../../app/listSelectors';
import { withStylesPropTypes, useMountEffect } from '../../helper/misc';
import { isValidDate, yyToYYYY } from '../../helper/date';
import Config from '../../config';
import { getListSignatures, parsePayloadFileName, parsePayloadFileNameForExcel } from '../../helper/payloadmisc';
import NavigationByPayload from './navigationbypayload';
import UploadFile from '../common/uploadfile';
import HISTORY_ACTIONS from '../../const/history';

const useStyles = ((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    ...theme.mixins.header,
    fontSize: '1.1rem',
    '&>*': {
      flexGrow: 1,
      flexBasis: 0,
    },
    '&>button': {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      backgroundColor: 'transparent',
      border: 'none',
      textAlign: 'left',
      minHeight: theme.spacing(4),
      fontSize: 'inherit',
    },
  },
  activeButton: {
    color: theme.palette.primary.main,
    border: `solid 1px ${theme.palette.primary.main} !important`,
  },
  pane: {
    flexGrow: '1',
    minHeight: '0',
  },
  errorUploadFile: {
    left: theme.spacing(3),
    right: theme.spacing(3),
  },
}));

const SEARCH_BY = {
  PAYLOAD: 0,
  LOCATION: 1,
};

function NavigationQA({
  classes,
  listById,
  payloadsByListId,
  payloadLastHistoryById,
  fnResetPayloadViewSpecificSelection,
}) {
  useMountEffect(() => {
    fnResetPayloadViewSpecificSelection();
  });

  const [searchBy, setSearchBy] = useState(SEARCH_BY.PAYLOAD);

  const processPayloadUpload = (list, file, payloadFileName) => {
    const {
      listName, mm, dd, yy,
    } = payloadFileName;
    const yyyy = yyToYYYY(yy);
    if (!isValidDate(mm, dd, yyyy)) throw new Error(`Invalid date in file name ${mm}/${dd}/${yyyy}`);

    return {
      url: `${Config.apiEndpoint}/upload`,
      data: {
        raw: true,
        listId: list.listId,
        csvFile: file,
      },
    };
  };

  const processPayloadUploadForExcel = (list, file, payloadFileName) => {
    const {
      listId,
      payloadId,
    } = payloadFileName;
    if (list.listId !== listId) {
      const expectedList = listById[listId];
      throw new Error(`The uploaded file is for another list - ${(expectedList || {}).shortName || 'unknown'}`);
    }
    const payloads = payloadsByListId[listId];
    if (!payloads || !payloads.allIds || payloads.allIds.findIndex((id) => id === payloadId) < 0) {
      throw new Error('Can\'t find a payload for the uploaded file');
    }

    const history = payloadLastHistoryById[payloadId];
    if (!history || history.action !== HISTORY_ACTIONS.DOWNLOAD_CSV || history.fileName !== file.name) {
      throw new Error('The payload was modified after the uploaded file was downloaded');
    }

    return {
      url: `${Config.apiEndpoint}/upload`,
      data: {
        csvFile: file,
        payloadId,
      },
    };
  };

  const getUploadParams = (list, file) => {
    if (file && list) {
      let payloadFileName = parsePayloadFileName(file.name);
      if (payloadFileName) return processPayloadUpload(list, file, payloadFileName);

      payloadFileName = parsePayloadFileNameForExcel(file.name);
      if (payloadFileName) return processPayloadUploadForExcel(list, file, payloadFileName);

      throw new Error('Invalid file name. For the QAE it should match the pattern <list-name>_<mm>-<dd>-<yy>.csv. To upload previously downloaded file keep the file name unchanged.');
    }
    return undefined;
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div>
          Search By:
        </div>
        <button className={clsx(searchBy === SEARCH_BY.PAYLOAD && classes.activeButton)} type="button" onClick={() => setSearchBy(SEARCH_BY.PAYLOAD)}>
          Payload
        </button>
        <button className={clsx(searchBy === SEARCH_BY.LOCATION && classes.activeButton)} type="button" onClick={() => setSearchBy(SEARCH_BY.LOCATION)}>
          Location
        </button>
      </div>
      <div className={classes.pane}>
        { searchBy === SEARCH_BY.PAYLOAD
          && (
          <NavigationByPayload
            extraPayloadComponentRenderer={(list) => (
              <UploadFile onFileSelected={(file) => getUploadParams(list, file)} classes={{ error: classes.errorUploadFile }} />
            )}
          />
          )}
        { searchBy === SEARCH_BY.LOCATION && <>By Location</> }
      </div>
    </div>
  );
}


NavigationQA.propTypes = {
  ...withStylesPropTypes,
};

const mapStateToProps = (state, props) => ({
  listById: selectListById(state, props),
  payloadsByListId: selectPayloadsByListId(state, props),
  payloadLastHistoryById: selectPayloadLastHistoryById(state, props),
});

export default connect(mapStateToProps, {
  fnResetPayloadViewSpecificSelection: resetPayloadViewSpecificSelection,
})(withStyles(useStyles)(NavigationQA));
