import React, { useState,useEffect,useContext } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { Tabs, Tab } from '@material-ui/core';
import { withStylesPropTypes } from '../../helper/misc';
import NavigationQA from './navigationqa';
import History from './history';
import Editing from './editing';
import {HomeScreen} from '../../pages/home';

const useStyles = ((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
    height: '100%',
  },
  tabBar: {
    flexGrow: '0',
    fontSize: '1.1rem',
    backgroundColor: theme.palette.background.toolbar,
  },
  tab: {
    display: 'block',
    flexGrow: '1',
    overflow: 'hidden',
    '& > div': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
  },
  hidden: {
    display: 'none !important',
  },
}));

const TABS = {
  NAVIGATION: 0,
  EDITING: 1,
  HISTORY: 2,
};


function StationsBoardWidgetQA({ classes }) {
  const [currentTab, setCurrentTab] = useState(TABS.NAVIGATION);

  const { handleChangeSync, editForSync } = useContext(HomeScreen);

  useEffect(() => {
    if (editForSync) handleChangeSync()
  }, [currentTab])


  return (
    <div className={classes.root}>
      <Tabs
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
        value={currentTab}
        onChange={(event, value) => setCurrentTab(value)}
        className={classes.tabBar}
      >
        <Tab label="Navigation" value={TABS.NAVIGATION} />
        <Tab label="Editing" value={TABS.EDITING} />
        <Tab label="History" value={TABS.HISTORY} />
      </Tabs>

      <div className={classes.tab}>
        <div className={clsx(currentTab !== TABS.NAVIGATION && classes.hidden)}><NavigationQA /></div>
        { currentTab === TABS.EDITING && <Editing /> }
        { currentTab === TABS.HISTORY && <History /> }
      </div>
    </div>
  );
}

StationsBoardWidgetQA.propTypes = {
  ...withStylesPropTypes,
};

export default withStyles(useStyles)(StationsBoardWidgetQA);
