import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import { withStyles, useTheme } from '@material-ui/core/styles';
import { withStylesPropTypes } from '../../helper/misc';

const useStyles = ((theme) => ({
  root: {
    color: theme.palette.text.primary,
    fontStyle: 'italic',
  },

  block: {
    position: 'absolute',
    right: '0px',
    bottom: '0px',
    top: '0px',
    left: '0px',
    backgroundColor: theme.palette.border.primary,
    zIndex: 1000,
    opacity: 0.5,
    height: 'unset !important',
  },

  blockRoot: {
    zIndex: 1001,
    position: 'absolute',
    right: theme.spacing(4),
    bottom: theme.spacing(4),
    display: 'flex',
    height: 'unset !important',
    '&>$root': {
    },
  },
  fab: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    color: `${theme.palette.text.primary} !important`,
    backgroundColor: `${theme.palette.background.paper} !important`,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  blockProgress: {
    position: 'absolute',
  },
}));

function Loading({
  classes, inline, text, progress,
}) {
  const theme = useTheme();
  return inline ? (
    <div className={classes.root}>
      <CircularProgress size="1em" />
      {' '}
      { text }
    </div>
  ) : (
    <>
      <div className={classes.block} />
      <div className={classes.blockRoot}>
        <div className={classes.root}>
          <CircularProgress
            size={inline ? '5em' : `${theme.spacing(12)}px`}
            variant={progress != null ? 'static' : 'indeterminate'}
            value={progress}
            classes={{ root: classes.blockProgress }}
          />
          <Fab disabled classes={{ root: classes.fab }}>
            {text}
          </Fab>
        </div>
      </div>
    </>
  );
}

Loading.defaultProps = {
  inline: false,
  text: 'Loading...',
  progress: null,
};

Loading.propTypes = {
  ...withStylesPropTypes,
  inline: PropTypes.bool,
  text: PropTypes.string,
  progress: PropTypes.number,
};

export default withStyles(useStyles)(Loading);
