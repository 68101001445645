import React, { useEffect, useState , useContext} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import clsx from 'clsx';
import { AutoSizer, Table, Column } from 'react-virtualized';
import { withStylesPropTypes, formatDate } from '../../helper/misc';
import { MAX_DATE } from '../../helper/date';
import { expandList, setSelectedListId } from '../../app/listSlice';
import { selectLists, selectExpandedListIds } from '../../app/listSelectors';
import {
  fetchPayloads, setPayloadSelected,
} from '../../app/payloadSlice';
import {
  selectPayloadsByListId, selectPayloadById, selectSelectedPayloadIds, selectSelectedPayloadsWithList,
} from '../../app/payloadSelectors';
import { selectLockRecordByRecordType } from '../../app/lockRecordSelectors';
import ListPayloadCell from './listpayloadcell';
import SortableTableHeader from './sortabletableheader';
import Confirm from '../common/confirm';
import { HomeScreen } from '../../pages/home';

const useStyles = ((theme) => ({
  row: {
    display: 'flex',
    lineHeight: `${theme.spacing(4)}px`,
    '&>div': {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      borderRight: `solid 1px ${theme.palette.border.primary}`,
    },
    '&>div:first-child': {
      paddingLeft: theme.spacing(3),
    },
    '&>div:last-child': {
      paddingRight: theme.spacing(3),
      borderRight: 'none',
      borderLeft: `solid 1px ${theme.palette.border.primary}`,
    },
    '&>aside': {
      position: 'absolute',
      top: theme.spacing(4),
      width: '100%',
    },
  },
  bodyRow: {
    borderBottom: `solid 1px ${theme.palette.border.primary}`,
  },
  headerRow: {
    borderTop: `solid 1px ${theme.palette.border.primary}`,
  },
  table: {
    '& *:focus': {
      outline: 'none',
    },
  },
}));

const FIELDS = {
  NAME: 'shortName',
};

let tableRef;

function ListPayloadTable({
  classes,
  lists: filteredLists,
  selectedPayloads,
  columns,
  payloadsSelectable,
  listsSelectable,
  multiListSelect,
  extraPayloadComponentRenderer,
  fnExpandList,
  fnSetSelectedListId,
  fnFetchPayloads,
  fnSetPayloadSelected,
}) {
  const [currentSort, setCurrentSort] = useState({ key: FIELDS.NAME, direction: 1 });
  const [lists, setLists] = useState(filteredLists);
  const [confirmDlg, setConfirmDlg] = useState();
  const {resetCustomDedupe} = useContext(HomeScreen);

  useEffect(() => {
    if (tableRef) tableRef.recomputeRowHeights();
  });

  useEffect(() => {
    if (currentSort) {
      let type = 'string';
      if (currentSort.key !== FIELDS.NAME) {
        const column = columns.find((c) => c.dataKey === currentSort.key);
        if (column) type = column.type;
      }

      switch (type) {
        case 'string':
          filteredLists.sort((a, b) => currentSort.direction * (a[currentSort.key] || '').localeCompare(b[currentSort.key] || ''));
          break;

        case 'number':
          filteredLists.sort((a, b) => {
            let ret = currentSort.direction * ((a[currentSort.key] || 0) - (b[currentSort.key] || 0));
            if (ret === 0) ret = currentSort.direction * (a.shortName || '').localeCompare(b.shortName || '');
            return ret;
          });
          break;

        case 'date':
          filteredLists.sort((a, b) => {
            const d1 = a[currentSort.key] ? new Date(a[currentSort.key]) : MAX_DATE;
            const d2 = b[currentSort.key] ? new Date(b[currentSort.key]) : MAX_DATE;
            let ret = currentSort.direction * (d1.getTime() - d2.getTime());
            if (ret === 0) ret = currentSort.direction * (a.shortName || '').localeCompare(b.shortName || '');
            return ret;
          });
          break;

        default:
          break;
      }
    }
    setLists(filteredLists);
  }, [currentSort, columns, filteredLists]);

  const showListPayloads = (index) => {
    const list = lists[index];
    fnExpandList({ id: list.listId, collapse: !!list.expanded });
    if (!list.payloads) fnFetchPayloads(list.listId);
  };

  const handlePayloadSelect = (payload, select) => {
    if (payloadsSelectable) {
      let confirm;
      if (select) {
        const { scrapedAt, listId } = payload;
        const list = lists.find((l) => l.listId === listId);
        if (list && selectedPayloads.length) {
          const { shortName } = list;
          const to = `${shortName}:${formatDate(scrapedAt)}`;
          const [prevPayload] = selectedPayloads;
          if (prevPayload.list) {
            const from = `${prevPayload.list.shortName}:${formatDate(prevPayload.scrapedAt)}`;
            confirm = { from, to };
          }
        }
      }
      else{
        resetCustomDedupe();
      }
      if (confirm) {
        setConfirmDlg({
          title: 'Warning',
          text: `Are you sure you want to leave ${confirm.from} to open ${confirm.to}?`,
          buttons: ['Yes', 'No'],
          focusedButton: 'Yes',
          onClose: (answer) => {
            setConfirmDlg();
            if (answer === 'Yes') {
              fnSetPayloadSelected(payload._id, select);
              resetCustomDedupe();
            }
          },
        });
      } else {
        fnSetPayloadSelected(payload._id, select);
      }
    }
  };

  const handleListSelect = (index) => {
    if (listsSelectable) {
      fnSetSelectedListId({ id: lists[index].listId });
    } else {
      showListPayloads(index);
    }
  };

  const calculateRowHeigh = (index) => {
    const list = lists[index];
    let ret = 32;
    if (list.expanded) {
      if (list.payloadsLoading || list.payloadsError) {
        ret += 32;
      } else {
        if (list.payloads) ret += list.payloads.length * 24;
        if (extraPayloadComponentRenderer) ret += 32;
      }
    }
    return ret;
  };

  return (
    <>
      <AutoSizer>
        {({ height, width }) => (
          <Table
            height={height}
            width={width}
            rowHeight={({ index }) => calculateRowHeigh(index)}
            headerHeight={32}
            rowClassName={({ index }) => clsx(classes.row, index >= 0 && classes.bodyRow, index < 0 && classes.headerRow)}
            className={classes.table}
            rowCount={lists.length}
            rowGetter={({ index }) => lists[index]}
            ref={(ref) => { tableRef = ref; }}
          >
            <Column
              label="List"
              dataKey={FIELDS.NAME}
              width={width / (columns.length + 1) - 24}
              flexGrow={1}
              headerRenderer={({ label, dataKey }) => (
                <SortableTableHeader
                  label={label}
                  dataKey={dataKey}
                  sort={currentSort}
                  onSort={(sort) => setCurrentSort(sort)}
                />
              )}
              cellRenderer={(params) => (
                <ListPayloadCell
                  cellData={params.cellData}
                  rowData={params.rowData}
                  columnIndex={params.columnIndex}
                  rowIndex={params.rowIndex}
                  payloadsSelectable={payloadsSelectable}
                  multiListSelect={multiListSelect}
                  onListExpand={() => showListPayloads(params.rowIndex)}
                  onListSelect={() => handleListSelect(params.rowIndex)}
                  onPayloadSelect={(payload, select) => handlePayloadSelect(payload, select)}
                  extraPayloadComponentRenderer={extraPayloadComponentRenderer}
                />
              )}
            />
            {
              columns.map((column) => (
                <Column
                  key={column.dataKey}
                  label={column.title}
                  dataKey={column.dataKey}
                  cellDataGetter={column.dataGetter}
                  width={width / (columns.length + 1)}
                  flexGrow={1}
                  headerRenderer={({ label, dataKey }) => (
                    <SortableTableHeader
                      label={label}
                      dataKey={dataKey}
                      sort={currentSort}
                      onSort={(sort) => setCurrentSort(sort)}
                    />
                  )}
                  cellRenderer={(params) => (
                    <ListPayloadCell
                      cellData={params.cellData}
                      rowData={params.rowData}
                      columnIndex={params.columnIndex}
                      rowIndex={params.rowIndex}
                      extraPayloadComponentRenderer={extraPayloadComponentRenderer}
                      payloadRenderer={column.payloadCellRenderer}
                      payloadClass={column.payloadCellClass}
                    />
                  )}
                />
              ))
            }
          </Table>
        )}
      </AutoSizer>
      {
        confirmDlg && (
        <Confirm
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...confirmDlg}
        />
        )
      }
    </>
  );
}


const selectNameFilter = (state, props) => props.nameFilter;

const selectLockRecordType = (state, props) => props.lockRecordType;

const selectPayloadsFilter = (state, props) => props.payloadsFilter;

const selectFilteredList = createSelector(
  [selectLists, selectNameFilter],
  (lists, nameFilter) => {
    let ret = lists;
    if (nameFilter && nameFilter.length) {
      ret = lists.filter((list) => (list.shortName.indexOf(nameFilter) >= 0));
    }
    return ret;
  },
);

const selectLockedListIds = createSelector(
  [selectLockRecordType, selectLockRecordByRecordType],
  (lockRecordType, lockedRecordByReportType) => (lockRecordType && lockedRecordByReportType[lockRecordType]) || {},
);

const selectListsWithPayloads = createSelector(
  [selectFilteredList, selectExpandedListIds, selectPayloadsByListId, selectPayloadById, selectSelectedPayloadIds, selectLockedListIds, selectPayloadsFilter],
  (lists, expandedListIds, payloadsByListId, payloadById, selectedPayloadIds, lockedListIds, payloadsFilter) => lists.map((list) => ({
    ...list,
    locked: !!lockedListIds[list.listId],
    expanded: !!expandedListIds[list.listId],
    payloadsLoading: payloadsByListId[list.listId] ? payloadsByListId[list.listId].isLoading : false,
    payloadsError: payloadsByListId[list.listId] ? payloadsByListId[list.listId].error : null,
    payloads: payloadsByListId[list.listId] ? payloadsByListId[list.listId].allIds.map((pid) => ({
      ...payloadById[pid],
      selected: !!selectedPayloadIds[pid],
    })).filter(payloadsFilter || (() => true)).sort((a, b) => b.scrapedAt.localeCompare(a.scrapedAt)) : null,
  })),
);

const mapStateToProps = (state, props) => ({
  lists: selectListsWithPayloads(state, props),
  selectedPayloads: selectSelectedPayloadsWithList(state, props),
});

ListPayloadTable.defaultProps = {
  selectedPayloads: [],
  payloadsSelectable: true,
  listsSelectable: false,
  multiListSelect: false,
  extraPayloadComponentRenderer: null,
  columns: [],
  payloadsFilter: undefined,
};

ListPayloadTable.propTypes = {
  ...withStylesPropTypes,
  lists: PropTypes.arrayOf(PropTypes.shape).isRequired,
  selectedPayloads: PropTypes.arrayOf(PropTypes.shape),
  payloadsSelectable: PropTypes.bool,
  listsSelectable: PropTypes.bool,
  multiListSelect: PropTypes.bool,
  extraPayloadComponentRenderer: PropTypes.func,
  columns: PropTypes.arrayOf(PropTypes.shape()),
  payloadsFilter: PropTypes.func,
};

export default connect(mapStateToProps, {
  fnExpandList: expandList,
  fnSetSelectedListId: setSelectedListId,
  fnFetchPayloads: fetchPayloads,
  fnSetPayloadSelected: setPayloadSelected,
})(withStyles(useStyles)(ListPayloadTable));
