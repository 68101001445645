import { createSelector } from '@reduxjs/toolkit';
import { selectSelectedPayloadsWithHistory, selectPayloadById } from './payloadSelectors';

export const locationDataFields = (location) => Object.keys(location).filter((key) => key !== '_id');

export const selectLocationsLoading = (state) => state.locations.isLoading;

export const selectLocationsSaving = (state) => state.locations.isSaving;

export const selectLocationsError = (state) => state.locations.error;

export const selectLocationIdsByPayloadId = (state) => state.locations.byPayloadId;

export const selectHistoryByPayloadId = (state) => state.locations.historyByPayloadId;

export const selectLocationsById = (state) => state.locations.byId;

export const selectSelectedLocationIds = (state) => state.locations.selectedLocationIds;

export const selectCurrentLocationId = (state) => state.locations.currentLocationId;

export const selectCurrentField = (state) => state.locations.currentField;

export const selectSelectedPayloadLocations = createSelector(
  [selectSelectedPayloadsWithHistory, selectLocationIdsByPayloadId, selectLocationsById],
  (selectedPayloads, locationIdsByPayloadId, locationsById) => {
    const ret = [];

    selectedPayloads.forEach((payload) => {
      const locationIds = locationIdsByPayloadId[payload._id];
      if (locationIds) {
        ret.push(...locationIds.allIds.map((locationId) => {
          const location = { ...locationsById[locationId] };
          locationDataFields(location).forEach((key) => {
            const val = location[key];
            location[key] = val && val.formula ? val.formula : val;
          });
          return {
            ...location,
            payload,
          };
        }));
      }
    });

    return ret;
  },
);

export const selectCurrentLocation = createSelector(
  [selectCurrentLocationId, selectLocationsById],
  (currentLocationId, locationsById) => locationsById[currentLocationId],
);

export const selectCurrentLocationWithPayload = createSelector(
  [selectCurrentLocation, selectPayloadById],
  (currentLocation, payloadById) => ({ ...currentLocation, payload: currentLocation && payloadById[currentLocation.payloadId] }),
);

export const selectCurrentLocationAndField = createSelector(
  [selectCurrentLocation, selectCurrentField],
  (location, field) => (location && field ? { location, field } : undefined),
);
