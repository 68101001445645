import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from "@material-ui/core/styles";
import { withStylesPropTypes } from "../../helper/misc";
import red from "@material-ui/core/colors/red";

const useStyles = (theme) => ({
  
  paper: {
    position: "absolute",
    width: 450,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
  },
  header: {
      display: 'flex',
      justifyContent: 'flex-end'
  },
  content: {
      textAlign : 'center',
      padding : "20px"
    
  },
  singleButton: {
      display : 'flex',
      padding : '0 15px 15px 0',
      justifyContent: 'flex-end'
  },
  multiButton: {
    display : 'flex',
    padding : '0 15px 15px 15px',
    justifyContent: 'space-around'
}
});

function BasicModal({ classes, text, buttons, focusedButton, title, onClose}) {
   
    const [open, setOpen] = useState(true);

    const handleClose = (result) => {
      setOpen(false);
      onClose(result);
    };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={classes.paper}>
          <div className={classes.header}>
                <IconButton onClick={handleClose}>
                    <CloseIcon style={{color : "red"}}/>
                </IconButton>
          </div>
          <hr style={{margin : 0}}></hr>
          <div className={classes.content}>
            {text}
            </div>
           
            <div className={buttons.length > 1 ? classes.multiButton : classes.singleButton}>
            {
                buttons.map((b) => (<Button key={b} onClick={() => handleClose(b)} variant="contained" color="primary">{b}</Button>))
            }
          </div>
       
      </div>
    </Modal>
  );
}

BasicModal.propTypes = {
  ...withStylesPropTypes,
};

export default withStyles(useStyles)(BasicModal);