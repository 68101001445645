import { createSlice } from '@reduxjs/toolkit';
import api from '../api/list';
import normalize from '../helper/normalize';
import { setLoadingProgress, fetchMultiPage } from './helper';

export const slice = createSlice({
  name: 'list',
  initialState: {
    byId: {},
    allIds: [],
    isLoading: false,
    loadingProgress: null,
    error: false,
    expandedListIds: {},
    selectedListId: null,
  },
  reducers: {
    getListsStart: (state) => {
      state.isLoading = true;
      state.loadingProgress = null;
    },
    addListsPage: (state, { payload }) => {
      const { docs } = payload;
      state.error = null;
      const [allIds, byId] = normalize(docs, 'listId');
      state.byId = { ...state.byId, ...byId };
      state.allIds.push(...allIds);
      setLoadingProgress(state, { payload });
    },
    getListsFailed: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload.error;
    },
    getListsSuccess: (state) => {
      state.isLoading = false;
      state.error = null;
    },
    expandList: (state, { payload }) => {
      const { id, collapse } = payload;
      if (collapse) delete state.expandedListIds[id];
      else state.expandedListIds[id] = true;
    },
    setSelectedListId: (state, { payload }) => {
      const { id } = payload;
      state.selectedListId = id;
    },
    fieldToMatch: (state, { payload }) => {
      const { listId, field, selected } = payload;
      const list = state.byId[listId];
      if (list) {
        if (!list.fieldsToMatch) list.fieldsToMatch = {};
        if (selected) list.fieldsToMatch[field] = true;
        else delete list.fieldsToMatch[field];
      }
    },
    setCortexMatchType: (state, { payload }) => {
      const { listId, matchType } = payload;
      const list = state.byId[listId];
      if (list) {
        list.cortexMatchType = matchType;
      }
    },
    setMatchDistance: (state, { payload }) => {
      const { listId, distance } = payload;
      const list = state.byId[listId];
      if (list) {
        list.matchDistance = distance;
      }
    },
    setMatchFilter: (state, { payload }) => {
      const { listId, matchFilter } = payload;
      const list = state.byId[listId];
      if (list) {
        list.matchFilter = matchFilter;
      }
    },
    updateList: (state, { payload }) => {
      const { listId, ...changes } = payload;
      const list = state.byId[listId];
      if (list) {
        state.byId[listId] = { ...list, ...changes };
      }
    },
  },
});

export const {
  getListsStart,
  addListsPage,
  getListsSuccess,
  getListsFailed,
  expandList,
  setSelectedListId,
  fieldToMatch,
  setCortexMatchType,
  setMatchDistance,
  setMatchFilter,
  updateList,
} = slice.actions;

export default slice.reducer;

export const fetchLists = () => (dispatch) => {
  fetchMultiPage(
    (page) => api.fetchLists(page),
    () => dispatch(getListsStart()),
    (result) => dispatch(addListsPage(result)),
    () => dispatch(getListsSuccess()),
    (error) => dispatch(getListsFailed({ error })),
  );
};
