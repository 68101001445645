import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { withStylesPropTypes } from '../../helper/misc';

const useStyles = (() => ({
  root: {
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

function EllipsisTooltip({
  classes, text,
}) {
  const [showTooltip, setShowTooltip] = useState(false);
  const [divRef, setDivRef] = useState();

  const handleTooltipOpen = () => {
    const clip = text && divRef && divRef.scrollWidth > divRef.clientWidth;
    setShowTooltip(clip);
  };


  return (
    <Tooltip
      title={text}
      interactive
      onOpen={handleTooltipOpen}
      onClose={() => setShowTooltip(false)}
      open={showTooltip}
    >
      <div className={classes.root} ref={(ref) => setDivRef(ref)}>{text}</div>
    </Tooltip>
  );
}

EllipsisTooltip.defaultProps = {
  text: null,
};

EllipsisTooltip.propTypes = {
  ...withStylesPropTypes,
  text: PropTypes.string,
};

export default withStyles(useStyles)(EllipsisTooltip);
