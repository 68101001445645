import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { withStylesPropTypes, escapeRegex } from '../../../helper/misc';

const useStyles = ((theme) => ({
  paper: {
    overflow: 'initial',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  preview: {
    width: '100%',
    maxHeight: '150px',
    overflowY: 'auto',
    '&>table': {
      width: '100%',
      '& th': {
        textAlign: 'left',
      },
    },
  },
}));

const SEPARATOR_TYPE = {
  CHARACTER: 'character',
  FIXED_WIDTH: 'fixedWidth',
};


function TextToColumnsDlg({
  classes,
  visible,
  data,
  onClose,
}) {
  const [separatorType, setSeparatorType] = useState(SEPARATOR_TYPE.CHARACTER);
  const [separatorChars, setSeparatorChars] = useState();
  const [columnWidth, setColumnWidth] = useState();
  const [splitResult, setSplitResult] = useState([]);
  const [splitColumns, setSplitColumns] = useState(0);

  useEffect(() => {
    const splitCharcaters = (s, separators) => {
      const src = (s || '');
      return separators ? src.split(new RegExp(`[${escapeRegex(separators)}]`, 'gi')).filter((i) => !!i) : [src];
    };

    const splitWidth = (s, width) => {
      const src = s || '';
      if (Number.isNaN(width) || width < 1) return [src];
      const ret = [];

      let idx = 0;
      do {
        ret.push(src.substr(idx, width));
        idx += width;
      } while (idx < src.length);

      return ret;
    };


    let result = [];
    let columns = 0;
    if (data) {
      result = data.map(({ cell, value }) => {
        const item = { cell };

        switch (separatorType) {
          case SEPARATOR_TYPE.CHARACTER:
            item.values = splitCharcaters(value, separatorChars);
            break;

          case SEPARATOR_TYPE.FIXED_WIDTH:
            item.values = splitWidth(value, Number(columnWidth));
            break;

          default:
            item.values = [value];
            break;
        }

        if (columns < item.values.length) columns = item.values.length;
        return item;
      }).filter((i) => !!i);
    }
    setSplitResult(result);
    setSplitColumns(columns);
  }, [data, separatorType, separatorChars, columnWidth]);

  const handleClose = () => {
    onClose();
  };

  const handleOK = () => {
    onClose(splitResult);
  };

  return visible && (
  <Dialog open onClose={onClose} maxWidth="xs" fullWidth classes={{ paper: classes.paper }}>
    <DialogTitle>
      <Typography>Text to Columns</Typography>
      <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent>
      <FormControl component="fieldset" fullWidth>
        <FormLabel component="legend">Choose the separator type:</FormLabel>
        <RadioGroup name="groupBy" value={separatorType} onChange={(e, v) => setSeparatorType(v)}>
          <FormControlLabel value={SEPARATOR_TYPE.CHARACTER} control={<Radio color="primary" />} label="Character" />
          {
            separatorType === SEPARATOR_TYPE.CHARACTER && (
              <TextField
                label="Separator characters"
                variant="outlined"
                size="small"
                fullWidth
                value={separatorChars || ''}
                onChange={(e) => setSeparatorChars(e.target.value)}
              />
            )
          }
          <FormControlLabel value={SEPARATOR_TYPE.FIXED_WIDTH} control={<Radio color="primary" />} label="Fixed Width" />
          {
            separatorType === SEPARATOR_TYPE.FIXED_WIDTH && (
              <TextField
                label="Column width"
                variant="outlined"
                size="small"
                fullWidth
                type="number"
                inputProps={{
                  min: 1,
                  max: 100,
                }}
                value={columnWidth || ''}
                onChange={(e) => setColumnWidth(e.target.value)}
              />
            )
          }
        </RadioGroup>
      </FormControl>
      <div className={classes.preview}>
        <table>
          <thead>
            <tr>
              {
                Array.from({ length: splitColumns }, (_, i) => `Column ${i + 1}`).map((c) => (
                  <th key={c}>{c}</th>
                ))
              }
            </tr>
          </thead>
          <tbody>
            { splitResult.map((item) => (
              <tr key={`${item.cell.col}-${item.cell.row}`}>
                {
                item.values.map((v, idx) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <td key={idx}>{ v }</td>
                ))
              }
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleOK} color="primary">
        OK
      </Button>
      <Button onClick={handleClose} color="primary">
        Cancel
      </Button>
    </DialogActions>
  </Dialog>
  );
}

TextToColumnsDlg.defaultProps = {
  onClose: () => { },
  visible: false,
  data: [],
};

TextToColumnsDlg.propTypes = {
  ...withStylesPropTypes,
  onClose: PropTypes.func,
  visible: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape()),
};

export default withStyles(useStyles)(TextToColumnsDlg);
