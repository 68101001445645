import React from 'react';
import { Route, BrowserRouter as Router, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Home from './pages/home';
import Login from './pages/login';
import Logs from './pages/logs';
import User from './helper/user';
import ErrorBoundary from './errorboundary';

const ProtectedRoute = ({
  component: Component, path, exact,
}) => (
  <Route
    path={path}
    exact={exact}
    render={() => (
      (User.getId())
        ? <Component /> : <Redirect to={{ pathname: '/login' }} />
    )}
  />
);

ProtectedRoute.defaultProps = {
  exact: false,
};

ProtectedRoute.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.elementType.isRequired,
  exact: PropTypes.bool,
};

const AppRouter = () => (
  <ErrorBoundary>
    <Router>
      <ProtectedRoute path="/" exact component={Home} />
      <ProtectedRoute path="/logs" exact component={Logs} />
      <Route path="/login" component={Login} />
    </Router>
  </ErrorBoundary>
);

export default AppRouter;
