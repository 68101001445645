import { connect } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { setLocationSelected } from '../../app/locationsSlice';
import { selectSelectedLocationIds } from '../../app/locationsSelectors';
import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withStylesPropTypes } from '../../helper/misc';
import KeyboardHandle from '../../helper/keyboard';

const useStyles = ((theme) => ({
  root: {
    width: '100%',
    lineHeight: '1em',
    textAlign: 'center',
    cursor: 'pointer',
  },
  box: {
    width: '1em',
    height: '1em',
    lineHeight: '1em',
    textAlign: 'center',
    margin: 2,
    border: `solid 1px ${theme.palette.text.primary}`,
    color: theme.palette.text.primary,
    display: 'inline-block',
    boxSizing: 'content-box !important',
    '&>span': {
      display: 'inline-block',
      transform: 'scaleX(-1) rotate(-45deg)',
      fontWeight: 'bold',
    },
  },
}));


function LocationSheetQaSelect ({
  classes, value, selected, keyDownEvent,
}) {
  useEffect(() => {
    if (keyDownEvent) {
      KeyboardHandle(keyDownEvent, {
        onSpaceBar: handleSelect,
      });
    }
  },
  [keyDownEvent]);

  const handleSelect = useCallback(() => {
    setLocationSelected({ id: value, select: !selected });
  }, [setLocationSelected, value, selected]);

  return (
    <div className={classes.root}>
      <div className={classes.box} onClick={handleSelect}>
        { selected && (<span>L</span>) }
      </div>
    </div>
  );
}

LocationSheetQaSelect.defaultProps = {
  value: null,
  keyDownEvent: undefined,
};

LocationSheetQaSelect.propTypes = {
  ...withStylesPropTypes,
  value: PropTypes.string,
  selected: PropTypes.bool.isRequired,
  keyDownEvent: PropTypes.shape(),
};

export default withStyles(useStyles)(LocationSheetQaSelect);

