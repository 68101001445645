/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withStylesPropTypes } from '../../helper/misc';
import KeyboardHandle from '../../helper/keyboard';

const useStyles = ((theme) => ({
  root: {
    width: '100%',
    lineHeight: '1em',
    textAlign: 'center',
    cursor: 'pointer',
  },
  box: {
    width: '1em',
    height: '1em',
    lineHeight: '1em',
    textAlign: 'center',
    margin: 2,
    border: `solid 1px ${theme.palette.text.primary}`,
    color: theme.palette.text.primary,
    display: 'inline-block',
    boxSizing: 'content-box !important',
    '&>span': {
      display: 'inline-block',
      transform: 'scaleX(-1) rotate(-45deg)',
      fontWeight: 'bold',
    },
  },
}));

function RowSelect({
  classes, value, selected, keyDownEvent, fnSelectLocation,
}) {
  const handleSelect = useCallback(() => {
    fnSelectLocation({ id: value, select: !selected });
  }, [fnSelectLocation, value, selected]);

  useEffect(() => {
    if (keyDownEvent) {
      KeyboardHandle(keyDownEvent, {
        onSpaceBar: handleSelect,
      });
    }
  },
  // eslint-disable-next-line
  [keyDownEvent]);

  return (
    <div className={classes.root}>
      <div className={classes.box} onClick={handleSelect}>
        { selected && (<span>L</span>) }
      </div>
    </div>
  );
}

RowSelect.defaultProps = {
  value: null,
  keyDownEvent: undefined,
};

RowSelect.propTypes = {
  ...withStylesPropTypes,
  value: PropTypes.string,
  fnSelectLocation: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  keyDownEvent: PropTypes.shape(),
};

export default withStyles(useStyles)(RowSelect);
