import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PayloadFieldsEditor from './payloadfieldseditor';
import { storePayloadAutoFields } from '../../app/payloadSlice';

const RULES = {
  CONTAIN: 'contains',
  NOT_CONTAIN: 'notContains',
  IS_BLANK: 'isBlank',
  IS_NOT_BLANK: 'isNotBlank',
};


function AutoFields({
  payload,
  fnStorePayloadAutoFields,
}) {
  const isNoValueOperator = (operator) => [RULES.IS_BLANK, RULES.IS_NOT_BLANK].indexOf(operator) >= 0;

  return (
    <PayloadFieldsEditor
      records={payload.autoFields}
      createNewRecord={() => ({ condition: {}, rule: {} })}
      validateRecord={(r) => {
        let ret = r.rule && r.rule.field && r.rule.value && r.condition && r.condition.field && r.condition.operator;
        if (ret && !isNoValueOperator(r.condition.operator)) ret = !!r.condition.value;
        return ret;
      }}
      saveRecords={(records) => fnStorePayloadAutoFields(payload._id, records)}
      fields={[
        {
          title: 'Field', size: 2, key: 'rule.field', select: Object.keys(payload.headers || {}).map((h) => ([h, payload.headers[h]])),
        },
        {
          title: 'Value', size: 2, key: 'rule.value', text: true,
        },
        {
          title: 'If', size: 1, key: 'if',
        },
        {
          title: 'Field', size: 2, key: 'condition.field', select: Object.keys(payload.headers || {}).map((h) => ([h, payload.headers[h]])),
        },
        {
          title: 'Rule',
          size: 2,
          key: 'condition.operator',
          select: [
            [RULES.CONTAIN, 'Contains'],
            [RULES.NOT_CONTAIN, 'Doesn\'t contain'],
            [RULES.IS_BLANK, 'Is blank'],
            [RULES.IS_NOT_BLANK, 'Is not blank'],
          ],
        },
        {
          title: 'Criteria',
          size: 2,
          key: 'condition.value',
          text: true,
          isHidden: (row) => isNoValueOperator(row.condition.operator),
        },
      ]}
    />
  );
}

AutoFields.propTypes = {
  payload: PropTypes.shape().isRequired,
  fnStorePayloadAutoFields: PropTypes.func.isRequired,
};

export default connect(null, {
  fnStorePayloadAutoFields: storePayloadAutoFields,
})(AutoFields);
