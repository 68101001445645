import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';
import { withStylesPropTypes } from '../../helper/misc';
import httpClient from '../../api/client';
import Error from './error';

const useStyles = ((theme) => ({
  button: {
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  input: {
    display: 'none',
  },
  error: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
  },
}));

function UploadFile({
  classes,
  onFileSelected,
}) {
  const [progress, setProgress] = useState();
  const [error, setError] = useState();

  const reset = () => {
    setError();
    setProgress();
  };

  const onUploadProgress = (event) => {
    setProgress(Math.round((100 * event.loaded) / event.total));
  };

  const handleFileSelect = (f) => {
    reset();
    try {
      const uploadParams = onFileSelected(f);
      if (uploadParams) {
        const formData = new FormData();
        Object.keys(uploadParams.data).forEach((key) => formData.append(key, uploadParams.data[key]));
        httpClient.post(uploadParams.url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress,
        }).then(() => {
          setProgress(100);
        }).catch(() => {
          setError('Failed to upload a file');
        });
      }
    } catch (e) {
      setError(e.message);
    }
  };

  return (
    <>
      { !error && progress === undefined && (
        <Button
          classes={{
            root: classes.button,
          }}
          size="small"
          variant="contained"
          color="primary"
          component="label"
        >
          Upload
          <input
            accept="text/csv"
            className={classes.input}
            type="file"
            onChange={(e) => handleFileSelect(e.target.files[0])}
          />
        </Button>
      )}
      { !error && progress !== undefined && (
        <div width={400}><LinearProgress variant="determinate" value={progress} /></div>
      )}
      { error && (
        <div className={classes.error}>
          <Error message={error} inline ellipsis />
          <IconButton color="secondary" onClick={() => reset()} size="small">
            <CloseIcon />
          </IconButton>
        </div>
      )}
      { !error && progress === 100 && (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open
          autoHideDuration={5000}
          onClose={() => reset()}
          message="File has been uploaded"
          action={(
            <>
              <IconButton size="small" aria-label="close" color="inherit" onClick={() => reset()}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          )}
        />
      )}
    </>
  );
}


UploadFile.defaultProps = {
  onFileSelected: () => { },
};

UploadFile.propTypes = {
  ...withStylesPropTypes,
  onFileSelected: PropTypes.func,
};


export default withStyles(useStyles)(UploadFile);
