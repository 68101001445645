import { getLetterIndex, letterToIndex } from '../../../helper/misc';

const getCellsBoundary = (cells) => {
  let minRow;
  let maxRow;
  let minCol;
  let maxCol;
  cells.forEach(({ row, col }) => {
    if (minRow === undefined || row < minRow) minRow = row;
    if (maxRow === undefined || row > maxRow) maxRow = row;
    if (minCol === undefined || col < minCol) minCol = col;
    if (maxCol === undefined || col > maxCol) maxCol = col;
  });
  return {
    minRow,
    maxRow,
    minCol,
    maxCol,
  };
};

export const getCells2DArray = (cells, getValue) => {
  let table = null;
  if (cells && cells.length) {
    const boundary = getCellsBoundary(cells);
    table = Array(boundary.maxRow - boundary.minRow + 1);
    for (let row = boundary.minRow; row <= boundary.maxRow; row += 1) {
      table[row - boundary.minRow] = Array(boundary.maxCol - boundary.minCol + 1);
    }
    cells.forEach(({ row, col }) => {
      table[row - boundary.minRow][col - boundary.minCol] = getValue({ row, col });
    });
  }
  return table;
};

export const fillCellsWith2DArray = (cells, matrix, mCols) => {
  let result = null;
  if (cells && cells.length && matrix && matrix.length) {
    const mRows = matrix.length;
    const boundary = getCellsBoundary(cells);
    result = cells.map(({ row, col }) => {
      const rowIdx = (row - boundary.minRow) % mRows;
      const rowData = matrix[rowIdx];
      const colIdx = (col - boundary.minCol) % mCols;
      return { row, col, ...(colIdx < rowData.length && rowData[colIdx]) };
    });
  }
  return result;
};

export const getCellUpdates = (cells, value, getRow, getColumn, getColumnKey, includeColumnCallback) => {
  const fnIncludeColumnCallback = includeColumnCallback || ((column) => column && column.editable);
  const updates = [];
  let lastRowIdx;
  let currentRowChange;
  cells.forEach((cell) => {
    if (lastRowIdx === undefined || lastRowIdx !== cell.row) {
      lastRowIdx = cell.row;
      currentRowChange = {};
      updates.push({ row: getRow(cell), changes: currentRowChange });
    }
    const column = getColumn(cell);
    if (fnIncludeColumnCallback(column)) {
      const val = typeof (value) === 'function' ? value(cell) : value;
      currentRowChange[getColumnKey(column)] = val;
    }
  });
  return updates;
};

export const cellToTextRef = (cell) => `${cell.colAbsolute ? '$' : ''}${getLetterIndex(cell.col)}${cell.rowAbsolute ? '$' : ''}${cell.row + 1}`;

export const textRefToCell = (ref) => {
  const re = /^(\$?[A-Z]+)(\$?\d+)$/g;
  const m = re.exec(ref);
  if (m) {
    let colIdx = m[1];
    let colAbs = false;
    if (colIdx.indexOf('$') === 0) {
      colAbs = true;
      colIdx = colIdx.substring(1);
    }
    let rowIdx = m[2];
    let rowAbs = false;
    if (rowIdx.indexOf('$') === 0) {
      rowAbs = true;
      rowIdx = rowIdx.substring(1);
    }
    const ret = { col: letterToIndex(colIdx), row: Number(rowIdx) - 1 };
    if (colAbs) ret.colAbsolute = true;
    if (rowAbs) ret.rowAbsolute = true;
    return ret;
  }
  return null;
};

export const equalCells = (cell1, cell2) => cell1.col === cell2.col && cell1.row === cell2.row;
