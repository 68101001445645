import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import { withStylesPropTypes } from '../../helper/misc';
import { findDuplicates } from '../../api/dedupe';
import { deleteLocations } from '../../app/locationsSlice';
import { selectSelectedPayloadIds } from '../../app/payloadSelectors';
import Confirm from './confirm';

const useStyles = ((theme) => ({
  stepper: {
    padding: '0px',
  },
  navigationControls: {
    paddingTop: '1em'
  },
  headerLabel: {
    display: 'contents'
  }
}));
function Dedupe({
  classes,
  title,
  columns,
  fnDeleteLocations,
  onClose,
  selectedPayloadIds,
}) {
  const [activeStep, setActiveStep] = useState(0)
  const [headers, setHeaders] = useState({});
  const [open, setOpen] = useState(true);
  const [running, setRunning] = useState(false);
  const [response, setResponse] = useState('');
  const [sortOrder, setSortOrder] = useState('ascending');
  const [sortName, setSortName] = useState(null);
  const [locationsIds, setLocationsIds] = useState([]);
  const [inverted, setInverted] = useState(false);
  const [deleteLocationsConfirm, setDeleteLocationsConfirm] = useState();

  const getSteps = () => {
    return ['Choose', 'Review', 'Results'];
  }
  const steps = getSteps();

  const handleSortOrderChange = (event) => {
    setSortOrder(event.target.value);
  }
  
  const handleSortNameChange = (event, value) => {
    setSortName(value);
  }
  
  const anyHeadersChecked = () => {
    return Object.values(headers).some(v => v === true);
  }
  
  const getCheckedHeaders = () => {
    return Object.keys(headers).filter(h => headers[h] === true)
  }
  
  const getAllHeaders = () => {
    return Object.keys(headers)
  }
  
  const handleInvert = (event) => {
    const setValueTo = !anyHeadersChecked();
    for (const header of Object.keys(headers)) {
      headers[header] = setValueTo;
    }
    setInverted(event.target.checked);
  }
  
  const getCheckboxes = (includeToggle) => {
    const results = [];
    for (const column of columns) {
      let header = column.title;
      // initialize header and value to true for a checkbox to be selected by default
      if (typeof headers[header] === 'undefined') {
        headers[header] = true;
      }
      results.push(
        <FormControlLabel
          key={header}
          labelPlacement='end'
          control={<Checkbox value={header} disabled={!includeToggle}/>}
          checked={headers[header]}
          label={header}
        />
      );
    }
    if (includeToggle) {
      results.push(
        <FormControlLabel
          key='toggle'
          labelPlacement='end'
          control={<Checkbox value={'toggle'}/>}
          label={anyHeadersChecked() ? 'Unselect all' : 'Select all'}
          onChange={handleInvert}
        />
      );
    }
    return results;
  }
  
  const hasDuplicates = () => {
    return locationsIds.length > 0;
  }
  
  const getSortFormGroup = (disableControls) => {
    const emptyFunction = () => {}
    const sortOrderHandler = disableControls ? handleSortOrderChange : emptyFunction;
    const sortNameHandler = disableControls ? handleSortNameChange : emptyFunction;
    return <FormGroup row>
      <FormControlLabel
        label='Sort By'
        labelPlacement='start'
        control={
          <Autocomplete
            id="combo-box-demo"
            options={getAllHeaders()}
            renderInput={(params) => <TextField {...params} />}
            onChange={sortNameHandler}
            disabled={!disableControls}
            value={sortName}
          />
        }
      />
      <FormControlLabel
        label='Sort Order'
        labelPlacement='start'
        control={
          <Select
            onChange={sortOrderHandler}
            value={sortOrder}
            disabled={!disableControls}
          >
            <MenuItem value={'ascending'}>
              A-Z
            </MenuItem>
            <MenuItem value={'descending'}>
              Z-A
            </MenuItem>
          </Select>
        }
      />
    </FormGroup>
  }
  
  const getGroups = (includeSelectAll) => {
    const emptyFunction = () => {}
    const checkboxHandler = includeSelectAll ?  handleChange : emptyFunction;
    return <>  
      {running && <LinearProgress/>}
      <FormControlLabel
        className={classes.headerLabel}
        labelPlacement='top'
        variant="outlined"
        control={
          <FormGroup onChange={checkboxHandler}>
            {getCheckboxes(includeSelectAll)}
          </FormGroup>
        }
      />
      {getSortFormGroup(includeSelectAll)}
    </>
  }
  
  const getFormGroups = (step) => {
    switch(step) {
      case 0:
        return getGroups(true)
      case 1:
        return getGroups(false)
      case 2:
        return <>
          {locationsIds.length > 0 ? (
            <>
              <span>Found {response.split('\n').length} duplicates</span>
              <textarea cols="30" rows="10" defaultValue={response}/>
            </>
          ) : (
            <span>Unable to find any duplicate locations</span>
          )}
        </>
      default:
        return `Unrecognized step '${step}' given; Unable to display contents.`;
    }
  }
  
  const handleDeleteLocationsConfirm = (answer) => {
    if (answer === 'Yes') {
      fnDeleteLocations(deleteLocationsConfirm);
      handleClose();
    }
    setDeleteLocationsConfirm();
  };

  const findDuplicateLocations = (finish) => {
    setRunning(true);
    const selectedHeaders = getCheckedHeaders();
    const id = Object.keys(selectedPayloadIds)[0]
    findDuplicates({id, sortName, sortOrder, selectedHeaders}, (data) => {
      const {duplicates} = data;
      
      if (duplicates.length > 0) {
        // temporarily store location ids to delete when user is ready
        setLocationsIds(duplicates.map(d => d.id));
        // set "response text" for previewing the duplicates in a textbox
        setResponse(duplicates.map(d => d.value).join('\r\n'));
      }
      
      setRunning(false);
      finish();
    })
  }
  const handleNext = () => {
    const lastStep = (getSteps().length - 1);

    if (activeStep === (lastStep-1)) {
      findDuplicateLocations(() => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      });
    } else if (activeStep < lastStep) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else if (activeStep === lastStep) {
      if (hasDuplicates()) {
        // update "deleteLocationsConfirm" array so that additional dialog appears
        setDeleteLocationsConfirm(locationsIds)
      } else {
        handleClose();
      }
    } else {
      console.error(`unexpected active step ${activeStep}`)
    }
  }
  
  const handleChange = (event) => {
    if (event.target.value !== 'toggle')
      setHeaders({...headers, [event.target.value]: event.target.checked });
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }
  
  const handleReset = () => {
    setActiveStep(0);
  }
  
  const handleClose = (result) => {
    setOpen(false);
    onClose(result)
  };
  
  return (
    <>
      {
        deleteLocationsConfirm && (
          <Confirm
            text={`Are you sure you want to delete ${deleteLocationsConfirm.length} location${deleteLocationsConfirm.length > 1 ? 's' : ''}?`}
            buttons={['Yes', 'No']}
            focusedButton="Yes"
            onClose={(answer) => handleDeleteLocationsConfirm(answer)}
          />
        )
      }
      <Dialog
        open={open}
        PaperProps={{
          style: {
            width: '30%'
          }
        }}
        onClose={() => handleClose()}
      >
        <DialogTitle>{ title }</DialogTitle>
        <DialogContent>
          <Stepper className={classes.stepper} activeStep={activeStep} orientation='vertical'>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
                <StepContent> 
                  <FormControl disabled={running}>
                    {getFormGroups(index)}
                  </FormControl>
                  <div className={classes.navigationControls}>
                    {activeStep === 1 && 
                      <Button
                        disabled={running || activeStep < 1}
                        onClick={handleBack} 
                      >
                        Back
                      </Button>
                    }
                    <Button
                      variant='contained'
                      color='primary'
                      disabled={running || (!anyHeadersChecked())}
                      onClick={handleNext}
                    >
                      {activeStep === steps.length - 1 ? (locationsIds.length > 0 ? 'Remove Duplicates' : 'Close') : 'Next'}
                    </Button>
                    {activeStep > 1 && (
                      <Button 
                        disabled={activeStep > 2}
                        onClick={handleReset}
                      >
                        Restart
                      </Button>
                    )}
                  </div>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </DialogContent>
        <DialogActions>
          <Button 
            key='cancel' 
            autoFocus={false}
            onClick={
              () => handleClose()
            } 
            color='primary'
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

Dedupe.defaultProps = {
  title: '',
  fnDeleteLocations: deleteLocations,
  onClose: () => {},
  columns: [],
};

Dedupe.propTypes = {
  ...withStylesPropTypes,
  onClose: PropTypes.func,
  fnDeleteLocations: PropTypes.func,
  title: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.object),
};
const mapStateToProps = (state, props) => ({
  selectedPayloadIds: selectSelectedPayloadIds(state, props),
});
export default connect(mapStateToProps, {
  fnDeleteLocations: deleteLocations,
})(withStyles(useStyles)(Dedupe));
