import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Draggable from 'react-draggable';
import { withStyles } from '@material-ui/core/styles';
import { withStylesPropTypes } from '../../../helper/misc';


const useStyles = ((theme) => ({
  drag: {
    display: 'block',
    cursor: 'col-resize',
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100%',
    width: '2px',
    backgroundColor: theme.palette.border.primary,
  },
  dragMove: {
    display: 'block',
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100%',
    width: '100%',
  },
  dragMoveOver: {
    backgroundColor: theme.palette.primary.main,
  },
  selected: {
    backgroundColor: '#9ecede',
  },
}));

function SheetColumn({
  classes, style, moveable, anchorStyle, selected, onResize, onMove, onMoveStart, onMoveEnd, onContextMenu, onDragOver, children, dragOverColumn,
}) {
  const [segment, setSegment] = useState();

  const handleClientRectChange = (ref) => {
    if (ref && moveable) {
      const rect = ref.getBoundingClientRect();
      if (!segment || segment.x !== rect.x || segment.width !== rect.width) {
        setSegment({
          x: rect.x,
          width: rect.width,
        });
      }
    }
  };

  useEffect(() => {
    if (dragOverColumn && segment) {
      const { x } = dragOverColumn;
      if ((x >= segment.x) && (x <= segment.x + segment.width)) {
        onDragOver(dragOverColumn, (x < segment.x + segment.width / 2 ? -1 : 0));
      }
    }
  }, [segment, dragOverColumn, onDragOver]);

  return (
    <div style={style} className={clsx(selected && classes.selected)} onContextMenu={onContextMenu} ref={(ref) => handleClientRectChange(ref)}>
      {
        moveable ? (
          <Draggable
            axis="x"
            defaultClassName={classes.dragMove}
            onDrag={(event, data) => onMove(event, data)}
            onStart={(event, data) => onMoveStart(event, data)}
            onStop={(event, data) => onMoveEnd(event, data)}
            position={{ x: 0 }}
            zIndex={999}
          >
            <div className={classes.dragMove}>
              { children }
            </div>
          </Draggable>
        ) : (
          <div className={classes.dragMove}>
            { children }
          </div>
        )
      }
      <Draggable
        axis="x"
        defaultClassName={classes.drag}
        onDrag={(event, { deltaX }) => onResize(deltaX)}
        position={{ x: 0 }}
        zIndex={999}
      >
        <span className={clsx(classes.drag)} style={anchorStyle} />
      </Draggable>

    </div>
  );
}


SheetColumn.defaultProps = {
  anchorStyle: null,
  moveable: true,
  onResize: () => { },
  onMove: () => { },
  onMoveStart: () => { },
  onMoveEnd: () => { },
  onContextMenu: () => { },
  onDragOver: () => { },
  children: null,
  dragOverColumn: null,
  selected: false,
};

SheetColumn.propTypes = {
  ...withStylesPropTypes,
  moveable: PropTypes.bool,
  anchorStyle: PropTypes.shape(),
  onResize: PropTypes.func,
  onMove: PropTypes.func,
  onMoveStart: PropTypes.func,
  onMoveEnd: PropTypes.func,
  onContextMenu: PropTypes.func,
  onDragOver: PropTypes.func,
  style: PropTypes.shape().isRequired,
  children: PropTypes.node,
  dragOverColumn: PropTypes.shape(),
  selected: PropTypes.bool,
};

export default withStyles(useStyles)(SheetColumn);
