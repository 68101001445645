import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import { withStylesPropTypes } from '../../helper/misc';

const useStyles = (() => ({
}));

function Confirm({
  title,
  text,
  buttons,
  focusedButton,
  onClose,
}) {
  const [open, setOpen] = useState(true);

  const handleClose = (result) => {
    setOpen(false);
    onClose(result);
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
    >
      <DialogTitle>{ title }</DialogTitle>
      <DialogContent>
        <DialogContentText>{ text }</DialogContentText>
      </DialogContent>
      <DialogActions>
        {
            buttons.map((b) => (<Button key={b} autoFocus={focusedButton === b} onClick={() => handleClose(b)} color="primary">{b}</Button>))
        }
      </DialogActions>
    </Dialog>
  );
}

Confirm.defaultProps = {
  title: '',
  text: '',
  buttons: [],
  focusedButton: null,
  onClose: () => {},
};

Confirm.propTypes = {
  ...withStylesPropTypes,
  title: PropTypes.string,
  text: PropTypes.string,
  buttons: PropTypes.arrayOf(PropTypes.string),
  focusedButton: PropTypes.string,
  onClose: PropTypes.func,
};

export default withStyles(useStyles)(Confirm);
