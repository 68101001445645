/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import GoogleMapView from './google/mapview';
import LeafletMapView from './leaflet/mapview';
import { withStylesPropTypes } from '../../../helper/misc';

export const MAP_TYPE = {
  GOOGLE: 'google',
  LEAFLET: 'leaflet',
};

const useStyles = ((theme) => ({
  mapTypeToggle: {
    position: 'absolute',
    bottom: theme.spacing(1),
    left: theme.spacing(1),
    zIndex: 1000,
  },
}));

const MapView = ({
  classes,
  children,
  ...params
}) => {
  const [mapType, setMapType] = useState(MAP_TYPE.LEAFLET);
  let mapComponent;
  const childs = children && React.Children.toArray(children).map((c) => React.cloneElement(c, { mapType }));
  switch (mapType) {
    case MAP_TYPE.GOOGLE:
      mapComponent = <GoogleMapView {...params}>{ childs }</GoogleMapView>;
      break;
    case MAP_TYPE.LEAFLET:
      mapComponent = <LeafletMapView {...params}>{ childs }</LeafletMapView>;
      break;
    default:
      break;
  }

  return (
    <>
      {mapComponent}
      <Box boxShadow={3} className={classes.mapTypeToggle}>
        <ToggleButtonGroup
          value={mapType}
          exclusive
          onChange={(e, value) => setMapType(value)}
          size="small"
        >
          <ToggleButton value={MAP_TYPE.LEAFLET}>OSM</ToggleButton>
          <ToggleButton value={MAP_TYPE.GOOGLE}>Google</ToggleButton>
        </ToggleButtonGroup>
      </Box>
    </>
  );
};

MapView.defaultProps = {
  center: undefined,
  zoom: undefined,
  children: undefined,
  bounds: undefined,
  drawing: undefined,
  onNewPolygon: () => {},
};

MapView.propTypes = {
  ...withStylesPropTypes,
  center: PropTypes.shape(),
  zoom: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  bounds: PropTypes.shape(),
  drawing: PropTypes.shape({
    polygon: PropTypes.bool,
  }),
  onNewPolygon: PropTypes.func,
};

export default withStyles(useStyles)(MapView);
