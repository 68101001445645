import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { createSelector } from '@reduxjs/toolkit';
import { withStylesPropTypes } from '../../helper/misc';
import { setCortexMatchType, setMatchDistance, setMatchFilter } from '../../app/payloadSlice';
import { selectPayloadById } from '../../app/payloadSelectors';
import { setCurrentMatchGroupIndex, setShowCoreColumns } from '../../app/aliMatchesSlice';
import { selectALIMatches, selectCurrentGroupIndex, selectShowCoreColumns } from '../../app/aliMatchesSelectors';
import AliMatching from './alimatching';
import { MATCH_TYPE_DEFAULT, DISTANCE } from '../../const/alimatch';

const useStyles = (() => ({
}));


function ALIMatchingQA({
  payloadId,
  matchFilter,
  cortexMatchType,
  matchDistance,
  currentGroupIndex,
  showCoreColumns,
  aliMatches,
  fnSetCortexMatchType,
  fnSetMatchDistance,
  fnSetCurrentMatchGroupIndex,
  fnSetShowCoreColumns,
  fnSetMatchFilter,
}) {
  const handleMatchFilterChange = useCallback((value) => {
    fnSetMatchFilter({ payloadId, matchFilter: value });
  }, [payloadId, fnSetMatchFilter]);

  const handleCortexMatchTypeChange = useCallback((value) => {
    fnSetCortexMatchType({ payloadId, matchType: value });
  }, [payloadId, fnSetCortexMatchType]);

  const handleMatchDistanceChange = useCallback((value) => {
    fnSetMatchDistance({ payloadId, distance: value });
  }, [payloadId, fnSetMatchDistance]);

  const handleCurrentGroupIndexChange = useCallback((groupIndex) => {
    fnSetCurrentMatchGroupIndex({ groupIndex });
  }, [fnSetCurrentMatchGroupIndex]);

  const handleShowCoreColumnsChange = useCallback((checked) => {
    fnSetShowCoreColumns({ set: checked });
  }, [fnSetShowCoreColumns]);


  return (
    <AliMatching
      hiddenStations={{
        all: true,
        polygon: true,
        ungroup: true,
        merged: true,
      }}
      matchFilter={matchFilter}
      onMatchFilterChange={handleMatchFilterChange}
      cortexMatchType={cortexMatchType}
      onCortextMatchTypeChange={handleCortexMatchTypeChange}
      matchDistance={matchDistance}
      onMatchDistanceChange={handleMatchDistanceChange}
      currentGroupIndex={currentGroupIndex}
      onCurrentGroupIndexChange={handleCurrentGroupIndexChange}
      showCoreColumns={showCoreColumns}
      onShowCoreColumnsChange={handleShowCoreColumnsChange}
      aliMatches={aliMatches}
    />
  );
}

ALIMatchingQA.defaultProps = {
  payloadId: null,
};

ALIMatchingQA.propTypes = {
  ...withStylesPropTypes,
  payloadId: PropTypes.string,
};

const selectPayloadId = (state, props) => props.payloadId;

const selectSelectedListMatchFilter = createSelector(
  [selectPayloadId, selectPayloadById],
  (payloadId, payloadById) => (payloadById[payloadId] || {}).matchFilter || '',
);

const selectSelectedListCortexMatchType = createSelector(
  [selectPayloadId, selectPayloadById],
  (payloadId, payloadById) => (payloadById[payloadId] || {}).cortexMatchType || MATCH_TYPE_DEFAULT,
);

const selectSelectedListMatchDistance = createSelector(
  [selectPayloadId, selectPayloadById],
  (payloadId, payloadById) => (payloadById[payloadId] || {}).matchDistance || DISTANCE.MIN,
);


const mapStateToProps = (state, props) => ({
  matchFilter: selectSelectedListMatchFilter(state, props),
  cortexMatchType: selectSelectedListCortexMatchType(state, props),
  matchDistance: selectSelectedListMatchDistance(state, props),
  aliMatches: selectALIMatches(state, props),
  currentGroupIndex: selectCurrentGroupIndex(state, props),
  showCoreColumns: selectShowCoreColumns(state, props),
});

export default connect(mapStateToProps, {
  fnSetCortexMatchType: setCortexMatchType,
  fnSetMatchDistance: setMatchDistance,
  fnSetCurrentMatchGroupIndex: setCurrentMatchGroupIndex,
  fnSetShowCoreColumns: setShowCoreColumns,
  fnSetMatchFilter: setMatchFilter,
})(withStyles(useStyles)(ALIMatchingQA));
