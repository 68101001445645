import { createSlice } from '@reduxjs/toolkit';
import api from '../api/alimatches';
import normalize from '../helper/normalize';
import { compareSimpleArrays } from '../helper/misc';
import { updateLocationsSuccess } from './locationsSlice';
import { updatePayload } from './payloadSlice';
import { batchStatusListener } from '../api/socketclient';
import { GROUP_BY } from '../const/alimatch';
import { setLoadingProgress, fetchMultiPage } from './helper';

const removeSingleRecordGroups = (listData) => {
  const groupCounts = {};
  listData.allIds.forEach((id) => {
    const { group } = listData.byId[id];
    if (!groupCounts[group]) groupCounts[group] = 1;
    else groupCounts[group] += 1;
  });
  const groupsToRemove = {};
  Object.keys(groupCounts).forEach((group) => {
    if (groupCounts[group] === 1) groupsToRemove[group] = true;
  });
  if (Object.keys(groupsToRemove).length) {
    listData.allIds = listData.allIds.filter((id) => !groupsToRemove[listData.byId[id].group]);
  }
};

export const slice = createSlice({
  name: 'aliMatches',
  initialState: {
    params: null,
    isLoading: false,
    isSaving: false,
    loadingProgress: null,
    error: false,
    selectedIds: {},
    lockedListIds: {},
    mergingLogs: {}
  },
  reducers: {
    getALIMatchesStart: (state) => {
      state.isLoading = true;
      state.error = null;
      state.loadingProgress = null;
    },
    getALIMatchesProgress: setLoadingProgress,
    getALIOpeningsAndClosings: (state, { payload }) => {
      const {
        locations, matchFilter, listId, payloadId, matchingId, csvFileUrl
      } = payload;
      let { params } = state;
      if (!params) {
        params = {};
        state.params = params;
      }
      const [allIds, byId] = normalize(locations, '_id');
      debugger;
      if (params.matchFilter !== matchFilter
        || (listId && params.listId !== listId)
        || (payloadId && params.payloadId !== payloadId)
        || (matchingId !== params.matchingId)) {
        params.matchFilter = matchFilter;
        params.listId = listId;
        params.payloadId = payloadId;
        params.matchingId = matchingId;
        params.allIds = [];
        params.byId = {};
        params.allIds.push(...allIds);
        params.byId = { ...params.byId, ...byId };
        params.openingsAndClosingsCsvFileUrl = csvFileUrl;
      }
      setLoadingProgress(state, { payload });
    },
    getMergedMatches: (state, { payload }) => {
      const {
        locations, matchFilter, listId, payloadId, matchingId,
      } = payload;
      let { params } = state;
      if (!params) {
        params = {};
        state.params = params;
      }
      const [allIds, byId] = normalize(locations, '_id');
      if (params.matchFilter !== matchFilter
        || (listId && params.listId !== listId)
        || (payloadId && params.payloadId !== payloadId)
        || (matchingId !== params.matchingId)
      ) {
        params.matchFilter = matchFilter;
        params.listId = listId;
        params.payloadId = payloadId;
        params.matchingId = matchingId;
        params.allIds = [];
        params.byId = {};
        params.allIds.push(...allIds);
        params.byId = { ...params.byId, ...byId };
      }
      setLoadingProgress(state, { payload });
    },

    getALIMatchesNextPage: (state, { payload }) => {
      const {
        locations, matchFilter, matchingId, cortexMatchType, customFields, matchDistance, listId, payloadId,
      } = payload;
      let { params } = state;
      if (!params) {
        params = {};
        state.params = params;
      }
      const [allIds, byId] = normalize(locations, '_id');
      if (params.matchFilter !== matchFilter
        || params.matchingId !== matchingId
        || (listId && params.listId !== listId)
        || (payloadId && params.payloadId !== payloadId)
        || (cortexMatchType && params.cortexMatchType !== cortexMatchType)
        || (customFields && !compareSimpleArrays(params.customFields, customFields))
        || (matchDistance && params.matchDistance !== matchDistance)) {
        params.matchFilter = matchFilter;
        params.matchingId = matchingId;
        params.listId = listId;
        params.payloadId = payloadId;
        params.cortexMatchType = cortexMatchType;
        params.customFields = customFields;
        params.matchDistance = matchDistance;
        params.allIds = [];
        params.byId = {};
      }
      params.allIds.push(...allIds);
      params.byId = { ...params.byId, ...byId };
      setLoadingProgress(state, { payload });
    },
    getALIMatchesSuccess: (state) => {
      state.error = null;
      state.isLoading = false;
    },
    getALIMatchesFailed: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload.error;
    },
    setCortexAutoMergeNotification: (state, { payload }) => {
      const {
        matchingId, matchingScore, remaining, set = true,
      } = payload;
      const { params } = state;
      if (params && params.matchingId === matchingId) {
        if (set) params.cortexAutoMerge = { matchingId, matchingScore, remaining, showAutoMergeButton: true };
        else delete params.cortexAutoMerge;
      }
    },
    setCurrentMatchGroupIndex: (state, { payload }) => {
      const { groupIndex } = payload;
      const { params } = state;
      if (params) params.groupIndex = groupIndex;
    },
    setShowCoreColumns: (state, { payload }) => {
      const { set } = payload;
      const { params } = state;
      if (params) {
        if (set) params.showCoreColumns = true;
        else delete params.showCoreColumns;
      }
    },
    mergeMatchesStart: (state) => {
      state.isSaving = true;
      state.error = null;
    },
    mergeMatchesSuccess: (state, { payload }) => {
      const {
        alis, targetAli,
      } = payload;
      state.isSaving = false;
      state.error = null;
      const { params } = state;
      if (params) {
        const alisMap = alis.reduce((m, a) => { m[a] = true; return m; }, {});

        const locations = params.allIds.map((id) => params.byId[id]).filter((l) => alisMap[l.ali]);
        let scrapedAt;
        let lastScrapedAt;
        locations.forEach((l) => {
          if (!scrapedAt || scrapedAt > l.scrapedAt) scrapedAt = l.scrapedAt;
          if (!lastScrapedAt || lastScrapedAt < l.lastScrapedAt) lastScrapedAt = l.lastScrapedAt;
        });

        if (scrapedAt && lastScrapedAt) {
          const targetLocation = locations.find((l) => l.ali === targetAli);
          if (targetLocation) {
            targetLocation.scrapedAt = scrapedAt;
            targetLocation.lastScrapedAt = lastScrapedAt;
          }
        }

        params.allIds = params.allIds.filter((id) => {
          const { ali } = params.byId[id];
          return ali === targetAli || !alisMap[ali];
        });
        removeSingleRecordGroups(params);
      }
    },
    mergeMatchesFailed: (state, { payload }) => {
      state.isSaving = true;
      state.error = payload.error;
    },
    unmergeSuccess: (state, { payload }) => {
      const {
        result, currentAli, ids,
      } = payload;
      state.isSaving = false;
      state.error = null;

      const alisMap = {};
      if (result) {
        if (result.originalAlis && result.currentAli) {
          [...result.originalAlis, result.currentAli].forEach((l) => {
            alisMap[l.ali] = l;
          });
        }
      }

      const { params } = state;
      if (params) {
        if (ids) {
          params.allIds = ids.filter((id) => params.byId[id]);
        } else if (params.matchFilter === GROUP_BY.MERGED) {
          params.allIds = params.allIds.filter((id) => {
            const { originalAli, ali } = params.byId[id];
            return !(originalAli && alisMap[originalAli] && ali === currentAli);
          });
          removeSingleRecordGroups(params);
        }
        Object.keys(alisMap).forEach((ali) => {
          const locationId = params.allIds.find((id) => params.byId[id].ali === ali);
          if (locationId) {
            const updatedLocation = alisMap[ali];
            const existingLocation = params.byId[locationId];
            existingLocation.scrapedAt = updatedLocation.scrapedAt;
            existingLocation.lastScrapedAt = updatedLocation.lastScrapedAt;
          }
        });
      }
    },
    autoMergeSuccess: (state) => {
      state.params = null;
      state.isSaving = false;
      state.error = null;
    },
    appendLocationAliStart: (state) => {
      state.isSaving = true;
      state.error = null;
    },
    appendLocationAliSuccess: (state, { payload }) => {
      const { locations } = payload;
      state.isSaving = false;
      state.error = null;
      const { params } = state;
      if (params && params.matchFilter === GROUP_BY.OPENING_CLOSING) {
        params.matchingId = (new Date()).getTime().toString();
        return;
      }

      if (params) {
        const { allIds, byId } = params;
        const locationIdMap = allIds.reduce((map, id) => {
          const location = byId[id];
          const { locationId } = location;
          if (locationId) map[locationId] = location;
          return map;
        }, {});

        const groupMap = locations.reduce((map, l) => {
          const { group } = locationIdMap[l._id];
          if (group) map[group] = true;
          return map;
        }, {});

        params.allIds = allIds.filter((id) => !groupMap[byId[id].group]);
      }
    },
    appendLocationAliFailed: (state, { payload }) => {
      state.isSaving = true;
      state.error = payload.error;
    },
    autoAppendLocationAliSuccess: (state) => {
      state.params = null;
      state.isSaving = false;
      state.error = null;
    },
    resetALIMatchSelection: (state) => {
      state.selectedIds = {};
    },
    setALIMatchSelected: (state, { payload }) => {
      if (payload.select !== undefined && !payload.select) delete state.selectedIds[payload.id];
      else state.selectedIds[payload.id] = true;
    },
    mergingLogsStart: (state) => {
      state.isLoading = true;
      state.error = null;
      state.mergingLogsDownloadUrl = null;
    },
    mergingLogsSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.error = null;
      state.mergingLogsDownloadUrl = payload.downloadPath;
    },
    mergingLogsFailed: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload.error;
      state.mergingLogsDownloadUrl = null;
    },
  },
});

export const {
  getALIMatchesStart,
  getALIMatchesProgress,
  getALIMatchesNextPage,
  getALIMatchesSuccess,
  getALIMatchesFailed,
  getALIOpeningsAndClosings,
  getMergedMatches,
  setCortexAutoMergeNotification,
  setCurrentMatchGroupIndex,
  setShowCoreColumns,
  mergeMatchesStart,
  mergeMatchesSuccess,
  mergeMatchesFailed,
  unmergeSuccess,
  autoMergeSuccess,
  appendLocationAliStart,
  appendLocationAliSuccess,
  appendLocationAliFailed,
  autoAppendLocationAliSuccess,
  resetALIMatchSelection,
  setALIMatchSelected,
  mergingLogsStart,
  mergingLogsSuccess,
  mergingLogsFailed,
} = slice.actions;

export default slice.reducer;

export const fetchALIMatches = (listId, matchType, customFields) => (dispatch) => {
  const matchingId = (new Date()).getTime().toString();
  dispatch(getALIMatchesStart());

  batchStatusListener(
    'aliMatchingUpdated',
    { listId, matchingId },
    () => {
      fetchMultiPage(
        (page) => api.fetchMatchesByMatchingId(matchingId, page),
        null,
        (result) => dispatch(getALIMatchesNextPage({
          listId, matchFilter: GROUP_BY.CORTEX, matchingId, cortexMatchType: matchType, customFields, locations: result.docs, totalPages: result.totalPages, page: result.page,
        })),
        () => dispatch(getALIMatchesSuccess()),
        (error) => dispatch(getALIMatchesFailed({ error })),
      );
    },
    (error) => {
      dispatch(getALIMatchesFailed({ error }));
    },
  );
  api.findMatchesByListId(listId, matchingId, matchType, customFields).catch((error) => {
    dispatch(getALIMatchesFailed({ error }));
  });
};

export const fetchALIMatchesByPayloadId = (payloadId, matchType, customFields) => (dispatch) => {
  const matchingId = (new Date()).getTime().toString();
  dispatch(getALIMatchesStart());

  batchStatusListener(
    'aliMatchingUpdated',
    { payloadId, matchingId },
    () => {
      fetchMultiPage(
        (page) => api.fetchMatchesByMatchingId(matchingId, page),
        null,
        (result) => dispatch(getALIMatchesNextPage({
          payloadId, matchFilter: GROUP_BY.CORTEX, matchingId, cortexMatchType: matchType, customFields, locations: result.docs, totalPages: result.totalPages, page: result.page,
        })),
        () => dispatch(getALIMatchesSuccess()),
        (error) => dispatch(getALIMatchesFailed({ error })),
      );
    },
    (error) => {
      dispatch(getALIMatchesFailed({ error }));
    },
  );
  api.findMatchesByPayloadId(payloadId, matchingId, matchType, customFields).catch((error) => {
    dispatch(getALIMatchesFailed({ error }));
  });
};

export const fetchOpeningsAndClosingsByPayloadId = (payloadId) => (dispatch) => {
  dispatch(getALIMatchesStart());
  api.openingsAndClosings(payloadId).then((result) => {
    dispatch(getALIOpeningsAndClosings({
      payloadId, matchFilter: GROUP_BY.OPENING_CLOSING, locations: result.locations, csvFileUrl: result.csvFileUrl
    }));
    dispatch(getALIMatchesSuccess());
  })
    .catch((error) => {
      dispatch(getALIMatchesFailed({ error }));
    });
};

export const fetchMergedByPayloadId = (payloadId) => (dispatch) => {
  dispatch(getALIMatchesStart());
  api.fetchMergedMatches(payloadId).then((result) => {
    const matchingId = result.length;
    dispatch(getMergedMatches({
      payloadId, matchFilter: GROUP_BY.MERGED, locations: result, matchingId,
    }));
    dispatch(getALIMatchesSuccess());
  })
    .catch((error) => {
      dispatch(getALIMatchesFailed({ error }));
    });
};

export const unmergeLocationAli = (mergedMatchesIds) => (dispatch) => {
  dispatch(getALIMatchesStart());
  api.unmergeLocationAli(mergedMatchesIds).then((result) => {
    const { payload, locations } = result;
    const matchingId = locations.length;
    dispatch(getMergedMatches({
      payloadId: payload._id, matchFilter: GROUP_BY.MERGED, locations, matchingId,
    }));
    dispatch(updatePayload(payload));
    dispatch(getALIMatchesSuccess());
  })
    .catch((error) => {
      dispatch(getALIMatchesFailed({ error }));
    });
};

export const fetchALIMatchesByDistance = (listId, distance) => (dispatch) => {
  const matchingId = (new Date()).getTime().toString();
  dispatch(getALIMatchesStart());
  batchStatusListener(
    'aliMatchingUpdated',
    { listId, matchingId },
    () => {
      fetchMultiPage(
        (page) => api.fetchMatchesByMatchingId(matchingId, page),
        null,
        (result) => dispatch(getALIMatchesNextPage({
          listId, matchingId, matchFilter: GROUP_BY.DISTANCE, matchDistance: distance, locations: result.docs, totalPages: result.totalPages, page: result.page,
        })),
        () => dispatch(getALIMatchesSuccess()),
        (error) => dispatch(getALIMatchesFailed({ error })),
      );
    },
    (error) => {
      dispatch(getALIMatchesFailed({ error }));
    },
  );
  api.findMatchesByDistance(listId, matchingId, distance).catch((error) => {
    dispatch(getALIMatchesFailed({ error }));
  });
};

export const fetchALIMatchesByPayloadIdDistance = (payloadId, distance) => (dispatch) => {
  const matchingId = (new Date()).getTime().toString();
  dispatch(getALIMatchesStart());
  batchStatusListener(
    'aliMatchingUpdated',
    { payloadId, matchingId },
    () => {
      fetchMultiPage(
        (page) => api.fetchMatchesByMatchingId(matchingId, page),
        null,
        (result) => dispatch(getALIMatchesNextPage({
          payloadId, matchingId, matchFilter: GROUP_BY.DISTANCE, matchDistance: distance, locations: result.docs, totalPages: result.totalPages, page: result.page,
        })),
        () => dispatch(getALIMatchesSuccess()),
        (error) => dispatch(getALIMatchesFailed({ error })),
      );
    },
    (error) => {
      dispatch(getALIMatchesFailed({ error }));
    },
  );
  api.findMatchesByPayloadIdDistance(payloadId, matchingId, distance).catch((error) => {
    dispatch(getALIMatchesFailed({ error }));
  });
};

const getGroupByPath = (groupBy) => {
  let groupByPath;
  switch (groupBy) {
    case GROUP_BY.PHONE: groupByPath = 'byPhone'; break;
    case GROUP_BY.STORE_NUMBER: groupByPath = 'byStoreNumber'; break;
    default: break;
  }
  return groupByPath;
};

export const fetchAll = (listId, groupBy) => (dispatch) => {
  const groupByPath = getGroupByPath(groupBy);
  const matchingId = (new Date()).getTime().toString();
  dispatch(getALIMatchesStart());
  fetchMultiPage(
    (page) => api.fetchAllByListId(listId, false, groupByPath, page),
    null,
    (result) => dispatch(getALIMatchesNextPage({
      listId, matchingId, matchFilter: groupBy, locations: result.docs, totalPages: result.totalPages, page: result.page,
    })),
    () => dispatch(getALIMatchesSuccess()),
    (error) => dispatch(getALIMatchesFailed({ error })),
  );
};

export const fetchALIMatchesByPayloadIdField = (payloadId, groupBy) => (dispatch) => {
  const matchingId = (new Date()).getTime().toString();
  dispatch(getALIMatchesStart());
  batchStatusListener(
    'aliMatchingUpdated',
    { payloadId, matchingId },
    () => {
      fetchMultiPage(
        (page) => api.fetchMatchesByMatchingId(matchingId, page),
        null,
        (result) => dispatch(getALIMatchesNextPage({
          payloadId, matchingId, matchFilter: groupBy, locations: result.docs, totalPages: result.totalPages, page: result.page,
        })),
        () => dispatch(getALIMatchesSuccess()),
        (error) => dispatch(getALIMatchesFailed({ error })),
      );
    },
    (error) => {
      dispatch(getALIMatchesFailed({ error }));
    },
  );
  api.findMatchesByPayloadIdField(payloadId, matchingId, getGroupByPath(groupBy)).catch((error) => {
    dispatch(getALIMatchesFailed({ error }));
  });
};

export const fetchAllMerged = (listId) => (dispatch) => {
  const matchingId = (new Date()).getTime().toString();
  dispatch(getALIMatchesStart());
  fetchMultiPage(
    (page) => api.fetchAllMergedByListId(listId, page),
    null,
    (result) => dispatch(getALIMatchesNextPage({
      listId, matchingId, matchFilter: GROUP_BY.MERGED, locations: result.docs.map((d) => ({ ...d, group: d.ali })), totalPages: result.totalPages, page: result.page,
    })),
    () => dispatch(getALIMatchesSuccess()),
    (error) => dispatch(getALIMatchesFailed({ error })),
  );
};

export const mergeMatches = (alis, targetAli, listId, groupBy, matchingId) => (dispatch) => {
  dispatch(mergeMatchesStart());
  dispatch(resetALIMatchSelection());
  api.mergeMatches(alis, targetAli, listId, groupBy, matchingId).then((result) => {
    dispatch(mergeMatchesSuccess({
      targetAli, alis: result,
    }));
  }).catch((error) => {
    dispatch(mergeMatchesFailed({ error }));
  });
};

export const unmerge = (currentAli, originalAlis, listId, ids) => (dispatch) => {
  dispatch(mergeMatchesStart());
  dispatch(resetALIMatchSelection());
  api.unmerge(currentAli, originalAlis, listId).then((result) => {
    dispatch(unmergeSuccess({
      currentAli, result, ids,
    }));
  }).catch((error) => {
    dispatch(mergeMatchesFailed({ error }));
  });
};

export const autoMerge = (listId, matchingId, mergedAtStation) => (dispatch) => {
  dispatch(mergeMatchesStart());
  dispatch(resetALIMatchSelection());
  dispatch(setCortexAutoMergeNotification({ matchingId, set: false }));
  api.autoMerge(listId, matchingId, mergedAtStation).then(() => {
    batchStatusListener(
      'cortexAutoMergeCompleteUpdated',
      { matchingId },
      () => dispatch(autoMergeSuccess()),
      (error) => dispatch(mergeMatchesFailed({ error })),
    );
  }).catch((error) => {
    dispatch(mergeMatchesFailed({ error }));
  });
};


export const appendLocationAli = (ali, locationIds, subStation, matchingId, aliMatchesIds, payloadId) => (dispatch) => {
  dispatch(appendLocationAliStart());
  dispatch(resetALIMatchSelection());
  api.appendLocationAli(ali, locationIds, subStation, matchingId, aliMatchesIds, payloadId).then((result) => {
    const { payload, locations } = result;
    dispatch(appendLocationAliSuccess({ locations }));
    dispatch(updateLocationsSuccess({ updated: locations }));
    dispatch(updatePayload(payload));
  }).catch((error) => {
    dispatch(appendLocationAliFailed({ error }));
  });
};

export const autoAppendAlis = (matchingId, subStation) => (dispatch) => {
  dispatch(appendLocationAliStart());
  dispatch(resetALIMatchSelection());
  api.autoAppendAlis(matchingId, subStation).then(() => {
    batchStatusListener(
      'aliAutoAppendCompleteUpdated',
      { matchingId },
      () => dispatch(autoAppendLocationAliSuccess()),
      (error) => dispatch(appendLocationAliFailed({ error })),
    );
  }).catch((error) => {
    dispatch(appendLocationAliFailed({ error }));
  });
};

export const mergingLogs = (fromDate, toDate) => (dispatch) => {
  dispatch(mergingLogsStart());
  api.mergingLogs(fromDate, toDate).then((result) => {
    dispatch(mergingLogsSuccess({ downloadPath: result.downloadPath}));
  }).catch((error) => {
    dispatch(appendLocationAliFailed({ error }));
  });
};
