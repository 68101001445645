import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withStylesPropTypes } from '../../../helper/misc';
import { equalStyle } from './cellsstyle';
import ColorPicker from './colorpicker';

const useStyles = ((theme) => ({
  colorOption: {
    height: theme.spacing(4),
    margin: `${theme.spacing(1)}px 0`,
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: `${theme.spacing(1.5)}px`,
    paddingBottom: `${theme.spacing(1.5)}px`,
  },
  textColor: {
    position: 'absolute',
    zIndex: 2,
  },
  backgroundColor: {
    position: 'absolute',
    zIndex: 1,
    marginLeft: `${theme.spacing(1.5)}px`,
    marginTop: `${theme.spacing(1.5)}px`,
  },
  shortcutsBar: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  shortcut: {
    marginLeft: theme.spacing(0.5),
    flexGrow: 1,
    flexBasis: 0,
    padding: theme.spacing(1),
    border: `solid 2px ${theme.palette.border.primary}`,
    outline: 'none',
    cursor: 'pointer',
    '&:first-child': {
      marginLeft: 0,
    },
  },
  customBar: {
    textAlign: 'center',
  },
}));

export const StyleShortcuts = [
  { title: 'Bad', style: { color: '#9e0000', backgroundColor: '#f98989' } },
  { title: 'Good', style: { color: '#005500', backgroundColor: '#99eebb' } },
  { title: 'Neutral', style: { color: '#747433', backgroundColor: '#fcfcdd' } },
];

function CellFormatOptions({
  classes,
  style,
  defaultStyle,
  onChange,
}) {
  const [currentStyle, setCurrentStyle] = useState(style);
  const [customStyle, setCustomStyle] = useState({ color: defaultStyle.backgroundColor, backgroundColor: defaultStyle.color });

  const shortcuts = [
    { title: 'Normal', style: { ...defaultStyle } },
    ...StyleShortcuts,
    { title: 'Custom', style: customStyle },
  ];

  useEffect(() => {
    setCurrentStyle(style);
    if (!equalStyle(style, defaultStyle)) {
      if (!StyleShortcuts.some((s) => equalStyle(s.style, style))) {
        setCustomStyle({ ...style });
      }
    }
  }, [style, defaultStyle]);

  const handleTextColorChange = (color) => {
    setCustomStyle({ ...customStyle, color });
    const changes = { ...currentStyle, color };
    setCurrentStyle(changes);
    onChange(changes);
  };

  const handleBackgroundColorChange = (backgroundColor) => {
    setCustomStyle({ ...customStyle, backgroundColor });
    const changes = { ...currentStyle, backgroundColor };
    setCurrentStyle(changes);
    onChange(changes);
  };

  const handleShortcutStyleSelect = (shortcutStyle) => {
    const newStyle = { ...shortcutStyle };
    setCurrentStyle(newStyle);
    onChange(newStyle);
  };

  const getShortcutStyle = (shortcut) => {
    const ret = { ...shortcut.style };
    if (equalStyle(ret, currentStyle)) ret.borderColor = ret.color;
    return ret;
  };

  return (
    <>
      <div className={classes.shortcutsBar}>
        {
        shortcuts.map((s) => (
          <button type="button" key={s.title} style={getShortcutStyle(s)} className={classes.shortcut} onClick={() => handleShortcutStyleSelect(s.style)}>{s.title}</button>
        ))
        }
      </div>
      {
        equalStyle(currentStyle, customStyle) && (
          <>
            <div className={classes.colorOption}>
              <div className={classes.textColor}>
                <ColorPicker
                  mode="border"
                  color={currentStyle.color || defaultStyle.color}
                  defaultColor={defaultStyle.color}
                  onChange={handleTextColorChange}
                />
              </div>
              <div className={classes.backgroundColor}>
                <ColorPicker
                  mode="border"
                  color={currentStyle.backgroundColor || defaultStyle.backgroundColor}
                  defaultColor={defaultStyle.backgroundColor}
                  onChange={handleBackgroundColorChange}
                />
              </div>
            </div>
          </>
        )
      }
    </>
  );
}

CellFormatOptions.defaultProps = {
  style: {},
  onChange: () => {},
};

CellFormatOptions.propTypes = {
  ...withStylesPropTypes,
  style: PropTypes.shape(),
  defaultStyle: PropTypes.shape().isRequired,
  onChange: PropTypes.func,
};

export default withStyles(useStyles)(CellFormatOptions);
