import client from './client';
import { error } from '../helper/misc';

const fetchPayloads = (listId) => new Promise((resolve, reject) => {
  client.get(`lists/${listId}/payloads`)
    .then((result) => resolve(result.data))
    .catch((err) => reject(error(err)));
});

const fetchPayloadHistory = (payloadId) => new Promise((resolve, reject) => {
  client.get(`payloads/${payloadId}/history`)
    .then((result) => resolve(result.data))
    .catch((err) => reject(error(err)));
});

const fetchPayloadHistoryLatest = (payloadId) => new Promise((resolve, reject) => {
  client.get(`payloads/${payloadId}/historyOne`)
    .then((result) => resolve(result.data))
    .catch((err) => reject(error(err)));
});

const restorePayloadHistory = (payloadId, batchId) => new Promise((resolve, reject) => {
  client.post(`payloads/${payloadId}/restore/${batchId}`)
    .then((result) => resolve(result.data))
    .catch((err) => reject(error(err)));
});

const logCsvDownload = (payloadId, fileName) => new Promise((resolve, reject) => {
  client.post(`payloads/${payloadId}/downloadCsv`, { fileName })
    .then((result) => resolve(result.data))
    .catch((err) => reject(error(err)));
});

const storePayloadAutoFields = (payloadId, autoFields) => new Promise((resolve, reject) => {
  client.post(`payloads/${payloadId}/autoFields`, autoFields)
    .then((result) => resolve(result.data))
    .catch((err) => reject(error(err)));
});

const storePayloadFlags = (payloadId, flags) => new Promise((resolve, reject) => {
  client.post(`payloads/${payloadId}/flags`, flags)
    .then((result) => resolve(result.data))
    .catch((err) => reject(error(err)));
});

const fetchStandardHeaders = (payloadId) => new Promise((resolve, reject) => {
  client.get(`payloads/${payloadId}/standardizedHeaders`)
    .then((result) => resolve(result.data))
    .catch((err) => reject(error(err)));
});

const renameHeader = (payloadId, fromName, toName, approve) => new Promise((resolve, reject) => {
  client.post(`payloads/${payloadId}/renameHeader`, { fromName, toName, approve })
    .then((result) => resolve(result.data))
    .catch((err) => reject(error(err)));
});

const addHeader = (payloadId, name, index) => new Promise((resolve, reject) => {
  client.post(`payloads/${payloadId}/addHeader`, { name, index })
    .then((result) => resolve(result.data))
    .catch((err) => reject(error(err)));
});

const deleteHeader = (payloadId, names) => new Promise((resolve, reject) => {
  client.post(`payloads/${payloadId}/deleteHeader`, { names })
    .then((result) => resolve(result.data))
    .catch((err) => reject(error(err)));
});

const pushHeader = (payloadId, names) => new Promise((resolve, reject) => {
  client.post(`payloads/${payloadId}/approveHeader`, { names })
    .then((result) => resolve(result.data))
    .catch((err) => reject(error(err)));
});

const publishQAE = (payloadId) => new Promise((resolve, reject) => {
  client.get(`payloads/${payloadId}/uploadToQae`)
    .then((result) => resolve(result.data))
    .catch((err) => reject(error(err)));
});

const approve = (payloadId, station, subStation) => new Promise((resolve, reject) => {
  client.post(`payloads/${payloadId}/approve`, { station, subStation })
    .then((result) => resolve(result.data))
    .catch((err) => reject(error(err)));
});

export default {
  fetchPayloads,
  fetchPayloadHistory,
  fetchPayloadHistoryLatest,
  restorePayloadHistory,
  logCsvDownload,
  storePayloadAutoFields,
  storePayloadFlags,
  fetchStandardHeaders,
  renameHeader,
  addHeader,
  deleteHeader,
  pushHeader,
  publishQAE,
  approve,
};
