import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { withStylesPropTypes } from '../../helper/misc';
import EllipsisTooltip from './ellipsistooltip';

const useStyles = ((theme) => ({
  root: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.main,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  block: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    padding: theme.spacing(2),
    zIndex: 10000,
    height: 'unset !important',
  },
  ellipsis: {
    maxWidth: '100%',
    overflow: 'hidden',
  },
}));

function Error({
  classes, message, inline, ellipsis,
}) {
  return (
    <div className={clsx(classes.root, !inline && classes.block, ellipsis && classes.ellipsis)}>
      {ellipsis ? (
        <EllipsisTooltip text={message} />
      )
        : <>{message}</>}
    </div>
  );
}

Error.defaultProps = {
  inline: false,
  ellipsis: false,
};

Error.propTypes = {
  ...withStylesPropTypes,
  inline: PropTypes.bool,
  ellipsis: PropTypes.bool,
  message: PropTypes.string.isRequired,
};

export default withStyles(useStyles)(Error);
