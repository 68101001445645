import client from './client';
import { error } from '../helper/misc';

const geocode = (address, country) => new Promise((resolve, reject) => {
  let url = `geocoder?address=${encodeURI(address)}`;
  if (country) url += `&country=${encodeURI(country)}`;
  client.get(url)
    .then((result) => resolve(result.data))
    .catch((err) => reject(error(err)));
});

export default { geocode };
